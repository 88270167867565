/**
 * @prettier
 */
import * as React from 'react';

export function StoreOpenIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M0 3.6375C0 3.46641 0.0512373 3.3 0.147262 3.15703L1.94549 0.500859C2.1573 0.187992 2.51308 0 2.89623 0H12.3345C12.7177 0 13.0723 0.187992 13.2841 0.500859L15.0832 3.15703C15.1784 3.3 15.2308 3.46641 15.2308 3.6375C15.2308 4.11328 14.8381 4.5 14.355 4.5H0.876721C0.392668 4.5 7.13942e-06 4.11328 7.13942e-06 3.6375H0ZM1.52308 5.25H3.04615V9H7.61538V5.25H9.13846V10.875C9.13846 11.4961 8.6268 12 7.99615 12H2.66538C2.0345 12 1.52308 11.4961 1.52308 10.875V5.25ZM12.1846 5.25H13.7077V11.25C13.7077 11.6648 13.3674 12 12.9462 12C12.5249 12 12.1846 11.6648 12.1846 11.25V5.25Z'
                fill={color}
            />
        </svg>
    );
}
type Props = {
    color: string;
};
