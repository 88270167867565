/**
 * @prettier
 */
import { ButtonBase } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { BottomDialog } from 'src/components/BottomDialog';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { ClosedMenuIcon } from 'src/icons/ClosedMenuIcon';
import { ComingSoonIcon } from 'src/icons/ComingSoonIcon';
import { actions } from 'src/reducers';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantClosedDialog(): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();

    const [titleText, setTitleText] = useState('');
    const [bodyText, setBodyText] = useState('');

    const open = useSelector((state) => state.app.restaurantClosedDialog.open);
    const temporarilyOffline = useSelector((state) => state.app.restaurant?.temporarilyOffline);
    const temporarilyWithoutDelivery = useSelector((state) => state.app.restaurant?.temporarilyWithoutDelivery);
    const comingSoon = useSelector((state) => state.app.restaurant?.comingSoon);
    const name = useSelector((state) => state.app.restaurant?.name);

    const closeRestaurantClosedDialog = useAction(actions.closeRestaurantClosedDialog);

    useEffect(() => {
        loadContentDialog();
    }, [temporarilyOffline, comingSoon, temporarilyWithoutDelivery, open]);

    const loadContentDialog = () => {
        if (temporarilyOffline) {
            setTitleText(translate('Temporarily offline'));
            setBodyText(translate('@name is temporarily offline', { name }));
            return;
        }

        if (temporarilyWithoutDelivery) {
            setTitleText(translate('Temporarily without delivery'));
            setBodyText(translate('@name is temporarily without delivery', { name }));
            return;
        }

        if (comingSoon) {
            setTitleText(translate('Coming soon'));
            setBodyText(translate('We are working so that you can soon order from @name online', { name }));
            return;
        }

        setTitleText(translate('Closed'));
        setBodyText(translate('The store is currently closed, but you can still see the menu and check the hours.'));
    };

    const handleCloseDialog = () => {
        if (comingSoon) return;
        closeRestaurantClosedDialog();
    };

    return (
        <BottomDialog open={open} onClickBackground={handleCloseDialog} onClickHardwareBack={handleCloseDialog}>
            <div className={classes.closedContainer}>
                <div className={classes.backgroundIcon}>
                    {comingSoon && <ComingSoonIcon color={theme.palette.primary.main} />}
                    {!comingSoon && <ClosedMenuIcon color={theme.palette.primary.main} />}
                </div>
                <Text className={classes.titleClosed}>{titleText}</Text>
                <Text className={classes.closedText}>{bodyText}</Text>
            </div>

            <div className={classes.footerContainer}>
                <ButtonBase onClick={handleCloseDialog}>
                    {!comingSoon && (
                        <div className={classes.buttonTextContainer}>
                            <Text className={classes.buttonTextCloseDialog}>{translate('View menu')}</Text>
                        </div>
                    )}
                </ButtonBase>
            </div>
        </BottomDialog>
    );
}

const useStyles = makeStyles((theme) => ({
    closedContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(7),
        gap: theme.spacing(2),
    },

    footerContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    buttonTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1.5),
        paddingInline: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    titleClosed: {
        fontWeight: 'bold',
        padding: theme.spacing(2),
        fontSize: 20,
        textAlign: 'center',
    },

    buttonTextCloseDialog: {
        color: 'white',
        fontSize: 18,
    },

    closedText: {
        textAlign: 'center',

        marginBottom: '10px',
    },
    backgroundIcon: {
        backgroundColor: `${theme.palette.primary.main}40`,
        padding: theme.spacing(3),
        borderRadius: theme.spacing(6),
    },
}));
