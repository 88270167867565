/**
 * @prettier
 */

export function getInLocalStorage<T>(key: string): T | undefined {
    const jsonString = window.localStorage.getItem(key);
    try {
        return jsonString ? JSON.parse(jsonString) : undefined;
    } catch (e) {
        console.log(`Failed to parse localStorage key ${key} value ${jsonString} as json, returning undefined`);
        return undefined;
    }
}
