/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Text } from 'src/components/Text';
import { CardBrands } from 'src/constants/CardBrand';
import { translate } from 'src/i18n/translate';
import { CardIcon } from 'src/icons/CardIcon';
import amex from 'src/images/card/amex.png';
import mastercard from 'src/images/card/mastercard.png';
import sodexo from 'src/images/card/sodexo.png';
import visa from 'src/images/card/visa.png';
import type { CardVm } from 'src/types/CardVm';
import { isKnownCard } from 'src/utils/card/isKnownCard';
import { upperCase } from 'src/utils/transform/upperCase';

export function CardInfo({ card }: Props): React.ReactElement {
    const classes = useStyles();

    const getCardImage = () => {
        switch (upperCase(card.brand)) {
            case CardBrands.VISA:
                return visa;
            case CardBrands.MASTERCARD:
                return mastercard;
            case CardBrands.AMERICAN_EXPRESS:
                return amex;
            case CardBrands.SODEXO:
                return sodexo;
            default:
                return '';
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.cardIconContainer}>
                {isKnownCard(card.brand) ? <img src={getCardImage()} className={classes.cardBrandImage} alt={translate('card brand image')} /> : <CardIcon />}
            </div>
            <Text variant='semiBold' className={classes.cardNumber}>
                {card.cardNumber}
            </Text>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    cardIconContainer: {
        width: 32,
        height: 32,
        borderRadius: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
    },
    cardBrandImage: {
        width: 25,
        height: 15,
        objectFit: 'contain',
    },
    cardNumber: {
        color: '#4F586E',
    },
}));

type Props = {
    card: CardVm;
};
