/**
 * @prettier
 */
import { KEY_NAME } from 'src/localStorage/getDeviceIdInLocalStorage';
import { setInLocalStorage } from 'src/localStorage/utils/setInLocalStorage';
import type { DeviceId } from 'src/types/Id';

export function setDeviceIdInLocalStorage(value: DeviceId): void {
    setInLocalStorage(KEY_NAME, value);
}
