/**
 * @prettier
 */
import type { AddressVm } from 'src/api/pidedirecto/types/AddressVm';
import type { GeoJsonPointVm } from 'src/api/pidedirecto/types/GeoJsonPointVm';
import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomerId, GooglePlaceId } from 'src/types/Id';

export async function addAddressApi(request: AddAddressApiRequest): ApiSauceResponse<AddressVm> {
    return pidedirectoApiMethod('address/addAddressApi', request);
}

export type AddAddressApiRequest = {
    customerId: CustomerId;
    location?: GeoJsonPointVm;
    street: string;
    other?: string;
    instructions?: string;
    googlePlaceId?: GooglePlaceId;
    name?: string; // backup data from google place details,
    formattedAddress?: string; // backup data from google place details,
    text?: string; // backup data from google autocomplete or nearby suggestion,
    subtext?: string; // backup data from google autocomplete or nearby suggestion
};
