/**
 * @prettier
 */
import moment from 'moment-timezone';
import OpeningHours from 'opening_hours';
import type { RestaurantVm } from 'src/api/pidedirecto/getAppContextApi';
import { RestaurantTypes } from 'src/constants/RestaurantType';
import type { PickupTimesVm } from 'src/types/PickupTimesVm';
import { toMinutes } from 'src/utils/duration/toMinutes';
import { isBlankString } from 'src/utils/string/isBlankString';

export function generatePickupTimes(restaurant?: RestaurantVm, drivingDuration?: number): PickupTimesVm | undefined {
    if (!restaurant || restaurant.restaurantType === RestaurantTypes.PICKUP_STATION) {
        return;
    }
    const now = moment.tz(restaurant.timeZone);
    const openIntervals = getOpenIntervals(restaurant, now.clone());

    const pickupTimes = {
        asap: getAsapPickupTime(now.clone(), restaurant, openIntervals, drivingDuration),
        planned: {
            days: [],
            times: [],
        },
    } as PickupTimesVm;

    const dateIterator = now.clone().startOf('minute').add(restaurant.preparationTime, 'minute');
    if (dateIterator.minute() % STEP_MINUTES === 0) {
        dateIterator.add(STEP_MINUTES, 'minutes');
    } else {
        dateIterator.add(2 * STEP_MINUTES - (dateIterator.minute() % STEP_MINUTES), 'minutes');
    }
    const lastPickupDate = now.clone().add(NUMBER_OF_DAYS, 'days').startOf('day');
    while (dateIterator.isBefore(lastPickupDate)) {
        if (isOpen(dateIterator, openIntervals)) {
            let value;
            if (drivingDuration) {
                value = dateIterator.clone().add(toMinutes(drivingDuration), 'minutes');
            } else {
                value = dateIterator;
            }
            const day = value.format('YYYY-MM-DD');
            const time = value.format('HH:mm');
            if (pickupTimes.planned.days[pickupTimes.planned.days.length - 1] !== day) {
                pickupTimes.planned.days.push(day);
                pickupTimes.planned.times.push([time]);
            } else {
                pickupTimes.planned.times[pickupTimes.planned.days.length - 1].push(time);
            }
        }
        dateIterator.add(STEP_MINUTES, 'minutes');
    }
    return pickupTimes;
}

function getAsapPickupTime(now: any, restaurant: RestaurantVm, openIntervals: OpenIntervals, drivingDuration?: number) {
    let asap;

    if (drivingDuration) {
        asap = now.add(restaurant.preparationTime, 'minutes').add(toMinutes(drivingDuration), 'minutes');
    } else {
        asap = now.add(restaurant.preparationTime, 'minutes');
    }
    if (!isOpen(asap, openIntervals)) {
        return;
    }
    return {
        day: asap.format('YYYY-MM-DD'),
        time: asap.format('HH:mm'),
    };
}

function getOpenIntervals(restaurant: RestaurantVm, now: any): OpenIntervals {
    const lastPickupDate = now.clone().add(NUMBER_OF_DAYS, 'days').startOf('day');
    const hours = isBlankString(restaurant.hours) ? '24/7' : restaurant.hours;
    const openIntervals = new OpeningHours(hours).getOpenIntervals(now.toDate(), lastPickupDate.toDate()) as any;
    return openIntervals.map(([opens, closes]: [Date, Date]) => ({
        opens: moment.tz(opens, restaurant.timeZone),
        closes: moment.tz(closes, restaurant.timeZone),
    }));
}

function isOpen(date: any, openIntervals: OpenIntervals) {
    return openIntervals.some((interval) => !(date.isBefore(interval.opens) || date.isAfter(interval.closes)));
}

const NUMBER_OF_DAYS = 7;
const STEP_MINUTES = 5;

type OpenIntervals = Array<{
    opens: moment.Moment;
    closes: moment.Moment;
}>;
