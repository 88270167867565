/**
 * @prettier
 */
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { ConnectDeviceToWebSocket } from 'src/components/app/ConnectDeviceToWebSocket';
import { EnvironmentLabel } from 'src/components/EnvironmentLabel';
import { LoadingContentProgress } from 'src/components/LoadingContentProgress';
import { GeneratePlexoPaymentSecurityId } from 'src/components/plexo/GeneratePlexoPaymentSecurityId';
import { translate } from 'src/i18n/translate';
import { ContentNotFoundPage } from 'src/scenes/ContentNotFoundPage';
import { InvoicePage } from 'src/scenes/InvoicePage';
import { OrderPage } from 'src/scenes/OrderPage';
import { PaymentLinkPage } from 'src/scenes/PaymentLinkPage';
import { RestaurantChainPage } from 'src/scenes/RestaurantChainPage';
import { RestaurantMenuPage } from 'src/scenes/RestaurantMenuPage';
import { SurveyPage } from 'src/scenes/survey/SurveyPage';
import { UrlHasChangedPage } from 'src/scenes/UrlHasChangedPage';
import { useInitializeApp } from 'src/services/useInitializeApp';
import { useSelector } from 'src/utils/react/useSelector';

export function App({ urlSubdomain }: Props): React.ReactElement {
    const { orderId, paymentLinkId } = useParams<{
        urlPathname?: string;
        orderId?: string;
        paymentLinkId: string;
    }>();
    const initializeApp = useInitializeApp({ urlSubdomain });

    const loading = useSelector((state) => !!state.app.inProgress.fetchAppContext);
    const contentNotFound = useSelector((state) => state.app.contentNotFound);
    const redirectUrl = useSelector((state) => state.app.redirectUrl);
    const restaurant = useSelector((state) => state.app.restaurant);
    const restaurantChain = useSelector((state) => state.app.restaurantChain);
    const page = useSelector((state) => state.app.page);

    const backgroundImageUrl = restaurantChain?.backgroundImageUrl ?? restaurant?.backgroundImageUrl;
    const loadBackgroundColor = restaurantChain?.chainColors[0] ?? 'rgba(26, 154, 160, 0.9)';
    const classes = useStyles({ loadColor: loadBackgroundColor })();

    useEffect(() => {
        initializeApp();
    }, [page]);

    if (contentNotFound) {
        return <ContentNotFoundPage />;
    }

    if (redirectUrl) {
        return <UrlHasChangedPage redirectUrl={redirectUrl} />;
    }

    return (
        <main className={classes.layout}>
            {!!backgroundImageUrl && !paymentLinkId && (
                <img
                    src={backgroundImageUrl}
                    style={{
                        position: 'fixed',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        zIndex: -1,
                    }}
                />
            )}
            <Fade in={loading}>
                <div className={classes.loadingContainer}>
                    <LoadingContentProgress loading={true} text={translate('Loading')} />
                </div>
            </Fade>
            <Switch>
                <Route path={'/'} exact>
                    <RestaurantChainPage />
                </Route>
                <Route path={`/:urlPathname/survey`} exact>
                    <SurveyPage />
                </Route>
                <Route path={`/:urlPathname/survey/:surveyId`}>
                    <SurveyPage />
                </Route>
                <Route path={'/:urlPathname/factura'} exact>
                    <InvoicePage />
                </Route>
                <Route path={'/:urlPathname/factura/:shortOrderId'} exact>
                    <InvoicePage />
                </Route>
                <Route path={'/:urlPathname/pay/:paymentLinkId'} exact>
                    <PaymentLinkPage />
                </Route>
                <Route path={'/:urlPathname'} exact>
                    <RestaurantMenuPage />
                </Route>
                <Route path={'/:urlPathname/:orderId'} exact>
                    <OrderPage />
                </Route>
                <Route>
                    <ContentNotFoundPage />
                </Route>
            </Switch>
            <EnvironmentLabel />
            <GeneratePlexoPaymentSecurityId />
            <ConnectDeviceToWebSocket />
        </main>
    );
}

const useStyles = (props: { loadColor: string }) =>
    makeStyles((theme) => ({
        layout: {},
        loadingContainer: {
            zIndex: 999999,
            position: 'fixed',
            height: '100%',
            width: '100%',
            display: 'flex',
            flex: 1,
            backgroundColor: theme.palette.primary.main,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
    }));

type Props = {
    urlSubdomain?: string;
    urlPathname?: string;
};
