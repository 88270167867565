/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { ContactInfoIcon } from 'src/components/ContactInfoIcon';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { AppTheme } from 'src/styles/AppTheme';
import { useSelector } from 'src/utils/react/useSelector';

const ANY_DIGIT = /[\d]/;

export function ContactInfoSummary({ phoneNumber, name, title }: Props): React.ReactElement | null {
    const classes = useStyles();

    const hideEcommerceRestaurantPhoneNumberEnabled = useSelector<boolean | undefined>((state) => state.app.restaurant?.hideEcommerceRestaurantPhoneNumberEnabled);

    const phoneNumberIsValid = phoneNumber && phoneNumber?.match(ANY_DIGIT);

    if (!phoneNumber || !name) return null;

    return (
        <div className={classes.orderInfoRowContainer}>
            <Text className={classes.title}>{translate(title)}</Text>
            <div className={classes.infoContainer}>
                <p className={classes.text}>{name}</p>

                {!hideEcommerceRestaurantPhoneNumberEnabled && (
                    <div className={classes.contactContainer}>
                        <ContactInfoIcon />
                        {phoneNumberIsValid && (
                            <a href={`tel:${phoneNumber}`} className={classes.text} style={{ marginLeft: 12 }}>
                                {phoneNumber}
                            </a>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    orderInfoRowContainer: {
        marginTop: 12,
    },
    contactContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        boxShadow: '0px 0px',
        color: theme.palette.primary.main,
        backgroundColor: '#fafbfb',
        flexShrink: 0,
        marginRight: 10,
    },
    text: {
        fontFamily: AppTheme.typography.medium,
        textDecoration: 'none',
        color: '#4F586E',
        fontSize: 12,
    },
    title: {
        fontFamily: AppTheme.typography.regular,
        fontSize: 13,
        color: '#868C9F',
        [theme.breakpoints.up('sm')]: {
            fontSize: 16,
        },
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 12,
    },
}));

type Props = {
    phoneNumber?: string;
    name: string;
    title: string;
};
