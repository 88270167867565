/**
 * @prettier
 */
import type { AddressVm } from 'src/api/pidedirecto/types/AddressVm';
import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { AddressId, CustomerId } from 'src/types/Id';

export async function removeAddressApi(request: RemoveAddressApiRequest): ApiSauceResponse<AddressVm> {
    return pidedirectoApiMethod('address/removeAddressApi', request);
}

export type RemoveAddressApiRequest = {
    customerId: CustomerId;
    addressId: AddressId;
};
