/**
 * @prettier
 */
import * as React from 'react';
import { SelectPaymentMethodDialogDeprecated } from 'src/components/cart/SelectPaymentMethodDialogDeprecated';
import { SelectRestaurantPaymentMethodDialog } from 'src/components/cart/SelectRestaurantPaymentMethodDialog';
import { useSelector } from 'src/utils/react/useSelector';

export function SelectPaymentMethodDialog({ isPaymentLink }: Props): React.ReactElement {
    const restaurantPaymentMethods = useSelector((state) => state.app.restaurant?.restaurantPaymentMethods);

    if (restaurantPaymentMethods) {
        return <SelectRestaurantPaymentMethodDialog isPaymentLink={isPaymentLink} />;
    }

    return <SelectPaymentMethodDialogDeprecated isPaymentLink={isPaymentLink} />;
}

type Props = {
    isPaymentLink?: boolean;
};
