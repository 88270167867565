/**
 * @prettier
 */
import { Countries, Country } from 'src/constants/Country';
import { Currencies, Currency } from 'src/constants/Currency';
import type { CurrencyFormatVm } from 'src/types/CurrencyFormatVm';

const CURRENCY_FORMAT_MEXICO = {
    locale: 'es-MX',
    currency: Currencies.MXN,
    fractionDigits: 0,
} as const;

const CURRENCY_FORMAT_SWEDEN = {
    locale: 'sv-SE',
    currency: Currencies.SEK,
    fractionDigits: 0,
} as const;

const CURRENCY_FORMAT_USA = {
    locale: 'en-US',
    currency: Currencies.USD,
    fractionDigits: 0,
} as const;

export function getCurrencyFormat(country: Country, currency: Currency): CurrencyFormatVm {
    if (!country && !currency) {
        return CURRENCY_FORMAT_MEXICO; // Should never happen, default to mexico rather than potentially causing app crash
    }
    switch (country) {
        case Countries.MX: {
            return { ...CURRENCY_FORMAT_MEXICO, currency };
        }
        case Countries.SE: {
            return { ...CURRENCY_FORMAT_SWEDEN, currency };
        }
        case Countries.US: {
            return { ...CURRENCY_FORMAT_USA, currency };
        }
    }
    return CURRENCY_FORMAT_MEXICO; // Default to mexico rather than potentially causing app crash
}
