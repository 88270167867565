/**
 * @prettier
 */
export const SubscriptionStatuses = Object.freeze({
    NEW: 'NEW',
    PAID: 'PAID',
    PENDING: 'PENDING',
    CANCELLED: 'CANCELLED',
    TEST: 'TEST',
});

export type SubscriptionStatus = (typeof SubscriptionStatuses)[keyof typeof SubscriptionStatuses];
