/**
 * @prettier
 */
import * as React from 'react';

export function Separator({ variant = 'default' }: Props): React.ReactElement {
    return <div style={Variant[variant]} />;
}

const Variant = {
    default: {
        height: 1,
        backgroundColor: '#EAEAEA',
    },
    menu: {
        height: 1,
        backgroundColor: '#000',
    },
} as const;

type Props = {
    variant?: 'default' | 'menu';
};
