/**
 * @prettier
 */
import moment from 'moment';

export function millisToNextMinute(): number {
    const now = moment();
    const startOfMinute = now.clone().startOf('minute');
    return 60000 - now.diff(startOfMinute);
}
