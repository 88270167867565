/**
 * @prettier
 */
import { useEffect, useState } from 'react';
import { ScreenSizes } from 'src/constants/ScreenSize';

export function useIsSmallScreen(): boolean {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < ScreenSizes.MEDIUM_SCREEN);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            return window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth < ScreenSizes.MEDIUM_SCREEN);
    };

    return isSmallScreen;
}
