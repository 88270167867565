/**
 * @prettier
 */
import hash from 'object-hash';
import type { CartItemVm } from 'src/types/CartItemVm';
import { isItemSoldByWeight } from 'src/utils/order/isItemSoldByWeight';

export function createCartItemKey(cartItem: CartItemVm): string {
    if (isItemSoldByWeight(cartItem)) {
        const { key, ...keyData } = cartItem;
        return hash(JSON.stringify(keyData));
    }
    const { quantity, key, ...keyData } = cartItem;
    return hash(JSON.stringify(keyData));
}
