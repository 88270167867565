/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { useLoadScript } from '@react-google-maps/api';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeAddressApi } from 'src/api/pidedirecto/address/changeAddressApi';
import { Dialog } from 'src/components/Dialog';
import { Form } from 'src/components/form/Form';
import { FormGoogleMapsAddressSearchAutocomplete } from 'src/components/form/FormGoogleMapsAddressSearchAutocomplete';
import { FormTextField } from 'src/components/form/FormTextField';
import { googleMapsConfig } from 'src/config/googleMapsConfig';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { createSelectAddressLogEvent } from 'src/services/logEvent/createSelectAddressLogEvent';
import { alertSomethingWentWrongCheckYourInternetConnectionOrContactSupport } from 'src/utils/alert/alertSomethingWentWrongCheckYourInternetConnectionOrContactSupport';
import { toLatLng } from 'src/utils/googlemaps/toLatLng';
import { logError } from 'src/utils/log/logError';
import { useAction } from 'src/utils/react/useAction';
import { useCurrentLocation } from 'src/utils/react/useCurrentLocation';
import { useSelector } from 'src/utils/react/useSelector';

export function EditAddressDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const { location, error } = useCurrentLocation();
    const { isLoaded, loadError } = useLoadScript(googleMapsConfig);

    const open = useSelector((state) => state.app.editAddressDialog.open);
    const editAddress = useSelector((state) => state.app.editAddressDialog.address);
    const customerId = useSelector((state) => state.app.customerId);
    const address = useSelector((state) => state.app.address);
    const country = useSelector((state) => state.app.restaurant?.country);

    const closeEditAddressDialog = useAction(actions.closeEditAddressDialog);
    const changeAddress = useAction(actions.changeAddress);
    const selectAddress = useAction(actions.selectAddress);

    const [selected, setSelected] = useState(address);
    const [geocoder, setGeocoder] = useState<any>();
    const [addressFound, setAddressFound] = useState('');
    const [addressInputFocused, setAddressInputFocused] = useState(false);
    const [addressOther, setAddressOther] = useState('');
    const [addressInstructions, setAddressInstructions] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setAddressFound(editAddress.street);
            if (editAddress.other !== undefined) setAddressOther(editAddress.other);
            if (editAddress.instructions !== undefined) setAddressInstructions(editAddress.instructions);
            setSelected(address);
        }
    }, [open]);

    useEffect(() => {
        if (!geocoder && isLoaded && !loadError) {
            const geocoder = new window.google.maps.Geocoder();
            setGeocoder(geocoder);
        }
    }, [geocoder, isLoaded, loadError]);

    const setAddressToCustomerLocation = async () => {
        const response = await geocoder.geocode({ location: toLatLng(location) });
        if (response.results[0]) setAddressFound(response.results[0].formatted_address);
        setAddressFound('');
    };

    const onFocusAddressInput = () => setAddressInputFocused(true);
    const onBlurAddressInput = () => setAddressInputFocused(false);

    const onSubmit = async (form: any) => {
        const request = {
            addressId: editAddress.addressId,
            customerId: customerId,
            location: form.mapsResult.location,
            street: form.mapsResult.street, // a veces manda el lastInputAddress y es el primero que se agrego, deberia ser siempre el que pongo
            other: form.other,
            instructions: form.instructions,
            googlePlaceId: form.mapsResult.googlePlaceId,
            name: form.mapsResult.name, // backup data from google place details
            formattedAddress: form.mapsResult.formattedAddress, // backup data from google place details
        } as const;
        setLoading(true);
        const response = await changeAddressApi(request);
        setLoading(false);
        if (!response.ok) {
            logError('Failed to edit address', { request, response });
            alertSomethingWentWrongCheckYourInternetConnectionOrContactSupport();
            return;
        }

        let address = response.data;
        changeAddress(address);
        selectAddress(address);
        createSelectAddressLogEvent({
            address: selected?.street,
        });
        closeEditAddressDialog();
    };

    return (
        <Dialog open={open} onClose={closeEditAddressDialog} loading={loading} title={translate('Edit Address')} actionButtonFormId={'form'} actionText={translate('Next')}>
            <Form form={form} onSubmit={onSubmit} className={classes.form} id={'form'}>
                <div className={classes.formContent}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormGoogleMapsAddressSearchAutocomplete
                                name='mapsResult'
                                label={translate('Street address and building number')}
                                addressFound={addressFound}
                                required
                                onFocus={onFocusAddressInput}
                                onBlur={onBlurAddressInput}
                                country={country}
                                rightIcon={isLoaded && !loadError && <MyLocationIcon className={classes.iconTarget} onClick={setAddressToCustomerLocation} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='other' defaultValue={addressOther} label={translate('Staircase / apartment / floor / other')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='instructions' defaultValue={addressInstructions} label={translate('Instructions for carrier')} multiline rowsMax={3} />
                        </Grid>
                    </Grid>
                </div>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    formContent: {
        padding: theme.spacing(2),
    },
    iconTarget: {
        cursor: 'pointer',
        marginLeft: 5,
    },
}));
