/**
 * @prettier
 */
import type { StateVm } from 'src/types/StateVm';

export function selectMenus(state: StateVm): Array<any> {
    const { cartItemsByMenuItem, openCategoriesMemory } = state.app;
    const menus = state.app.restaurantMenu?.menus ?? [];

    return menus.map((menu) => {
        if (menu.categories.length === 1) {
            return {
                menuId: menu.menuId,
                name: menu.name,
                type: 'WITHOUT_CATEGORIES',
                items: menu.categories[0].menuItems.map((menuItem) => {
                    return {
                        key: menuItem.menuItemId,
                        show: true,
                        menuItem,
                        cartItems: cartItemsByMenuItem[menuItem.menuItemId],
                    };
                }),
            };
        }

        return {
            menuId: menu.menuId,
            name: menu.name,
            type: 'WITH_CATEGORIES',
            sections: menu.categories.map((category, i) => {
                // const prevMenuCategory = menu.categories[i - 1];
                // const prevMenuCategoryId = prevMenuCategory?.menuCategoryId;
                const isOpen = openCategoriesMemory[category.menuCategoryId] !== undefined ? openCategoriesMemory[category.menuCategoryId] : !!category.open;
                return {
                    key: category.menuCategoryId,
                    menuCategoryId: category.menuCategoryId,
                    // first: i === 0,
                    // last: i === menu.categories.length - 1,
                    name: category.name,
                    numberOfItems: category.menuItems.length,
                    // isOpenAbove: prevMenuCategoryId && (openCategoriesMemory[prevMenuCategoryId] !== undefined ? openCategoriesMemory[prevMenuCategoryId] : !!prevMenuCategory.open),
                    isOpen,
                    data: isOpen
                        ? category.menuItems.map((menuItem) => {
                              return {
                                  key: menuItem.menuItemId,
                                  menuItem,
                                  cartItems: cartItemsByMenuItem[menuItem.menuItemId],
                              };
                          })
                        : [],
                };
            }),
        };
    });
}
