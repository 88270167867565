/**
 * @prettier
 */
import type { GeoJsonPointVm } from 'src/api/pidedirecto/types/GeoJsonPointVm';
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';

export function createUserOpenedPidedirectoWebLogEvent(params: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_OPENED_PIDEDIRECTO,
        data: params,
    });
}

type Params = {
    url?: string;
    customerOrigin?: string;
    location?: GeoJsonPointVm;
};
