/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { CardInfo } from 'src/components/CardInfo';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { AppTheme } from 'src/styles/AppTheme';
import type { PaymentLinkVm } from 'src/types/PaymentLinkVm';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PaymentLinkSummary({ paymentLink }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const paymentMethod = useSelector((state) => state.app.paymentMethod);
    const card = useSelector((state) => state.app.card);

    const openSelectPaymentMethodDialog = useAction(actions.openSelectPaymentMethodDialog);

    return (
        <div className={classes.container}>
            <div className={classes.totalContainer}>
                <Text>{translate('Total')}</Text>
                <Text className={classes.total}>{formatAsCurrencyNumber(paymentLink.amount.toString())}</Text>
            </div>
            <section className={classes.section}>
                <Text className={classes.title}> {translate('Payment information')}</Text>
                <div className={classes.paymentMethodContainer}>
                    {!isCashPayment(paymentMethod) && !isCardPayment(paymentMethod) && <Text className={classes.paymentMethod}>{translate('Select payment method')}</Text>}
                    {isCashPayment(paymentMethod) && <Text className={classes.paymentMethod}>{translate('Cash')}</Text>}
                    {isCardPayment(paymentMethod) && !!card && (
                        <div>
                            <Text className={classes.smallText} size={'small'}>
                                {translate('Selected card')}
                            </Text>
                            <CardInfo card={card} />
                        </div>
                    )}
                    {isCardPayment(paymentMethod) && !card && <Text className={classes.paymentMethod}>{translate('Pay with card')}</Text>}
                    <Button onClick={openSelectPaymentMethodDialog} text>
                        <Text className={classes.changeText}>{translate('Change')}</Text>
                    </Button>
                </div>
            </section>
            {!!paymentLink.orderId && (
                <section className={classes.section}>
                    <Text className={classes.smallText} size={'small'}>
                        {translate('Summary')}
                    </Text>
                    {BigNumber(paymentLink.subtotal ?? 0).isGreaterThan(0) && (
                        <div className={classes.row}>
                            <Text>{translate('Subtotal')}</Text>
                            <Text variant={'bold'}>{formatAsCurrencyNumber(paymentLink.subtotal)}</Text>
                        </div>
                    )}
                    {BigNumber(paymentLink.deliveryCost ?? 0).isGreaterThan(0) && (
                        <div className={classes.row}>
                            <Text>{translate('Delivery cost')}</Text>
                            <Text variant={'bold'}>{formatAsCurrencyNumber(paymentLink.deliveryCost)}</Text>
                        </div>
                    )}
                    {BigNumber(paymentLink.discount ?? 0).isGreaterThan(0) && (
                        <div className={classes.row}>
                            <Text>{translate('Discount')}</Text>
                            <Text variant={'bold'}>-{formatAsCurrencyNumber(paymentLink.discount)}</Text>
                        </div>
                    )}
                </section>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        gap: 10,
    },
    totalContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        gap: 5,
        marginBottom: 20,
    },
    section: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderBottom: '1px solid #D9D9D9',
        gap: 10,
        paddingBottom: 10,
    },
    total: {
        fontFamily: AppTheme.typography.bold,
        fontSize: 28,
        color: '#0D3037',
    },
    title: {
        fontFamily: AppTheme.typography.semiBold,
        fontSize: 16,
        color: '#0D3037',
    },
    paymentMethodContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    paymentMethod: {
        color: '#4F586E',
        fontFamily: AppTheme.typography.medium,
    },
    changeText: {
        fontFamily: AppTheme.typography.medium,
        color: theme.palette.primary.main,
    },
    smallText: {
        color: '#818696',
        marginBottom: 10,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
}));

type Props = {
    paymentLink: PaymentLinkVm;
};
