/**
 * @prettier
 */
export const SubscriptionPlans = Object.freeze({
    PRO_REGULAR: 'PRO_REGULAR',
    BASIC_REGULAR: 'BASIC_REGULAR',
    COMMISSION: 'COMMISSION',
    PAY_PER_USE: 'PAY_PER_USE',
    FREE_TRIAL: 'FREE_TRIAL',
});

export type SubscriptionPlan = (typeof SubscriptionPlans)[keyof typeof SubscriptionPlans];
