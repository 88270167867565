/**
 * @prettier
 */
export const RestaurantTypes = Object.freeze({
    RESTAURANT: 'RESTAURANT',
    PICKUP_STATION: 'PICKUP_STATION',
    KITCHEN: 'KITCHEN',
});

export type RestaurantType = (typeof RestaurantTypes)[keyof typeof RestaurantTypes];
