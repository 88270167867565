/**
 * @prettier
 */
import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomerId, RestaurantId } from 'src/types/Id';

export async function getAddPlexoCardUrlApi(request: GetAddPlexoCardUrlApiRequest): ApiSauceResponse<string> {
    return pidedirectoApiMethod('card/getAddPlexoCardUrlApi', request);
}

export type GetAddPlexoCardUrlApiRequest = {
    restaurantId: RestaurantId;
    customerId: CustomerId;
};
