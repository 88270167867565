/**
 * @prettier
 */
import { markPaymentAsPaidAndVerifiedWithStripe3dsVerificationApi } from 'src/api/pidedirecto/order/markPaymentAsPaidAndVerifiedWithStripe3dsVerificationApi';
import { translate } from 'src/i18n/translate';
import type { PaymentId } from 'src/types/Id';

export async function stripePaymentSucceeded({ setMessage, paymentId, onAuthorized, onUnauthorized }: StripePaymentSucceededRequest): Promise<void> {
    setMessage(translate('Payment succeeded'));
    const response = await markPaymentAsPaidAndVerifiedWithStripe3dsVerificationApi({ paymentId });
    if (!response.ok) {
        alert(translate('Ordering Failed') + '\n' + translate('Something went wrong when ordering. Please try again'));
        onUnauthorized?.();
    }
    onAuthorized?.();
}

type StripePaymentSucceededRequest = {
    setMessage: any;
    paymentId: PaymentId;
    onAuthorized?: any;
    onUnauthorized?: any;
};
