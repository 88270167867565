/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { LoadingIndicator } from 'src/components/LoadingIndicator';
import { CircleCheckIcon } from 'src/icons/CircleCheckIcon';
import { XCircleIcon } from 'src/icons/XCircleIcon';

export function PaymentLinkLoader({ loading, isCompleted, isPaymentSuccessful, children }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {isCompleted && <div className={classes.confirmationIcon}>{isPaymentSuccessful ? <CircleCheckIcon /> : <XCircleIcon />}</div>}
            <LoadingIndicator
                loading={loading}
                classes={{
                    loader: isCompleted ? classes.completedLoader : '',
                    children: isCompleted ? classes.completedChildren : '',
                }}
            >
                {children}
            </LoadingIndicator>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
    },
    completedLoader: {
        zIndex: 97,
        position: 'absolute',
        borderRadius: '50%',
        border: `4px solid ${theme.palette.primary.main}`,
        width: '96px',
        height: '96px',
        animation: '$complete 1s linear, $spin 1s linear infinite',
        transition: '1s ease-out',
    },
    completedChildren: {
        marginTop: 200,
        transition: '1s ease-out',
        justifyContent: 'flex-start',
    },
    confirmationIcon: {
        zIndex: 98,
        position: 'absolute',
        display: 'grid',
        placeItems: 'center',
        width: '95%',
        height: '95%',
        animation: '$fadeIn 1s ease-in',
    },
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
    '@keyframes complete': {
        '0%': {
            border: '4px solid #d9d9d9',
            borderTop: `4px solid ${theme.palette.primary.main}`,
        },
        '50%': {
            borderRight: `4px solid ${theme.palette.primary.main}`,
        },
        '75%': {
            borderBottom: `4px solid ${theme.palette.primary.main}`,
        },
        '100%': {
            borderLeft: `4px solid ${theme.palette.primary.main}`,
            border: `4px solid ${theme.palette.primary.main}`,
        },
    },
    '@keyframes fadeIn': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
}));

type Props = {
    isPaymentSuccessful: boolean;
    loading: boolean;
    isCompleted: boolean;
    children?: React.ReactNode;
};
