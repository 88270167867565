/**
 * @prettier
 */
import { Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { CancelButton } from 'src/components/buttons/CancelButton';
import { EditButton } from 'src/components/buttons/EditButton';
import { OkButton } from 'src/components/buttons/OkButton';
import { Separator } from 'src/components/Separator';
import { Text } from 'src/components/Text';

export function BottomDialogHeader({ header, onClose, onOk, onEdit }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <>
            <div className={classes.container}>
                <Text className={classes.header}>{header || ''}</Text>
                <Fade in={!!onClose}>
                    <div className={classes.leftButton}>
                        <CancelButton onClick={onClose} />
                    </div>
                </Fade>
                <Fade in={!!onOk}>
                    <div className={classes.rightButton}>
                        <OkButton onClick={onOk} />
                    </div>
                </Fade>
                <Fade in={!!onEdit}>
                    <div className={classes.rightButton}>
                        <EditButton onClick={onEdit} />
                    </div>
                </Fade>
            </div>
            <Separator />
        </>
    );
}

export const headerHeight = 56;
export const buttonWidth = 56;

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        minHeight: headerHeight,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        overflow: 'hidden',
    },
    header: {
        color: '#000000',
        fontSize: 16,
        marginLeft: buttonWidth,
        marginRight: buttonWidth,
        textAlign: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    leftButton: {
        position: 'absolute',
        left: 0,
        top: 4,
        height: headerHeight,
        width: buttonWidth,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rightButton: {
        position: 'absolute',
        right: 0,
        top: 4,
        height: headerHeight,
        width: buttonWidth,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

type Props = {
    children?: React.ReactNode;
    header?: string;
    onClose?: any;
    onOk?: any;
    onEdit?: any;
};
