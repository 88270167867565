/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { useRef } from 'react';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { PaymentLinkLoader } from 'src/scenes/paymentLink/PaymentLinkLoader';
import { useCanRestaurantInvoice } from 'src/services/restaurant/useCanRestaurantInvoice';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { AppTheme } from 'src/styles/AppTheme';
import type { PaymentLinkVm } from 'src/types/PaymentLinkVm';
import { toShortId } from 'src/utils/uuid/toShortId';

export function PaymentLinkStatus({ paymentLink, paid, failed, errorMessage }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const { urlPathname } = useParams<{
        urlPathname?: string;
        orderId?: string;
        paymentLinkId: string;
    }>();
    const history = useHistory();
    const totalContainerRef = useRef<HTMLDivElement>(null);
    const canRestaurantInvoice = useCanRestaurantInvoice();

    const loading = !paid && !failed;

    const goToInvoice = () => {
        if (!paymentLink.orderId) return;
        history.push(`/${urlPathname}/factura/${toShortId(paymentLink.orderId)}`);
    };

    const goToSurvey = () => {
        if (!paymentLink.surveyId) return;
        history.push(`/${urlPathname}/survey/${paymentLink.surveyId}`);
    };

    return (
        <div className={classes.container}>
            <PaymentLinkLoader isPaymentSuccessful={!!paid} loading={loading} isCompleted={!loading}>
                {!failed && (
                    <div className={classes.totalContainer} ref={totalContainerRef}>
                        <Text>{translate('Total')}</Text>
                        <Text className={classes.total}>{formatAsCurrencyNumber(paymentLink.amount?.toString())}</Text>
                    </div>
                )}
            </PaymentLinkLoader>
            {!paid && !failed && (
                <div className={classes.payingTextContainer}>
                    <Text size={'large'} variant={'semiBold'} style={{ color: '#0D3037', textAlign: 'center' }}>
                        {translate('We are processing your payment')}
                    </Text>
                    <Text size={'small'} style={{ textAlign: 'center' }}>
                        {translate('Please wait until the transactions is completed')}
                    </Text>
                </div>
            )}
            {paid && (
                <div className={classes.linksContainer} style={{ marginTop: totalContainerRef.current?.offsetHeight || 0 }}>
                    <Text variant='semiBold' className={classes.paymentSuccessful}>
                        {translate('Payment successful')}
                    </Text>
                    {canRestaurantInvoice && paymentLink.orderId && (
                        <div className={classes.linkContainer}>
                            <Text size='small'>{translate('In case you need invoice, ')}</Text>
                            <span className={classes.link} onClick={goToInvoice}>
                                {translate('click here')}
                            </span>
                        </div>
                    )}
                    {!!paymentLink.surveyId && (
                        <div className={classes.linkContainer}>
                            <Text size='small'>{translate('Leave us your comments ')}</Text>
                            <span className={classes.link} onClick={goToSurvey}>
                                {translate('here')}
                            </span>
                        </div>
                    )}
                </div>
            )}
            {failed && <Text size='small'>{errorMessage || translate('There was an error while processing your payment, please try again.')}</Text>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    totalContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        alignItems: 'center',
    },
    total: {
        fontFamily: AppTheme.typography.bold,
        fontSize: 28,
        color: '#0D3037',
    },
    linksContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        textAlign: 'center',
    },
    linkContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 3,
        justifyContent: 'center',
    },
    link: {
        fontFamily: AppTheme.typography.regular,
        fontSize: 12,
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    paymentSuccessful: {
        marginTop: 10,
        color: '#0D3037',
    },
    payingTextContainer: {
        marginTop: 20,
    },
}));

type Props = {
    paymentLink: PaymentLinkVm;
    paid?: boolean;
    failed?: boolean;
    errorMessage?: string;
};
