/**
 * @prettier
 */
import type { SurveyVm } from 'src/api/pidedirecto/types/SurveyVm';
import { pidedirectoApiMethodPublic } from 'src/api/pidedirectoApiMethodPublic';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SurveyId } from 'src/types/Id';

export async function findSurveyApi(request: FindSurveyApiRequest): ApiSauceResponse<SurveyVm> {
    return pidedirectoApiMethodPublic('survey/findSurveyApi', request);
}

export type FindSurveyApiRequest = {
    surveyId: SurveyId;
};
