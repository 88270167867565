/**
 * @prettier
 */
import isString from 'lodash/isString';

export function upperCaseFirstLetterInString(value?: string | null): string {
    // TODO: Return maybe type
    if (value === undefined || value === null) {
        return value as any;
    }
    if (!isString(value)) {
        return value;
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
}
