/**
 * @prettier
 */
import { envENVIRONMENT } from 'src/env/envENVIRONMENT';
import { requireStringNotBlank } from 'src/utils/require/requireStringNotBlank';

export function envGOOGLE_MAPS_API_KEY(): string {
    try {
        return requireStringNotBlank(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    } catch (e: any) {
        throw Error(`REACT_APP_GOOGLE_MAPS_API_KEY is not configured correctly in .env.${envENVIRONMENT().toLowerCase()}`);
    }
}
