/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Text } from '@pidedirecto/ui';
import { useTheme } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import type { MenuItemVm } from 'src/api/pidedirecto/getAppContextApi';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import PhotoNotFoundImage from 'src/images/PhotoNotFoundImage.png';
import { actions } from 'src/reducers';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { clipString } from 'src/utils/clipString';
import { transformImageUrlMenuItem } from 'src/utils/image/transformImageUrlMenuItem';
import { isMediumLayout } from 'src/utils/layouts/isMediumLayout';
import { isSmallLayout } from 'src/utils/layouts/isSmallLayout';
import { doesPromotionIncludeAllOrderTypes } from 'src/utils/promotion/doesPromotionIncludeAllOrderTypes';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function MenuItem({ menuItem }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const theme = useTheme();

    const restaurant = useSelector((state) => state.app.restaurant);
    const currencyFormat = useSelector((state) => state.app.restaurantMenu?.currencyFormat);
    const productLayout = useSelector((state) => state.app.restaurant?.productLayout);
    const promotions = useSelector((state) => state.app.promotions);

    const openAddMenuItemDialog = useAction(actions.openAddMenuItemDialog);

    const promotion = promotions.find((promotion) => promotion.menuItemIds.includes(menuItem.menuItemId));

    useEffect(() => {
        if (!restaurant.facebookPixelId) return;
        ReactPixel.init(restaurant.facebookPixelId);
    }, []);

    const addCartItem = () => {
        if (restaurant.facebookPixelId) {
            ReactPixel.track('ViewContent', {
                content_type: 'product',
                content_ids: [menuItem.menuItemId],
                content_name: menuItem.name,
                content_category: menuItem.menuItemType,
                value: menuItem.price,
                currency: restaurant.country,
            });
        }
        openAddMenuItemDialog(menuItem);
    };

    const getPromotionLabel = () => {
        if (!promotion) return '';
        if (doesPromotionIncludeAllOrderTypes(promotion)) return promotion.name;
        const orderTypeTranslations = promotion.orderTypes.map((orderType) => translate(`OrderTypes.${orderType}`)) ?? [];
        return `${promotion.name} ${translate('only @orderTypes', { orderTypes: removeDuplicates(orderTypeTranslations)?.join(', ') })}`;
    };

    return (
        <Button
            text
            classes={{
                button: classNames(classes.container, {
                    [classes.smallContainer]: isSmallLayout(productLayout),
                    [classes.mediumContainer]: isMediumLayout(productLayout),
                }),
            }}
            onClick={addCartItem}
        >
            <div
                className={classNames(classes.itemContainer, {
                    [classes.itemContainerMedium]: isMediumLayout(productLayout),
                })}
            >
                {menuItem.imageUrl ? (
                    <img
                        className={classNames(classes.image, {
                            [classes.imagePng]: menuItem.imageUrl && menuItem.imageUrl.endsWith('png'), // TODO: .endsWith('png') does not work with encoded image url
                            [classes.imageSmall]: isSmallLayout(productLayout),
                            [classes.imageMedium]: isMediumLayout(productLayout),
                        })}
                        src={transformImageUrlMenuItem(menuItem.imageUrl)}
                        draggable='false'
                        alt='itemImage'
                    />
                ) : (
                    <img
                        className={classNames(classes.image, {
                            [classes.imagePng]: menuItem.imageUrl && menuItem.imageUrl.endsWith('png'), // TODO: .endsWith('png') does not work with encoded image url
                            [classes.imageSmall]: isSmallLayout(productLayout),
                            [classes.imageMedium]: isMediumLayout(productLayout),
                        })}
                        src={PhotoNotFoundImage}
                        alt='PhotoNotFoundImage'
                    />
                )}
                <div className={classNames(classes.subContainer, { [classes.subContainerMedium]: isMediumLayout(productLayout) })}>
                    <div
                        className={classNames(classes.name, {
                            [classes.nameSmall]: isSmallLayout(productLayout),
                            [classes.nameMedium]: isMediumLayout(productLayout),
                        })}
                    >
                        <div>
                            {menuItem.name}
                            {!!promotion && (
                                <div className={classes.promoTextContainer}>
                                    <Text style={{ color: theme.palette?.text?.brand }}>{getPromotionLabel()}</Text>
                                </div>
                            )}
                        </div>
                        <div className={classes.priceContainer}>
                            <div className={classes.pricesContainer}>
                                <div
                                    className={classNames(classes.price, {
                                        [classes.nonPromoPrice]: menuItem.promoPrice,
                                        [classes.priceSmall]: isSmallLayout(productLayout),
                                    })}
                                >
                                    {formatAsCurrencyNumber(menuItem.price)}
                                </div>
                                {!!menuItem.promoPrice && <div className={classes.price}>{formatAsCurrencyNumber(menuItem.promoPrice)} </div>}
                            </div>
                        </div>
                    </div>

                    {!!menuItem.description && <div className={classNames(classes.description)}>{clipString({ string: menuItem.description ?? '', max: 70 })}</div>}
                    {!!menuItem.promoText && (
                        <div className={classes.promoTextContainer}>
                            <p className={classes.promoText}>{menuItem.promoText}</p>
                        </div>
                    )}
                </div>
            </div>
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        height: 'fit-content',
        '&:hover': {
            cursor: 'pointer',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'flex-start',
        },
    },
    image: {
        width: '100%',
        height: '100%',
        maxHeight: 200,
        borderRadius: 12,
        objectFit: 'cover',
        marginBottom: '12px',
        boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1)',
        [theme.breakpoints.up('sm')]: {
            minWidth: 150,
            minHeight: 150,
            width: 120,
            height: 120,
            marginRight: '12px',
            marginBottom: '0px',
        },
    },
    name: {
        fontSize: '16px',
        color: '#333333',
        fontWeight: 500,
        lineHeight: '16px',
        marginBottom: 8,
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'left',
        gap: '12px',
        [theme.breakpoints.up('sm')]: {
            fontSize: 20,
            lineHeight: '20px',
            justifyContent: 'space-between',
        },
    },
    promoTextContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    promoText: {
        width: 'fit-content',
        padding: '4px 8px',
        fontSize: 12,
        lineHeight: '20px',
        color: '#E17234',
        backgroundColor: '#F8E3D7',
        borderRadius: 8,
        fontWeight: 600,
        [theme.breakpoints.up('sm')]: {
            fontSize: 12,
        },
    },
    pricesContainer: {
        width: '100%',
        display: 'flex',
        gap: 5,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    priceContainer: {
        justifySelf: 'flex-end',
    },
    price: {
        fontSize: 16,
        color: '#333333',
        fontWeight: 500,
        width: 'max-content',
        [theme.breakpoints.up('sm')]: {
            fontSize: 20,
        },
    },
    nonPromoPrice: {
        color: '#CCCCCC',
        textDecorationLine: 'line-through',
        textDecorationStyle: 'solid',
    },
    description: {
        fontSize: '12px',
        lineHeight: '16px',
        color: '#818696',
        textAlign: 'left',
        [theme.breakpoints.up('sm')]: {
            fontSize: 16,
            lineHeight: '19px',
        },
    },
    itemInfo: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        justifyContent: 'space-between',
        width: '85%',
        paddingTop: '12px',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            width: '85%',
        },
    },
    smallContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 0,
        marginBottom: 18,
        width: '100%',
        gap: 12,
        padding: 0,
        height: 'fit-content',
        '&:hover': {
            cursor: 'pointer',
        },
        '&:focus': {
            outline: 'none',
        },
        [theme.breakpoints.up('md')]: {
            width: '100%',
            justifyContent: 'space-between',
        },
    },
    imageSmall: {
        width: '100%',
        height: '100px',
        padding: 0,
        borderRadius: 12,
        marginBottom: 12,
        objectFit: 'cover',
        boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1)',
        [theme.breakpoints.up('sm')]: {
            minWidth: 120,
            minHeight: 120,
            width: 120,
            height: 120,
            marginRight: 12,
        },
    },
    nameSmall: {
        fontSize: '14px',
        color: '#333333',
        fontWeight: 500,
        lineHeight: '16px',
        marginBottom: 8,
        display: 'flex',
        width: '100%',
        gap: '12px',
        justifyContent: 'space-between',
        textAlign: 'left',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            fontSize: 20,
            lineHeight: '20px',
            justifyContent: 'space-between',
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            gap: 4,
        },
    },
    priceSmall: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        },
    },
    mediumContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    imageMedium: {
        width: '100%',
        height: '100%',
        maxWidth: '130px',
        maxHeight: '130px',
    },
    itemContainerMedium: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'flex-start',
        },
    },
    nameMedium: {
        fontSize: '16px',
        color: '#333333',
        fontWeight: 500,
        lineHeight: '16px',
        marginBottom: 8,
        display: 'flex',
        width: '100%',
        gap: '12px',
        justifyContent: 'space-between',
        textAlign: 'left',
        [theme.breakpoints.up('sm')]: {
            fontSize: 20,
            lineHeight: '20px',
            justifyContent: 'space-between',
        },
    },
    subContainer: {
        width: '100%',
    },
    subContainerMedium: {
        width: '100%',
        paddingLeft: 12,
    },
    imagePng: {},
}));

type Props = {
    menuItem: MenuItemVm;
};
