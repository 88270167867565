/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import type { OrderItemVm } from 'src/api/pidedirecto/types/OrderVm';
import type { CartItemVm } from 'src/types/CartItemVm';

export function calculateOrderItemProductDiscount(orderItem: OrderItemVm | CartItemVm): BigNumber {
    return BigNumber(orderItem.unitPrice)
        .minus(orderItem.promoUnitPrice ?? orderItem.unitPrice)
        .multipliedBy(orderItem.quantity);
}
