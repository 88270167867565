/**
 * @prettier
 */
import * as React from 'react';
import { OrderItemsDelivery } from 'src/components/OrderItemsDelivery';
import { OrderItemsPickup } from 'src/components/OrderItemsPickup';
import { OrderTypes } from 'src/constants/OrderType';
import { getCurrencyFormat } from 'src/utils/getCurrencyFormat';
import { useSelector } from 'src/utils/react/useSelector';

export function OrderItems(): React.ReactElement | null {
    const order = useSelector((state) => state.app.order);

    if (!order) return null;

    const currencyFormat = getCurrencyFormat(order.country, order.currency);

    if (order.orderType === OrderTypes.PICKUP_STATION_ORDER) {
        return <OrderItemsDelivery orderItems={order.orderItems} currencyFormat={currencyFormat} timeZone={order.timeZone} />;
    }

    return <OrderItemsPickup orderItems={order.orderItems} currencyFormat={currencyFormat} />;
}
