/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import type { CartItemVm } from 'src/types/CartItemVm';

export function checkMenuLimit(quantity: number, cartItems: Array<CartItemVm>, orderItemsMaximumQuantity: string): boolean {
    const cartItemsQuantity = cartItems.reduce((total, cartItem) => total + cartItem.quantity, 0);
    const isBiggerThanLimitMenu = BigNumber(quantity + cartItemsQuantity).isGreaterThan(parseInt(orderItemsMaximumQuantity));

    return isBiggerThanLimitMenu;
}
