/**
 * @prettier
 */
import * as React from 'react';
import { useState } from 'react';
import { BottomDialog } from 'src/components/BottomDialog';
import { BottomDialogCheckButton, runAfterCheckAnimation } from 'src/components/BottomDialogCheckButton';
import { BottomDialogHeader } from 'src/components/BottomDialogHeader';
import { OrderTypes } from 'src/constants/OrderType';
import { ServiceTypes } from 'src/constants/ServiceType';
import { translate } from 'src/i18n/translate';
import { EatHereIcon } from 'src/icons/EatHereIcon';
import { MopedIcon } from 'src/icons/MopedIcon';
import { TakeAwayIcon } from 'src/icons/TakeAwayIcon';
import { actions } from 'src/reducers';
import { createUserSelectedOrderTypeLogEvent } from 'src/services/logEvent/createUserSelectedOrderTypeLogEvent';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function SelectOrderTypeDialog(): React.ReactElement {
    const open = useSelector((state) => state.app.selectOrderTypeDialog.open);
    const orderType = useSelector((state) => state.app.orderType);
    const deliveryEnabled = useSelector((state) => state.app.restaurant?.deliveryEnabled);
    const takeAwayEnabled = useSelector((state) => state.app.restaurant?.takeAwayEnabled);
    const eatHereEnabled = useSelector((state) => state.app.restaurant?.eatHereEnabled);
    const serviceType = useSelector((state) => state.app.restaurant?.serviceType);
    const closeSelectOrderTypeDialog = useAction(actions.closeSelectOrderTypeDialog);
    const selectOrderType = useAction(actions.selectOrderType);

    const [selected, setSelected] = useState(orderType);
    // Attempt to solve backbutton support, though needs further investigation since index.html has some logic disturbing this:
    // window.addEventListener('load', function() {
    //     window.history.pushState({}, '')
    // })
    //
    // window.addEventListener('popstate', function() {
    //     window.history.go(1);
    // })
    //
    // Solotion below:
    // see https://codesandbox.io/s/7zf07?file=/demo.js:488-728 and https://7zf07.codesandbox.io/
    // const openSelectOrderTypeDialog = useAction(actions.openSelectOrderTypeDialog);
    //
    // useEffect(() => {
    //     if (open) setSelected(orderType);
    //     console.log('open = ', open);
    //     if (open) {
    //         window.location.hash = "#dialog";
    //     } else {
    //         window.location.hash = "";
    //     }
    // }, [open]);
    //
    // useEffect(() => {
    //     const onHashChange = () => {
    //         console.log('change detected = ', window.location.hash);
    //         window.location.hash === '#dialog' ? openSelectOrderTypeDialog() : closeSelectOrderTypeDialog();
    //     };
    //     window.addEventListener("hashchange", onHashChange);
    //     return () => window.removeEventListener("hashchange", onHashChange);
    // }, []);

    return (
        <BottomDialog open={open} onClickBackground={closeSelectOrderTypeDialog} onClickHardwareBack={closeSelectOrderTypeDialog}>
            <BottomDialogHeader header={translate('How do you want your order?')} />
            <BottomDialogCheckButton
                checked={selected === OrderTypes.DELIVERY_ORDER}
                Icon={MopedIcon}
                text={translate('Delivery')}
                subtext={deliveryEnabled ? undefined : translate('Not available')}
                onClickRow={() => {
                    createUserSelectedOrderTypeLogEvent({
                        orderType: OrderTypes.DELIVERY_ORDER,
                    });
                    setSelected(OrderTypes.DELIVERY_ORDER);
                    selectOrderType(OrderTypes.DELIVERY_ORDER);
                    runAfterCheckAnimation(closeSelectOrderTypeDialog);
                }}
                disabled={!deliveryEnabled}
            />
            <BottomDialogCheckButton
                checked={selected === OrderTypes.TAKE_AWAY_ORDER}
                Icon={TakeAwayIcon}
                text={translate('Take Away')}
                subtext={takeAwayEnabled ? undefined : translate('Not available')}
                onClickRow={() => {
                    createUserSelectedOrderTypeLogEvent({
                        orderType: OrderTypes.TAKE_AWAY_ORDER,
                    });
                    setSelected(OrderTypes.TAKE_AWAY_ORDER);
                    selectOrderType(OrderTypes.TAKE_AWAY_ORDER);
                    runAfterCheckAnimation(closeSelectOrderTypeDialog);
                }}
                disabled={!takeAwayEnabled}
            />
            <BottomDialogCheckButton
                checked={selected === OrderTypes.SELF_SERVICE_ORDER}
                Icon={EatHereIcon}
                text={translate('Eat Here')}
                subtext={eatHereEnabled ? undefined : translate('Not available')}
                onClickRow={() => {
                    const selectedOrderType = serviceType === ServiceTypes.TABLE_SERVICE ? OrderTypes.TABLE_ORDER : OrderTypes.SELF_SERVICE_ORDER;
                    createUserSelectedOrderTypeLogEvent({
                        orderType: selectedOrderType,
                    });
                    setSelected(selectedOrderType);
                    selectOrderType(selectedOrderType);
                    runAfterCheckAnimation(closeSelectOrderTypeDialog);
                }}
                disabled={!eatHereEnabled}
            />
        </BottomDialog>
    );
}
