/**
 * @prettier
 */
import * as React from 'react';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getError } from 'src/components/form/utils/getError';
import { transformPercentInput } from 'src/components/form/utils/transformPercentInput';
import { transformPercentOutput } from 'src/components/form/utils/transformPercentOutput';
import { Input } from 'src/components/Input';
import { translate } from 'src/i18n/translate';

export function FormPercentNumberField({ name, label, placeholder, helperText, defaultValue = null, disabled, required, min, max }: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const inputRef = useRef<HTMLInputElement>();

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <Input
                    name={name}
                    label={label}
                    leftIcon={'%'}
                    value={transformPercentInput(value)}
                    onChange={(value: any) => onChange(transformPercentOutput(value))}
                    helperText={helperText}
                    placeholder={placeholder}
                    disabled={isSubmitting || disabled}
                    error={!!getError(errors, name)}
                    type='number'
                />
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
                min: {
                    value: (min as any)?.value ?? min,
                    message: (min as any)?.message ?? translate('Must be number equal to or larger than @number', { number: (min as any)?.value ?? min }),
                },
                max: {
                    value: (max as any)?.value ?? max,
                    message: (max as any)?.message ?? translate('Must be number equal to or less than @number', { number: (max as any)?.value ?? max }),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    min?:
        | number
        | {
              message: string;
              value: number;
          };
    max?:
        | number
        | {
              message: string;
              value: number;
          };
};
