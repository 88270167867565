/**
 * @prettier
 */
export const ProductLayouts = Object.freeze({
    LARGE: 'LARGE',
    MEDIUM: 'MEDIUM',
    SMALL: 'SMALL',
});

export type ProductLayout = (typeof ProductLayouts)[keyof typeof ProductLayouts];
