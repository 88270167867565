/**
 * @prettier
 */
import { envCONEKTA_PUBLIC_KEY } from 'src/env/envCONEKTA_PUBLIC_KEY';

export async function createConektaCardToken(request: CreateConektaCardTokenRequest): Promise<CreateConektaCardTokenResponse> {
    if (!window.Conekta.getPublicKey()) {
        window.Conekta.setPublicKey(envCONEKTA_PUBLIC_KEY());
    }
    return new Promise((resolve, reject: (error?: any) => void) => {
        window.Conekta.Token.create(
            {
                card: {
                    number: request.cardNumber,
                    name: request.name ?? '',
                    exp_year: request.expiry.substring(2, 4),
                    exp_month: request.expiry.substring(0, 2),
                    cvc: request.cvc,
                },
            },
            (response) => {
                resolve({
                    ok: true,
                    data: {
                        conektaCardToken: response.id,
                    },
                });
            },
            (error) => {
                console.error(error.message_to_purchaser ?? error.message);
                resolve({
                    ok: false,
                    data: {
                        errorMessage: error.message_to_purchaser ?? error.message,
                    },
                });
            },
        );
    });
}

export type CreateConektaCardTokenRequest = {
    cardNumber: string;
    expiry: string;
    name: string;
    cvc: string;
};

export type CreateConektaCardTokenResponse = {
    ok: boolean;
    data: {
        conektaCardToken?: string;
        errorMessage?: string;
    };
};
