/**
 * @prettier
 */

import { makeStyles } from '@material-ui/core/styles';
import { CheckIcon, CrossIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { validatePassword } from 'src/services/customer/validatePassword';

export function PasswordValidatorIndicator({ password }: Props): React.ReactElement {
    const classes = useStyles();

    const passwordValidation = validatePassword(password);

    return (
        <div className={classes.container}>
            <div className={classes.validation}>
                {passwordValidation.numberValidator ? (
                    <CheckIcon title={translate('Password number validation passed')} />
                ) : (
                    <CrossIcon color='#f44336' title={translate('Password number validation failed')} />
                )}
                <Text>{translate('Password should have at least one number')}</Text>
            </div>
            <div className={classes.validation}>
                {passwordValidation.lengthValidator ? (
                    <CheckIcon title={translate('Password length validation passed')} />
                ) : (
                    <CrossIcon color='#f44336' title={translate('Password length validation failed')} />
                )}
                <Text>{translate('Password should have at least 8 characters')}</Text>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        gap: 4,
    },
    validation: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        gap: 4,
    },
}));

type Props = {
    password: string;
};
