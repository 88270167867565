/**
 * @prettier
 */

export const RestaurantPaymentMethodTypes = Object.freeze({
    PIDE_DIRECTO: 'PIDE_DIRECTO',
    RESTAURANT: 'RESTAURANT',
});

export type RestaurantPaymentMethodType = (typeof RestaurantPaymentMethodTypes)[keyof typeof RestaurantPaymentMethodTypes];
