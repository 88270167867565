/**
 * @prettier
 */
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Text } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Footer } from 'src/components/Footer';
import { MenuItem } from 'src/components/MenuItem';
import { MenuItemsContainer } from 'src/components/MenuItemsContainer';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { AppTheme } from 'src/styles/AppTheme';
import { theme } from 'src/styles/theme';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function MenuItems(): React.ReactElement {
    const classes = useStyles();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const sectionElements = useRef<Array<HTMLDivElement | null>>([]);

    const openCategory = useSelector((state) => state.app.openCategory.category);
    const animationToCategory = useSelector((state) => state.app.openCategory.animationToCategory);
    const restaurantMenu = useSelector((state) => state.app.restaurantMenu);
    const menuItemsFound = useSelector((state) => state.app.menuItemsFound);

    const scrollContainerTo = useAction(actions.scrollContainerTo);

    const sections = restaurantMenu?.menus[0]?.categories;
    const section = sections?.find((s) => s.menuCategoryId === openCategory);
    const MENU_TOP_POSITION = smallScreen ? 250 : 250 + 60;

    useEffect(() => {
        if (section && animationToCategory) {
            scrollContainerTo(getScrollToPosition());
        }
    }, [section, animationToCategory, openCategory]);

    const getScrollToPosition = () => {
        let scrollTo = 0;

        const sectionSelectedIdx = sectionElements.current.findIndex((element) => element?.id === openCategory);
        if (sectionSelectedIdx <= 0) return scrollTo;

        const elementsBeforeSectionSelected = sectionElements.current.slice(0, sectionSelectedIdx);
        for (const elementBefore of elementsBeforeSectionSelected) {
            scrollTo += elementBefore?.clientHeight ?? 0;
        }
        return scrollTo + MENU_TOP_POSITION;
    };

    if (menuItemsFound) {
        return (
            <div className={classes.container}>
                {menuItemsFound.map((menuItem) => (
                    <MenuItem menuItem={menuItem} key={menuItem.menuItemId} />
                ))}
            </div>
        );
    }

    return (
        <div className={classes.sectionContainer}>
            {sections.map((section, idx) => (
                <MenuItemsContainer key={section.menuCategoryId} section={section} idx={idx} sectionElements={sectionElements} />
            ))}
            <Footer>
                <Text className={classes.footerText}>{translate('With technology from')}</Text>
                <a className={classes.footerText} href={'https://www.ambit.la/'}>
                    Ambit.la
                </a>
            </Footer>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    sectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'space-between',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
    },
    footerText: {
        fontFamily: AppTheme.typography.regular,
        fontSize: 14,
    },
}));
