/**
 * @prettier
 */
import * as React from 'react';
import { CheckoutElement } from 'src/components/checkout/CheckoutElement';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function SelectTableButton(): React.ReactElement {
    const table = useSelector((state) => state.app.table);

    const openSelectTableDialog = useAction(actions.openSelectTableDialog);

    return (
        <CheckoutElement
            title={translate('Table')}
            content={table ? table : translate('No table')}
            action={openSelectTableDialog}
            actionTitle={table ? translate('Change') : translate('Select Table')}
        />
    );
}
