/**
 * @prettier
 */
import { pidedirectoApiMethodPublic } from 'src/api/pidedirectoApiMethodPublic';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentLinkId } from 'src/types/Id';
import type { PaymentLinkVm } from 'src/types/PaymentLinkVm';

export async function findPaymentLinkApi(request: FindPaymentLinkApiRequest): ApiSauceResponse<PaymentLinkVm> {
    return pidedirectoApiMethodPublic('paymentLink/findPaymentLinkApi', request);
}

export type FindPaymentLinkApiRequest = {
    paymentLinkId: PaymentLinkId;
};
