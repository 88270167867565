/**
 * @prettier
 */
import { pidedirectoApiMethodPublic } from 'src/api/pidedirectoApiMethodPublic';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CardType } from 'src/constants/CardType';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { RegimeFiscal } from 'src/constants/RegimeFiscal';
import type { EmailAddress, OrderId } from 'src/types/Id';

export async function createMexicanOrderInvoiceApi(request: CreateMexicanOrderInvoiceApiRequest): ApiSauceResponse<void> {
    return pidedirectoApiMethodPublic('invoice/createMexicanOrderInvoiceApi', request);
}

export type CreateMexicanOrderInvoiceApiRequest = {
    orderId: OrderId;
    paymentMethod: PaymentMethod;
    rfc: string;
    businessName: string;
    regimeFiscal: RegimeFiscal;
    cardType?: CardType;
    email: EmailAddress;
    street: string;
    zipCode: string;
};
