/**
 * @prettier
 */
import type { CartItemVm } from 'src/types/CartItemVm';
import type { MenuItemId } from 'src/types/Id';

export function setItemSessionStorage(key: string, value: any) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
}

export function setCartItemsInSessionStorage({ cartItems, cartItemsByMenuItem }: { cartItems: Array<CartItemVm>; cartItemsByMenuItem: Partial<Record<MenuItemId, Array<CartItemVm>>> }): void {
    setItemSessionStorage('cartItems', cartItems);
    setItemSessionStorage('cartItemsByMenuItem', cartItemsByMenuItem);
}
