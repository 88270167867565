/**
 * @prettier
 */
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Text } from '@pidedirecto/ui';
import * as React from 'react';
import { BottomDrawer } from 'src/components/BottomDrawer';
import { BottomDrawerTopBar } from 'src/components/BottomDrawerTopBar';
import { Footer } from 'src/components/Footer';
import { GoogleMapsRestaurantLocations } from 'src/components/googlemaps/GoogleMapsRestaurantLocations';
import { SearchRestaurantItem } from 'src/components/SearchRestaurantItem';
import { translate } from 'src/i18n/translate';
import { RestaurantItemsList } from 'src/scenes/restaurantChainPage/RestaurantItemsList';
import { AppTheme } from 'src/styles/AppTheme';
import { theme } from 'src/styles/theme';
import { distanceInKm } from 'src/utils/geojson/distanceInKm';
import { transformImageUrlRestaurant } from 'src/utils/image/transformImageUrlRestaurant';
import { classNames } from 'src/utils/react/classNames';
import { useCurrentLocation } from 'src/utils/react/useCurrentLocation';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantChainBottomDrawer(): React.ReactElement {
    const classes = useStyles();
    const { location } = useCurrentLocation();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const restaurantChain = useSelector((state) => state.app.restaurantChain);
    const restaurants = useSelector((state) => state.app.restaurants);
    const addressLocationMap = useSelector((state) => state.app.addressLocationMap);
    const restaurant = useSelector((state) => state.app.restaurant);
    const isCartDialogOpen = useSelector((state) => state.app.cartDialog.open);
    const isCheckoutDialogOpen = useSelector((state) => state.app.checkoutDialog.open);

    const isFixed = isCartDialogOpen || isCheckoutDialogOpen;

    const locationToMeasure = addressLocationMap?.location || location;
    const restaurantsSortedByDistance = [...restaurants]
        ?.sort((a, b) => (distanceInKm(a.location, locationToMeasure) ?? 0) - (distanceInKm(b.location, locationToMeasure) ?? 0))
        .filter((restaurant) => restaurant.urlPathname && restaurant.webOrdersEnabled);

    return (
        <BottomDrawer className={classNames(isFixed ? classes.positionFixed : classes.positionSticky, classes.bottomDrawer)}>
            <div className={classes.stickyContainer}>
                <BottomDrawerTopBar />
                <div className={classes.restaurantChainInfo}>
                    {restaurant?.logoImageUrl && <img className={classes.logo} src={transformImageUrlRestaurant(restaurant?.logoImageUrl)} draggable='false' alt='imageUrl' />}
                    <div className={classes.textContainer}>
                        <Text variant={'title'} className={classes.restaurantChainName}>
                            {translate('Welcome to @restaurantChainName!', { restaurantChainName: restaurantChain.name })}
                        </Text>
                        <Text className={classes.text}>{translate('Select the nearest branch')}</Text>
                    </div>
                </div>
            </div>
            <div className={classes.content}>
                <RestaurantItemsList restaurantsSortedByDistance={restaurantsSortedByDistance} locationToMeasure={locationToMeasure} />
                <div className={classes.mapContainer}>
                    <GoogleMapsRestaurantLocations customerLocation={locationToMeasure} restaurantsSortedByDistance={restaurantsSortedByDistance} />
                </div>
            </div>
            {!smallScreen && <SearchRestaurantItem customerLocation={location} />}
            <Footer>
                <Text className={classes.footerText}>{translate('With technology from')}</Text>
                <a className={classes.footerText} href={'https://www.ambit.la/'}>
                    Ambit.la
                </a>
            </Footer>
        </BottomDrawer>
    );
}
const useStyles = makeStyles((theme) => ({
    bottomDrawer: {
        [theme.breakpoints.up('md')]: {
            minHeight: 'calc(100vh - 100px)',
        },
    },
    positionFixed: {
        position: 'fixed',
    },
    positionSticky: {
        position: 'sticky',
    },
    stickyContainer: {
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 99,
        [theme.breakpoints.up('sm')]: {
            paddingTop: 32,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        },
    },
    content: {
        display: 'flex',
        width: '100%',
        alignItems: 'flex-start',
        gap: 24,
    },
    mapContainer: {
        height: '50vh',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    restaurantChainInfo: {
        display: 'flex',
        alignItems: 'center',
    },
    restaurantChainName: {
        fontFamily: theme.typography.bold,
        fontSize: 20,
        color: '#393B41',
        margin: 0,
        [theme.breakpoints.up('sm')]: {
            fontSize: 24,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 26,
            margin: 0,
        },
    },
    text: {
        fontSize: 12,
        fontWeight: 500,
        color: '#4F586E',
        lineHeight: '14px',
        margin: 0,
        marginBottom: 8,
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 16,
            margin: 0,
        },
    },
    logo: {
        width: 35,
        height: 35,
        marginRight: 12,
        borderRadius: '50%',
        background: 'white',
        objectFit: 'cover',
    },
    textContainer: {
        marginBottom: 8,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 16,
        },
    },
    footerText: {
        fontFamily: AppTheme.typography.regular,
        fontSize: 14,
    },
}));
