/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Text } from '@pidedirecto/ui';
import { useTheme } from '@pidedirecto/ui/hooks';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { CartContinueButton } from 'src/components/cart/CartContinueButton';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useSelector } from 'src/utils/react/useSelector';

export function CartSummary(): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const minimumOrderAmount = useSelector((state) => state.app.restaurant?.minimumOrderAmount);
    const maximumOrderAmount = useSelector((state) => state.app.restaurant?.maximumOrderAmount);
    const total = useSelector((state) => state.app.payment?.total);
    const subtotal = useSelector((state) => state.app.payment?.subtotal);
    const promotionsDiscount = useSelector((state) => state.app.payment?.promotionsDiscount);

    return (
        <div>
            <div className={classes.summaryContainer}>
                <div className={classes.row}>
                    <Text className={classes.summaryText} style={{ color: theme.palette?.text?.secondary }}>
                        {translate('Subtotal')}
                    </Text>
                    <Text className={classes.summaryText} style={{ color: theme.palette?.text?.secondary }}>
                        {formatAsCurrencyNumber(subtotal)}
                    </Text>
                </div>
                {!BigNumber(promotionsDiscount ?? 0).isZero() && (
                    <div className={classes.row}>
                        <Text className={classes.summaryText} style={{ color: theme.palette?.text?.secondary }}>
                            {translate('Promotions')}
                        </Text>
                        <Text className={classes.summaryText} style={{ color: theme.palette?.text?.secondary }}>
                            -{formatAsCurrencyNumber(promotionsDiscount)}
                        </Text>
                    </div>
                )}
            </div>
            <div className={classes.line}></div>
            <div className={classes.row}>
                <Text className={classes.amountText}>{translate('Amount to pay')}</Text>
                <Text weight='semiBold' className={classes.amount}>
                    {formatAsCurrencyNumber(total)}
                </Text>
            </div>
            {!!minimumOrderAmount && (
                <Text>
                    {translate('Minimum order amount')}: {formatAsCurrencyNumber(minimumOrderAmount)}
                </Text>
            )}
            {!!maximumOrderAmount && (
                <Text>
                    {translate('Maximum order amount')}: {formatAsCurrencyNumber(maximumOrderAmount)}
                </Text>
            )}
            <CartContinueButton />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    summaryContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
    },
    line: {
        width: '100%',
        height: 1,
        backgroundColor: 'rgba(0,0,0,0.2)',
        marginTop: 12,
        marginBottom: 12,
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
    },
    amountText: {
        fontSize: 20,
        color: 'white',
        [theme.breakpoints.up('sm')]: {
            color: '#0D3037',
        },
    },
    amount: {
        fontSize: 26,
        fontWeight: 700,
        color: 'white',
        [theme.breakpoints.up('sm')]: {
            color: '#0D3037',
        },
    },
    button: {
        display: 'grid',
        placeItems: 'center',
        width: '100%',
        height: 60,
        fontSize: 20,
        fontWeight: 600,
        marginTop: 24,
        [theme.breakpoints.down('xs')]: {
            backgroundColor: 'white',
            color: theme.palette.primary.main,
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
    summaryText: {
        fontSize: 18,
    },
}));
