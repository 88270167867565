/**
 * @prettier
 */
export const AuthenticationTypes = Object.freeze({
    SMS: 'SMS',
    WHATSAPP: 'WHATSAPP',
    EMAIL: 'EMAIL',
    PASSWORD: 'PASSWORD',
});

export type AuthenticationType = (typeof AuthenticationTypes)[keyof typeof AuthenticationTypes];
