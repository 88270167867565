/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import type { PromoCodeVm } from 'src/api/pidedirecto/types/PromoCodeVm';
import type { OrderType } from 'src/constants/OrderType';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import { isFirstOrderPromoCode } from 'src/modules/promoCode/utils/isFirstOrderPromoCode';
import type { MenuItemId } from 'src/types/Id';

export function isPromoCodeApplicable({ promoCode, orderType, paymentMethod, amount, customerDeliveryCost, menuItemIds, numberOfOrders }: Params): boolean {
    if (promoCode.orderTypes) {
        if (!isPromoCodeApplicableForOrderType(promoCode, orderType)) {
            return false;
        }
    }
    if (promoCode.paymentMethods) {
        if (!isPromoCodeApplicableForPaymentMethod(promoCode, paymentMethod)) {
            return false;
        }
    }
    if (promoCode.minOrderAmount) {
        if (!isPromoCodeApplicableForMinOrderAmount(promoCode, amount, customerDeliveryCost)) {
            return false;
        }
    }
    if (promoCode.menuItemIds) {
        if (!isPromoCodeApplicableForOrderItems(promoCode, menuItemIds)) return false;
    }

    if (isFirstOrderPromoCode(promoCode.promoType)) {
        if (numberOfOrders > 0) return false;
    }

    return true;
}

export function isPromoCodeApplicableForOrderType(promoCode?: PromoCodeVm, orderType?: OrderType): boolean {
    if (!promoCode?.orderTypes) {
        return true;
    }
    return !!orderType && promoCode.orderTypes.includes(orderType);
}

export function isPromoCodeApplicableForPaymentMethod(promoCode?: PromoCodeVm, paymentMethod?: PaymentMethod): boolean {
    if (!promoCode?.paymentMethods) {
        return true;
    }
    return !!paymentMethod && promoCode.paymentMethods.includes(paymentMethod);
}

export function isPromoCodeApplicableForMinOrderAmount(promoCode: PromoCodeVm | undefined, amount: string, deliveryCost?: string): boolean {
    if (!promoCode?.minOrderAmount) {
        return true;
    }
    if (promoCode.excludeDeliveryCost) {
        const amountWithoutDeliveryCost = BigNumber(amount).minus(deliveryCost ?? 0);
        return BigNumber(amountWithoutDeliveryCost).isGreaterThanOrEqualTo(promoCode?.minOrderAmount ?? 0);
    }
    return BigNumber(amount).isGreaterThanOrEqualTo(promoCode?.minOrderAmount ?? 0);
}

export function isPromoCodeApplicableForOrderItems(promoCode: PromoCodeVm | undefined, menuItemIds: Array<MenuItemId>): boolean {
    if (!promoCode?.menuItemIds) return true;

    return menuItemIds.some((menuItemId) => promoCode.menuItemIds?.includes(menuItemId));
}

type Params = {
    promoCode: PromoCodeVm;
    orderType: OrderType | undefined;
    paymentMethod: PaymentMethod | undefined;
    amount: string;
    customerDeliveryCost: string | undefined;
    numberOfOrders: number;
    menuItemIds: Array<MenuItemId>;
};
