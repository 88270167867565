/**
 * @prettier
 */
import distance from '@turf/distance';
import type { GeoJsonPointVm } from 'src/api/pidedirecto/types/GeoJsonPointVm';

export function distanceInKm(point1?: GeoJsonPointVm, point2?: GeoJsonPointVm): number | undefined {
    if (!point1 || !point2) {
        return;
    }
    return distance(point1, point2, { units: 'kilometers' });
}
