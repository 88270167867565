/**
 * @prettier
 */
import { isString } from 'src/utils/string/isString';

export function removeSpaceCharacters<T extends string | null | undefined>(value: T): T {
    if (value === undefined || value === null || !isString(value)) {
        return value;
    }
    return value.replace(/\s/g, '') as any;
}
