/**
 * @prettier
 */
import axios from 'axios';
import type { Country } from 'src/constants/Country';
import { isDevelopmentEnvironment } from 'src/utils/environment/isDevelopmentEnvironment';

export async function createMercadoPagoCardToken(request: CreateMercadoPagoCardTokenRequest): Promise<CreateMercadoPagoCardTokenResponse | undefined> {
    const mercadoPagoRequest = {
        card_number: request.cardNumber,
        expiration_month: `${request.expiry[0]}${request.expiry[1]}`,
        expiration_year: `20${request.expiry[2]}${request.expiry[3]}`,
        security_code: request.cvc,
        cardholder: {
            name: isDevelopmentEnvironment() ? 'APRO' : request.name,
        },
    } as const;

    try {
        const urlRefer = window.location.href;

        const { data } = await axios.post(
            `https://api.mercadopago.com/v1/card_tokens?public_key=${
                request.mercadoPagoPublicKey ?? credentials[isDevelopmentEnvironment() ? 'dev' : 'prod'][request.country]
            }&locale=es-419&js_version=2.0.0&referer=${urlRefer}`,
            mercadoPagoRequest,
        );

        console.log(`Credit card token created on Mercado Pago ${data}`);
        return { mercadoPagoCardToken: data.id };
    } catch (e: any) {
        console.log(`Error adding Mercado Pago card ${e}`);
        return { errorMessage: e?.message };
    }
}

const credentials: any = {
    prod: {
        MX: 'APP_USR-281f0a7e-0295-4829-a8d9-75b8b04e794d',
        UY: 'APP_USR-535bc7dc-e1a2-408e-976e-c73d61f78e2a',
        AR: '',
        BR: '',
        PE: '',
        CL: '',
        PY: '',
    },
    dev: {
        MX: 'TEST-3996fe3a-a8b0-4a5a-9715-5714d220667d',
        UY: 'TEST-0546cfa7-4cdf-483c-aeec-f7e085ca0535',
        AR: 'TEST-0546cfa7-4cdf-483c-aeec-f7e085ca0535',
        BR: '',
        PE: '',
        CL: '',
        PY: '',
    },
};

export type CreateMercadoPagoCardTokenRequest = {
    mercadoPagoPublicKey?: string;
    cardNumber: string;
    expiry: string;
    name: string;
    cvc: string;
    email: string;
    country: Country;
    deviceId: string;
};

export type CreateMercadoPagoCardTokenResponse = {
    mercadoPagoCardToken?: string;
    errorMessage?: string;
};
