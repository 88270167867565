/**
 * @prettier
 */
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Button } from '@pidedirecto/ui';
import { BigNumber } from 'bignumber.js';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { CheckoutElement } from 'src/components/checkout/CheckoutElement';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { AppTheme } from 'src/styles/AppTheme';
import { theme } from 'src/styles/theme';
import { formatAsPercentage } from 'src/utils/number/formatAsPercentage';
import { roundDigits } from 'src/utils/number/roundDigits';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function DriverTip(): React.ReactElement | null {
    const classes = useStyles();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [driverTipValue, setDriverTipValue] = useState<string | undefined>(undefined);

    const driverTip = useSelector((state) => state.app.driverTip);
    const orderType = useSelector((state) => state.app.orderType);
    const hideEcommerceDriverTipEnabled = useSelector((state) => state.app.restaurant?.hideEcommerceDriverTipEnabled);
    const pideDirectoTipAsPercentageEnabled = useSelector((state) => state.app.restaurant?.pideDirectoTipAsPercentageEnabled);
    const pideDirectoDefaultTipPercentage = useSelector((state) => state.app.restaurant?.pideDirectoDefaultTipPercentage);
    const pideDirectoCustomTipPercentage = useSelector((state) => state.app.restaurant?.pideDirectoCustomTipPercentage);
    const pideDirectoTipEnabled = useSelector((state) => state.app.restaurant?.pideDirectoTipEnabled);
    const subtotalAfterDiscount = useSelector((state) => state.app.payment?.subtotalAfterDiscount);

    const openOtherDriverTipDialog = useAction(actions.openOtherDriverTipDialog);
    const setDriverTip = useAction(actions.setDriverTip);

    useEffect(() => {
        if (!driverTipValue && !!pideDirectoDefaultTipPercentage) {
            setDriverTipValue(calculateTip(pideDirectoDefaultTipPercentage));
            setDriverTip(calculateTip(pideDirectoDefaultTipPercentage));
        }
    }, [pideDirectoDefaultTipPercentage, driverTipValue]);

    const handleChange = (value: any, newDriverTip: any) => {
        if (newDriverTip === 'other') {
            openOtherDriverTipDialog();
            setDriverTipValue(newDriverTip);
            return;
        }
        setDriverTipValue(newDriverTip);
        setDriverTip(newDriverTip);
    };

    const calculateTip = (tipPercentage: number) => {
        if (!pideDirectoTipAsPercentageEnabled) return;
        return roundDigits(BigNumber(subtotalAfterDiscount).multipliedBy(tipPercentage));
    };

    const getDriverTips = () => {
        const driverTips = Object.values(DefaultTips);
        if (!!pideDirectoCustomTipPercentage) {
            const indexToPushCustomTip = driverTips.findIndex((driverTip) => driverTip > pideDirectoCustomTipPercentage);
            if (indexToPushCustomTip < 0) driverTips.push(pideDirectoCustomTipPercentage);
            else driverTips.splice(indexToPushCustomTip, 0, pideDirectoCustomTipPercentage);
        }
        return driverTips;
    };

    const getDescription = () => {
        if (!pideDirectoTipEnabled || !pideDirectoDefaultTipPercentage) return '';
        return translate('Tip is fixed');
    };

    if (!isDeliveryOrder(orderType) || hideEcommerceDriverTipEnabled) return null;

    if (pideDirectoTipAsPercentageEnabled) {
        return (
            <CheckoutElement
                title={translate('Driver Tip')}
                description={getDescription()}
                content={
                    <div className={classes.container}>
                        <Button
                            variant={driverTipValue === '0' ? 'outline' : 'secondary'}
                            onClick={() => handleChange(undefined, calculateTip(0.0))}
                            classes={{ button: classes.buttonLeft }}
                            disabled={pideDirectoTipEnabled}
                        >
                            <div className={classes.tipContainer}>
                                <span>0%</span>
                                <span>{formatAsCurrencyNumber('0')}</span>
                            </div>
                        </Button>
                        {getDriverTips().map((driverTip: number) => (
                            <Button
                                variant={driverTipValue === calculateTip(driverTip) ? 'outline' : 'secondary'}
                                onClick={() => handleChange(undefined, calculateTip(driverTip))}
                                classes={{ button: classNames(classes.button, { [classes.buttonSelected]: driverTipValue === calculateTip(driverTip) && pideDirectoTipEnabled }) }}
                                disabled={pideDirectoTipEnabled}
                            >
                                <div className={classes.tipContainer}>
                                    <span>{formatAsPercentage(driverTip)}</span>
                                    <span>{formatAsCurrencyNumber(calculateTip(driverTip))}</span>
                                </div>
                            </Button>
                        ))}
                        <Button
                            variant={driverTipValue === 'other' ? 'outline' : 'secondary'}
                            onClick={() => handleChange(undefined, 'other')}
                            classes={{ button: classes.buttonRight }}
                            disabled={pideDirectoTipEnabled}
                        >
                            <div className={classes.tipContainer}>
                                <span>{translate('Other')}</span>
                                {driverTipValue === 'other' && <span>{formatAsCurrencyNumber(driverTip)}</span>}
                            </div>
                        </Button>
                    </div>
                }
            />
        );
    }

    return (
        <CheckoutElement
            title={translate('Driver Tip')}
            content={
                <div className={classes.container}>
                    <ToggleButtonGroup color='primary' value={driverTipValue} exclusive onChange={handleChange}>
                        <ToggleButton value='5' disabled={pideDirectoTipEnabled}>
                            $5
                        </ToggleButton>
                        <ToggleButton value='10' disabled={pideDirectoTipEnabled}>
                            $10
                        </ToggleButton>
                        <ToggleButton value='15' disabled={pideDirectoTipEnabled}>
                            $15
                        </ToggleButton>
                        {!smallScreen && (
                            <ToggleButton value='20' disabled={pideDirectoTipEnabled}>
                                $20
                            </ToggleButton>
                        )}
                        <ToggleButton value='other' disabled={pideDirectoTipEnabled}>
                            {translate('Other')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            }
        />
    );
}

const DefaultTips = {
    '5': 0.05,
    '10': 0.1,
    '15': 0.15,
};

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 5,
        '& > div': {
            width: '100%',
        },
        height: 60,
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        alignItems: 'flex-start',
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 14,
        paddingLeft: 8,
        textAlign: 'start',
    },
    text: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 14,
        paddingLeft: 8,
        textAlign: 'start',
        fontSize: AppTheme.listRowButton.fontSize,
        fontWeight: 600,
        color: '#4F586E',
    },
    icon: {
        color: theme.palette.primary.main,
    },
    tipContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    button: {
        borderRadius: 0,
        flexGrow: 1,
        height: '100%',
        outline: '0 !important',
        flexShrink: 1,
    },
    buttonLeft: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        flexGrow: 1,
        height: '100%',
        outline: '0 !important',
    },
    buttonRight: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        flexGrow: 1,
        height: '100%',
        outline: '0 !important',
    },
    buttonSelected: {
        border: `1px solid ${theme.palette.primary.main} !important`,
        color: `${theme.palette.primary.main} !important`,
    },
}));
