/**
 * @prettier
 */
import { StripeError, StripeErrors } from 'src/constants/StripeError';

export function isKnownStripeError(errorType?: StripeError): boolean {
    if (errorType === StripeErrors.CARD_ERROR) return true;
    if (errorType === StripeErrors.VALIDATION_ERROR) return true;
    if (errorType === StripeErrors.INVALID_REQUEST_ERROR) return true;
    return false;
}
