/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog } from '@pidedirecto/ui';
import { useNotification } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { hasUserSignedUpApi } from 'src/api/pidedirecto/customer/hasUserSignedUpApi';
import { setMobileNumberApi } from 'src/api/pidedirecto/customer/setMobileNumberApi';
import type { GetAppContextSignedInApiResponse } from 'src/api/pidedirecto/getAppContextSignedInApi';
import { Form } from 'src/components/form/Form';
import { FormVerificationCodeField } from 'src/components/form/FormVerificationCodeField';
import { Text } from 'src/components/Text';
import { AuthenticationTypes } from 'src/constants/AuthenticationType';
import { LogEventTypes } from 'src/constants/LogEventType';
import { SECONDS } from 'src/constants/TimeUnit';
import { AwsFacade } from 'src/facade/aws/AwsFacade';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { useGetAppContext } from 'src/services/useGetAppContext';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isProductionEnvironment } from 'src/utils/environment/isProductionEnvironment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { formatAsInternationalPhoneNumber } from 'src/utils/string/formatAsInternationalPhoneNumber';
import { getUrlSubdomain } from 'src/utils/url/getUrlSubdomain';

const timeout: any = null;

export function EnterVerificationCodeDialog(): React.ReactElement {
    const classes = useStyles();

    const form = useForm();
    const notification = useNotification();
    const { control } = form;

    const input: any = useRef(null);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<{ message: string }>();
    const [secondsLeft, setSecondsLeft] = useState<number | undefined>(60);

    const restaurant = useSelector((state) => state.app.restaurant);
    const open = useSelector((state) => state.app.enterVerificationCodeDialog.open);
    const isSignUp = useSelector((state) => state.app.enterVerificationCodeDialog.isSignUp);
    const mobileNumber = useSelector((state) => state.app.enterVerificationCodeDialog.mobileNumber);
    const authenticationType = useSelector((state) => state.app.enterVerificationCodeDialog.authenticationType);
    const onSuccess = useSelector((state) => state.app.enterVerificationCodeDialog.onSuccess);

    const closeEnterVerificationCodeDialog = useAction(actions.closeEnterVerificationCodeDialog);
    const openSignInDialog = useAction(actions.openSignInDialog);
    const openCreateCustomerAccountDialog = useAction(actions.openCreateCustomerAccountDialog);
    const setMobileNumber = useAction(actions.setMobileNumber);
    const openEnterEmailDialog = useAction(actions.openEnterEmailDialog);
    const openEnterNameDialog = useAction(actions.openEnterNameDialog);

    const { getAppContextSignedIn } = useGetAppContext();

    const verificationCode = useWatch({ name: 'verificationCode', control, defaultValue: '' });

    useEffect(() => {
        if (open) {
            clearTimeout(timeout);
            setIsSubmitting(false);
            setError(undefined);
            setSecondsLeft(60);
            setTimeout(() => {
                input?.current?.focus();
            }, 300);
        }
    }, [open]);

    useEffect(() => {
        if (secondsLeft === undefined) return;
        if (secondsLeft === 0) {
            setSecondsLeft(undefined);
            return;
        }
        const timout = setTimeout(() => {
            setSecondsLeft(secondsLeft - 1);
        }, SECONDS);
        return () => clearTimeout(timout);
    }, [secondsLeft]);

    const onVerificationCodeEntered = async (form: any) => {
        setIsSubmitting(true);
        setError(undefined);
        const response = await AwsFacade.verifySignIn(mobileNumber, verificationCode, authenticationType);
        if (response.error) {
            setError(response.error);
            setIsSubmitting(false);
            input.current?.focus();
            return;
        }

        const hasUserSignedUp = await hasUserSignedUpBefore();

        if (hasUserSignedUp) {
            if (isSignUp) {
                notification({ message: translate('User already registered') });
            }
            await fetchAppContextRecursive();
            return;
        }

        closeEnterVerificationCodeDialog();
        if (!isSignUp) notification({ message: translate('Unregistered user') });
        openCreateCustomerAccountDialog({ onSuccess, mobileNumber });
        setIsSubmitting(false);
    };

    const hasUserSignedUpBefore = async () => {
        const response = await hasUserSignedUpApi();
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            setIsSubmitting(false);
            input.current?.focus();
            return;
        }

        return response.data;
    };

    const fetchAppContextRecursive = async () => {
        const appContextResponse = await getAppContextSignedIn({ urlSubdomain: getUrlSubdomain(), urlPathname: restaurant.urlPathname ?? '', preventAskForUserInfo: true });

        if (!appContextResponse.ok) {
            alert(translate('Failed to sign in, check you internet connection and press ok to retry'));
            fetchAppContextRecursive();
            return;
        }

        if (!appContextResponse.data) return;

        const appContext: GetAppContextSignedInApiResponse = appContextResponse.data as any;

        if (!appContext.mobileNumber) {
            const response = await setMobileNumberApi({ customerId: appContext.customerId, mobileNumber, urlSubdomain: getUrlSubdomain() });
            if (!response.ok) {
                alert(translate('Failed to sign in, check you internet connection and press ok to retry'));
                fetchAppContextRecursive();
                return;
            }
            setMobileNumber(mobileNumber);
        }

        if (!appContext.email) {
            closeEnterVerificationCodeDialog();
            openEnterEmailDialog({
                onSuccess: () => {
                    if (!!appContext.firstName && !!appContext.lastName) return;

                    openEnterNameDialog({ onSuccess });
                },
            });
            setIsSubmitting(false);
            return;
        }

        if (!appContext.firstName || !appContext.lastName) {
            closeEnterVerificationCodeDialog();
            openEnterNameDialog({ onSuccess });
            setIsSubmitting(false);
            return;
        }

        closeEnterVerificationCodeDialog();
        onSuccess(appContext);
        setIsSubmitting(false);
        createUiInteractionLogEvent({
            logEventType: LogEventTypes.USER_SIGNED_IN_TO_PIDE_DIRECTO,
        });
    };

    return (
        <>
            <Dialog open={open} loading={isSubmitting} classes={{ dialog: classes.dialog, title: classes.title }} title={translate('Confirm your code')} onClose={closeEnterVerificationCodeDialog}>
                <Form form={form} onSubmit={onVerificationCodeEntered}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {authenticationType === AuthenticationTypes.EMAIL && (
                                <Text style={{ textAlign: 'center', marginBottom: 12, fontSize: 12 }}>
                                    {translate('The verification code was sent by email to @mobileNumber', { mobileNumber: formatAsInternationalPhoneNumber(mobileNumber) })}
                                </Text>
                            )}
                            {authenticationType === AuthenticationTypes.SMS && (
                                <Text style={{ textAlign: 'center', marginBottom: 12, fontSize: 12 }}>
                                    {translate('The verification code was sent to @mobileNumber', { mobileNumber: formatAsInternationalPhoneNumber(mobileNumber) })}
                                </Text>
                            )}
                            {authenticationType === AuthenticationTypes.WHATSAPP && (
                                <Text style={{ textAlign: 'center', marginBottom: 12, fontSize: 12 }}>
                                    {translate('The verification code was sent by WhatsApp to @mobileNumber', { mobileNumber: formatAsInternationalPhoneNumber(mobileNumber) })}
                                </Text>
                            )}
                            <div>
                                <FormVerificationCodeField
                                    name='verificationCode'
                                    placeholder='XXXXXX'
                                    autoFocus
                                    required
                                    disabled={isSubmitting}
                                    autoComplete={'off'}
                                    label={translate('Verification Code')}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            {!isProductionEnvironment() && (
                                <Text style={{ color: 'red', textAlign: 'center', fontSize: 12 }}>{translate('Your in a dev environment. Use code 11 11 11 to verify sign in/sing up.')}</Text>
                            )}
                        </Grid>
                        <Grid item xs={12} container justify='center'>
                            {!isSubmitting && !error && (
                                <div style={{ display: 'flex', gap: 8, alignItems: 'center', justifyContent: 'center' }}>
                                    <Text style={{ textAlign: 'center', fontSize: 14 }}>{translate('You didn’t receive the code?')}</Text>
                                    <Button
                                        onClick={() => {
                                            closeEnterVerificationCodeDialog();
                                            openSignInDialog();
                                        }}
                                        variant={'text'}
                                        disabled={isSubmitting || !!secondsLeft}
                                        classes={{ button: classes.textButton }}
                                    >
                                        {!!secondsLeft ? translate('in @secondsLeft seconds', { secondsLeft }) : translate('Send again')}
                                    </Button>
                                </div>
                            )}
                            {!!error && <Text style={{ color: 'red', textAlign: 'center', fontSize: 14 }}>{translate(error?.message)}</Text>}
                            <Grid item xs={12}>
                                <Button type={'submit'} classes={{ button: classes.button }}>
                                    {translate('Verify')}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container justify='center'>
                            <Text style={{ textAlign: 'center', fontSize: 10 }}>
                                {translate('By continuing, your accepting the ')}
                                <a className={classes.textTermsAndPrivacy} href='privacy' target='_blank'>
                                    {translate('Privacy Policy')}
                                </a>
                                {translate(' and ')}
                                <a className={classes.textTermsAndPrivacy} href='terms-and-conditions' target='_blank'>
                                    {translate('Terms and Conditions')}
                                </a>
                            </Text>
                        </Grid>
                    </Grid>
                </Form>
            </Dialog>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    textTermsAndPrivacy: {
        color: 'black',
        textDecoration: 'none',
        fontWeight: 'bold',
    },
    dialog: {
        maxWidth: '90%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 420,
        },
        overflow: 'hidden',
    },
    title: {
        color: theme.palette.text.primary,
    },
    textButton: {
        width: 'fit-content !important',
        marginLeft: 5,
        color: '#4D989E',
        '&:disabled': {
            backgroundColor: 'transparent',
        },
    },
    button: {
        marginTop: 10,
        width: '100%',
    },
}));
