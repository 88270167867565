/**
 * @prettier
 */
export const OrderTypes = Object.freeze({
    TABLE_ORDER: 'TABLE_ORDER',
    SELF_SERVICE_ORDER: 'SELF_SERVICE_ORDER',
    TAKE_AWAY_ORDER: 'TAKE_AWAY_ORDER',
    PICKUP_STATION_ORDER: 'PICKUP_STATION_ORDER',
    DELIVERY_ORDER: 'DELIVERY_ORDER',
    ROOM_SERVICE_ORDER: 'ROOM_SERVICE_ORDER',
});

export type OrderType = (typeof OrderTypes)[keyof typeof OrderTypes];
