/**
 * @prettier
 */
export const RegimesFiscal = Object.freeze({
    '601': '601',
    '603': '603',
    '605': '605',
    '606': '606',
    '612': '612',
    '620': '620',
    '621': '621',
    '622': '622',
    '623': '623',
    '624': '624',
    '625': '625',
    '626': '626',
});

export type RegimeFiscal = (typeof RegimesFiscal)[keyof typeof RegimesFiscal];
