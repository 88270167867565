/**
 * @prettier
 */
import moment from 'moment';
import OpeningHours from 'opening_hours';

export function getNextOpeningHoursChange(hours: string): string {
    if (!hours) return '';

    const openingHours = new OpeningHours(hours);
    const nextChange = openingHours.getNextChange();
    if (!nextChange) return '';

    return moment(nextChange).format('HH:mm');
}
