/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { AppTheme } from 'src/styles/AppTheme';

export function KeepShoppingButton(): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <a href='/' style={{ textDecoration: 'none' }}>
                <Text className={classes.text}>{translate('Keep shopping')}</Text>
            </a>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        color: theme.palette.primary.main,
        fontSize: 15,
        lineHeight: '20px',
        fontFamily: AppTheme.typography.medium,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
