/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { getCardsApi } from 'src/api/pidedirecto/card/getCardsApi';
import { getManagePlexoCardsUrlApi } from 'src/api/pidedirecto/card/getManagePlexoCardsUrlApi';
import { runAfterCheckAnimation } from 'src/components/BottomDialogCheckButton';
import { LoadingContentProgress } from 'src/components/LoadingContentProgress';
import { PaymentMethod, PaymentMethods } from 'src/constants/PaymentMethod';
import { SECONDS } from 'src/constants/TimeUnit';
import { actions } from 'src/reducers';
import { createUserSelectedPaymentMethodLogEvent } from 'src/services/logEvent/createUserSelectedPaymentMethodLogEvent';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { wait } from 'src/utils/wait';

export function PlexoCustomerCards({ selectedPaymentMethod, onStartLoading, onEndLoading }: Props): React.ReactElement {
    const classes = useStyles();
    const plexoLoads = useRef(1);

    const [loading, setLoading] = useState(false);
    const [managePlexoCardsUrl, setManagePlexoCardsUrl] = useState('');

    const restaurantId = useSelector((state) => state.app.restaurant?.restaurantId);
    const customerId = useSelector((state) => state.app.customerId);

    const selectPaymentMethod = useAction(actions.selectPaymentMethod);
    const closeSelectPaymentMethodDialog = useAction(actions.closeSelectPaymentMethodDialog);

    useEffect(() => {
        getManagePlexoCardsUrl();
    }, [restaurantId, customerId]);

    const getManagePlexoCardsUrl = async () => {
        setLoading(true);
        const response = await getManagePlexoCardsUrlApi({
            restaurantId,
            customerId,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setManagePlexoCardsUrl(response.data);
    };

    const handleLoadPlexoForm = async () => {
        if (plexoLoads.current === 1) {
            plexoLoads.current = plexoLoads.current + 1;
            return;
        }
        await getSelectedCard();
    };

    const getSelectedCard = async () => {
        onStartLoading();
        setLoading(true);
        await wait(2 * SECONDS);
        const response = await getCardsApi({ customerId, restaurantId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const selectedPlexoCard = response.data.find((card) => card.isPlexoCardSelected);
        if (!selectedPlexoCard) {
            getSelectedCard();
            return;
        }

        createUserSelectedPaymentMethodLogEvent({
            paymentMethodChangedFrom: selectedPaymentMethod,
            paymentMethodChangedTo: PaymentMethods.CREDIT_CARD,
        });
        selectPaymentMethod({ paymentMethod: PaymentMethods.CREDIT_CARD, card: selectedPlexoCard });
        runAfterCheckAnimation(closeSelectPaymentMethodDialog);

        setLoading(false);
        onEndLoading();
        setManagePlexoCardsUrl('');
    };

    return (
        <div className={classes.container}>
            {loading && <LoadingContentProgress loading={loading} />}
            {!loading && <iframe src={managePlexoCardsUrl} className={classes.plexoForm} onLoad={handleLoadPlexoForm} />}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '40vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            color: `${theme.palette.primary.main} !important`,
        },
    },
    plexoForm: {
        width: '100%',
        height: '100%',
        border: 0,
    },
}));

type Props = {
    selectedPaymentMethod: PaymentMethod;
    onStartLoading: any;
    onEndLoading: any;
};
