/**
 * @prettier
 */
import { nonBlanks } from 'src/utils/filter/nonBlanks';
import { isObject } from 'src/utils/object/isObject';

export function fullName(...value: Array<Name | string | null | undefined | undefined>): string {
    if (!value) {
        return '';
    }
    if (isObject(value[0])) {
        const firstArg: Name = value[0] as any;
        if (!firstArg) {
            return '';
        }
        return [firstArg.firstName, firstArg.lastName].filter(nonBlanks).join(' ');
    }

    const args: Array<string | null | undefined | undefined> = value as any;
    return args.filter(nonBlanks).join(' ');
}

export type Name = {
    firstName: any; // firstName?: string, is not working with flow for some reason,
    lastName: any; // lastName?: string, is not working with flow for some reason
};
