/**
 * @prettier
 */
import type { LanguageTag } from 'src/types/Id';

export const Languages = Object.freeze({
    ES: 'es' as LanguageTag,
    ES_MX: 'es-MX' as LanguageTag,
    EN_US: 'es-MX' as LanguageTag,
});

export type Language = (typeof Languages)[keyof typeof Languages];
