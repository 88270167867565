/**
 * @prettier
 */
import moment from 'moment-timezone';
import type { TimeZone } from 'src/constants/TimeZone';
import { upperCaseFirstLetterInString } from 'src/utils/transform/upperCaseFirstLetterInString';

export function formatPickupTime(pickupTime: string | Date | undefined, timeZone: TimeZone): string {
    if (!pickupTime) {
        return '';
    }
    return upperCaseFirstLetterInString(
        moment.tz(pickupTime, timeZone).calendar(null, {
            sameElse: 'llll',
        }),
    );
}
