/**
 * @prettier
 */
import * as React from 'react';

export function MinusIcon({ color, width, height }: Props): React.ReactElement {
    return (
        <svg width={width || '24'} height={height || '24'} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M19.5 12H4.5' stroke={color || 'currentColor'} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
}

type Props = {
    color?: string;
    width?: number | string;
    height?: number | string;
};
