/**
 * @prettier
 */
import type { Uuid } from 'src/types/Id';

export function toShortId(uuid: Uuid): string;
export function toShortId(uuid: undefined): void;

export function toShortId(uuid?: Uuid): string | undefined {
    return uuid?.substring(0, 6)?.toUpperCase();
}
