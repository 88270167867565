/**
 * @prettier
 */
import { pidedirectoApiMethodPublic } from 'src/api/pidedirectoApiMethodPublic';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { DriverId, OrderId } from 'src/types/Id';

export async function getDriverPositionApi(request: GetDriverPositionApiRequest): ApiSauceResponse<DriverPositionVm> {
    return pidedirectoApiMethodPublic('driverPosition/getDriverPositionApi', request);
}

type GetDriverPositionApiRequest = {
    orderId: OrderId;
};

export type DriverPositionVm = {
    driverId: DriverId;
    position?: GeoJsonPoint;
    positionedAt?: Date;
};
