/**
 * @prettier
 */
import { ButtonBase } from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import * as React from 'react';
import { BottomDialog } from 'src/components/BottomDialog';
import { BottomDialogHeader } from 'src/components/BottomDialogHeader';
import { Text } from 'src/components/Text';
import { LogEventTypes } from 'src/constants/LogEventType';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { toLatLng } from 'src/utils/googlemaps/toLatLng';
import { getRestaurantOpeningHoursPerDay } from 'src/utils/hours/getRestaurantOpeningHoursPerDay';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantOpeningHoursDialog(): React.ReactElement {
    const classes = useStyles();

    const open = useSelector((state) => state.app.restaurantOpeningHoursDialog.open);
    const restaurant = useSelector((state) => state.app.restaurant);

    const closeRestaurantOpeningHoursDialog = useAction(actions.closeRestaurantOpeningHoursDialog);

    const LOCAL_FORMAT = 'YYYY-MM-DDTHH:mm';
    const now = moment(moment.tz(restaurant.timeZone).format(LOCAL_FORMAT)).toDate();

    const currentDay = now.getDay() === 0 ? 6 : now.getDay() - 1;

    useEffect(() => {
        if (open) {
            createUiInteractionLogEvent({
                logEventType: LogEventTypes.USER_OPENED_RESTAURANT_OPENING_HOURS_DIALOG,
            });
        }
    }, [open]);

    const gotoGoogleMaps = () => {
        const coords = toLatLng(restaurant.location);
        window.open(`https://www.google.com/maps/search/?api=1&query=${coords?.lat ?? ''},${coords?.lng ?? ''}`, '_blank')?.focus();
    };

    return (
        <BottomDialog open={open} onClickBackground={closeRestaurantOpeningHoursDialog} onClickHardwareBack={closeRestaurantOpeningHoursDialog}>
            <BottomDialogHeader header={translate('Restaurant Opening Hours')} onClose={closeRestaurantOpeningHoursDialog} />
            <List>
                {getRestaurantOpeningHoursPerDay(restaurant)?.map(
                    (
                        openingHours: {
                            day: string;
                            openingHours: string;
                        },
                        idx: number,
                    ) => (
                        <ListItem key={idx}>
                            <div className={classes.openingHoursContainer}>
                                <Text className={idx === currentDay ? classes.currentDayText : ''}>{openingHours.day}</Text>
                                <Text className={idx === currentDay ? classes.currentDayText : ''}>{openingHours.openingHours}</Text>
                            </div>
                        </ListItem>
                    ),
                )}
            </List>
            <div className={classes.addressContainer}>
                <DialogContentText style={{ fontWeight: 'bold' }}>{translate('Address')}</DialogContentText>
                <ButtonBase onClick={gotoGoogleMaps}>
                    <div className={classes.addressTextContainer}>
                        <Text className={classes.addressText}>{restaurant.street}</Text>
                        <LocationOnIcon className={classes.addressText} style={{ marginLeft: '5px' }} />
                    </div>
                </ButtonBase>
            </div>
        </BottomDialog>
    );
}

const useStyles = makeStyles((theme) => ({
    openingHoursContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    currentDayText: {
        color: theme.palette.primary.main,
    },
    addressContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    addressTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    addressText: {
        color: theme.palette.primary.main,
        fontSize: 18,
    },
}));
