/**
 * @prettier
 */
import * as React from 'react';

export function SuccessIcon(): React.ReactElement {
    return (
        <svg width='120' height='120' viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M60 0C26.8615 0 0 26.8615 0 60C0 93.1385 26.8615 120 60 120C93.1385 120 120 93.1385 120 60C120 26.8615 93.1385 0 60 0ZM78.8246 49.6123C79.2899 49.2143 79.6714 48.7277 79.9468 48.1808C80.2222 47.6339 80.386 47.0377 80.4287 46.4269C80.4714 45.816 80.3921 45.2028 80.1955 44.623C79.9989 44.0431 79.6888 43.5081 79.2834 43.0492C78.878 42.5903 78.3854 42.2167 77.8342 41.9501C77.2829 41.6835 76.6842 41.5292 76.0728 41.4963C75.4613 41.4634 74.8495 41.5524 74.2728 41.7583C73.6961 41.9641 73.1662 42.2827 72.7138 42.6954C64.2929 50.1233 57.5595 59.2676 52.9662 69.5138L44.8 61.3538C43.9251 60.5386 42.7679 60.0947 41.5722 60.1158C40.3765 60.1369 39.2356 60.6213 38.39 61.4669C37.5444 62.3126 37.06 63.4534 37.0389 64.6491C37.0178 65.8448 37.4617 67.002 38.2769 67.8769L51.4154 81.0215C51.9701 81.5756 52.6568 81.9792 53.4108 82.1943C54.1647 82.4093 54.961 82.4288 55.7245 82.2509C56.4881 82.0729 57.1937 81.7034 57.7749 81.1771C58.356 80.6509 58.7935 79.9853 59.0462 79.2431C62.9449 67.8117 69.7629 57.5974 78.8246 49.6123Z'
                fill='#13D48F'
            />
        </svg>
    );
}
