/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormCardNumberField } from 'src/components/form/cardDetails/FormCardNumberField';
import { FormCvcField } from 'src/components/form/cardDetails/FormCvcField';
import { FormExpiryField } from 'src/components/form/cardDetails/FormExpiryField';
import { FormHiddenField } from 'src/components/form/FormHiddenField';
import { translate } from 'src/i18n/translate';

export function FormCardDetailsField({ name, label, required, disabled }: Props): React.ReactElement {
    const { errors } = useFormContext();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormHiddenField name={`${name}.cardId`} />
                <FormHiddenField name={`${name}.brand`} />
                {!!label && (
                    <Typography variant='overline' color='textSecondary'>
                        {translate(label)}
                    </Typography>
                )}
                <FormCardNumberField name={name} rules={{ required }} disabled={disabled} label={translate('Card number')} />
            </Grid>
            <Grid item xs={6}>
                <FormExpiryField name={name} rules={{ required }} disabled={disabled} label={translate('Expiry date')} />
            </Grid>
            <Grid item xs={6}>
                <FormCvcField name={name} rules={{ required }} disabled={disabled} label={translate('CVV/CVC')} />
            </Grid>
        </Grid>
    );
}

type Props = {
    name: string;
    label?: string;
    required?: boolean;
    disabled?: boolean;
    icons?: boolean;
};
