/**
 * @prettier
 */
export const en: {
    [key: string]: string;
} = {
    'FOOD_WAS_COLD': 'Food was cold',
    'MISSING_OR_INCORRECT_ITEMS': 'Missing or incorrect items',
    'FOOD_WAS_NOT_PREPARED': 'Food was not prepared',
    'FOOD_ARRIVED_LATE': 'Food arrived late',
    'CONFUSED_PICK_UP_PLACE': 'Confused pick up place',
    'BAD_SERVICE': 'Bad service',
    'PUNCTUALITY': 'Punctuality',
    'APPLICATION': 'Application',
    'FOOD': 'Food',
    'PRESENTATION': 'Presentation',
    'SERVICE': 'Service',
    'CREDIT_CARD': 'Credit card',
    'CARD_ON_DELIVERY': 'Pay with card on delivery',
    'CASH': 'Cash',
    'MULTIPLE': 'Multiple',

    'OrderTypes.TABLE_ORDER': 'Eat here',
    'OrderTypes.SELF_SERVICE_ORDER': 'Eat here',
    'OrderTypes.TAKE_AWAY_ORDER': 'Take away',
    'OrderTypes.DELIVERY_ORDER': 'Delivery',
    'OrderTypes.ROOM_SERVICE_ORDER': 'Room service',

    '601': '601 - General de Ley Personas Morales',
    '603': '603 - Personas Morales con Fines no Lucrativos',
    '605': '605 - Sueldos y Salarios e Ingresos Asimilados a Salarios',
    '606': '606 - Arrendamiento',
    '612': '612 - Personas Físicas con Actividades Empresariales y Profesionales',
    '620': '620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
    '621': '621 - Incorporación Fiscal',
    '622': '622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
    '623': '623 - Opcional para Grupos de Sociedades',
    '624': '624 - Coordinados',
    '625': '625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
    '626': '626 - Régimen Simplificado de Confianza',
    '628': '628 - Hidrocarburos',
    '629': '629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales',
    '630': '630 - Enajenación de acciones en bolsa de valores',

    'DriverArrivesAtStoreTimes.DEFAULT': '45-60',
    'DriverArrivesAtStoreTimes.TIME_30_40_MIN': '60-70',
    'DriverArrivesAtStoreTimes.TIME_40_50_MIN': '70-80',
    'DriverArrivesAtStoreTimes.TIME_50_60_MIN': '80-90',

    'UnitsOfMeasurement.KILOGRAM': 'kg',
    'UnitsOfMeasurement.GRAM': 'gr',
    'UnitsOfMeasurement.LITRE': 'lt',
    'UnitsOfMeasurement.MILLILITER': 'ml',
    'TipTypes.AMOUNT': 'Amount',
    'TipTypes.PERCENT': 'Percent',
};
