/**
 * @prettier
 */
import { CardBrands } from 'src/constants/CardBrand';
import { Countries, Country } from 'src/constants/Country';
import { translate } from 'src/i18n/translate';
import type { CardVm } from 'src/types/CardVm';
import { upperCase } from 'src/utils/transform/upperCase';

export function translateCard(card?: CardVm): string {
    if (!card) {
        return '';
    }
    switch (upperCase(card.brand)) {
        case CardBrands.VISA: {
            return `${translate('Visa')} ${card.cardNumber}`;
        }
        case CardBrands.MASTERCARD: {
            return `${translate('Master Card')} ${card.cardNumber}`;
        }
        case CardBrands.AMERICAN_EXPRESS: {
            return `${translate('American Express')} ${card.cardNumber}`;
        }
        case CardBrands.SODEXO: {
            return `${translate('Sodexo')} ${card.cardNumber}`;
        }
        case CardBrands.CARNET:
        default: {
            return `${translate('Card')} ${card.cardNumber}`;
        }
    }
}

export function translateCardStatus(card: CardVm, country: Country): string {
    if (country !== Countries.MX) return '';
    if (card.blocked) return `${translate('BLOCKED')}`;
    if (card.needsValidation && !card.validated) return `${translate('NOT VALIDATED')}`;
    if (!card.needsValidation && !card.validated) return `${translate('NOT VALIDATED')}`;
    return '';
}
