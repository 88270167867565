/**
 * @prettier
 */
import * as React from 'react';

export function CopyIcon(): React.ReactElement {
    return (
        <svg width='17' height='19' viewBox='0 0 17 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M11.5769 13.8077V16.5769C11.5769 17.0865 11.1634 17.5 10.6538 17.5H2.65385C2.40904 17.5 2.17425 17.4027 2.00114 17.2296C1.82803 17.0565 1.73077 16.8217 1.73077 16.5769V6.11539C1.73077 5.60585 2.14431 5.19231 2.65385 5.19231H4.19231C4.60468 5.19203 5.01636 5.22606 5.42308 5.29405M11.5769 13.8077H14.3462C14.8557 13.8077 15.2692 13.3942 15.2692 12.8846V8.88462C15.2692 5.22513 12.6083 2.18841 9.11539 1.60175C8.70866 1.53375 8.29699 1.49972 7.88462 1.5H6.34616C5.83662 1.5 5.42308 1.91354 5.42308 2.42308V5.29405M11.5769 13.8077H6.34616C6.10134 13.8077 5.86655 13.7104 5.69344 13.5373C5.52033 13.3642 5.42308 13.1294 5.42308 12.8846V5.29405M15.2692 10.7308V9.19231C15.2692 8.45786 14.9775 7.7535 14.4581 7.23417C13.9388 6.71484 13.2344 6.42308 12.5 6.42308H11.2692C11.0244 6.42308 10.7896 6.32583 10.6165 6.15271C10.4434 5.9796 10.3462 5.74482 10.3462 5.5V4.26923C10.3462 3.90557 10.2745 3.54547 10.1354 3.20949C9.9962 2.87352 9.79222 2.56824 9.53507 2.31109C9.27792 2.05394 8.97264 1.84996 8.63667 1.7108C8.30069 1.57163 7.94059 1.5 7.57693 1.5H6.65385'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}
