/**
 * @prettier
 */
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect } from 'react';
import { OrderBottomDrawer } from 'src/components/bottomDrawers/OrderBottomDrawer';
import { Page } from 'src/components/Page';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { Pages } from 'src/constants/Page';
import { SECONDS } from 'src/constants/TimeUnit';
import { actions } from 'src/reducers';
import { OngoingOrderBanner } from 'src/scenes/OngoingOrderBanner';
import { useFetchOrder } from 'src/services/order/useFetchOrder';
import { useRestaurantTheme } from 'src/services/restaurant/useRestaurantTheme';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function OrderPage(): React.ReactElement {
    const classes = useStyles();

    const orderId = useSelector((state) => state.app.orderId);
    const orderStatus = useSelector((state) => state.app.orderStatus);
    const restaurant = useSelector((state) => state.app.restaurant);

    const restaurantTheme = useRestaurantTheme(restaurant);

    const setPage = useAction(actions.setPage);

    const fetchOrder = useFetchOrder();

    useEffect(() => {
        setPage(Pages.ORDER_PAGE);
    }, []);

    useEffect(() => {
        const pollUpdates = [OrderStatuses.NEW, OrderStatuses.ACCEPTED].includes(orderStatus);
        if (!pollUpdates && orderStatus) return;

        const interval = setInterval(async () => {
            fetchOrder(orderId);
        }, 2 * SECONDS);
        return () => clearInterval(interval);
    }, [orderId, orderStatus, orderId]);

    return (
        <ThemeProvider theme={restaurantTheme}>
            <Page title={'OrderPage'} className={classes.container}>
                <OngoingOrderBanner />
                <OrderBottomDrawer />
            </Page>
        </ThemeProvider>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        height: '100vh',
    },
}));
