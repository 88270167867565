/**
 * @prettier
 */
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Text } from 'src/components/Text';
import { AppTheme } from 'src/styles/AppTheme';
import { classNames } from 'src/utils/react/classNames';

export const listRowHeight = 48;

export function ListRowButton({ Icon, text, subtext, RightIcon, onClick, onClickRightIcon, disabled }: Props): React.ReactElement {
    const classes = useStyles();
    return (
        <div className={classNames(classes.container, disabled ? classes.containerDisabled : '')}>
            <ButtonBase className={classes.buttonContainer} onClick={onClick ? onClick : undefined} disabled={!onClick || disabled}>
                <Icon className={classes.leftIcon} />
                <div className={classes.textContainer}>
                    <Text className={classes.text}>{text}</Text>
                    {subtext && <Text className={classes.subtext}>{subtext}</Text>}
                </div>
                {!!RightIcon && !onClickRightIcon && <RightButton Icon={RightIcon} onClick={onClickRightIcon} />}
            </ButtonBase>
            {!!RightIcon && onClickRightIcon && <RightButton Icon={RightIcon} onClick={onClickRightIcon} />}
        </div>
    );
}

function RightButton({ Icon, onClick }: { Icon: React.ComponentType<any>; onClick: any }) {
    const classes = useStyles();
    if (!onClick) {
        return (
            <div className={classes.rightIconContainer}>
                <Icon className={classes.rightIcon} size={30} />
            </div>
        );
    }
    return (
        <ButtonBase className={classes.rightIconContainer} onClick={onClick}>
            <Icon className={classes.rightIcon} size={30} />
        </ButtonBase>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: listRowHeight,
    },
    containerDisabled: {
        '& *': {
            color: `${theme.palette.text.disabled} !important`,
        },
    },
    buttonContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minHeight: listRowHeight,
        paddingLeft: 8,
    },
    leftIcon: {
        color: theme.palette.primary.main,
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        alignItems: 'flex-start',
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 16,
        paddingLeft: 8,
        textAlign: 'start',
    },
    text: {
        fontSize: AppTheme.listRowButton.fontSize,
        fontWeight: 600,
        color: AppTheme.listRowButton.color,
    },
    subtext: {
        fontSize: AppTheme.listRowButton.subtext.fontSize,
        color: AppTheme.listRowButton.subtext.color,
    },
    rightIconContainer: {
        display: 'flex',
        height: 48,
        width: 52,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rightIcon: {
        color: theme.palette.primary.main,
        opacity: 0.6,
    },
}));

type Props = {
    Icon: React.ComponentType<any>;
    text: string;
    subtext?: string;
    RightIcon?: React.ComponentType<any>;
    onClick?: () => void;
    onClickRightIcon?: () => void;
    disabled?: boolean;
};
