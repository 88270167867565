/**
 * @prettier
 */
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

export function FormHiddenField({ name }: Props): React.ReactElement {
    const { register } = useFormContext();
    return <input type='hidden' ref={register} name={name} />;
}

export type Props = {
    name: string;
};
