/**
 * @prettier
 */
import * as React from 'react';

export function PidedirectoBigIcon(): React.ReactElement {
    return (
        <svg width='500' height='50' viewBox='0 0 571 104' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M300.151 51.0867C300.151 41.4662 300.151 31.8457 300.151 22.2252C300.151 21.3789 300.329 21.0671 301.264 21.0671C306.966 21.1117 312.667 21.0226 318.368 21.1117C323.401 21.2007 328.434 21.7352 333.021 24.0067C339.969 27.3917 344.512 32.9591 346.65 40.3081C349.278 49.3051 348.61 58.1239 344.379 66.5418C340.415 74.4253 333.778 78.8793 325.138 80.3491C322.643 80.7945 320.06 80.9726 317.521 81.0172C312.177 81.1062 306.876 81.0617 301.532 81.0617C300.106 81.0617 300.106 81.0617 300.106 79.5919C300.151 70.105 300.151 60.6181 300.151 51.0867ZM312.488 50.9531C312.488 57.2777 312.488 63.6022 312.488 69.9268C312.488 70.6395 312.667 70.9513 313.424 70.8622C315.606 70.7286 317.789 70.7286 319.926 70.5059C325.405 69.8823 329.681 67.1654 332.531 62.3997C336.183 56.2978 336.495 49.7505 334.446 43.1141C332.62 37.1459 328.478 33.4045 322.421 31.7566C319.526 30.9549 316.541 31.133 313.557 31.0885C312.667 31.0885 312.488 31.3557 312.488 32.202C312.488 38.4375 312.488 44.7175 312.488 50.9531Z'
                fill='#1F416D'
            />
            <path
                d='M0 0.0445394C0.489933 1.11759e-08 0.75717 0 1.06895 0C8.10617 0 15.1434 0 22.1806 0C22.8042 0 23.205 0.178158 23.6059 0.668091C33.0482 11.7139 42.5351 22.8042 52.022 33.8499C52.4229 34.2953 52.4674 34.6071 52.022 35.097C44.3167 44.094 36.6114 53.091 28.9061 62.0879C27.08 64.2258 25.2984 66.3192 23.4723 68.4125C23.2496 68.6798 22.8042 68.947 22.4479 68.947C15.1879 68.9915 7.92801 68.9915 0.623552 68.947C0.445394 68.947 0.311776 68.9025 0 68.8579C9.84321 57.3222 19.6419 45.9201 29.4405 34.429C19.6864 23.0714 9.93228 11.6248 0 0.0445394Z'
                fill='#63D6B3'
            />
            <path
                d='M96.5614 34.8743C92.2856 39.9073 87.9653 44.9402 83.6895 49.9732C79.2801 55.1398 74.8707 60.2618 70.4613 65.4284C69.4369 66.6309 68.4125 67.8335 67.299 69.1251C77.0977 80.5718 86.8964 92.0184 96.7396 103.51C96.4723 103.554 96.2942 103.599 96.116 103.599C88.9006 103.599 81.6407 103.599 74.4253 103.599C74.1135 103.599 73.6682 103.42 73.4455 103.198C63.7804 91.9293 54.1154 80.6608 44.4503 69.3924C44.4058 69.3478 44.3612 69.2588 44.2722 69.1251C45.9201 67.1654 47.6126 65.2057 49.3051 63.2459C55.6297 55.8524 61.9988 48.4143 68.368 41.0208C70.0605 39.061 71.7084 37.1013 73.4009 35.1416C73.6236 34.8743 74.0245 34.6516 74.3363 34.6516C81.7298 34.6516 89.1679 34.6516 96.5614 34.6516C96.5614 34.6516 96.5614 34.7407 96.5614 34.8743Z'
                fill='#63D6B3'
            />
            <path
                d='M226.795 75.9842C225.77 77.0532 224.79 78.2112 223.677 79.191C221.583 81.0172 219.089 81.9079 216.417 82.2643C211.206 83.0214 206.262 82.1752 201.808 79.3692C196.73 76.2069 194.058 71.3967 192.989 65.6065C192.677 63.7359 192.366 61.8207 192.455 59.95C192.9 51.3985 196.107 44.4058 204.035 40.3527C210.048 37.2795 216.239 36.9677 222.296 40.2636C223.944 41.1544 225.325 42.5797 226.839 43.7377C226.884 43.7377 226.928 43.7377 226.973 43.6931C226.973 36.6114 226.973 29.5742 227.017 22.4924C227.017 21.1117 227.017 21.1117 228.398 21.1117C231.427 21.1117 234.455 21.1117 237.484 21.1117C238.241 21.1117 238.508 21.2898 238.508 22.0915C238.508 41.4216 238.508 60.7517 238.508 80.0818C238.508 80.7499 238.419 81.1062 237.618 81.1062C234.455 81.0617 231.249 81.0172 228.086 81.0617C227.195 81.0617 226.973 80.7499 227.017 79.9482C227.062 78.6566 227.017 77.3649 227.017 76.0733C226.928 75.9842 226.884 75.9842 226.795 75.9842ZM226.839 60.2173C227.062 57.9903 226.438 55.6297 225.236 53.3582C221.895 46.9891 214.012 46.0983 209.157 50.0623C206.039 52.601 204.881 56.1196 204.614 59.95C204.391 62.6669 205.059 65.2502 206.396 67.6553C208.311 71.0403 211.25 72.911 215.036 73.3118C217.486 73.5791 219.846 72.8219 221.851 71.3076C225.458 68.6352 227.017 65.0275 226.839 60.2173Z'
                fill='#1F426D'
            />
            <path
                d='M124.265 51.0421C124.265 41.4662 124.265 31.8457 124.265 22.2697C124.265 21.1117 124.265 21.1117 125.423 21.1117C131.748 21.1117 138.072 21.0226 144.397 21.1117C148.94 21.1562 153.394 21.9579 157.358 24.3185C162.747 27.5699 165.286 32.4692 165.419 38.7047C165.464 41.288 165.553 43.8713 164.84 46.41C162.702 53.9817 157.58 58.3021 150.009 59.8609C148.183 60.2173 146.267 60.3509 144.397 60.3954C142.214 60.4845 139.987 60.3954 137.76 60.3954C136.602 60.3954 136.602 60.3954 136.602 61.5534C136.602 67.4772 136.602 73.4009 136.602 79.2801C136.602 81.1953 136.87 81.0172 134.821 81.0172C131.658 81.0172 128.452 80.9281 125.289 80.9726C124.443 80.9726 124.22 80.7054 124.22 79.8591C124.265 70.2832 124.265 60.6626 124.265 51.0421ZM136.602 40.709C136.602 43.6041 136.602 46.4546 136.602 49.3496C136.602 49.795 136.558 50.285 137.226 50.2404C139.809 50.1514 142.437 50.285 144.976 49.9732C148.45 49.5278 151.256 47.8353 152.458 44.3612C154.106 39.4619 152.547 33.1373 145.51 31.7566C142.927 31.2221 140.21 31.4448 137.538 31.3112C136.78 31.2667 136.558 31.5339 136.602 32.2465C136.647 35.097 136.602 37.903 136.602 40.709Z'
                fill='#1F426D'
            />
            <path
                d='M418.893 62.9787C418.982 63.5132 419.027 63.914 419.116 64.2703C420.363 68.5016 423.035 71.3076 427.445 72.1093C432.077 72.911 435.996 71.6193 438.802 67.6553C439.158 67.1209 439.47 66.9872 440.094 67.1654C443.033 68.0117 445.973 68.7688 448.957 69.5705C449.18 69.6151 449.402 69.7487 449.714 69.8378C449.536 70.1941 449.447 70.5059 449.269 70.8176C445.305 77.6767 439.515 81.7743 431.498 82.3979C424.015 82.9769 417.468 80.839 412.346 75.1825C409.139 71.6639 407.535 67.3881 407.046 62.6669C406.511 57.5894 407.402 52.8237 409.941 48.4143C413.236 42.6242 418.403 39.3283 424.906 38.3484C430.963 37.4131 436.709 38.3039 441.875 41.7334C447.086 45.1629 449.982 50.1514 451.006 56.2087C451.318 58.0348 451.273 59.95 451.362 61.8207C451.407 62.9342 451.362 62.9787 450.16 62.9787C440.138 62.9787 430.161 62.9787 420.14 62.9787C419.828 62.9787 419.427 62.9787 418.893 62.9787ZM439.604 56.3423C438.847 52.6456 436.976 49.9287 433.546 48.4589C431.854 47.7462 430.072 47.7017 428.246 47.7462C424.817 47.8353 422.189 49.3051 420.407 52.2002C419.65 53.4473 419.116 54.7835 418.938 56.3423C425.841 56.3423 432.656 56.3423 439.604 56.3423Z'
                fill='#1F426D'
            />
            <path
                d='M289.729 69.8823C288.66 72.0647 287.324 73.9799 285.676 75.6724C281.934 79.5919 277.347 81.8634 271.958 82.3533C265.41 82.9769 259.487 81.418 254.364 77.1868C250.356 73.8463 248.084 69.4815 247.283 64.3594C246.392 58.5248 247.105 52.9573 250.222 47.8798C253.474 42.5797 258.284 39.5064 264.386 38.4375C269.641 37.5022 274.764 38.0366 279.618 40.4418C286.344 43.7822 289.951 49.4387 291.065 56.6987C291.332 58.5248 291.199 60.44 291.288 62.3106C291.288 62.8005 291.11 63.0232 290.62 62.9787C290.352 62.9787 290.085 62.9787 289.818 62.9787C279.886 62.9787 269.998 62.9787 260.066 62.9787C258.818 62.9787 258.729 63.0678 259.086 64.2703C260.333 68.5016 263.005 71.3076 267.415 72.0647C272.091 72.8665 276.011 71.5303 278.817 67.5217C279.084 67.1209 279.307 66.9427 279.841 67.0763C283.003 67.9671 286.21 68.8134 289.372 69.7042C289.462 69.7042 289.551 69.7487 289.729 69.8823ZM279.44 56.3423C279.44 56.0751 279.485 55.8524 279.44 55.6742C278.906 53.4473 277.837 51.5321 276.1 49.9732C273.026 47.2118 267.014 47.0336 263.762 48.8597C260.912 50.5077 259.531 53.1355 258.863 56.2978C265.767 56.3423 272.581 56.3423 279.44 56.3423Z'
                fill='#1F426D'
            />
            <path
                d='M525.698 60.7072C525.788 53.8036 527.703 48.1471 532.513 43.6931C535.764 40.6645 539.728 38.972 544.093 38.3039C548.993 37.5467 553.803 37.9476 558.346 40.0409C564.715 42.9805 568.724 47.9689 570.282 54.7389C571.93 61.8207 570.817 68.4571 566.14 74.2026C562.354 78.8793 557.366 81.4626 551.398 82.2643C545.118 83.1105 539.283 81.8189 534.161 78.033C529.351 74.5144 526.767 69.6151 525.921 63.7804C525.788 62.6224 525.743 61.4198 525.698 60.7072ZM537.19 60.7517C537.279 61.4198 537.368 62.5778 537.635 63.6913C538.838 68.8579 542.713 72.0647 547.879 72.2874C553.09 72.4656 557.277 69.526 558.836 64.493C559.459 62.4888 559.504 60.3954 559.281 58.3021C558.791 52.7346 555.273 48.9488 549.75 48.3252C546.097 47.9244 542.802 48.8597 540.218 51.6657C537.991 54.1154 537.323 57.0995 537.19 60.7517Z'
                fill='#1F416D'
            />
            <path
                d='M487.974 51.8884C486.37 50.4186 484.767 49.1715 482.763 48.6816C475.681 46.9 470.069 50.9085 468.777 57.4558C468.02 61.4198 468.688 65.1611 471.271 68.368C474.656 72.5547 481.649 73.9354 486.548 69.9268C487.929 68.8134 487.974 68.8134 489.176 70.105C491.047 72.1093 492.917 74.1581 494.877 76.2514C493.274 77.7658 491.537 79.0129 489.622 79.9928C486.949 81.418 484.054 82.1752 481.025 82.3979C475.191 82.8433 469.802 81.5516 465.125 77.9885C460.537 74.4699 457.91 69.7041 457.152 64.0476C456.262 57.7231 457.375 51.7548 461.25 46.5437C464.858 41.6443 469.802 39.0165 475.77 38.2593C483.386 37.2349 489.844 39.6846 495.412 44.7621C495.456 44.8066 495.456 44.8512 495.59 45.0293C493.051 47.2563 490.557 49.5278 487.974 51.8884Z'
                fill='#1F416D'
            />
            <path
                d='M503.874 61.9543C503.874 57.9458 503.874 53.9372 503.874 49.9287C503.874 49.127 503.607 48.8597 502.805 48.9043C501.825 48.9488 500.801 48.9043 499.821 48.9043C499.287 48.9043 499.064 48.7261 499.108 48.1916C499.108 45.4747 499.108 42.7133 499.108 39.9964C499.108 39.4174 499.331 39.2392 499.91 39.2392C500.935 39.2837 501.959 39.1947 502.983 39.2392C503.696 39.2837 503.919 39.0165 503.874 38.3484C503.83 35.3197 503.874 32.2911 503.83 29.2624C503.83 28.4161 504.008 28.1044 504.943 28.1044C508.061 28.1489 511.179 28.1489 514.296 28.1044C515.143 28.1044 515.321 28.3716 515.321 29.1733C515.276 32.2465 515.321 35.2752 515.276 38.3484C515.276 39.0165 515.454 39.2837 516.167 39.2837C517.325 39.2392 518.528 39.2837 519.686 39.2837C520.265 39.2837 520.487 39.5064 520.443 40.0409C520.443 42.7133 520.443 45.3856 520.443 48.058C520.443 48.6816 520.22 48.9043 519.597 48.8597C518.439 48.8152 517.236 48.8597 516.078 48.8597C515.499 48.8597 515.232 48.9933 515.232 49.6614C515.232 56.1196 515.232 62.5333 515.232 68.9915C515.232 70.4168 515.766 71.0403 517.147 71.3076C517.993 71.4412 518.839 71.4412 519.686 71.4412C520.265 71.4412 520.443 71.6639 520.443 72.2429C520.443 74.9153 520.443 77.5876 520.443 80.26C520.443 80.839 520.22 81.0172 519.641 81.0172C516.523 80.9726 513.406 81.0617 510.288 80.9281C509.219 80.8835 508.105 80.6163 507.126 80.2155C505.166 79.4583 504.231 77.8103 503.919 75.8061C503.785 75.0043 503.741 74.2472 503.741 73.4455C503.874 69.6151 503.874 65.7847 503.874 61.9543Z'
                fill='#1F426E'
            />
            <path
                d='M391.368 44.8957C391.501 44.6285 391.635 44.4058 391.724 44.1385C393.283 40.1745 396.445 38.4375 400.498 38.0812C402.992 37.8585 402.992 37.903 402.992 40.3972C402.992 43.1141 402.992 45.8756 402.992 48.5925C402.992 49.7505 402.948 49.795 401.79 49.706C398.984 49.4833 396.401 50.0623 394.218 51.9329C391.813 54.0263 391.234 56.8323 391.234 59.8164C391.234 65.8292 391.234 71.8866 391.234 77.8994C391.234 78.5675 391.189 79.2801 391.234 79.9482C391.323 80.839 391.056 81.1062 390.121 81.1062C387.003 81.0617 383.885 81.1062 380.767 81.0617C380.456 81.0617 380.144 81.0172 379.743 80.9726C379.743 80.5718 379.743 80.26 379.743 79.9482C379.743 66.8091 379.743 53.7145 379.743 40.5754C379.743 39.4174 379.743 39.4174 380.901 39.4174C384.063 39.4174 387.181 39.4174 390.343 39.4174C391.011 39.4174 391.279 39.5955 391.234 40.3082C391.189 41.8225 391.234 43.2923 391.234 44.8066C391.279 44.8512 391.323 44.8512 391.368 44.8957Z'
                fill='#1F426D'
            />
            <path
                d='M183.903 60.1727C183.903 66.72 183.903 73.2673 183.903 79.8591C183.903 80.7499 183.68 81.0172 182.79 81.0172C179.672 80.9726 176.554 80.9726 173.436 81.0172C172.635 81.0172 172.457 80.7499 172.457 79.9928C172.457 66.8091 172.457 53.5809 172.457 40.3972C172.457 39.6846 172.679 39.4619 173.392 39.4619C176.554 39.5064 179.761 39.5064 182.923 39.4619C183.725 39.4619 183.903 39.6846 183.903 40.4418C183.903 47.0336 183.903 53.6254 183.903 60.1727Z'
                fill='#1F426E'
            />
            <path
                d='M357.028 60.2173C357.028 53.67 357.028 47.1672 357.028 40.6199C357.028 39.4619 357.028 39.4619 358.186 39.4619C361.304 39.4619 364.421 39.4619 367.539 39.4619C368.252 39.4619 368.519 39.6401 368.474 40.3972C368.474 53.5809 368.474 66.8091 368.474 79.9928C368.474 80.839 368.163 81.0172 367.405 81.0172C364.288 80.9726 361.17 80.9726 358.052 81.0172C357.25 81.0172 356.983 80.7499 356.983 79.9482C357.028 73.3564 357.028 66.8091 357.028 60.2173Z'
                fill='#1F426E'
            />
            <path
                d='M184.482 27.7035C184.482 31.3112 181.721 34.0726 178.069 33.9836C174.55 33.8945 171.788 31.3557 171.788 27.5254C171.788 23.9177 174.55 21.2453 178.202 21.2453C181.943 21.2453 184.482 23.8286 184.482 27.7035Z'
                fill='#1F416D'
            />
            <path
                d='M368.964 27.659C369.41 31.0885 366.337 34.0281 362.684 33.939C358.676 33.8499 356.404 30.8213 356.404 27.5699C356.449 23.8286 359.077 21.2898 362.862 21.2007C366.426 21.1562 369.499 24.3185 368.964 27.659Z'
                fill='#1F416D'
            />
            <path d='M96.5614 34.8743C96.5614 34.7853 96.5614 34.6962 96.5614 34.5626C96.5614 34.6517 96.5614 34.7407 96.5614 34.8743Z' fill='#4BA187' />
            <path d='M226.795 75.9842C226.839 75.9842 226.884 76.0287 226.973 76.0287C226.928 76.0287 226.884 75.9842 226.795 75.9842Z' fill='#08111C' />
        </svg>
    );
}
