/**
 * @prettier
 */
import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomerId } from 'src/types/Id';

export async function changePasswordApi(request: ChangePasswordApiRequest): ApiSauceResponse<void> {
    return pidedirectoApiMethod('customer/changePasswordApi', request);
}

export type ChangePasswordApiRequest = {
    customerId: CustomerId;
    encryptedPassword: string;
};
