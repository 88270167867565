/**
 * @prettier
 */
import { OrderType, OrderTypes } from 'src/constants/OrderType';
import { PromotionVm } from 'src/types/PromotionVm';

const orderTypes: Array<OrderType> = [OrderTypes.TAKE_AWAY_ORDER, OrderTypes.TABLE_ORDER, OrderTypes.DELIVERY_ORDER];
export function doesPromotionIncludeAllOrderTypes(promotion: PromotionVm | undefined): boolean {
    return orderTypes.every((orderType) => !!promotion?.orderTypes?.some((orderTypeInPromotion) => orderTypeInPromotion === orderType));
}
