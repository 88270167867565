/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { PayPaymentLinkButton } from 'src/components/buttons/PayPaymentLinkButton';
import { EnterPasswordDialog } from 'src/components/cart/EnterPasswordDialog';
import { EnterVerificationCodeDialog } from 'src/components/cart/EnterVerificationCodeDialog';
import { SignInDialog } from 'src/components/cart/SignInDialog';
import { SignUpDialog } from 'src/components/cart/SignUpDialog';
import { Text } from 'src/components/Text';
import { AwsFacade, IsSignedInResults } from 'src/facade/aws/AwsFacade';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { PaymentLinkPreview } from 'src/scenes/paymentLink/PaymentLinkPreview';
import { PaymentLinkStatus } from 'src/scenes/paymentLink/PaymentLinkStatus';
import { PaymentLinkSummary } from 'src/scenes/paymentLink/PaymentLinkSummary';
import type { PaymentLinkVm } from 'src/types/PaymentLinkVm';
import { checkYourInternetConnectionAndTryAgain } from 'src/utils/alert/checkYourInternetConnectionAndTryAgain';
import { isPaymentLinkPaid } from 'src/utils/paymentLink/isPaymentLinkPaid';
import { isPaymentLinkRefunded } from 'src/utils/paymentLink/isPaymentLinkRefunded';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { translatePaymentRejectReason } from 'src/utils/translate/translatePaymentRejectReason';

export function PaymentLinkCheckoutSteps({ paymentLink }: Props): React.ReactElement {
    const classes = useStyles();

    const [paymentLinkCheckoutStep, setPaymentLinkCheckoutStep] = useState(PaymentLinkCheckoutStaps.INITIAL);
    const [paymentErrorMessage, setPaymentErrorMessage] = useState('');

    const signedIn = useSelector((state) => state.app.signedIn);

    const openSignInDialog = useAction(actions.openSignInDialog);

    useEffect(() => {
        if (isPaymentLinkPaid(paymentLink?.status)) setPaymentLinkCheckoutStep(PaymentLinkCheckoutStaps.SUCCEEDED);
    }, [paymentLink]);

    const handleContinue = async () => {
        if (signedIn) return showPaymentLinkSummary();

        const result = await AwsFacade.isSignedIn(true);
        if (result === IsSignedInResults.NETWORK_ERROR) return checkYourInternetConnectionAndTryAgain();
        if (result === IsSignedInResults.SIGNED_IN) return showPaymentLinkSummary();
        openSignInDialog();
    };

    const showPaymentLinkSummary = () => setPaymentLinkCheckoutStep(PaymentLinkCheckoutStaps.SUMMARY);

    const showPaymentSuccessful = () => {
        setPaymentLinkCheckoutStep(PaymentLinkCheckoutStaps.SUCCEEDED);
    };

    const showPaymentFailed = (paymentLinkFailed: any) => {
        setPaymentLinkCheckoutStep(PaymentLinkCheckoutStaps.FAILED);
        setPaymentErrorMessage(translatePaymentRejectReason(paymentLinkFailed?.paymentRejectReason));
    };

    const showPaymentLoading = () => {
        setPaymentLinkCheckoutStep(PaymentLinkCheckoutStaps.PAYING);
    };

    const tryAgain = () => setPaymentLinkCheckoutStep(PaymentLinkCheckoutStaps.SUMMARY);

    if (paymentLinkCheckoutStep === PaymentLinkCheckoutStaps.INITIAL) {
        return (
            <div className={classes.container}>
                <SignInDialog onSuccess={showPaymentLinkSummary} />
                <SignUpDialog onSuccess={showPaymentLinkSummary} />
                <EnterVerificationCodeDialog />
                <EnterPasswordDialog />
                <PaymentLinkPreview paymentLink={paymentLink} />
                <Button onClick={handleContinue} classes={{ button: classes.button }} disabled={isPaymentLinkRefunded(paymentLink?.status)}>
                    {translate('Continue')}
                </Button>
                {isPaymentLinkRefunded(paymentLink?.status) && (
                    <Text className={classes.refundedText}>{translate('We have refunded your payment successfully. If you have any question or you need more information please contact us.')}</Text>
                )}
            </div>
        );
    }

    if (paymentLinkCheckoutStep === PaymentLinkCheckoutStaps.SUMMARY) {
        return (
            <div className={classes.container}>
                <PaymentLinkSummary paymentLink={paymentLink} />
                <PayPaymentLinkButton
                    paymentLink={paymentLink}
                    onPaymentStarted={showPaymentLoading}
                    onSuccess={showPaymentSuccessful}
                    onFailed={showPaymentFailed}
                    classes={{ button: classes.button }}
                >
                    {translate('Pay')}
                </PayPaymentLinkButton>
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <PaymentLinkStatus
                paymentLink={paymentLink}
                paid={paymentLinkCheckoutStep === PaymentLinkCheckoutStaps.SUCCEEDED}
                failed={paymentLinkCheckoutStep === PaymentLinkCheckoutStaps.FAILED}
                errorMessage={paymentErrorMessage}
            />
            {paymentLinkCheckoutStep === PaymentLinkCheckoutStaps.FAILED && (
                <Button onClick={tryAgain} classes={{ button: classes.button }} outlined color={'#F64F4F'}>
                    {translate('Try again')}
                </Button>
            )}
        </div>
    );
}

const PaymentLinkCheckoutStaps = Object.freeze({
    INITIAL: 'INITIAL',
    SUMMARY: 'SUMMARY',
    PAYING: 'PAYING',
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
});

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    button: {
        width: '100%',
        marginTop: 30,
    },
    refundedText: {
        color: '#F64F4F',
        textAlign: 'center',
        marginTop: 10,
    },
}));

type Props = {
    paymentLink: PaymentLinkVm;
};
