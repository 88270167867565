/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@pidedirecto/ui';
import { ClosedEyeIcon, EyeIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { AutoCompleteInputProp, Variant } from 'src/components/form/FormTypes';
import { getError } from 'src/components/form/utils/getError';
import { Input } from 'src/components/Input';
import { translate } from 'src/i18n/translate';

export function FormPasswordField({ name, label, placeholder, helperText, defaultValue = null, autoComplete = 'off', disabled, required, autoFocus }: Props): React.ReactElement {
    const classes = useStyles();
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const inputRef = useRef<HTMLInputElement>();

    const [showPassword, setShowPassword] = useState(false);

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <Input
                    {...props}
                    type={showPassword ? 'text' : 'password'}
                    name={name}
                    value={value}
                    onChange={onChange}
                    label={label}
                    placeholder={placeholder}
                    helperText={getError(errors, name)?.message ?? helperText}
                    disabled={isSubmitting || disabled}
                    required={(required as any)?.value ?? required}
                    error={!!getError(errors, name)}
                    autoFocus={autoFocus}
                    inputRef={inputRef}
                    autoComplete={autoComplete}
                    rightIcon={
                        <Button variant='icon' onClick={() => setShowPassword(!showPassword)} classes={{ button: classes.button }}>
                            {showPassword ? <ClosedEyeIcon className={classes.icon} /> : <EyeIcon className={classes.icon} />}
                        </Button>
                    }
                />
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        width: 28,
        height: 28,
        minHeight: 20,
    },
    icon: {
        flexShrink: 0,
        width: 18,
        height: 18,
    },
}));

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null;
    autoComplete?: AutoCompleteInputProp;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    autoFocus?: boolean;
    InputProps?: any;
    inputProps?: any;
};
