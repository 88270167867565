/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { getAddPlexoCardUrlApi } from 'src/api/pidedirecto/card/getAddPlexoCardUrlApi';
import { getCardsApi } from 'src/api/pidedirecto/card/getCardsApi';
import { Dialog } from 'src/components/Dialog';
import { LoadingContentProgress } from 'src/components/LoadingContentProgress';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { SECONDS } from 'src/constants/TimeUnit';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { CardVm } from 'src/types/CardVm';
import { WebSocketEvent } from 'src/types/WebSocketEvent';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { wait } from 'src/utils/wait';
import { WebSocketEvents } from 'src/utils/webSocketEvent/WebSocketEvents';

export function AddNewPlexoCardDialog(): React.ReactElement {
    const classes = useStyles();
    const plexoLoads = useRef(1);
    const plexoFormRef = useRef<HTMLIFrameElement | null>(null);

    const [addPlexoCardUrl, setAddPlexoCardUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurant?.restaurantId);
    const customerId = useSelector((state) => state.app.customerId);
    const open = useSelector((state) => state.app.addNewCardDialog.open);

    const closeAddNewCardDialog = useAction(actions.closeAddNewCardDialog);
    const addCard = useAction(actions.addCard);
    const selectPaymentMethod = useAction(actions.selectPaymentMethod);

    useEffect(() => {
        const plexoCardAddedWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.PLEXO_CARD_ADDED, handlePlexoCardAdded);
        if (!open) plexoCardAddedWebSocketEvent.remove();
        return () => {
            plexoCardAddedWebSocketEvent.remove();
        };
    }, [open]);

    useEffect(() => {
        if (open && customerId) getAddPlexoCardUrl();
    }, [restaurantId, customerId, open]);

    const handlePlexoCardAdded = (event: WebSocketEvent<{ card: CardVm }>) => {
        if (!event.data) return;
        handleNewCard(event.data.card);
    };

    const getAddPlexoCardUrl = async () => {
        setLoading(true);
        const response = await getAddPlexoCardUrlApi({
            restaurantId,
            customerId,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setAddPlexoCardUrl(response.data);
    };

    const handleLoadPlexoForm = async () => {
        plexoLoads.current = plexoLoads.current + 1;
        if (!hasPlexoFormFinished()) return;
        await getNewCard();
    };

    const hasPlexoFormFinished = () => {
        try {
            const plexoFormUrl = plexoFormRef.current?.contentWindow?.location.href ?? '';
            return plexoFormUrl.includes(window.location.hostname);
        } catch (e) {
            return false;
        }
    };

    const getNewCard = async () => {
        setUpdating(true);
        await wait(2 * SECONDS);
        const response = await getCardsApi({ customerId, restaurantId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const newCard = response.data.find((card) => card.isPlexoCardSelected);
        if (!newCard) {
            getNewCard();
            return;
        }
        handleNewCard(newCard);
    };

    const handleNewCard = (card: CardVm) => {
        addCard(card);
        selectPaymentMethod({ paymentMethod: PaymentMethods.CREDIT_CARD, card });
        closeAddNewCardDialog();
        setUpdating(false);
        setAddPlexoCardUrl('');
    };

    const handleClose = () => {
        plexoLoads.current = 1;
        closeAddNewCardDialog();
    };

    return (
        <Dialog title={translate('Add New Card')} open={open} onClose={handleClose} classes={{ dialog: classes.dialog }} loading={loading}>
            {!updating && <iframe className={classes.container} src={addPlexoCardUrl} onLoad={handleLoadPlexoForm} ref={plexoFormRef} />}
            {updating && <LoadingContentProgress loading={updating} />}
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& svg': {
            color: `${theme.palette.primary.main} !important`,
        },
    },
    container: {
        width: '100%',
        height: '60vh',
        border: 0,
    },
}));
