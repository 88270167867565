/**
 * @prettier
 */
import * as React from 'react';
import type { MenuItemVm } from 'src/api/pidedirecto/getAppContextApi';
import { MenuItem } from 'src/components/MenuItem';

export function MenuItemList({ items }: Props): React.ReactElement | null {
    const renderItem = (item: { menuItem: MenuItemVm; show: boolean }) => {
        if (!item.show) {
            return null;
        }
        return renderMenuItem(item);
    };

    const renderMenuItem = (item: { menuItem: MenuItemVm; show: boolean }) => <MenuItem menuItem={item.menuItem} key={item.menuItem.menuItemId} />;

    return <div>{items.map(renderItem)}</div>;
}

type Props = {
    items: Array<{
        menuItem: MenuItemVm;
        show: boolean;
    }>;
};
