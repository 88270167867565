/**
 * @prettier
 */
import type { OrderItemVm, OrderPaymentVm, OrderVm } from 'src/api/pidedirecto/types/OrderVm';
import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DiscountType } from 'src/constants/DiscountType';
import type { OrderType } from 'src/constants/OrderType';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { PickupTimeType } from 'src/constants/PickupTimeType';
import type { PromoType } from 'src/constants/PromoType';
import { PromotionId, type AddressId, type CardId, type CustomerId, type DeliveryEstimateId, type PaymentId, type PromoCodeId, type RestaurantId } from 'src/types/Id';

export async function createOrderApi(request: CreateOrderApiRequest): ApiSauceResponse<OrderVm> {
    return pidedirectoApiMethod('order/createOrderApi', request);
}

export type CreateOrderApiRequest = {
    customerId: CustomerId;
    restaurantId: RestaurantId;
    deliveryEstimateId?: DeliveryEstimateId;
    orderType: OrderType;
    addressId?: AddressId;
    paymentMethod: PaymentMethod;
    mandatoryCashAmount: string;
    cardId?: CardId;
    paymentId?: PaymentId;
    promotionIds?: Array<PromotionId>;
    table?: string;
    pickupTimeType?: PickupTimeType;
    pickupTime?: Date;
    deliveryTime?: Date;
    orderItems: Array<OrderItemVm>;
    driverTip?: string;
    payments?: Array<OrderPaymentVm>;
    promoCode?: {
        promoCodeId: PromoCodeId;
        code: string;
        promoType: PromoType;
        discount: string;
        discountType: DiscountType;
        endsAt: Date;
    };
    useLetsEatCredits: boolean;
    cvc?: string;
    metaData?: string;
    notes?: string;
    roomServiceNumber?: string;
    plexoPaymentSecurityId?: string;
};
