/**
 * @prettier
 */
import * as React from 'react';
import { RestaurantMenuBottomDrawer } from 'src/components/bottomDrawers/RestaurantMenuBottomDrawer';
import { RestaurantBanner } from 'src/components/RestaurantBanner';
import { RestaurantMenuContainer } from 'src/scenes/restaurantMenuPage/RestaurantMenuContainer';
import { UserOpenedWebLogEvent } from 'src/scenes/restaurantMenuPage/UserOpenedWebLogEvent';

export function RestaurantMenu(): React.ReactElement {
    return (
        <RestaurantMenuContainer>
            <RestaurantBanner />
            <RestaurantMenuBottomDrawer />
            <UserOpenedWebLogEvent />
        </RestaurantMenuContainer>
    );
}
