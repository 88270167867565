/**
 * @prettier
 */
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';

export function createSelectAddressLogEvent(params: createSelectAddressLogEventParams): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_SELECTED_ADDRESS,
        message: `Address: ${params.address ?? ''}`,
        data: params,
    });
}

type createSelectAddressLogEventParams = {
    address?: string;
};
