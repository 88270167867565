/**
 * @prettier
 */
import type { OrderVm } from 'src/api/pidedirecto/types/OrderVm';
import { pidedirectoApiMethodPublic } from 'src/api/pidedirectoApiMethodPublic';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomerId, OrderId } from 'src/types/Id';

export async function cancelOrderApi(request: CancelOrderApiRequest): ApiSauceResponse<OrderVm> {
    return pidedirectoApiMethodPublic('order/cancelOrderApi', request);
}

export type CancelOrderApiRequest = {
    customerId?: CustomerId;
    orderId: OrderId;
};
