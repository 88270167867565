/**
 * @prettier
 */
export function requireValue<T>(value?: T | null): T {
    if (value === undefined) {
        throw new Error('Required value is undefined when it should be a value');
    }
    if (value === null) {
        throw new Error('Required value is null when it should be a value');
    }
    return value;
}
