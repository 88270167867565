/**
 * @prettier
 */
export const StripeErrors = Object.freeze({
    VALIDATION_ERROR: 'validation_error',
    INVALID_REQUEST_ERROR: 'invalid_request_error',
    CARD_ERROR: 'card_error',
});

export type StripeError = (typeof StripeErrors)[keyof typeof StripeErrors] | string;
