/**
 * @prettier
 */
import { Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { GetAppContextSignedInApiResponse } from 'src/api/pidedirecto/getAppContextSignedInApi';
import { SelectAuthenticationTypeDialog } from 'src/components/cart/SelectAuthenticationTypeDialog';
import { ChangePasswordDialog } from 'src/components/dialog/customer/ChangePasswordDialog';
import { Form } from 'src/components/form/Form';
import { FormPrefixPhoneNumberField } from 'src/components/form/FormPrefixPhoneNumberField';
import { Text } from 'src/components/Text';
import { AuthenticationTypes, type AuthenticationType } from 'src/constants/AuthenticationType';
import { AwsFacade } from 'src/facade/aws/AwsFacade';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { AppTheme } from 'src/styles/AppTheme';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function SignInDialog({ onSuccess }: Props): React.ReactElement {
    const classes = useStyles();

    const form = useForm();

    const [mobileNumber, setMobileNumber] = useState('');
    const [error, setError] = useState<{ errorType: string; message: string }>();
    const [selectAuthenticationTypeDialogOpen, setSelectAuthenticationTypeDialogOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [shouldRecoverPassword, setShouldRecoverPassword] = useState(false);
    const [doesUserExists, setDoesUserExists] = useState(true);
    const [changePasswordDialogState, setChangePasswordDialogState] = useState({ open: false });

    const open = useSelector((state) => state.app.signInDialog.open);
    const restaurantId = useSelector((state) => state.app.restaurant?.restaurantId);

    const closeSignInDialog = useAction(actions.closeSignInDialog);
    const openSignUpDialog = useAction(actions.openSignUpDialog);
    const openEnterVerificationCodeDialog = useAction(actions.openEnterVerificationCodeDialog);
    const openEnterPasswordDialog = useAction(actions.openEnterPasswordDialog);
    const closeEnterPasswordDialog = useAction(actions.closeEnterPasswordDialog);

    const formMobileNumber: string | undefined = useWatch({ name: 'mobileNumber', control: form.control });

    useEffect(() => {
        if (open) setError(undefined);
        else setShouldRecoverPassword(false);
    }, [open]);

    const signInWithPassword = async (form: any) => {
        const mobileNumber = form?.mobileNumber || formMobileNumber;
        setIsSubmitting(true);
        const response = await AwsFacade.requestSignUpSignIn({ username: mobileNumber, authenticationType: AuthenticationTypes.PASSWORD, restaurantId });
        setIsSubmitting(false);
        if (response.error) {
            if (response.error.errorType === 'USER_DOES_NOT_EXISTS_ERROR') setDoesUserExists(false);
            setError(response.error);
            return;
        }

        closeSignInDialog();
        openEnterPasswordDialog({ mobileNumber, onForgotPassword: forgotPassword, onSuccess });
    };

    const forgotPassword = (mobileNumber: string) => {
        setMobileNumber(mobileNumber);
        setShouldRecoverPassword(true);
        closeEnterPasswordDialog();
        setSelectAuthenticationTypeDialogOpen(true);
    };

    const handleSignInWithCode = async (_authenticationType: AuthenticationType) => {
        if (!formMobileNumber && !mobileNumber) return;

        setIsSubmitting(true);
        const response = await AwsFacade.requestSignUpSignIn({ username: formMobileNumber ?? mobileNumber, authenticationType: _authenticationType, restaurantId });
        setIsSubmitting(false);
        if (response.error) {
            setError(response.error);
            return;
        }
        closeSignInDialog();
        openEnterVerificationCodeDialog({
            mobileNumber: formMobileNumber ?? mobileNumber,
            isSignUp: false,
            authenticationType: _authenticationType,
            onSuccess: handleSignInWithCodeSucceeded,
        });
    };

    const handleSignInWithCodeSucceeded = (appContext: GetAppContextSignedInApiResponse) => {
        if (shouldRecoverPassword || !appContext?.hasPassword) return openChangePasswordDialog();
        onSuccess?.();
    };

    const closeSelectAuthenticationTypeDialog = () => {
        setSelectAuthenticationTypeDialogOpen(false);
    };

    const openChangePasswordDialog = () => {
        setChangePasswordDialogState({ open: true });
    };

    const closeChangePasswordDialog = () => {
        setChangePasswordDialogState({ open: false });
    };

    const handleSignUp = () => {
        closeSignInDialog();
        openSignUpDialog();
    };

    return (
        <>
            <Dialog classes={{ dialog: classes.dialog, title: classes.title }} open={open} loading={isSubmitting} title={translate('Welcome')} onClose={closeSignInDialog}>
                <Form form={form} onSubmit={signInWithPassword} id={'form'}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Text style={{ textAlign: 'center' }}>{translate('Enter your phone number and choose if you want to continue by SMS or with a password')}</Text>
                        </Grid>
                        <Grid item xs={12}>
                            <FormPrefixPhoneNumberField
                                label={translate('Phone Number')}
                                name={'mobileNumber'}
                                disabled={isSubmitting}
                                helperText={translate('Make sure to select the flag of your country')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {!!error && <Text>{translate(error.message)}</Text>}
                        </Grid>
                    </Grid>
                    <DialogActions>
                        {doesUserExists && (
                            <Button type='submit' classes={{ button: classes.button }} disabled={isSubmitting || !formMobileNumber}>
                                {translate('Use password')}
                            </Button>
                        )}
                        <Button variant='secondary' classes={{ button: classes.button }} onClick={() => handleSignInWithCode(AuthenticationTypes.SMS)} disabled={isSubmitting || !formMobileNumber}>
                            {translate('Use SMS')}
                        </Button>
                        <div className={classes.text}>
                            {translate('Are you a new user?')}
                            <Button onClick={handleSignUp} variant={'text'} classes={{ button: classes.registerButton }} disabled={isSubmitting}>
                                {translate('Register')}
                            </Button>
                        </div>
                        <div className={classes.divider}>
                            <Divider className={classes.line} />
                            <div className={classes.circle}>{translate('o')}</div>
                            <Divider className={classes.line} />
                        </div>
                        <Button variant='secondary' classes={{ button: classes.button }} onClick={() => handleSignInWithCode(AuthenticationTypes.EMAIL)} disabled={isSubmitting || !formMobileNumber}>
                            {translate('Continue with email')}
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
            <SelectAuthenticationTypeDialog open={selectAuthenticationTypeDialogOpen} onClose={closeSelectAuthenticationTypeDialog} onSelectAuthenticationType={handleSignInWithCode} />
            <ChangePasswordDialog open={changePasswordDialogState.open} onClose={closeChangePasswordDialog} onSuccess={onSuccess} />
        </>
    );
}
const useStyles = makeStyles((theme) => ({
    dialog: {
        borderRadius: 8,
        borderBottomRightRadius: 18,
        borderBottomLeftRadius: 18,
        maxWidth: '90%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 420,
        },
        overflow: 'hidden',
    },
    text: {
        width: '100%',
        fontFamily: AppTheme.typography.regular,
        color: theme.palette.text.primary,
        textAlign: 'center',
    },
    registerButton: {
        textDecoration: 'underline',
        display: 'inline',
        width: 'fit-content !important',
        marginLeft: 5,
        color: '#4D989E',
        '&:disabled': {
            backgroundColor: 'transparent',
        },
    },
    button: {
        flexGrow: 1,
    },
    title: {
        color: theme.palette.text.primary,
    },
    divider: {
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    line: {
        flexGrow: 1,
        height: '1px',
    },
    circle: {
        margin: '0 10px',
    },
}));

type Props = {
    onSuccess?: Function;
};
