/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { removeCardApi } from 'src/api/pidedirecto/card/removeCardApi';
import { runAfterCheckAnimation } from 'src/components/BottomDialogCheckButton';
import { Button } from 'src/components/Button';
import { CardInfo } from 'src/components/CardInfo';
import { PaymentMethod, PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { TrashcanIcon } from 'src/icons/TrashcanIcon';
import { actions } from 'src/reducers';
import { createUserSelectedPaymentMethodLogEvent } from 'src/services/logEvent/createUserSelectedPaymentMethodLogEvent';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { AppTheme } from 'src/styles/AppTheme';
import { CardVm } from 'src/types/CardVm';
import { CardId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isPaymentLinkPage } from 'src/utils/page/isPaymentLinkPage';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function DefaultCustomerCards({ selectedPaymentMethod, disabled, onSelectCard, onStartRemoving, onEndRemoving }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const cards = useSelector((state) => state.app.cards);
    const selectedCard = useSelector((state) => state.app.card);
    const inAppPaymentsEnabled = useSelector((state) => state.app.restaurant?.inAppPaymentsEnabled);
    const customerId = useSelector((state) => state.app.customerId);
    const ecommerceOrdersCashbackEnabled = useSelector((state) => state.app.restaurant?.ecommerceOrdersCashbackEnabled);
    const ecommerceOrdersCashbackPercentage = useSelector((state) => state.app.restaurant?.ecommerceOrdersCashbackPercentage);
    const total = useSelector((state) => state.app.payment?.total);
    const page = useSelector((state) => state.app.page);

    const closeSelectPaymentMethodDialog = useAction(actions.closeSelectPaymentMethodDialog);
    const selectPaymentMethod = useAction(actions.selectPaymentMethod);
    const removeCard = useAction(actions.removeCard);

    const cashbackOrder = BigNumber(total)
        .multipliedBy(ecommerceOrdersCashbackPercentage ?? 0)
        .dividedBy(100)
        .toString();

    const handleSelectCard = async (card: CardVm) => {
        createUserSelectedPaymentMethodLogEvent({
            paymentMethodChangedFrom: selectedPaymentMethod,
            paymentMethodChangedTo: PaymentMethods.CREDIT_CARD,
        });
        onSelectCard?.(card);
        selectPaymentMethod({ paymentMethod: PaymentMethods.CREDIT_CARD, card: card });
        runAfterCheckAnimation(closeSelectPaymentMethodDialog);
    };

    const handleRemoveCard = async (cardId: CardId) => {
        if (disabled) return;

        onStartRemoving?.();
        const request = {
            customerId: requireValue(customerId),
            cardId: cardId,
        } as const;
        const response = await removeCardApi(request);
        if (!response.ok) {
            onEndRemoving?.();
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        removeCard(request);
        selectPaymentMethod({ paymentMethod: undefined });
        onEndRemoving?.();
        closeSelectPaymentMethodDialog();
    };

    if (!cards) return null;

    return (
        <div>
            <div className={classes.titleContainer}>
                <h3 className={classes.titleSection}>{translate('Pay online')}</h3>
                {ecommerceOrdersCashbackEnabled && !isPaymentLinkPage(page) && (
                    <span className={classes.cashbackText}>{translate('You will receive @totalCashback in cashback if you pay online', { totalCashback: formatAsCurrencyNumber(cashbackOrder) })}</span>
                )}
            </div>
            <div className={classes.cardContainer}>
                {cards?.map((card: CardVm) => (
                    <Button
                        outlined
                        color={card.cardId === selectedCard?.cardId ? '#06B7A2' : '#8C9196'}
                        classes={{ button: classes.buttonCardContainer }}
                        onClick={() => handleSelectCard(card)}
                        disabled={!inAppPaymentsEnabled || disabled}
                        key={card.cardId}
                    >
                        <div className={classes.buttonCardContent}>
                            <CardInfo card={card} />
                            <Button
                                icon
                                onClick={(event: React.MouseEvent) => {
                                    event.stopPropagation();
                                    handleRemoveCard(card.cardId);
                                }}
                            >
                                <TrashcanIcon color={disabled ? 'grey' : '#E32F45'} />
                            </Button>
                        </div>
                    </Button>
                ))}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 12,
    },
    titleSection: {
        color: '#2E3748',
        fontSize: 14,
        fontFamily: AppTheme.typography.light,
    },
    cashbackText: {
        color: '#818696',
        fontSize: 14,
        fontFamily: AppTheme.typography.light,
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 12,
    },
    buttonCardContainer: {
        width: '100%',
        maxHeight: 56,
    },
    buttonCardContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        maxHeight: 50,
        height: '100%',
        gap: 12,
    },
}));

type Props = {
    selectedPaymentMethod: PaymentMethod;
    disabled?: boolean;
    onSelectCard?: any;
    onStartRemoving?: any;
    onEndRemoving?: any;
};
