/**
 * @prettier
 */
import { Auth } from 'aws-amplify';

export async function isCognitoCurrentSession(): Promise<boolean> {
    try {
        await Auth.currentSession();
        return true;
    } catch {
        return false;
    }
}
