/**
 * @prettier
 */
export const RewardTypes = Object.freeze({
    DISCOUNT: 'DISCOUNT',
    CREDITS: 'CREDITS',
    PRODUCTS: 'PRODUCTS',
});

export type RewardType = (typeof RewardTypes)[keyof typeof RewardTypes];
