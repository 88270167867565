/**
 * @prettier
 */
import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CardVm } from 'src/types/CardVm';
import type { CardId } from 'src/types/Id';

export async function validateCardApi(request: ValidateCardApiRequest): ApiSauceResponse<CardVm> {
    return pidedirectoApiMethod('card/validateCardApi', request);
}

export type ValidateCardApiRequest = {
    cardId: CardId;
    validationAmount: string;
};
