/**
 * @prettier
 */
import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentRejectReason } from 'src/constants/PaymentRejectReason';
import type { PaymentStatus } from 'src/constants/PaymentStatus';
import type { CardId, CustomerId, PaymentLinkId } from 'src/types/Id';

export async function payPaymentLinkApi(request: PayPaymentLinkApiRequest): ApiSauceResponse<PayPaymentLinkApiResponse> {
    return pidedirectoApiMethod('paymentLink/payPaymentLinkApi', request);
}

export type PayPaymentLinkApiRequest = {
    paymentLinkId: PaymentLinkId;
    customerId: CustomerId;
    cardId: CardId;
};

type PayPaymentLinkApiResponse = {
    paymentStatus: PaymentStatus;
    paymentRejectReason?: PaymentRejectReason;
};
