/**
 * @prettier
 */
import pako from 'pako';

export function unzip(base64str: any): any {
    const strData = atob(base64str);

    // Convert binary string to character-number array
    const charData = strData.split('').map((x) => {
        return x.charCodeAt(0);
    });

    // Turn number array into byte-array
    const binData = new Uint8Array(charData);

    return JSON.parse(pako.inflate(binData, { to: 'string' }));
}
