/**
 * @prettier
 */
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { getDriverPositionApi } from 'src/api/pidedirecto/driverPosition/getDriverPositionApi';
import { GeoJsonPointVm } from 'src/api/pidedirecto/types/GeoJsonPointVm';
import { googleMapsConfig } from 'src/config/googleMapsConfig';
import { DeliveryStatuses } from 'src/constants/DeliveryStatus';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import { theme } from 'src/styles/theme';
import { toLatLng } from 'src/utils/googlemaps/toLatLng';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function GoogleMapsTrackDriverPosition(): React.ReactElement | null {
    const classes = useStyles();
    const showMap = useSelector((state) => state.app.showMap);

    const { isLoaded, loadError } = useLoadScript(googleMapsConfig);

    if (!isLoaded || !showMap) return null;

    if (loadError) {
        return <Typography>{translate('Failed to load google maps, please refresh page.')}</Typography>;
    }

    return (
        <div className={classes.container}>
            <Map />
        </div>
    );
}

function Map() {
    const [map, setMap] = useState<google.maps.Map>();

    const restaurantLocation = useSelector((state) => state.app.restaurantLocation);
    const customerLocation = useSelector((state) => state.app.customerLocation);

    const onLoad = useCallback(
        (map: google.maps.Map) => {
            setMap(map);
        },
        [restaurantLocation, customerLocation],
    );

    const onUnmount = useCallback(() => {
        setMap(undefined);
    }, []);

    if (!restaurantLocation || !customerLocation) return null;

    return (
        <GoogleMap
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                styles: mapStyles,
                gestureHandling: 'cooperative',
            }}
            mapContainerStyle={mapContainerStyle}
        >
            <Marker
                label={{
                    text: 's',
                    color: 'white',
                    fontFamily: 'LetsEatIcons',
                    fontSize: '26px',
                }}
                position={requireValue(toLatLng(restaurantLocation))}
            />
            {customerLocation && (
                <Marker
                    label={{
                        text: "'",
                        color: 'white',
                        fontFamily: 'LetsEatIcons',
                        fontSize: '34px',
                    }}
                    position={requireValue(toLatLng(customerLocation))}
                />
            )}
            <DriverMarker map={map} />
        </GoogleMap>
    );
}

function DriverMarker({ map }: DriverMarkerProps): React.ReactElement | null {
    const [driverPosition, setDriverPosition] = useState<GeoJsonPointVm>();
    const [positionedAt, setPositionedAt] = useState<Date>();

    const driverId = useSelector((state) => state.app.driverId);
    const orderId = useSelector((state) => state.app.orderId);
    const restaurantLocation = useSelector((state) => state.app.restaurantLocation);
    const customerLocation = useSelector((state) => state.app.customerLocation);
    const deliveryStatus = useSelector((state) => state.app.deliveryStatus);

    useEffect(() => {
        if (!map) return;

        const bounds = new window.google.maps.LatLngBounds();
        !driverPosition && bounds.extend(toLatLng(restaurantLocation));
        bounds.extend(toLatLng(customerLocation));
        driverPosition && bounds.extend(toLatLng(driverPosition));
        map.fitBounds(bounds, { top: 40 });
    }, [map, restaurantLocation, customerLocation, driverPosition]);

    useEffect(() => {
        if (!driverId || deliveryStatus !== DeliveryStatuses.PICKED_UP) {
            return;
        }

        const interval = setInterval(async () => {
            const update = await getDriverPositionApi({ orderId: orderId });
            if (!update.ok) return;

            setDriverPosition(update.data.position);
            setPositionedAt(update.data.positionedAt);
        }, 2 * SECONDS);

        return () => clearInterval(interval);
    }, [driverId, deliveryStatus]);

    if (!driverId) return null;
    if (!driverPosition) return null;

    return (
        <Marker
            icon={{
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 8,
                strokeWeight: 16,
                strokeColor: theme.palette.primary.dark,
            }}
            label={{
                text: '"',
                color: 'white',
                fontFamily: 'LetsEatIcons',
                fontSize: '30px',
            }}
            position={toLatLng(driverPosition)}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '40vh',
        position: 'fixed',
        top: 0,
        [theme.breakpoints.up('sm')]: {
            position: 'relative',
            top: 0,
            height: '40%',
        },
    },
}));

const mapContainerStyle = {
    width: '100%',
    height: '100%',
} as const;

export const mapStyles = [
    {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.business',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
];

type DriverMarkerProps = {
    map: any;
};
