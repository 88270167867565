/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Button, Text } from '@pidedirecto/ui';
import { useNotification } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { usePromoCodeStore } from 'src/modules/promoCode/promoCodeStore';
import { isAmountDiscount } from 'src/modules/promoCode/utils/isAmountDiscount';
import { isFirstOrderPromoCode } from 'src/modules/promoCode/utils/isFirstOrderPromoCode';
import { actions } from 'src/reducers';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PromoCodeAlert(): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const notification = useNotification();

    const promoCodes = usePromoCodeStore((state) => state.promoCodes);

    const promoCodeBannerEnabled = useSelector((state) => state.app.restaurant?.promoCodeBannerEnabled);

    const addPromoCode = useAction(actions.addPromoCode);

    const promoCode = promoCodes[0];

    const getPromoCodeDescription = () => {
        if (!promoCode) return '';
        let description = '';
        if (isAmountDiscount(promoCode.discountType)) {
            description = translate(`@discount OFF`, { discount: formatAsCurrencyNumber(promoCode.discount) });
        } else {
            description = translate(`@discount OFF`, { discount: `${promoCode.discount}%` });
        }
        return description + (doesPromoCodeHaveConditions() ? '*' : '');
    };

    const doesPromoCodeHaveConditions = () => {
        if (!promoCode) return false;

        return !!promoCode.orderTypes || !!promoCode.minOrderAmount || !!promoCode.maxDiscountAmount || !!promoCode.paymentMethods || isFirstOrderPromoCode(promoCode.promoType);
    };

    const selectPromoCode = () => {
        addPromoCode(promoCode);
        notification({ message: translate('Promo code added') });
    };

    if (!promoCode || !promoCodeBannerEnabled) return null;

    return (
        <div className={classes.container} role='status'>
            <Text>{getPromoCodeDescription()}</Text>
            <Button classes={{ button: classes.button }} onClick={selectPromoCode}>
                {promoCode.code}
            </Button>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 66,
        color: 'white',
        gap: 12,
        backgroundColor: theme.palette.primary.main,
        padding: 12,
    },
    button: {
        backgroundColor: '#FFF858',
        color: 'black',
        '&:hover': {
            backgroundColor: '#FFF858',
        },
    },
}));
