/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import type { OrderItemVm } from 'src/api/pidedirecto/types/OrderVm';
import type { CartItemVm } from 'src/types/CartItemVm';
import { calculateOrderItemModifierGroupsPrice } from 'src/utils/order/calculateOrderItemModifierGroupsPrice';

export function calculateOrderItemProductDiscountUnitPrice(orderItem: OrderItemVm | CartItemVm): BigNumber {
    return BigNumber(orderItem.promoUnitPrice ? orderItem.promoUnitPrice : orderItem.unitPrice).plus(calculateOrderItemModifierGroupsPrice(orderItem.modifierGroups));
}
