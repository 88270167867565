/**
 * @prettier
 */
import { Button, ButtonBase, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { Text } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { answerSurveyApi } from 'src/api/pidedirecto/survey/answerSurveyApi';
import { createAnsweredSurveyApi } from 'src/api/pidedirecto/survey/createAnsweredSurveyApi';
import { findSurveyApi } from 'src/api/pidedirecto/survey/findSurveyApi';
import type { SurveyVm } from 'src/api/pidedirecto/types/SurveyVm';
import { Footer } from 'src/components/Footer';
import { NegativeSurveyAnswer, NegativeSurveyAnswers } from 'src/constants/NegativeSurveyAnswer';
import { Pages } from 'src/constants/Page';
import { PositiveSurveyAnswer, PositiveSurveyAnswers } from 'src/constants/PositiveSurveyAnswer';
import type { SurveyAnswer } from 'src/constants/SurveyAnswer';
import { translate } from 'src/i18n/translate';
import { HeartFilledIcon } from 'src/icons/HeartFilledIcon';
import { actions } from 'src/reducers';
import { AppTheme } from 'src/styles/AppTheme';
import { SurveyId } from 'src/types/Id';
import { alertSomethingWentWrongCheckYourInternetConnectionOrContactSupport } from 'src/utils/alert/alertSomethingWentWrongCheckYourInternetConnectionOrContactSupport';
import { emptyArrayToUndefined } from 'src/utils/array/emptyArrayToUndefined';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { uuidFromShortUuid } from 'src/utils/uuid/uuidFromShortUuid';

export function SurveyPage(): React.ReactElement {
    const { surveyId: shortSurveyId } = useParams<{
        surveyId?: SurveyId;
    }>();
    const surveyId = uuidFromShortUuid(shortSurveyId);
    const classes = useStyles();

    const restaurant = useSelector((state) => state.app.restaurant);

    const [loading, setLoading] = useState(false);
    const [otherAnswer, setOther] = useState('');
    const [rating, setRating] = useState<number | undefined>(undefined);
    const [recommendationRating, setRecommendationRating] = useState<number | undefined>(undefined);
    const [surveyAnswers, setSurveyAnswers] = useState<Array<SurveyAnswer>>([]);
    const [surveySent, setSurveySent] = useState(false);
    const [surveyAnswered, setSurveyAnswered] = useState(false);
    const [restaurantName, setRestaurantName] = useState('');

    const setPage = useAction(actions.setPage);
    const setContentNotFound = useAction(actions.setContentNotFound);

    useEffect(() => {
        setPage(Pages.SURVEY_PAGE);
    }, []);

    useEffect(() => {
        load();
    }, [surveyId, restaurant]);

    const load = async () => {
        setLoading(true);
        setRestaurantName(restaurant?.name);
        if (!surveyId) {
            setSurveyAnswered(false);
            setLoading(false);
            return;
        }
        const response = await findSurveyApi({ surveyId });
        if (!response.ok) {
            return setContentNotFound(true);
        }
        setLoading(false);
        const survey: SurveyVm = response.data;
        setSurveyAnswered(survey.answered);
    };

    const onPressAnswer = (answer: SurveyAnswer) => {
        if (surveyAnswers.includes(answer)) {
            setSurveyAnswers(surveyAnswers.filter((a) => a !== answer));
        } else {
            setSurveyAnswers([...surveyAnswers, answer]);
        }
    };

    const sendSurveyAnswer = async () => {
        if (!rating || !recommendationRating) return;
        setLoading(true);

        const answerRequest = {
            restaurantId: restaurant.restaurantId,
            restaurantName: restaurant.name,
            rating: rating,
            recommendationRating: recommendationRating,
            surveyAnswers: emptyArrayToUndefined(
                surveyAnswers.filter((surveyAnswer) => {
                    if (rating === 5) {
                        return Object.values(PositiveSurveyAnswers).includes(surveyAnswer as PositiveSurveyAnswer);
                    } else {
                        return Object.values(NegativeSurveyAnswers).includes(surveyAnswer as NegativeSurveyAnswer);
                    }
                }),
            ),
            otherAnswer,
        };

        const response = surveyId ? await answerSurveyApi({ surveyId, ...answerRequest }) : await createAnsweredSurveyApi(answerRequest);

        setLoading(false);
        if (!response.ok) {
            alertSomethingWentWrongCheckYourInternetConnectionOrContactSupport();
            return;
        }
        setSurveySent(true);
    };

    if (surveyAnswered) {
        return (
            <Grid container spacing={0} direction='column' alignItems='center' justify='center' style={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <h1 className={classes.surveyTitle}>{translate('This survey has been answered!')}</h1>
                </Grid>
            </Grid>
        );
    }

    if (surveySent) {
        return (
            <Grid container spacing={0} direction='column' alignItems='center' justify='center' style={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <h1 className={classes.surveyTitle}>{translate('Thank you for sending this survey, now you can close this page.')}</h1>
                </Grid>
            </Grid>
        );
    }

    return (
        <div className={classes.container}>
            <Grid container direction='column' alignItems='center'>
                <Grid item md={12} className={classes.banner}>
                    <Text style={{ fontSize: 30, color: 'white' }} variant='subtitle'>
                        {restaurant?.name}
                    </Text>
                    <Text style={{ fontSize: 20, color: 'white' }} variant='paragraph'>
                        {translate('Your feedback helps us to improve!')}
                    </Text>
                </Grid>
                <Grid item md={4} className={classes.card}>
                    <h1 className={classes.header}>{translate('How was your experience?')}</h1>
                    <div className={classes.ratingsContainer}>
                        {[1, 2, 3, 4, 5].map((heartNumber) => {
                            const heartFilled = rating && heartNumber <= rating;
                            return (
                                <IconButton
                                    size='small'
                                    key={heartNumber}
                                    className={heartFilled ? classes.heartFilled : classes.heart}
                                    onClick={() => {
                                        setRating(heartNumber);
                                    }}
                                >
                                    {heartFilled ? <HeartFilledIcon /> : <HeartFilledIcon />}
                                </IconButton>
                            );
                        })}
                    </div>

                    {rating && (
                        <>
                            <h1 className={classes.header}>{translate('How much would you recommend us?')}</h1>
                            <div className={classes.ratingsContainer}>
                                {[1, 2, 3, 4, 5].map((heartNumber) => {
                                    const heartFilled = recommendationRating && heartNumber <= recommendationRating;
                                    return (
                                        <IconButton
                                            size='small'
                                            key={heartNumber}
                                            className={heartFilled ? classes.heartFilled : classes.heart}
                                            onClick={() => {
                                                setRecommendationRating(heartNumber);
                                            }}
                                        >
                                            {heartFilled ? <HeartFilledIcon /> : <HeartFilledIcon />}
                                        </IconButton>
                                    );
                                })}
                            </div>
                        </>
                    )}

                    {rating && recommendationRating && (
                        <>
                            {rating === 5 && <h1 className={classes.subheader}>{translate('What do you enjoy most about @restaurantName?', { restaurantName })} </h1>}
                            <div className={classes.buttonsContainer}>
                                {Object.values(rating === 5 ? PositiveSurveyAnswers : NegativeSurveyAnswers).map((answer: any) => {
                                    const selected = surveyAnswers.includes(answer);
                                    return (
                                        <ButtonBase
                                            key={answer}
                                            disabled={rating <= 0 || loading}
                                            onClick={() => {
                                                onPressAnswer(answer);
                                            }}
                                            className={classNames(classes.answerButton, selected && classes.answerButtonSelected)}
                                        >
                                            {translate(answer as any)}
                                        </ButtonBase>
                                    );
                                })}
                                <TextField
                                    placeholder={translate('Other')}
                                    className={classes.otherInput}
                                    multiline
                                    onChange={(e) => {
                                        setOther(e.target.value);
                                    }}
                                />
                                <Button
                                    disabled={loading || !rating}
                                    className={classes.sendButton}
                                    variant='contained'
                                    color='primary'
                                    onClick={async () => {
                                        await sendSurveyAnswer();
                                    }}
                                >
                                    {loading && <CircularProgress color='primary' className={classes.buttonProgress} />}
                                    {translate('Send')}
                                </Button>
                            </div>
                        </>
                    )}
                    <Footer>
                        <Text className={classes.footerText}>{translate('With technology from')}</Text>
                        <a className={classes.footerText} href={'https://www.ambit.la/'}>
                            Ambit.la
                        </a>
                    </Footer>
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    header: {
        fontSize: 21,
        color: '#424242',
        textAlign: 'center',
        wordBreak: 'break-word',
    },
    subheader: {
        fontSize: 16,
        color: '#424242',
        textAlign: 'center',
        marginTop: 8,
        marginBottom: 22,
    },
    ratingsContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 16,
    },
    answerButton: {
        textTransform: 'none',
        height: 48,
        width: 300,
        fontSize: 15,
        border: '1px solid',
        borderColor: '#B7B7B7DB',
        borderWidth: 1,
        borderRadius: 48 / 2,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 6,
        marginBottom: 10,
        color: 'gray',
        fontWeight: 'bold',
        '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
        },
    },
    answerButtonSelected: {
        border: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        '&:hover': {
            border: `2px solid ${theme.palette.primary.main}`,
        },
    },
    sendButton: {
        textTransform: 'none',
        height: 52,
        width: '100%',
        borderRadius: 48 / 2,
        fontSize: 20,
    },
    buttonProgress: {
        position: 'absolute',
    },
    otherInput: {
        marginTop: 10,
        marginBottom: 30,
        width: '95%',
    },
    card: {
        width: '90%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#F5F5F5',
        borderRadius: 10,
        boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
        padding: '20px',
        marginBottom: '20px',
        marginTop: 20,
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
        marginBottom: '20px',
        alignSelf: 'center',
        color: 'rgba(31,153,160,1)',
    },
    heartFilled: {
        color: theme.palette.primary.main,
        flex: 1,
    },
    heart: {
        color: 'rgba(0,0,0,0.54)',
        flex: 1,
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: 16,
    },
    surveyTitle: {
        backgroundColor: 'white',
        color: '#424242',
        borderRadius: 10,
        textAlign: 'center',
        padding: 20,
    },
    banner: {
        backgroundColor: '#8D25FF',
        width: '100%',
        padding: 25,
        margin: 'auto',
        textAlign: 'center',
    },
    footerText: {
        fontFamily: AppTheme.typography.regular,
        fontSize: 14,
    },
}));
