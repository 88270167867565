/**
 * @prettier
 */
import { Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { ListInfo } from 'src/components/ListInfo';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { CircleInfoIcon } from 'src/icons/CircleInfoIcon';
import { CartItemVm } from 'src/types/CartItemVm';
import { isPromoCodeApplicable } from 'src/utils/promoCode/isPromoCodeApplicable';
import { isPromoCodeApplicableForRequireAtLeastOneCard } from 'src/utils/promoCode/isPromoCodeApplicableForRequireAtLeastOneCard';
import { useGetInvalidPromoCodeMessage } from 'src/utils/react/useGetInvalidPromoCodeMessage';
import { useSelector } from 'src/utils/react/useSelector';

export function CheckoutInfo(): React.ReactElement {
    const classes = useStyles();
    const promoCode = useSelector((state) => state.app.promoCode);
    const cartItems = useSelector((state) => state.app.cartItems);
    const orderType = useSelector((state) => state.app.orderType);
    const numberOfOrders = useSelector((state) => state.app.numberOfOrders);
    const paymentMethod = useSelector((state) => state.app.paymentMethod);
    const numberOfCards = useSelector((state) => state.app.cards?.length ?? 0);
    const promoCodeSubtotal = useSelector((state) => state.app.payment?.promoCodeSubtotal);
    const customerDeliveryCost = useSelector((state) => state.app.payment?.customerDeliveryCost);
    const uberDaasOrdersWithCashEnabled = useSelector((state) => state.app.restaurant?.uberDaasOrdersWithCashEnabled);

    const menuItemIds = cartItems?.map((cartItem: CartItemVm) => cartItem?.menuItemId);

    const promoCodeApplicable = promoCode ? isPromoCodeApplicable({ promoCode, orderType, paymentMethod, amount: promoCodeSubtotal, customerDeliveryCost, menuItemIds, numberOfOrders }) : false;
    const promoCodeApplicableForRequireAtLeastOneCard = promoCode ? isPromoCodeApplicableForRequireAtLeastOneCard({ promoCode, numberOfCards }) : false;
    const showCartInfo = !promoCode || (promoCodeApplicable && promoCodeApplicableForRequireAtLeastOneCard);

    const getInvalidPromoCodeMessage = useGetInvalidPromoCodeMessage();

    const uberDaasCashDisclaimer = translate(
        'Please have the exact change ready. Delivery drivers carry limited change and may not always have enough change. Your delivery will be canceled if you cant pay the full amount. Please reward your driver with a tip for outstanding service.',
    );

    return (
        <>
            <Collapse in={!showCartInfo}>
                <div className={classes.container}>
                    <ListInfo text={getInvalidPromoCodeMessage(promoCode, menuItemIds)} />
                </div>
            </Collapse>
            <div className={classes.container}>
                {uberDaasOrdersWithCashEnabled && paymentMethod === PaymentMethods.CASH && (
                    <div className={classes.container}>
                        <CircleInfoIcon className={classes.icon} />
                        <div className={classes.textContainer}>
                            <div className={classes.text}>{uberDaasCashDisclaimer}</div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    icon: {
        color: theme.palette.primary.main,
    },
    textContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    text: {
        fontSize: '11px',
        fontWeight: 600,
        color: theme.palette.primary.main,
    },
}));
