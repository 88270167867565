/**
 * @prettier
 */
export const RefundMethods = Object.freeze({
    CREDITS: 'CREDITS',
    CREDIT_CARD: 'CREDIT_CARD',
    PAYROLL: 'PAYROLL',
});

export type RefundMethod = (typeof RefundMethods)[keyof typeof RefundMethods];
