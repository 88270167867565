/**
 * @prettier
 */
import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CardVm } from 'src/types/CardVm';
import type { CardId, CustomerId } from 'src/types/Id';

export async function removeCardApi(request: RemoveCardApiRequest): ApiSauceResponse<CardVm> {
    return pidedirectoApiMethod('card/removeCardApi', request);
}

export type RemoveCardApiRequest = {
    customerId: CustomerId;
    cardId: CardId;
};
