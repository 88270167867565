/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Radio, RadioGroup, Text } from '@pidedirecto/ui';
import { useTheme } from '@pidedirecto/ui/hooks';
import { GiftIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { PromoCodeVm } from 'src/api/pidedirecto/types/PromoCodeVm';
import { translate } from 'src/i18n/translate';
import { usePromoCodeStore } from 'src/modules/promoCode/promoCodeStore';
import { isAmountDiscount } from 'src/modules/promoCode/utils/isAmountDiscount';
import { isCreditsReward } from 'src/modules/promoCode/utils/isCreditsReward';
import { isFirstOrderPromoCode } from 'src/modules/promoCode/utils/isFirstOrderPromoCode';
import { actions } from 'src/reducers';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { PromoCodeId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PromoCodeRadioGroup(): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const theme = useTheme();

    const promoCodes = usePromoCodeStore((state) => state.promoCodes);

    const promoCodeBannerEnabled = useSelector((state) => state.app.restaurant?.promoCodeBannerEnabled);
    const selectedPromoCode = useSelector((state) => state.app.promoCode);
    const restaurantMenu = useSelector((state) => state.app.restaurantMenu);

    const addPromoCode = useAction(actions.addPromoCode);

    const menus = restaurantMenu.menus ?? [];
    const menuCategories = menus.flatMap((menu) => menu.categories);
    const menuItems = menuCategories.flatMap((menuCategory) => menuCategory.menuItems);

    const getPromoCodeLabel = (promoCode: PromoCodeVm) => {
        if (isCreditsReward(promoCode.rewardType)) {
            return getCreditsRewardPromoCodeLabel(promoCode);
        } else {
            return getDiscountRewardPromoCodeLabel(promoCode);
        }
    };

    const getDiscountRewardPromoCodeLabel = (promoCode: PromoCodeVm) => {
        const discount = isAmountDiscount(promoCode.discountType) ? formatAsCurrencyNumber(promoCode.discount) : `${promoCode.discount}%`;
        const label = translate('@discount off', { discount });

        if (!promoCode.menuItemIds) {
            return label + ' ' + translate('in total');
        }

        const validMenuItems = menuItems.filter((menuItem) => promoCode.menuItemIds?.includes(menuItem.menuItemId));

        if (validMenuItems.length === 1) {
            return label + ' ' + translate('in @menuItemName', { menuItemName: validMenuItems[0].name });
        }

        return label + ' ' + translate('in selected products');
    };

    const getCreditsRewardPromoCodeLabel = (promoCode: PromoCodeVm) => {
        const discount = isAmountDiscount(promoCode.discountType) ? formatAsCurrencyNumber(promoCode.discount) : `${promoCode.discount}%`;
        return translate('@discount in credits', { discount });
    };

    const getPromoCodeDescription = (promoCode: PromoCodeVm) => {
        let description = '';
        if (promoCode.menuItemIds) {
            description += getMenuItemsPromoCodeDescription(promoCode);
        }
        if (promoCode.freeDelivery) {
            description += ' ' + translate('Free Delivery.');
        }
        if (promoCode.maxDiscountAmount) {
            description += ' ' + translate('Maximum discount @maxDiscountAmount.', { maxDiscountAmount: formatAsCurrencyNumber(promoCode.maxDiscountAmount) });
        }
        if (promoCode.minOrderAmount) {
            description += ' ' + translate('Minimum order @minOrderAmount.', { minOrderAmount: formatAsCurrencyNumber(promoCode.minOrderAmount) });
        }
        if (promoCode.orderTypes) {
            description += ' ' + translate('Valid for @orderTypes', { orderTypes: promoCode.orderTypes.map((orderType) => translate(`OrderTypes.${orderType}`)).join(', ') });
        }
        if (promoCode.paymentMethods) {
            description += ' ' + translate('Valid for @paymentMethods', { paymentMethods: promoCode.paymentMethods.map((paymentMethod) => translate(paymentMethod)).join(', ') });
        }
        if (isFirstOrderPromoCode(promoCode.promoType)) {
            description += ' ' + translate('Only valid for your first order.');
        }
        return description;
    };

    const getMenuItemsPromoCodeDescription = (promoCode: PromoCodeVm) => {
        if (!promoCode.menuItemIds) return '';
        const validMenuItems = menuItems.filter((menuItem) => promoCode.menuItemIds?.includes(menuItem.menuItemId));
        if (validMenuItems.length <= 1) return '';
        return translate('Promo code valid for @menuItemNames.', { menuItemNames: validMenuItems.map((menuItem) => menuItem.name).join(', ') });
    };

    const selectPromoCode = (promoCodeId: PromoCodeId) => {
        const selectedPromoCode = promoCodes.find((promoCode) => promoCode.promoCodeId === promoCodeId);
        addPromoCode(selectedPromoCode);
    };

    if (!promoCodes.length || !promoCodeBannerEnabled) return null;

    return (
        <div className={classes.container}>
            <div className={classes.labelContainer} style={{ backgroundColor: theme.palette?.surface?.tertiary }}>
                <GiftIcon size={20} color={theme.palette?.text?.brand} />
                <Text>{translate('Available promo codes')}</Text>
            </div>
            <div className={classes.radioGroupContainer}>
                <RadioGroup value={selectedPromoCode?.promoCodeId} name='promoCode' onChange={selectPromoCode}>
                    {promoCodes.map((promoCode) => (
                        <Radio
                            key={promoCode.promoCodeId}
                            value={promoCode.promoCodeId}
                            label={getPromoCodeLabel(promoCode)}
                            helperText={getPromoCodeDescription(promoCode)}
                            classes={{ label: classes.radioText, helperText: classes.radioText }}
                        />
                    ))}
                </RadioGroup>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        width: '100%',
        marginBottom: 20,
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 6,
        padding: '10px 12px',
        borderRadius: 6,
    },
    radioGroupContainer: {
        padding: '0 6px',
    },
    radioText: {
        [theme.breakpoints.down('xs')]: {
            color: 'white',
        },
    },
}));
