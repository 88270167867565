/**
 * @prettier
 */
import { Auth, Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';

export function useIsUserSignedIn(): [boolean, boolean] {
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Hub.listen('auth', async function (data) {
            // eslint-disable-next-line default-case
            switch (data.payload.event) {
                case 'signIn': {
                    setIsUserSignedIn(true);
                    setLoading(false);
                    break;
                }
                case 'signIn_failure': {
                    signInFailed(data.payload.data.message);
                    break;
                }
                default: {
                    setLoading(false);
                }
            }
        });
        initializeIsUserSignedIn();
    }, []);

    const signInFailed = (message?: string) => {
        setIsUserSignedIn(false);
        setLoading(false);
    };

    const initializeIsUserSignedIn = async () => {
        try {
            const cognitoUser = await Auth.currentUserPoolUser();
            setIsUserSignedIn(!!cognitoUser);
        } catch (e) {
            console.log(e);
        }
    };

    return [loading, isUserSignedIn];
}
