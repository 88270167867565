/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { ContactInfoSummary } from 'src/components/ContactInfoSummary';
import { OrderDetailsItem } from 'src/components/OrderDetailsItem';
import { OrderDetailsItemPickupTime } from 'src/components/OrderDetailsItemPickupTime';
import { OrderItems } from 'src/components/OrderItems';
import { OrderSummary } from 'src/components/OrderSummary';
import { PaymentMethodIcon } from 'src/components/PaymentMethodIcon';
import { Separator } from 'src/components/Separator';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { isCardPayment } from 'src/utils/order/isCardPayment';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isRoomServiceOrder } from 'src/utils/order/isRoomServiceOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { translatePaymentMethod } from 'src/utils/translate/translatePaymentMethod';

export function OrderDetails(): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const paymentMethod = useSelector((state) => state.app.orderPaymentMethod);
    const mandatoryCashAmount = useSelector((state) => state.app.mandatoryCashAmount);
    const address = useSelector((state) => state.app.customerAddress);
    const restaurant = useSelector((state) => state.app.restaurant);
    const orderType = useSelector((state) => state.app.orderType);
    const roomServiceNumber = useSelector((state) => state.app.order?.roomServiceNumber);
    const driver = useSelector((state) => state.app.driver);
    const order = useSelector((state) => state.app.order);
    const orderStatus = useSelector((state) => state.app.order?.orderStatus);
    const ecommerceOrdersCashbackPercentage = useSelector((state) => state.app.restaurant?.ecommerceOrdersCashbackPercentage);
    const ecommerceOrdersCashbackEnabled = useSelector((state) => state.app.restaurant?.ecommerceOrdersCashbackEnabled);
    const roomServiceCustomLabel = useSelector((state) => state.app.restaurant?.roomServiceCustomLabel);

    const finalAddress = !isDeliveryOrder(orderType) ? restaurant?.street : address?.formattedAddress ?? address?.street;
    const payments = order.payments;

    const cashbackOrder = BigNumber(order.total)
        .multipliedBy(ecommerceOrdersCashbackPercentage ?? 0)
        .dividedBy(100)
        .toString();

    if (!restaurant) return null;

    return (
        <div>
            <Separator />
            <div className={classes.headerInfo}>
                {finalAddress && !isRoomServiceOrder(orderType) && !isTakeAwayOrder(orderType) && (
                    <OrderDetailsItem text={translate(!isDeliveryOrder ? 'Pickup address' : 'Delivery address')} subtext={finalAddress} />
                )}
                {isRoomServiceOrder(orderType) && <OrderDetailsItem text={roomServiceCustomLabel || translate('Room number')} subtext={roomServiceNumber} />}
                {!isCompletedOrder(orderStatus) && <OrderDetailsItemPickupTime />}
            </div>
            <Separator />
            <OrderItems />
            <Separator />
            <OrderSummary />
            <Separator />
            {paymentMethod && !payments && (
                <OrderDetailsItem
                    text={translate('Payment Method')}
                    subtext={translatePaymentMethod(paymentMethod, undefined, orderType)}
                    icon={<PaymentMethodIcon />}
                    tag={
                        ecommerceOrdersCashbackEnabled && isCardPayment(paymentMethod)
                            ? translate('You will receive @totalCashback in cashback upon completing the order', { totalCashback: formatAsCurrencyNumber(cashbackOrder) })
                            : undefined
                    }
                />
            )}
            {mandatoryCashAmount && <OrderDetailsItem text={translate('You pay with')} subtext={formatAsCurrencyNumber(mandatoryCashAmount)} />}
            {payments && <OrderDetailsItem text={translate('Payment Method')} subtext={payments[0].customPaymentMethod} icon={<PaymentMethodIcon />} />}
            <Separator />
            <ContactInfoSummary phoneNumber={restaurant.phoneNumber} name={restaurant.name} title={translate('Business')} />
            <Separator />
            {isDeliveryOrder(orderType) && driver && <ContactInfoSummary phoneNumber={driver.mobileNumber} name={`${driver.firstName} ${driver.lastName}`} title={translate('Driver')} />}
        </div>
    );
}
const useStyles = makeStyles((theme) => ({
    headerInfo: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            gap: 32,
        },
    },
}));
