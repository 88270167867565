/**
 * @prettier
 */
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import * as React from 'react';
import { useContext, useState } from 'react';
import { startCardValidationApi } from 'src/api/pidedirecto/card/startCardValidationApi';
import { createOrderApi, CreateOrderApiRequest } from 'src/api/pidedirecto/order/createOrderApi';
import type { OrderPaymentVm } from 'src/api/pidedirecto/types/OrderVm';
import { Button } from 'src/components/Button';
import { useConfirmDialog } from 'src/components/cart/ConfirmDialog';
import { PlexoPaymentTransferDialog } from 'src/components/checkout/PlexoPaymentTransferDialog';
import { PromotionEndedDialog } from 'src/components/dialog/promotion/PromotionEndedDialog';
import { Text } from 'src/components/Text';
import { ApiErrors } from 'src/constants/ApiErrors';
import { CardBrands } from 'src/constants/CardBrand';
import { OrderTypes } from 'src/constants/OrderType';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { PickupTimeTypes } from 'src/constants/PickupTimeType';
import { RestaurantTypes } from 'src/constants/RestaurantType';
import { translate } from 'src/i18n/translate';
import { CartContext } from 'src/providers/CartProvider';
import { actions } from 'src/reducers';
import { useDoesTotalNeedCardValidation } from 'src/services/card/useDoesTotalNeedCardValidation';
import { useAmountToPay } from 'src/services/order/useAmountToPay';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { PaymentId } from 'src/types/Id';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { toMinutes } from 'src/utils/duration/toMinutes';
import { logError } from 'src/utils/log/logError';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isRejectedOrder } from 'src/utils/order/isRejectedOrder';
import { isRoomServiceOrder } from 'src/utils/order/isRoomServiceOrder';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { isTransferPayment } from 'src/utils/paymentMethod/isTransferPayment';
import { useAction } from 'src/utils/react/useAction';
import { usePrevious } from 'src/utils/react/usePrevious';
import { useSelector } from 'src/utils/react/useSelector';
import { removeNulls } from 'src/utils/removeNulls';
import { isRestaurantWithinDeliveryDistance } from 'src/utils/restaurant/isRestaurantWithinDeliveryDistance';
import { wait } from 'src/utils/wait';
import { openUrl } from 'src/utils/window/openUrl';

export function CreateOrderButton(): React.ReactElement {
    const classes = useStyles();
    const cartContext = useContext(CartContext);
    const doesTotalNeedCardValidation = useDoesTotalNeedCardValidation();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [inProgress, setInProgress] = useState(false);
    const [plexoPaymentTransferDialogState, setPlexoPaymentTransferDialogState] = useState({ open: false });
    const [promotionEndedDialogState, setPromotionEndedDialogState] = useState({ open: false, message: '' });

    const plexoStoreId = useSelector((state) => state.app.restaurant?.plexoStoreId);
    const customerId = useSelector((state) => state.app.customerId);
    const mobileNumber = useSelector((state) => state.app.mobileNumber);
    const restaurant = useSelector((state) => state.app.restaurant);
    const cartItems = useSelector((state) => state.app.cartItems);
    const payment = useSelector((state) => state.app.payment);
    const paymentFailsCount = useSelector((state) => state.app.paymentFailsCount);
    const useLetsEatCredits = useSelector((state) => state.app.useLetsEatCredits);
    const usedCredits = useSelector((state) => state.app.payment?.usedCredits);
    const promoCode = useSelector((state) => state.app.promoCode);
    const pickupTimeType = useSelector((state) => state.app.pickupTimeType);
    const pickupTime = useSelector((state) => state.app.pickupTime);
    const usedPromotions = useSelector((state) => state.app.payment?.usedPromotions);
    const table = useSelector((state) => state.app.table);
    const hasCartItems = useSelector((state) => !!state.app.cartItems?.length);
    const temporarilyOffline = useSelector((state) => state.app.restaurant?.temporarilyOffline);
    const comingSoon = useSelector((state) => state.app.restaurant?.comingSoon);
    const loadingDeliveryEstimate = useSelector((state) => state.app.loadingDeliveryEstimate);
    const restaurantIsClosed = useSelector((state) => !state.app.pickupTimes?.asap && !state.app.restaurant?.plannedOrdersEnabled);
    const plannedOrdersEnabled = useSelector((state) => state.app.restaurant?.plannedOrdersEnabled);
    const restaurantIsOpenForOrdering = useSelector((state) => {
        if (state.app.restaurant?.restaurantType === RestaurantTypes.PICKUP_STATION) {
            return state.app.restaurant?.cashPaymentsEnabled || state.app.restaurant?.inAppPaymentsEnabled;
        }
        if (isDeliveryOrder(state.app.orderType)) {
            return (
                (state.app.restaurant?.deliveryEnabled &&
                    (state.app.pickupTimes?.asap || !!state.app.pickupTimes?.planned.days.length) &&
                    (state.app.restaurant?.cashPaymentsEnabled || state.app.restaurant?.inAppPaymentsEnabled)) ||
                (plannedOrdersEnabled && state.app.restaurant?.deliveryEnabled)
            );
        }
        return (
            ((state.app.restaurant?.eatHereEnabled || state.app.restaurant?.takeAwayEnabled) && (state.app.pickupTimes?.asap || !!state.app.pickupTimes?.planned.days.length)) ||
            state.app.restaurant?.cashPaymentsEnabled ||
            state.app.restaurant?.inAppPaymentsEnabled
        );
    });
    const tooFarAway = useSelector((state) => {
        if (state.app.orderType !== OrderTypes.DELIVERY_ORDER) {
            return false;
        }
        if (!state.app.address?.location) {
            return false;
        }
        if (!state.app.address) {
            return false;
        }
        if (state.app.deliveryEstimate && !state.app.deliveryEstimate.isWithinDeliveryRadius) {
            return true;
        }
        return !isRestaurantWithinDeliveryDistance(state.app.restaurant, state.app.address?.location);
    });
    const total = useSelector((state) => state.app.payment?.total);
    const prevTotal = usePrevious(total); // Used to keep total during animation of order button disappearance
    const orderType = useSelector((state) => state.app.orderType);
    const address = useSelector((state) => state.app.address);
    const paymentMethod = useSelector((state) => state.app.paymentMethod);
    const cards = useSelector((state) => state.app.cards);
    const card = useSelector((state) => state.app.card);
    const driverTip = useSelector((state) => state.app.driverTip);
    const deliveryEstimate = useSelector((state) => state.app.deliveryEstimate);
    const requireAtLeastOneCard = useSelector((state) => state.app.promoCode?.requireAtLeastOneCard);
    const notes = useSelector((state) => state.app.notes);
    const mandatoryCashAmount = useSelector((state) => state.app.mandatoryCashAmount);
    const ecommerceCashPaymentsToEatHereEnabled = useSelector((state) => state.app.restaurant?.ecommerceCashPaymentsToEatHereEnabled);
    const mandatoryCashAmountEnabled = useSelector((state) => state.app.restaurant?.mandatoryCashAmountEnabled);
    const roomServiceNumber = useSelector((state) => state.app?.roomServiceNumber);
    const customPaymentMethod = useSelector((state) => state.app.customPaymentMethod);
    const plexoPaymentSecurityId = useSelector((state) => state.app.plexoPaymentSecurityId);

    const openSelectAddressDialog = useAction(actions.openSelectAddressDialog);
    const openSelectPaymentMethodDialog = useAction(actions.openSelectPaymentMethodDialog);
    const openAddNewCardDialog = useAction(actions.openAddNewCardDialog);
    const openValidateCardDialog = useAction(actions.openValidateCardDialog);
    const openEnterCvcDialog = useAction(actions.openEnterCvcDialog);
    const closeEnterCvcDialog = useAction(actions.closeEnterCvcDialog);
    const setOrder = useAction(actions.setOrder);
    const updateCard = useAction(actions.updateCard);

    const confirm = useConfirmDialog();

    const createOrderDisabled = mandatoryCashAmountEnabled && isCashPayment(paymentMethod) && isDeliveryOrder(orderType) ? cartContext.cartHasError : false;

    const handleDeliveryEstimate = isDeliveryOrder(orderType) && address?.location;
    const amountToPay = useAmountToPay();

    const getButtonSubtext = () => {
        if (comingSoon) {
            return translate('Coming Soon');
        }
        if (tooFarAway) {
            return translate('Your address is too far away');
        }
        if (!restaurantIsOpenForOrdering) {
            return translate('Not available for the moment');
        }
    };

    const tryToOrder = async () => {
        if (isDeliveryOrder(orderType) && !address) {
            openSelectAddressDialog();
            return;
        }

        if (!paymentMethod) {
            openSelectPaymentMethodDialog(() => {});
            return;
        }

        const isTableOrder = isEatHereOrder(orderType);
        const isCreditCardPayment = isCardPayment(paymentMethod) && !customPaymentMethod;
        const isTotalGreaterThanZero = BigNumber(total).isGreaterThan(0);

        if (
            (isTableOrder && !isCreditCardPayment && !ecommerceCashPaymentsToEatHereEnabled && isTotalGreaterThanZero) ||
            (isCreditCardPayment && isTotalGreaterThanZero && !card) ||
            (isCreditCardPayment && requireAtLeastOneCard && !card) ||
            !paymentMethod
        ) {
            if (cards) {
                openSelectPaymentMethodDialog(() => {});
                return;
            }
            openAddNewCardDialog(() => {});
            return;
        }

        if (isCreditCardPayment && doesTotalNeedCardValidation({ total, card })) {
            let updatedCard;
            if (!card.needsValidation) {
                const startCardValidation = await confirm({
                    title: translate('Are you sure you want to validate this card?'),
                    content: translate(
                        'For your own safety we need to verify this card belongs to you.\nWe will charge a random amount less than $20 on your card that you will need to send to us to verify that you are the owner of this card. The amount will be refunded and only used for verification.',
                    ),
                });
                if (startCardValidation !== true) return;
                const response = await startCardValidationApi({ cardId: card.cardId });
                if (!response.ok) {
                    alert(
                        translate(
                            'Failed to create payment needed for the card validation. Make sure you have at least $20 on your cards and try again. Also make sure you have internet connection. If the problem persists try another card.',
                        ),
                    );
                    return;
                }
                updatedCard = response.data;
                updateCard(updatedCard);
            }
            openValidateCardDialog({ card: updatedCard ?? card });
            return;
        }

        if (isCardPayment(paymentMethod) && BigNumber(total).isGreaterThan(0) && card && card.brand === CardBrands.SODEXO) {
            openEnterCvcDialog((cvc: string) => {
                closeEnterCvcDialog();
                createOrder({ cvc });
            });
            return;
        }

        if (isTransferPayment(paymentMethod) && !!plexoStoreId) {
            openPlexoTransferDialog();
            return;
        }

        createOrder();
    };

    const createOrder = async ({ cvc, metaData, paymentId }: { cvc?: string; metaData?: string; paymentId?: PaymentId } = {}) => {
        setInProgress(true);
        const request: CreateOrderApiRequest = removeNulls({
            customerId: customerId,
            restaurantId: restaurant.restaurantId,
            deliveryEstimateId: handleDeliveryEstimate ? deliveryEstimate.deliveryEstimateId : undefined,
            orderType,
            paymentMethod,
            mandatoryCashAmount,
            cardId: isCardPayment(paymentMethod) ? card?.cardId : undefined,
            paymentId: paymentId,
            promotionIds: removeDuplicates(usedPromotions?.map((usedPromotion) => usedPromotion.promotionId)),
            table: orderType === OrderTypes.TABLE_ORDER ? table : undefined,
            pickupTimeType: [OrderTypes.SELF_SERVICE_ORDER, OrderTypes.TAKE_AWAY_ORDER, OrderTypes.PICKUP_STATION_ORDER, OrderTypes.DELIVERY_ORDER].includes(orderType) ? pickupTimeType : undefined,
            pickupTime: [OrderTypes.SELF_SERVICE_ORDER, OrderTypes.TAKE_AWAY_ORDER, OrderTypes.PICKUP_STATION_ORDER, OrderTypes.DELIVERY_ORDER, OrderTypes.ROOM_SERVICE_ORDER].includes(orderType)
                ? isDeliveryOrder(orderType) || !address?.location
                    ? moment(pickupTime) // pickupTime here is actually delivery time for delivery orders
                          .subtract(toMinutes(deliveryEstimate?.drivingDuration), 'minutes')
                          .toDate()
                    : moment(pickupTime).toDate()
                : undefined,
            deliveryTime: isDeliveryOrder(orderType) ? moment(pickupTime).toDate() : undefined,
            addressId: isDeliveryOrder(orderType) ? address?.addressId : undefined,
            orderItems: cartItems.map((cartItem) => {
                return {
                    menuItemId: cartItem.menuItemId,
                    menuItemType: cartItem.menuItemType,
                    menuCategoryId: cartItem.menuCategoryId,
                    name: cartItem.name,
                    unitPrice: cartItem.unitPrice,
                    promoUnitPrice: cartItem.promoUnitPrice,
                    promoText: cartItem.promoText,
                    imageUrl: cartItem.imageUrl,
                    modifierGroups: cartItem.modifierGroups,
                    isSoldByWeight: cartItem.isSoldByWeight,
                    salesUnit: cartItem.salesUnit,
                    currency: cartItem.currency,
                    note: cartItem.note,
                    quantity: cartItem.quantity,
                    pickupTime: cartItem.pickupTime,
                };
            }),
            driverTip,
            promoCode:
                payment.promoCodeDiscount ?? payment.promoCodeCredits
                    ? {
                          promoCodeId: promoCode.promoCodeId,
                          code: promoCode.code,
                          promoType: promoCode.promoType,
                          discount: promoCode.discount,
                          discountType: promoCode.discountType,
                          endsAt: promoCode.endsAt,
                      }
                    : undefined,
            useLetsEatCredits: useLetsEatCredits,
            cvc,
            metaData,
            notes: notes,
            payments: customPaymentMethod ? getCustomPayments() : getPayments(),
            roomServiceNumber: roomServiceNumber,
            plexoPaymentSecurityId: plexoPaymentSecurityId,
        });

        console.info('Creating order', { request });

        const response = await createOrderApi(request);

        if (!response.ok) {
            setInProgress(false);
            if (response.status === 400 && (response.data as any)?.name === ApiErrors.InvalidPromotionError) {
                openPromotionEndedDialog((response.data as any)?.message);
                return;
            }
            logError('[Investigate] Failed to create order for unkown reason', { request, response });
            console.info('[Investigate] Failed to create order for unkown reason', { request, response });
            alert(translate('Ordering Failed') + '\n' + translate('Something went wrong when ordering. Please try again.'));
            return;
        }

        const order = response.data;
        setOrder(order);

        if (isRejectedOrder(order.orderStatus)) {
            alert(translate('We were unable to process the payment with the selected card. Please check that you have sufficient funds'));
            return;
        }
        console.info('Successfully created order', { request });

        if (restaurant.hidePlannedOrders && order.pickupTimeType === PickupTimeTypes.PLANNED) {
            await wait(300);
            setInProgress(false);
            alert(
                translate('Planned Order') +
                    '\n' +
                    translate('The restaurant will accept your order @preparationtime minutes before the specified time', { preparationtime: restaurant.preparationTime }),
            );
        }
        openUrl(`${window.location.pathname}/${order.urlFriendlyOrderId}`);

        setTimeout(() => {
            setInProgress(false);
        }, 3000);
    };

    const getPayments = (): Array<OrderPaymentVm> => {
        if (!useLetsEatCredits) return [];
        const payments: Array<OrderPaymentVm> = [
            {
                amount: usedCredits,
                paymentMethod: PaymentMethods.CREDITS,
            },
        ];

        if (BigNumber(amountToPay).isGreaterThan(0)) {
            payments.push({
                amount: amountToPay,
                paymentMethod: paymentMethod,
            });
        }

        return payments;
    };

    const getCustomPayments = (): Array<OrderPaymentVm> => {
        let payments = customPaymentMethod;

        if (BigNumber(amountToPay).isGreaterThan(0)) {
            payments = {
                amount: amountToPay,
                customPaymentMethod: customPaymentMethod.customPaymentMethod,
                paymentMethod,
                customerNumber: parseInt(mobileNumber),
            };
        }

        return [payments];
    };

    const openPlexoTransferDialog = () => setPlexoPaymentTransferDialogState({ open: true });

    const closePlexoTransferDialog = () => setPlexoPaymentTransferDialogState({ open: false });

    const openPromotionEndedDialog = (message: string) => setPromotionEndedDialogState({ open: true, message });

    const closePromotionEndedDialog = () => setPromotionEndedDialogState({ open: false, message: '' });

    const disabled =
        !hasCartItems ||
        inProgress ||
        comingSoon ||
        tooFarAway ||
        !restaurantIsOpenForOrdering ||
        restaurantIsClosed ||
        loadingDeliveryEstimate ||
        createOrderDisabled ||
        (isRoomServiceOrder(orderType) && !roomServiceNumber); // !hasCartItems to keep button disabled during animation
    const buttonSubtext = getButtonSubtext();

    return (
        <div className={classes.createOrderButton}>
            <PromotionEndedDialog open={promotionEndedDialogState.open} message={promotionEndedDialogState.message} onClose={closePromotionEndedDialog} />
            <PlexoPaymentTransferDialog open={plexoPaymentTransferDialogState.open} onClose={closePlexoTransferDialog} onSuccess={createOrder} />
            <div className={classes.topSection}>
                <div className={classes.priceContainer}>
                    <Text className={classes.totalText}>{translate('Amount to pay')}</Text>
                    <Text className={classes.total}>{hasCartItems ? formatAsCurrencyNumber(amountToPay) : formatAsCurrencyNumber(prevTotal)}</Text>
                </div>
                <Button onClick={tryToOrder} classes={{ button: classes.orderButton }} disabled={disabled}>
                    {inProgress || loadingDeliveryEstimate ? <CircularProgress size={20} /> : translate('Make order')}
                </Button>
            </div>
            {(temporarilyOffline || comingSoon || !restaurantIsOpenForOrdering || tooFarAway || restaurantIsClosed) && <p className={classes.buttonSubtext}>{buttonSubtext}</p>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    createOrderButton: {
        position: 'fixed',
        zIndex: 99,
        width: '90%',
        minHeight: 80,
        height: 'fit-content',
        transform: 'translateX(-50%)',
        left: '50%',
        bottom: 30,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 16,
        padding: '16px 24px',
        color: 'white',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up('sm')]: {
            position: 'fixed',
            maxWidth: 370,
            bottom: 20,
            transform: 'none',
            left: 'calc(100% - 395px)',
        },
    },
    topSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    totalText: {
        fontSize: 14,
        color: 'white',
    },
    total: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'white',
    },
    amountContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        color: 'white',
        margin: '24px 0',
        [theme.breakpoints.up('sm')]: {
            color: '#333333',
        },
    },
    orderButton: {
        cursor: 'pointer',
        borderRadius: 12,
        fontSize: 16,
        fontWeight: 600,
        padding: '12px 20px',
        display: 'grid',
        placeItems: 'center',
        outline: 'none',
        border: 0,
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    buttonSubtext: {
        margin: 0,
        alignSelf: 'center',
        marginTop: 8,
    },
    priceContainer: {},
}));
