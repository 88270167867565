/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useIsThereAvailablePromoCodes } from 'src/modules/promoCode/useIsThereAvailablePromoCodes';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function BottomDrawer({ children, className }: Props): React.ReactElement {
    const classes = useStyles();
    const isThereAvailablePromoCodes = useIsThereAvailablePromoCodes();

    const checkoutOpen = useSelector((state) => state.app.checkoutDialog.open);
    const promoCodeBannerEnabled = useSelector((state) => state.app.restaurant?.promoCodeBannerEnabled);

    const isShowingPromoCodeAlert = promoCodeBannerEnabled && isThereAvailablePromoCodes;

    return (
        <div id='bottomDrawer' className={classNames(classes.container, className)} style={{ top: isShowingPromoCodeAlert && !checkoutOpen ? 316 : 250 }}>
            <div>{children}</div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: 'white',
        borderRadius: '42px 42px 0 0',
        minHeight: 'calc(100vh - 100px)',
        paddingLeft: 24,
        paddingRight: 24,
        [theme.breakpoints.up('sm')]: {
            borderRadius: 0,
            minHeight: 'calc(100vh - 130px)',
        },
        [theme.breakpoints.up('md')]: {
            padding: '0 90px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0 180px',
        },
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
}));

type Props = {
    children?: any;
    className?: any;
    onChangeScroll?: any;
};
