/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import type { MenuCategoryVm } from 'src/api/pidedirecto/getAppContextApi';
import { MenuItem } from 'src/components/MenuItem';
import { Text } from 'src/components/Text';
import { actions } from 'src/reducers';
import { isSmallLayout } from 'src/utils/layouts/isSmallLayout';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useIsVisible } from 'src/utils/react/useIsVisible';
import { useSelector } from 'src/utils/react/useSelector';

export function MenuItemsContainer({ section, idx, sectionElements }: Props): React.ReactElement {
    const restaurantMenuPageComponent: any = document.getElementById('restaurantMenuPage');

    const containerRef = useRef<HTMLDivElement | null>(null);
    const isVisible = useIsVisible(containerRef, restaurantMenuPageComponent);
    const classes = useStyles();

    const animationToCategory = useSelector((state) => state.app.openCategory.animationToCategory);
    const productLayout = useSelector((state) => state.app.restaurant?.productLayout);

    const categoryVisible = useAction(actions.categoryVisible);

    useEffect(() => {
        if (isVisible && !animationToCategory) {
            categoryVisible(section.menuCategoryId);
        }
    }, [isVisible, animationToCategory]);

    useEffect(() => {
        if (idx === 0) categoryVisible(section.menuCategoryId);
    }, [idx, section]);

    return (
        <div ref={(ref) => (sectionElements.current[idx] = ref)} id={section.menuCategoryId}>
            <Text className={classes.sectionName}>{section?.name}</Text>
            <div
                className={classNames(classes.menuContainer, {
                    [classes.menuContainerSmall]: isSmallLayout(productLayout),
                })}
                ref={(ref) => (containerRef.current = ref)}
            >
                {section?.menuItems.map((menuItem) => {
                    return <MenuItem menuItem={menuItem} key={menuItem.menuItemId} />;
                })}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    menuItemsContainer: {
        [theme.breakpoints.up('sm')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '42px',
        },
    },
    sectionName: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 12,
        color: '#393B41',
    },
    menuContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '28px',
        [theme.breakpoints.up('sm')]: {
            display: 'grid',
            gridTemplateColumns: ' 1fr',
            gap: '42px',
        },
        [theme.breakpoints.up('md')]: {
            display: 'grid',
            gridTemplateColumns: ' 1fr 1fr',
            gap: '42px',
        },
    },
    menuContainerSmall: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2,47%)',
        rowGap: '12px',
        columnGap: '18px',
        [theme.breakpoints.up('sm')]: {
            display: 'grid',
            gridTemplateColumns: ' 1fr',
            gap: '42px',
        },
        [theme.breakpoints.up('md')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2,1fr)',
            gap: '42px',
        },
    },
}));

type Props = {
    section: MenuCategoryVm;
    idx: number;
    sectionElements: any;
};
