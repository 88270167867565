/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import * as React from 'react';
import { EnvironmentLabel } from 'src/components/EnvironmentLabel';
import { Page } from 'src/components/Page';
import { translate } from 'src/i18n/translate';
import NotFoundImage from 'src/images/NotFoundImage.png';
import { actions } from 'src/reducers';
import { AppTheme } from 'src/styles/AppTheme';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function ContentNotFoundPage(): React.ReactElement {
    const classes = useStyles();

    const loadingAppContext = useSelector((state) => !!state.app.inProgress.fetchAppContext);

    const setLoadingAppContext = useAction(actions.setLoadingAppContext);

    useEffect(() => {
        if (loadingAppContext) {
            setLoadingAppContext(false);
        }
    }, [loadingAppContext]);

    return (
        <Page title={'ContentNotFoundPage'} className={classes.container}>
            <h1 className={classes.title}>{translate('Ups!')}</h1>
            <p className={classes.text}>{translate('Something went wrong, we did not find what you are looking for.')}</p>
            <img src={NotFoundImage} alt={translate('Not found content image')} className={classes.image} />
            <EnvironmentLabel />
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        overflow: 'hidden',
    },
    title: {
        color: '#0D3037',
        fontFamily: AppTheme.typography.bold,
        fontSize: 64,
        margin: 0,
    },
    text: {
        fontFamily: AppTheme.typography.medium,
        fontSize: 24,
        color: '#0D3037',
        textAlign: 'center',
        width: '30vw',
        [theme.breakpoints.down('md')]: {
            width: '50vw',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90vw',
        },
    },
    image: {
        position: 'relative',
        left: '3vw',
        marginTop: '3vh',
        [theme.breakpoints.down('sm')]: {
            left: '7vw',
        },
        [theme.breakpoints.down('xs')]: {
            left: '15vw',
        },
    },
}));
