/**
 * @prettier
 */
import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { Country } from 'src/constants/Country';
import type { CardVm } from 'src/types/CardVm';
import type { CustomerId, RestaurantId } from 'src/types/Id';

export async function addCardApi(request: AddCardApiRequest): ApiSauceResponse<CardVm> {
    return pidedirectoApiMethod('card/addCardApi', request);
}

export type AddCardApiRequest = {
    restaurantId: RestaurantId;
    customerId: CustomerId;
    cardToken: string;
    stripeCardToken?: string;
    conektaCardToken?: string;
    mercadoPagoCardToken?: string;
    cvv?: string;
    amount?: string;
    country: Country;
};
