/**
 * @prettier
 */
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { capitalize as _capitalize } from 'src/utils/string/capitalize';
import { formatAsE164PhoneNumber } from 'src/utils/string/formatAsE164PhoneNumber';
import { lowerCase as _lowerCase } from 'src/utils/string/lowerCase';
import { trim as _trim } from 'src/utils/string/trim';
import { upperCase as _upperCase } from 'src/utils/string/upperCase';

export const TransformedTextField: React.ComponentType<any> = React.forwardRef<any, any>(function TransformedTextField(
    { value, onChange, trim, lowerCase, upperCase, capitalize, phoneNumber, ...props },
    ref,
) {
    const [inputValue, setInputValue] = useState(transformToInput(value)); // Separate form value from input value
    useEffect(() => {
        let oldValue = transformToOutput(inputValue, trim, upperCase, capitalize, phoneNumber);
        if (value !== oldValue) setInputValue(transformToInput(value));
    }, [value, trim, upperCase, capitalize]);
    return (
        <TextField
            {...props}
            value={inputValue}
            onChange={(e) => {
                setInputValue(e.target.value);
                onChange(transformToOutput(e.target.value, trim, lowerCase, upperCase, capitalize, phoneNumber));
            }}
        />
    );
});

function transformToInput(value?: string | null): string {
    return value ?? '';
}

export function transformToOutput(value?: string | null, trim?: boolean, lowerCase?: boolean, upperCase?: boolean, capitalize?: boolean, phoneNumber?: boolean): string | null | undefined {
    let outputValue = value;
    if (trim) outputValue = _trim(outputValue);
    if (lowerCase) outputValue = _lowerCase(outputValue);
    if (upperCase) outputValue = _upperCase(outputValue);
    if (capitalize) outputValue = _capitalize(outputValue);
    if (phoneNumber) outputValue = formatAsE164PhoneNumber(outputValue);
    return !outputValue ? null : outputValue;
}
