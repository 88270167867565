/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import type { AppVm } from 'src/types/StateVm';
import { sumValidGifts } from 'src/utils/gift/sumValidGifts';

export function refreshCreditsWithGifts(app: AppVm): void {
    app.creditsWithGifts = BigNumber(app.credits ?? 0)
        .plus(sumValidGifts(app.gifts))
        .toString();
}
