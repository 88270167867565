/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { memo } from 'react';
import { AppTheme } from 'src/styles/AppTheme';
import { classNames } from 'src/utils/react/classNames';

export const Text: React.ComponentType<Props> = memo<Props>(function Text({ children, className, style, size, variant, error }: Props): React.ReactElement {
    const classes = useStyles();

    const getClassNames = () => {
        let textClasses = classNames(classes.text, className);

        if (size === 'small') textClasses = classNames(textClasses, classes.smallText);
        if (size === 'large') textClasses = classNames(textClasses, classes.largeText);
        if (error) textClasses = classNames(textClasses, classes.errorText);

        return textClasses;
    };

    return (
        <div className={getClassNames()} style={{ fontFamily: AppTheme.typography[variant || 'regular'], ...style }}>
            {children}
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: AppTheme.typography.regular,
        fontSize: 14,
        color: '#4F586E',
    },
    smallText: {
        fontSize: 12,
    },
    largeText: {
        fontSize: 16,
    },
    errorText: {
        color: '#f44336',
    },
}));

type Props = {
    size?: 'small' | 'large';
    variant?: 'bold' | 'semiBold' | 'medium' | 'light';
    error?: boolean;
    className?: string;
    style?: any;
    children?: React.ReactNode;
};
