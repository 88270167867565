/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Text } from 'src/components/Text';
import { AppTheme } from 'src/styles/AppTheme';

export function CheckoutElement({ title, content, action, actionTitle, icon, description, tag }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.checkoutElement}>
            <p className={classes.checkoutElementTitle}>{title}</p>
            <div className={classes.checkoutElementContainer}>
                <div className={classes.checkoutElementContent}>
                    {icon}
                    <Text style={{ margin: 0, marginLeft: icon ? 8 : 0, width: '100%', color: '#0D3037' }}>{content}</Text>
                </div>
                {action && actionTitle && (
                    <Button text classes={{ button: classes.checkoutElementAction }} onClick={action}>
                        {actionTitle}
                    </Button>
                )}
            </div>
            {description && <p className={classes.checkoutElementDescription}>{description}</p>}
            {tag && <p className={classes.checkoutElementTag}>{tag}</p>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    checkoutElement: {
        cursor: 'default',
        borderBottom: '1px solid #F0F0F1',
        paddingBottom: 12,
        paddingTop: 12,
        width: '100%',
    },
    checkoutElementTitle: {
        fontWeight: 400,
        fontSize: 16,
        color: '#818696',
        margin: 0,
        marginBottom: 8,
        fontFamily: AppTheme.typography.regular,
    },
    checkoutElementContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    checkoutElementContent: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 1.4,
        color: '#4F586E',
        width: '100%',
    },
    checkoutElementAction: {
        fontFamily: AppTheme.typography.medium,
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: 'right',
    },
    checkoutElementDescription: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: AppTheme.typography.regular,
        fontSize: 10,
        lineHeight: 1.4,
        color: '#4F586E',
        width: '100%',
    },
    checkoutElementTag: {
        backgroundColor: '#F1F1F1',
        borderRadius: 20,
        display: 'flex',
        fontFamily: AppTheme.typography.light,
        width: 'fit-content',
        justifyContent: 'center',
        fontSize: 12,
        lineHeight: 1.4,
        padding: '5px 10px',
    },
}));

type Props = {
    title: string;
    content?: any;
    action?: any;
    actionTitle?: any;
    icon?: React.ReactNode;
    description?: string;
    tag?: string;
};
