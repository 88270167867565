/**
 * @prettier
 */
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import type { BottomDialogProps } from 'src/components/BottomDialog';

export function BottomDialogDesktop({ open, onClickBackground, maxWidth = 'xs', children }: BottomDialogProps): React.ReactElement {
    const classes = useStyles();

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition as any}
                onClose={onClickBackground ? onClickBackground : undefined}
                fullWidth
                maxWidth={maxWidth}
                classes={{ paperScrollPaper: classes.root }}
                disableEscapeKeyDown={!onClickBackground}
                keepMounted={false}
            >
                {/*<DialogTitle>{"Use Google's location service?"}</DialogTitle>*/}
                {/*<DialogContent>*/}
                {children}
                {/*</DialogContent>*/}
            </Dialog>
        </>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export const BOTTOM_DIALOG_TIMEOUT = 275;

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 16,
        overflowY: 'hidden',
    },
}));
