/**
 * @prettier
 */
import type { RestaurantVm } from 'src/api/pidedirecto/getAppContextApi';
import { OrderType, OrderTypes } from 'src/constants/OrderType';
import { ServiceTypes } from 'src/constants/ServiceType';

export function getDefaultOrderType(restaurant: RestaurantVm, rememberedOrderType?: OrderType): OrderType | undefined {
    if (rememberedOrderType === OrderTypes.ROOM_SERVICE_ORDER && restaurant.roomServiceEnabled) {
        return OrderTypes.ROOM_SERVICE_ORDER;
    }

    if (rememberedOrderType === OrderTypes.DELIVERY_ORDER && restaurant.deliveryEnabled) {
        return OrderTypes.DELIVERY_ORDER;
    }
    if (rememberedOrderType === OrderTypes.TAKE_AWAY_ORDER && restaurant.takeAwayEnabled) {
        return OrderTypes.TAKE_AWAY_ORDER;
    }
    if (rememberedOrderType === OrderTypes.SELF_SERVICE_ORDER && restaurant.eatHereEnabled && restaurant.serviceType === ServiceTypes.SELF_SERVICE) {
        return OrderTypes.SELF_SERVICE_ORDER;
    }
    if (rememberedOrderType === OrderTypes.TABLE_ORDER && restaurant.eatHereEnabled && restaurant.serviceType === ServiceTypes.TABLE_SERVICE) {
        return OrderTypes.TABLE_ORDER;
    }

    if (rememberedOrderType === OrderTypes.SELF_SERVICE_ORDER && restaurant.eatHereEnabled && restaurant.serviceType === ServiceTypes.TABLE_SERVICE) {
        return OrderTypes.TABLE_ORDER;
    }
    if (rememberedOrderType === OrderTypes.TABLE_ORDER && restaurant.eatHereEnabled && restaurant.serviceType === ServiceTypes.SELF_SERVICE) {
        return OrderTypes.SELF_SERVICE_ORDER;
    }

    if (restaurant.roomServiceEnabled) {
        return OrderTypes.ROOM_SERVICE_ORDER;
    }
    if (restaurant.deliveryEnabled) {
        return OrderTypes.DELIVERY_ORDER;
    }
    if (restaurant.takeAwayEnabled) {
        return OrderTypes.TAKE_AWAY_ORDER;
    }
    if (restaurant.eatHereEnabled && restaurant.serviceType === ServiceTypes.SELF_SERVICE) {
        return OrderTypes.SELF_SERVICE_ORDER;
    }
    if (restaurant.eatHereEnabled && restaurant.serviceType === ServiceTypes.TABLE_SERVICE) {
        return OrderTypes.TABLE_ORDER;
    }
    return;
}
