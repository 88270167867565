/**
 * @prettier
 */
import type { SessionId } from 'src/types/Id';

export const KEY_NAME = 'sessionId';

export function getSessionIdInSessionStorage(): SessionId | undefined {
    return (window.sessionStorage.getItem(KEY_NAME) as SessionId) ?? undefined;
}
