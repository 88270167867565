/**
 * @prettier
 */
import * as React from 'react';

export function StoreClosedIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='21' height='16' viewBox='0 0 21 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M1.23147 0.159551C0.901435 -0.0966993 0.422246 -0.0373243 0.162024 0.287676C-0.0981982 0.612676 -0.0379029 1.08455 0.292135 1.3408L19.0789 15.8408C19.4089 16.0971 19.8881 16.0377 20.1483 15.7127C20.4086 15.3877 20.3483 14.9158 20.0182 14.6596L18.2792 13.3158V7.00018H16.2482V11.7502L12.1862 8.6158V7.00018H10.1552V7.04705L8.79695 6.00018H19.1424C19.7866 6.00018 20.3102 5.48455 20.3102 4.85018C20.3102 4.62205 20.2404 4.40018 20.1134 4.21268L17.7143 0.668926C17.4319 0.250176 16.959 0.000175656 16.4481 0.000175656H3.86225C3.3545 0.000175656 2.87849 0.250176 2.59605 0.668926L2.3517 1.02518L1.23147 0.159551ZM1.168 6.00018H3.86543L0.6666 3.5158L0.196931 4.20955C0.069994 4.40018 0.000178378 4.62205 0.000178378 4.84705C0.000178378 5.48455 0.523796 6.00018 1.168 6.00018ZM10.1552 12.0002H4.06218V7.00018H2.03118V12.0002V14.5002C2.03118 15.3283 2.71347 16.0002 3.55443 16.0002H10.6629C11.5039 16.0002 12.1862 15.3283 12.1862 14.5002V12.4533L10.1552 10.8783V12.0002Z'
                fill={color}
            />
        </svg>
    );
}
type Props = {
    color: string;
};
