/**
 * @prettier
 */
const primaryColor = '#1A9AA0';

export const AppTheme = {
    primaryColor: primaryColor,
    typography: {
        htmlFontSize: 10,
        fontFamily: 'Roboto, sans-serif',
        bold: 'PoppinsBold',
        semiBold: 'PoppinsSemiBold',
        regular: 'PoppinsRegular',
        medium: 'PoppinsMedium',
        light: 'PoppinsLight',
    },
    text: {
        highlight: primaryColor,
        colorDisabled: '#B7B7B7',
        colorFailure: '#f17781',
        colorSuccess: '#75C272',
        dark: '#525252',
        light: '#8a8a8a',
    },
    input: {
        colorDisabled: '#B7B7B7',
    },
    button: {
        fontSize: 20,
        fontSizeRightText: 14,
        color: '#ffffff',
        backgroundColor: primaryColor,
        backgroundColorDisabled: '#B0B0B0',
    },
    addRemoveButton: {
        color: primaryColor,
        backgroundColor: '#e3e5e8',
    },
    listRowButton: {
        fontSize: 15,
        color: '#000000',
        subtext: {
            fontSize: 12,
            color: '#909090',
        },
    },
    checkbox: {
        color: primaryColor,
        colorChecked: primaryColor,
        colorDisabled: '#E2E4E8',
    },
    radioButton: {
        color: primaryColor,
        colorChecked: primaryColor,
        colorDisabled: '#E2E4E8',
    },
    imagePlaceholder: {
        backgroundColor: '#eee',
    },
    backgroundColor: {
        menu: '#fafafa',
    },
} as const;
