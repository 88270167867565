/**
 * @prettier
 */
export const MomentFormats = Object.freeze({
    ISO_DATE: 'YYYY-MM-DD',
    ISO_TIME: 'HH:mm',
    ISO_DATE_TIME: 'YYYY-MM-DDTHH:mm',
    ISO_YEAR_MONTH: 'YYYY-MM',
});

export type MomentFormat = (typeof MomentFormats)[keyof typeof MomentFormats];
