/**
 * @prettier
 */
import { createContext, useState } from 'react';
import * as React from 'react';

export function CartProvider({ children }: Props): React.ReactElement {
    const [cartHasError, setCartHasError] = useState(true);

    const handleDisableOrderButton = (value: boolean) => {
        setCartHasError(value);
    };

    return <CartContext.Provider value={{ cartHasError, handleDisableOrderButton }}>{children}</CartContext.Provider>;
}

export const CartContext = createContext<Context>({
    cartHasError: true,
    handleDisableOrderButton: () => {},
});

type Props = {
    children: React.ReactNode;
};

type Context = {
    cartHasError: boolean;
    handleDisableOrderButton: (value: boolean) => any;
};
