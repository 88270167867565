/**
 * @prettier
 */
import { envENVIRONMENT } from 'src/env/envENVIRONMENT';
import { requireStringNotBlank } from 'src/utils/require/requireStringNotBlank';

export function envDOMAIN_NAMES(): Array<string> {
    try {
        const domainNames = requireStringNotBlank(process.env.REACT_APP_DOMAIN_NAMES).split(',');
        domainNames.forEach(requireStringNotBlank);
        return domainNames;
    } catch (e: any) {
        throw Error(`DOMAIN_NAMES is not configured correctly in .env.${envENVIRONMENT().toLowerCase()}`);
    }
}
