/**
 * @prettier
 */
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import * as React from 'react';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { AutoCompleteInputProp, Variant } from 'src/components/form/FormTypes';
import { getError } from 'src/components/form/utils/getError';
import { Input } from 'src/components/Input';
import { translate } from 'src/i18n/translate';

export function FormEmailField({
    name,
    label,
    placeholder,
    helperText,
    defaultValue = null,
    autoComplete = 'off',
    disabled,
    required,
    variant = 'outlined',
    autoFocus,
    InputProps,
    inputProps,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const inputRef = useRef<HTMLInputElement>();

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <Input
                    {...props}
                    name={name}
                    value={value}
                    onChange={onChange}
                    label={label}
                    placeholder={placeholder}
                    helperText={getError(errors, name)?.message ?? helperText}
                    disabled={isSubmitting || disabled}
                    required={(required as any)?.value ?? required}
                    error={!!getError(errors, name)}
                    autoFocus={autoFocus}
                    inputRef={inputRef}
                    autoComplete={autoComplete}
                    rightIcon={<AlternateEmailIcon color='action' />}
                />
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
                pattern: {
                    value: /(?:(^\S+@\S+\.\S+$)|(^\s*$))/,
                    message: translate('Invalid email'),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null;
    autoComplete?: AutoCompleteInputProp;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    autoFocus?: boolean;
    InputProps?: any;
    inputProps?: any;
};
