/**
 * @prettier
 */
import moment from 'moment-timezone';
import { OrderTypes } from 'src/constants/OrderType';
import { PickupTimeTypes } from 'src/constants/PickupTimeType';
import type { TimeZone } from 'src/constants/TimeZone';
import type { PickupTimesVm } from 'src/types/PickupTimesVm';
import type { AppVm } from 'src/types/StateVm';
import { generatePickupTimes } from 'src/utils/cart/generatePickupTimes';

export function refreshPickupTimes(app: AppVm): void {
    const drivingDuration = app.orderType === OrderTypes.DELIVERY_ORDER ? app.deliveryEstimate?.drivingDuration : undefined;
    const pickupTimes = generatePickupTimes(app.restaurant, drivingDuration);
    app.pickupTimes = pickupTimes;
    if (!app.pickupTimeType || !app.pickupTime || !pickupTimes || !app.restaurant) {
        app.pickupTimeType = undefined;
        app.pickupTime = undefined;
        return;
    }

    const timeZone = app.restaurant.timeZone;
    if (app.pickupTimeType === PickupTimeTypes.ASAP) {
        if (pickupTimes.asap) {
            const asapDateString = toDateString(pickupTimes.asap.day, pickupTimes.asap.time, timeZone);
            if (app.pickupTime === asapDateString) {
                return;
            }
            app.pickupTimeType = PickupTimeTypes.ASAP;
            app.pickupTime = asapDateString;
            return;
        }
        if (pickupTimes.planned.days[0] && pickupTimes.planned.times[0][0]) {
            app.pickupTimeType = PickupTimeTypes.PLANNED;
            app.pickupTime = toDateString(pickupTimes.planned.days[0], pickupTimes.planned.times[0][0], timeZone);
            return;
        }
        app.pickupTimeType = undefined;
        app.pickupTime = undefined;
        return;
    }
    if (app.pickupTimeType === PickupTimeTypes.PLANNED) {
        if (plannedPickupTimeExists(pickupTimes, app.pickupTime)) {
            return;
        }
        if (pickupTimes.asap) {
            app.pickupTimeType = PickupTimeTypes.ASAP;
            app.pickupTime = toDateString(pickupTimes.asap.day, pickupTimes.asap.time, timeZone);
            return;
        }
        if (pickupTimes.planned.days[0] && pickupTimes.planned.times[0][0]) {
            app.pickupTimeType = PickupTimeTypes.PLANNED;
            app.pickupTime = toDateString(pickupTimes.planned.days[0], pickupTimes.planned.times[0][0], timeZone);
            return;
        }
        app.pickupTimeType = undefined;
        app.pickupTime = undefined;
        return;
    }
    if (app.pickupTimeType === PickupTimeTypes.PLANNED_MENU_ITEMS) {
        return;
    }

    app.pickupTimeType = undefined;
    app.pickupTime = undefined;
}

function toDateString(day: string, time: string, timeZone: TimeZone): string {
    return moment.tz(`${day}T${time}`, timeZone).format();
}

function plannedPickupTimeExists(pickupTimes: PickupTimesVm, pickupTime: string) {
    const day = indexOfDay(pickupTimes, pickupTime);
    if (!pickupTimes.planned.times[day]) {
        return false;
    }
    return pickupTimes.planned.times[day].indexOf(pickupTime?.substring(11, 16)) !== -1;
}

function indexOfDay(pickupTimes: PickupTimesVm, pickupTime: string) {
    return pickupTimes.planned.days.indexOf(pickupTime?.substring(0, 10));
}
