/**
 * @prettier
 */
import * as React from 'react';
import { BottomDialogDesktop } from 'src/components/bottomDialog/BottomDialogDesktop';

export function BottomDialog(props: BottomDialogProps): React.ReactElement {
    return <BottomDialogDesktop {...props} />;
}

export type BottomDialogProps = {
    open: boolean;
    onClickBackground?: any;
    onChangeHeight?: (arg1: number) => void;
    onClickHardwareBack?: () => void;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    children?: React.ReactNode;
};
