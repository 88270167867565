/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { PidedirectoBigIcon } from 'src/icons/PidedirectoBigIcon';

export function UruguayanTermsAndConditions(): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <header>
                <a href='https://pidedirecto.mx/' target='_blank'>
                    <div className={classes.image}>
                        <PidedirectoBigIcon />
                    </div>
                </a>
            </header>
            <h1 className={classes.title}>{translate('Terms and Conditions of Use of the “PideDirecto” Platform')}</h1>
            <h2 className={classes.subtitle}>1) Términos y condiciones generales y su aceptación</h2>
            <p className={classes.text}>
                A continuación, se describen los Términos y Condiciones (en adelante los "T&C") aplicables a la utilización de los servicios suministrados por PideDirecto (Axamer SAS) (en adelante,
                "PideDirecto") que PideDirecto, pone a disposición de los servicios gastronómicos o restaurantes para su actividad. Para utilizar las plataformas generadas a través de PideDirecto,
                Usted deberá aceptar los presentes T&C y la Política de Privacidad. Su registro y utilización, implica la aceptación expresa, plena y sin reservas de los T&C, en su versión vigente al
                momento de utilizar los servicios y posteriores actualizaciones. En caso de modificación de alguna de las condiciones establecidas, se notificará de inmediato al usuario sobre las
                nuevas condiciones de prestación del servicio, las que en ningún caso se aplicarán retroactivamente.
            </p>
            <h2 className={classes.subtitle}>2) Condiciones de utilización del servicio de usuario PideDirecto</h2>
            <h2 className={classes.subtitle}>2.1 Generales</h2>
            <p className={classes.text}>
                El Usuario reconoce y acepta que el contenido y la naturaleza de los Servicios que proporciona PideDirecto pueden variar ocasionalmente sin previo aviso. Asimismo, podrán suspenderse,
                temporal o permanente, los servicios prestados por PideDirecto, o alguna de las funciones, de modo general o bien para un usuario individual, a discreción de PideDirecto y sin previo
                aviso. El Usuario siempre podrá interrumpir el uso del servicio en cualquier momento en que lo desee. A fin de poder utilizar las funcionalidades de pedido remoto de alimentos por
                intermedio de los servicios proporcionados por PideDirecto, se solicita al Usuario información personal, al momento del Registro y bajo los parámetros indicados en la Política de
                Privacidad.
            </p>
            <h2 className={classes.subtitle}>2.2 Obligaciones del Usuario</h2>
            <p className={classes.text}>
                El Usuario se compromete a utilizar los servicios a título personal y únicamente a los efectos para los cuales fueron creados, de conformidad con estos T&C, y con fines que no
                contradigan la ley ni resulten lesivos de los derechos e intereses de terceros, o que de cualquier forma pudieran dañar, inutilizar, sobrecargar o deteriorar nuestros servicios o
                impedir su normal uso por parte de otros Usuarios.
            </p>
            <ol>
                Está prohibido:
                <li>Utilizar los servicios, directa o indirectamente, para violar cualquier normativa.</li>
                <li>
                    Enviar o transmitir información cuyo contenido sea, directa o indirectamente, abusivo, difamatorio y/o fraudulento, o que revele asuntos privados o personales que afecten a persona
                    alguna, o que de alguna manera violen derechos de terceros.
                </li>
                <li>Utilizar los servicios con un nombre falso, erróneo o inexistente.</li>
                <li>Intentar violar, degradar o interrumpir los sistemas de autenticación, seguridad, redes o cuentas de usuarios.</li>
            </ol>
            <h2 className={classes.subtitle}>2.3 Utilización bajo exclusiva responsabilidad</h2>
            <ol>
                <li>El Usuario acepta voluntariamente que el uso bajo su única y exclusiva responsabilidad.</li>
                <li>El Usuario reconoce y acepta que es el único responsable de mantener la confidencialidad de sus contraseñas.</li>
                <li>En consecuencia, acepta que será el único responsable ante PideDirecto de las actividades que se desarrollen mediante el acceso a los servicios con sus contraseñas.</li>
                <li>Asimismo, es obligación notificar a PideDirecto de cualquier vulneración de contraseña o suplantación de identidad.</li>
            </ol>
            <h2 className={classes.subtitle}>2.4. Derecho de revocación</h2>
            <p className={classes.text}>
                PideDirecto podrá, en cualquier momento y sin necesidad de darle notificación previa, suspender o cancelar el uso de las funcionalidades por violación de los T&C o de la Política de
                Privacidad.
            </p>
            <h2 className={classes.subtitle}>3) Límite de Responsabilidad y Exclusión de Garantías</h2>
            <p className={classes.text}>
                El Usuario acepta y reconoce que puede no siempre estar disponible debido a dificultades técnicas o fallas de comunicación en sentido amplio, de redes de telefonía móvil o fija,
                Internet, o por cualquier otro motivo ajeno a PideDirecto. En consecuencia, no se garantiza la disponibilidad y continuidad del funcionamiento.
            </p>
            <p className={classes.text}>
                Se excluye cualquier responsabilidad por los daños y perjuicios de toda naturaleza que sean originados en forma directa, indirecta o remota, por la interrupción, suspensión,
                finalización, falta de disponibilidad o de continuidad del funcionamiento de nuestros servicios.
            </p>
            <p className={classes.text}>
                No se asume responsabilidad de ninguna alguna, si los dispositivos de acceso del usuario se viese afectado por algún software malicioso, virus, o por la presencia de otros elementos en
                los contenidos que puedan producir alteraciones en el sistema informático o documentos electrónicos del Usuario, siendo responsabilidad del Usuario contar con los sistemas antivirus
                adecuados para proteger su equipo.
            </p>
            <h2 className={classes.subtitle}>4) Propiedad Intelectual</h2>
            <p className={classes.text}>
                La utilización de los servicios brindados por PideDirecto no podrá, en ningún supuesto, ser interpretada como una autorización y/o concesión de licencia de los derechos intelectuales
                de PideDirecto y/o de un tercero.
            </p>
            <h2 className={classes.subtitle}>5) Política de Privacidad</h2>
            <p className={classes.text}>El uso de las funciones está sujeto a que el Usuario haya leído y aceptado la Política de Privacidad que complementa estos T&C.</p>
            <h2 className={classes.subtitle}>6) Comunicación</h2>
            <p className={classes.text}>
                Toda notificación u otra comunicación que deba efectuarse bajo el presente, deberá realizarse: (i) al Usuario: al celular indicado vía Whatsapp o a la cuenta de correo electrónico
                declarada; (ii) a PideDirecto: a la cuenta de correo electrónico hola@soyPideDirecto.com o a Cebollatí 1744, Montevideo.
            </p>
            <h2 className={classes.subtitle}>7) Independencia de las cláusulas</h2>
            <p className={classes.text}>
                Si alguna de las disposiciones, en todo o en parte, de estas Condiciones Generales se considera no exigible por algún motivo, incluyendo, entre otros, la legalidad y la invalidez, por
                un juzgado o tribunal competente, dicha situación no afectará la aplicabilidad o cualquier otra parte o disposición de estas Condiciones Generales y tales disposiciones deberán
                reformarse, limitarse, eliminarse en la medida mínima necesaria o interpretarse de manera que cumplan los objetivos de la disposición original en la mayor medida permitida por la ley.
            </p>
            <h2 className={classes.subtitle}>8) Jurisdicción y Derecho Aplicable</h2>
            <p className={classes.text}>Los T&C se regirán por las leyes de la República Oriental del Uruguay.</p>
            <p className={classes.text}>
                Las partes acuerdan que cualquier disputa o reclamo que surja de o con relación a, o en conexión con la ejecución o cumplimiento de estas Condiciones Generales, incluyendo sin
                limitación, cualquier disputa sobre la validez, interpretación, exigibilidad o incumplimiento de las mismas, y/o cualquier disputa sobre las operaciones que realicen los Usuarios, será
                exclusiva y finalmente resuelta por la juzgados competentes de la ciudad de Montevideo, de la República Oriental del Uruguay, renunciando expresamente a cualquier otro fuero o
                jurisdicción.
            </p>
            <p className={classes.text}>Estos T&C fueron actualizadas por última vez el 15 de agosto de 2023.</p>
            <h1 className={classes.title}>POLÍTICAS DE PRIVACIDAD – PIDE DIRECTO</h1>
            <p className={classes.text}>
                Como usuario de PideDirecto (Axamer SAS), con domicilio en Cebollatí 1744, usted brinda cierta información personal a nosotros, según concepto definido en la Ley 18.331.
            </p>
            <p className={classes.text}>Por parte de PideDirecto, es imprescindible informar acerca de cómo serán tratados sus datos y los derechos con los que cuenta bajo la normativa vigente.</p>
            <p className={classes.text}>
                Es importante tener presente que nuestra política aplica con respecto a la información que es tratada por PideDirecto y que permanece en la base de datos de usuarios de PideDirecto. En
                este sentido, los restaurantes o locales gastronómicos también podrán contar información proporcionada por Ustedes a efectos de facturación, envío de pedidos, entre otros. El
                tratamiento adecuado de la información por parte los locales gastronómicos o restaurantes no se encuentra englobado por esta Política.
            </p>
            <p className={classes.text}>
                Mediante el consentimiento a la siguiente Política, Usted como usuario PideDirecto autoriza a Axamer SAS (PideDirecto) a la recopilación, procesamiento, uso y tratamiento de datos
                personales, de conformidad con la normativa vigente en Uruguay en materia de protección de datos personales, tomando como base la Ley 18.331 y demás normas complementarias,
                modificativas y concordantes.
            </p>
            <p className={classes.text}>
                Es importante, que cada Usuario se compromete a brindar la información adecuada, no siendo responsabilidad de PideDirecto si la información proporcionada es falsa, inexacta o no
                auténtica.
            </p>
            <p className={classes.text}>
                Ante cualquier consulta acerca de nuestra Política o el ejercicio de alguno de los derechos reconocidos en la Ley 18.331, por favor dirigirse a hola@soyPideDirecto.com , indicando el
                asunto: MIS DATOS.
            </p>
            <h2 className={classes.subtitle}>CUMPLIMIENTO DE PRINCIPIOS</h2>
            <p className={classes.text}>La Ley 18.331, en sus artículos 5 a 12 dispone ciertos principios básicos en materia de protección de datos personales.</p>
            <p className={classes.text}>
                Así, se detallan los principios de legalidad, veracidad, finalidad, previo consentimiento informado, seguridad de los datos, reserva y responsabilidad. Asimismo, por Decreto 64/020, se
                incorporan los principios de responsabilidad proactiva, privacidad por diseño y privacidad por defecto.
            </p>
            <p className={classes.text}>Desde PideDirecto se cumpliría en lo pertinente los principios enunciados por la normativa y siempre que fueren aplicables.</p>

            <h2 className={classes.subtitle}>RECOPILACIÓN Y TRATAMIENTO DE LA INFORMACIÓN</h2>
            <p className={classes.text}>El responsable de la base de datos es PideDirecto, con domicilio en Cebollatí 1744, Montevideo, Uruguay.</p>
            <p className={classes.text}>Tanto de la información proporcionada como de la que pueda ser proporcionada a futuro por Usted.</p>
            <p className={classes.text}>
                En el caso de PideDirecto, se recopilarán, entre otros, los siguientes datos: Nombre completo, género, celular, fecha de nacimiento, dirección y correo electrónico.
            </p>
            <p className={classes.text}>Todos los datos indicados serán únicamente utilizados a efectos de que Usted pueda utilizar los servicios de nuestro sistema.</p>
            <p className={classes.text}>
                En detalle, la recopilación y tratamiento de sus datos, será a efectos de que Usted pueda como Usuario PideDirecto, pueda realizar pedidos a través de las distintas tiendas
                personalizadas de los locales gastronómicos que son Partners de PideDirecto.
            </p>
            <p className={classes.text}>
                El proporcionar sus datos, será a efectos de dicho registro y contar con un usuario que permita la utilización del sistema. Otros datos que sean recabados y tratados/procesados durante
                la interacción del usuario con alguna de las Tiendas personalizadas de los Partners de PideDirecto, no serán responsabilidad de PideDirecto, sino de cada una de las tiendas
                personalizadas que será responsable por el tratamiento de dichos datos a efectos de funcionalidades propias de dicha Tienda.
            </p>
            <p className={classes.text}>
                Como parte del tratamiento de la información, podremos enviarte a tu celular o correo electrónico, publicidad y elementos de marketing de nuestros servicios y de las tiendas
                personalizadas de Partners de PideDirecto. Es importante destacar, que como parte del derecho de supresión de datos, tendrás la opción de eliminar la suscripción a la recepción de
                material publicitario.
            </p>
            <p className={classes.text}>
                Es importante resaltar, que tal como prevé la normativa vigente, PideDirecto podrá proporcionar tus datos personales en caso de que así lo soliciten autoridades administrativas o
                judiciales, y en general cualquier autoridad de gobierno.
            </p>

            <h2 className={classes.subtitle}>ACTUALIZACIÓN DE INFORMACIÓN</h2>
            <p className={classes.text}>
                Es importante destacar que Usted en calidad de usuario es el responsable de modificar la información que corresponda. Para ello Usted deberá ingresar a su perfil de usuario a través de
                cualquiera de las tiendas de los Partners de PideDirecto.
            </p>

            <h2 className={classes.subtitle}>DERECHOS DEL USUARIO PideDirecto</h2>
            <p className={classes.text}>
                Según la normativa de datos personales vigente en Uruguay, existen ciertos derechos del titular de los datos personales, que deben respetarse y garantizarse a los titulares de los
                datos. En este sentido, para PideDirecto resulta vital que Usted conozca que cuenta con los siguientes derechos:
            </p>
            <ol>
                <li>Acceso: Obtener la información la información sobre si que se encuentre en nuestra base de datos.</li>
                <li>Rectificación: Posibilidad de rectificar la información que tenemos sobre si en la base de datos.</li>
                <li>Actualización: Posibilidad de actualizar la información que tenemos sobre si en la base de datos.</li>
                <li>Inclusión: Posibilidad de incorporar información que corresponda según lo solicitado por PideDirecto para ser usuario.</li>
                <li>
                    Supresión: Potestad de solicitar la supresión de información de la base de datos. Sin perjuicio, de que la supresión de datos necesarios para ser Usuario PideDirecto, puede
                    implicar la imposibilidad de seguir utilizando nuestros servicios.
                </li>
            </ol>

            <h2 className={classes.subtitle}>SEGURIDAD DE LOS DATOS</h2>
            <p className={classes.text}>
                Los datos por Usted proporcionados serán tratados según las pautas de seguridad dispuestas por la normativa vigente y únicamente para los fines indicados en la presente Política.
            </p>
            <p className={classes.text}>Una vez que los fines para los cuales Usted los proporcionó cesaren, los datos serán eliminados de nuestra base de datos.</p>
            <p className={classes.text}>
                Usted también reconoce y aceptar saber que el proporcionar información a través de internet implica riesgos asociados a su propio uso. Riesgos que si bien PideDirecto hará todo lo
                posible por disminuir en lo que le es pertinente, no será responsable de garantizar la seguridad total de los datos proporcionados a través de internet. Por tanto, por mayores que
                serán los esfuerzos de PideDirecto en proteger sus datos, es importante que sepa que es imposible asegurar la completa protección de los mismos.
            </p>

            <h2 className={classes.subtitle}>ACTUALIZACIÓN DE POLÍTICAS</h2>
            <p className={classes.text}>Es posible que PideDirecto realice modificaciones a la presente Política. En tal caso, le será oportunamente notificado vía correo electrónico.</p>

            <h2 className={classes.subtitle}>JURISDICCIÓN Y LEY APLICABLE</h2>
            <p className={classes.text}>
                La presente Política se rige por las leyes de la República Oriental del Uruguay. Cualquier controversia que resultare de la existencia, validez, interpretación o cumplimiento de esta
                Política será suscitada ante los tribunales ordinarios con jurisdicción en Uruguay, renunciando a cualquier otro fuero o jurisdicción.
            </p>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 40,
    },
    title: {
        textAlign: 'center',
        color: theme.palette.primary.main,
        fontSize: 25,
    },
    text: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
    },
    subtitle: {
        fontSize: 18,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 'bold',
    },
    link: {
        color: 'black',
        fontWeight: 'bold',
        textDecoration: 'none',
    },
    image: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
}));
