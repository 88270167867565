/**
 * @prettier
 */
import { GetAppContextApiResponse, type GetAppContextApiRequest } from 'src/api/pidedirecto/getAppContextApi';
import type { AddressVm } from 'src/api/pidedirecto/types/AddressVm';
import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CardVm } from 'src/types/CardVm';
import type { GiftVm } from 'src/types/GiftVm';
import type { CustomerId } from 'src/types/Id';

export async function getAppContextSignedInApi(request: GetAppContextSignedInApiRequest): ApiSauceResponse<GetAppContextSignedInApiResponse> {
    return pidedirectoApiMethod('getAppContextSignedInApi', request);
}

export type GetAppContextSignedInApiRequest = GetAppContextApiRequest;

export type GetAppContextSignedInApiResponse = {
    customerId: CustomerId;
    mobileNumber?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    signedUpAt?: Date;
    firstOrderAt?: Date;
    lastOrderAt?: Date;
    addresses?: Array<AddressVm>;
    cards?: Array<CardVm>;
    credits: string;
    numberOfOrders: number;
    hasPassword: boolean;
    gifts: Array<GiftVm>;
    // orders: Array<OrderVm>,
    // surveys: Array<SurveyVm>,
} & GetAppContextApiResponse;
