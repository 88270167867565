/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import { actions } from 'src/reducers';
import type { CartItemVm } from 'src/types/CartItemVm';
import { createCartItemKey } from 'src/utils/cart/createCartItemKey';
import { deepClone } from 'src/utils/object/deepClone';
import { isItemSoldByWeight } from 'src/utils/order/isItemSoldByWeight';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function useRemoveItem(): { removeItem: RemoveItemFunction } {
    const cartItems = useSelector((state) => state.app.cartItems);
    const cartItemsByMenuItem = useSelector((state) => state.app.cartItemsByMenuItem);

    const setCartItems = useAction(actions.setCartItems);

    let removedQuantity = 0;

    const removeItem = (item: CartItemVm): void => {
        let currentCartItems = deepClone(cartItems);
        let currentCartItemsByMenuItem = deepClone(cartItemsByMenuItem);

        const cartItem = { ...item, key: createCartItemKey(item) } as const;
        const existingCartItem = currentCartItems.find((current) => current.key === cartItem.key);
        if (!existingCartItem) {
            return;
        }

        if (existingCartItem.quantity === 1 || isItemSoldByWeight(cartItem)) {
            currentCartItems = currentCartItems?.filter((item: CartItemVm) => item.key !== existingCartItem?.key);
            removedQuantity = cartItem.quantity;
        }

        if (existingCartItem.quantity > 1 && !isItemSoldByWeight(existingCartItem)) {
            existingCartItem.quantity = BigNumber(existingCartItem.quantity).minus(1).toNumber();
            removedQuantity = 1;
        }

        const cartItemsForMenuItemId = requireValue(currentCartItemsByMenuItem[cartItem.menuItemId]);
        const reversedExistingCartItemByMenuItemIndex = [...cartItemsForMenuItemId].reverse().findIndex((current) => current.key === cartItem.key);
        const existingCartItemByMenuItemIndex = cartItemsForMenuItemId.length - 1 - reversedExistingCartItemByMenuItemIndex;
        const existingCartItemByMenuItem = cartItemsForMenuItemId[existingCartItemByMenuItemIndex];
        const newQuantityByMenuItem = existingCartItemByMenuItem.quantity - removedQuantity ?? 1;
        if (newQuantityByMenuItem > 0) {
            existingCartItemByMenuItem.quantity = newQuantityByMenuItem;
        } else {
            cartItemsForMenuItemId.splice(existingCartItemByMenuItemIndex, 1);
            if (cartItemsForMenuItemId.length === 0) {
                delete currentCartItemsByMenuItem[cartItem.menuItemId];
            }
        }

        setCartItems({ cartItems: currentCartItems, cartItemsByMenuItem: currentCartItemsByMenuItem });
    };

    return { removeItem };
}

type RemoveItemFunction = (item: CartItemVm) => void;
