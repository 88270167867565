/**
 * @prettier
 */
import { envDOMAIN_NAMES } from 'src/env/envDOMAIN_NAMES';

export function getUrlSubdomain(): string | undefined {
    const hostname = window.location.hostname;
    if (!hostname) return;
    const subdomain = hostname.match(new RegExp(`^(\\w+)\\.(${envDOMAIN_NAMES().join('|')})$`))?.[1];
    return subdomain;
}
