/**
 * @prettier
 */
import * as React from 'react';
import { useEffect } from 'react';
import { createUserOpenedPidedirectoWebLogEvent } from 'src/services/logEvent/createUserOpenedPidedirectoWebLogEvent';
import { useCurrentLocation } from 'src/utils/react/useCurrentLocation';

export function UserOpenedWebLogEvent(): React.ReactElement | null {
    const { location } = useCurrentLocation();

    useEffect(() => {
        createUserOpenedPidedirectoWebLogEvent({
            url: window.location.href,
            customerOrigin: document.referrer || undefined,
            location,
        });
    }, []);

    return null;
}
