/**
 * @prettier
 */
import { configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { combineReducers } from 'redux';
import immutableStateInvariantMiddleware from 'redux-immutable-state-invariant';
import 'regenerator-runtime/runtime';
import { Store } from '@reduxjs/toolkit';
import { history } from 'src/config/history';
import { slice } from 'src/reducers';
import { StateVm } from 'src/types/StateVm';

let routeMiddleware = routerMiddleware(history);

const middleware = process.env.NODE_ENV === 'development' ? [immutableStateInvariantMiddleware(), routeMiddleware] : [routeMiddleware];

export const store = configureStore({
    reducer: combineReducers({
        router: connectRouter(history),
        app: slice.reducer,
    }),
    middleware,
    devTools: false,
    enhancers: [],
}) as Store<StateVm>;
