/**
 * @prettier
 */
import { useParams } from 'react-router-dom';
import { useFetchOrder } from 'src/services/order/useFetchOrder';
import { useGetAppContext } from 'src/services/useGetAppContext';
import { useKeepPaymentStateUpdated } from 'src/services/useKeepPaymentStateUpdated';
import { OrderId } from 'src/types/Id';
import { isOrderPage } from 'src/utils/page/isOrderPage';
import { useSelector } from 'src/utils/react/useSelector';

export function useInitializeApp({ urlSubdomain }: Props): any {
    const { urlPathname, orderId } = useParams<{
        urlPathname: string | undefined;
        orderId: OrderId | undefined;
    }>();

    const page = useSelector((state) => state.app.page);

    const { getAppContext } = useGetAppContext();

    const keepPaymentStateUpdated = useKeepPaymentStateUpdated();
    const fetchOrder = useFetchOrder();

    const initializeApp = () => {
        if (!page) return;

        if (orderId && isOrderPage(page)) {
            fetchOrder(orderId);
            return;
        }

        keepPaymentStateUpdated();
        getAppContext({ urlSubdomain, urlPathname });
    };

    return initializeApp;
}

type Props = {
    urlSubdomain: string | undefined;
};
