/**
 * @prettier
 */
import { actions } from 'src/reducers';
import { useAction } from 'src/utils/react/useAction';
import { millisToNextMinute } from 'src/utils/time/millisToNextMinute';
import { wait } from 'src/utils/wait';

let isRunning = false;
export function useKeepPaymentStateUpdated(): any {
    const refreshPaymentState = useAction(actions.refreshPaymentState);

    const handleKeepPaymentStateUpdated = () => {
        if (isRunning) return;
        isRunning = true;
        keepPaymentStateUpdated();
    };

    const keepPaymentStateUpdated = async () => {
        refreshPaymentState();
        await wait(millisToNextMinute());
        keepPaymentStateUpdated();
    };

    return handleKeepPaymentStateUpdated;
}
