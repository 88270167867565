/**
 * @prettier
 */
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { App } from 'src/App';
import { ConfirmDialogProvider } from 'src/components/cart/ConfirmDialog';
import { APP_VERSION } from 'src/config/appVersion';
import { APP_VERSION_NAME } from 'src/config/appVersionName';
import { UruguayanTermsAndConditions } from 'src/scenes/UruguayanTermsAndConditions';
import 'src/config/configureMoment';
import { Notifications } from '@pidedirecto/ui';
import { useEffect } from 'react';
import { history } from 'src/config/history';
import { store } from 'src/config/store';
import { envBUILD_TIME } from 'src/env/envBUILD_TIME';
import { Privacy } from 'src/scenes/Privacy';
import { TermsAndConditions } from 'src/scenes/TermsAndConditions';
import { useRestaurantTheme } from 'src/services/restaurant/useRestaurantTheme';
import { isProductionEnvironment } from 'src/utils/environment/isProductionEnvironment';
import { setTitle } from 'src/utils/html/setTitle';
import { getUrlDomainSuffix } from 'src/utils/url/getUrlDomainSuffix';
import { getUrlSubdomain } from 'src/utils/url/getUrlSubdomain';

export function AppRoot(): React.ReactElement {
    const classes = useStyles();
    const urlSubdomain = getUrlSubdomain();

    const restaurantTheme = useRestaurantTheme();

    useEffect(() => {
        if (!urlSubdomain) {
            if (window.location.hostname === 'localhost') {
                // Open default restaurant subdomain test on dev server start
                window.location.assign('http://test.localhost:3005');
                return;
            }
            window.location.assign(`https://tiendas.pidedirecto.${getUrlDomainSuffix()}`);
        }
        if (urlSubdomain) setTitle(urlSubdomain);
    }, []);

    return (
        <Provider store={store}>
            <ThemeProvider theme={restaurantTheme}>
                <Notifications />
                <ConfirmDialogProvider>
                    <CssBaseline />
                    <ConnectedRouter history={history}>
                        <Switch>
                            <Route path={'/privacy'} exact>
                                <Privacy />
                            </Route>
                            <Route path={'/terms-and-conditions'} exact>
                                <TermsAndConditions />
                            </Route>
                            <Route path={'/terms-and-conditions-uy'} exact>
                                <UruguayanTermsAndConditions />
                            </Route>
                            <Route path={['/:urlPathname/pay/:paymentLinkId', '/:urlPathname/:orderId', '/:urlPathname', '/']}>
                                <App urlSubdomain={urlSubdomain} />
                            </Route>
                        </Switch>
                    </ConnectedRouter>
                    <div className={classes.version}>
                        {`${APP_VERSION}${APP_VERSION_NAME ? `-${APP_VERSION_NAME}` : ''}`}
                        {!isProductionEnvironment() && !!envBUILD_TIME() && (
                            <>
                                <br />
                                {envBUILD_TIME()}
                            </>
                        )}
                    </div>
                </ConfirmDialogProvider>
            </ThemeProvider>
        </Provider>
    );
}

const useStyles = makeStyles((theme) => ({
    version: {
        fontSize: isProductionEnvironment() ? '8px' : '12px',
        position: 'fixed',
        bottom: 0,
        right: 8,
        textAlign: 'right',
        color: isProductionEnvironment() ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0)',
    },
}));
