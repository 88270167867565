/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { OrderItem } from 'src/components/OrderItem';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { AppTheme } from 'src/styles/AppTheme';
import type { PaymentLinkVm } from 'src/types/PaymentLinkVm';
import { createOrderItemKey } from 'src/utils/order/createOrderItemKey';
import { toShortId } from 'src/utils/uuid/toShortId';

export function PaymentLinkPreview({ paymentLink }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    return (
        <div className={classes.container}>
            <div className={classes.totalContainer}>
                <Text>{translate('Total')}</Text>
                <Text className={classes.total}>{formatAsCurrencyNumber(paymentLink.amount.toString())}</Text>
            </div>
            {!!paymentLink.orderId && paymentLink.orderItems && (
                <div className={classes.orderItemsContainer}>
                    <Text size='large' variant='semiBold' style={{ color: '#0D3037' }}>
                        {translate('Order details #@shortOrderId', { shortOrderId: toShortId(paymentLink.orderId) })}
                    </Text>
                    <section className={classes.orderItems}>{paymentLink.orderItems?.map((orderItem) => <OrderItem key={createOrderItemKey(orderItem)} orderItem={orderItem} />)}</section>
                </div>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    totalContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        gap: 5,
        marginBottom: 30,
    },
    total: {
        fontFamily: AppTheme.typography.bold,
        fontSize: 28,
        color: '#0D3037',
    },
    orderItemsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        maxHeight: '40vh',
        overflowY: 'scroll',
    },
    orderItems: {},
}));

type Props = {
    paymentLink: PaymentLinkVm;
};
