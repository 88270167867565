/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import * as React from 'react';
import type { OrderItemVm } from 'src/api/pidedirecto/types/OrderVm';
import { OrderItem } from 'src/components/OrderItem';
import { Text } from 'src/components/Text';
import { TimeZone, TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import type { CurrencyFormatVm } from 'src/types/CurrencyFormatVm';
import { createOrderItemKey } from 'src/utils/order/createOrderItemKey';

export function OrderItemsDelivery({ orderItems, currencyFormat, timeZone }: Props): React.ReactElement {
    const classes = useStyles();
    const orderItemsMonday = orderItems.filter((cartItem) => moment.tz(cartItem.pickupTime, timeZone ?? TimeZones.AMERICA_MONTERREY).isoWeekday() === 1);
    const orderItemsTuesday = orderItems.filter((cartItem) => moment.tz(cartItem.pickupTime, timeZone ?? TimeZones.AMERICA_MONTERREY).isoWeekday() === 2);
    const orderItemsWednesday = orderItems.filter((cartItem) => moment.tz(cartItem.pickupTime, timeZone ?? TimeZones.AMERICA_MONTERREY).isoWeekday() === 3);
    const orderItemsThursday = orderItems.filter((cartItem) => moment.tz(cartItem.pickupTime, timeZone ?? TimeZones.AMERICA_MONTERREY).isoWeekday() === 4);
    const orderItemsFriday = orderItems.filter((cartItem) => moment.tz(cartItem.pickupTime, timeZone ?? TimeZones.AMERICA_MONTERREY).isoWeekday() === 5);

    return (
        <div className={classes.container}>
            {!!orderItemsMonday?.length && (
                <>
                    <div className={classes.container}>
                        <Text className={classes.day}>{translate('Monday')}</Text>
                        {orderItemsMonday.map((orderItem) => (
                            <OrderItem key={createOrderItemKey(orderItem)} orderItem={orderItem} />
                        ))}
                    </div>
                </>
            )}
            {!!orderItemsTuesday?.length && (
                <>
                    <div className={classes.container}>
                        <Text className={classes.day}>{translate('Tuesday')}</Text>
                        {orderItemsTuesday.map((orderItem) => (
                            <OrderItem key={createOrderItemKey(orderItem)} orderItem={orderItem} />
                        ))}
                    </div>
                </>
            )}
            {!!orderItemsWednesday?.length && (
                <>
                    <div className={classes.container}>
                        <Text className={classes.day}>{translate('Wednesday')}</Text>
                        {orderItemsWednesday.map((orderItem) => (
                            <OrderItem key={createOrderItemKey(orderItem)} orderItem={orderItem} />
                        ))}
                    </div>
                </>
            )}
            {!!orderItemsThursday?.length && (
                <>
                    <div className={classes.container}>
                        <Text className={classes.day}>{translate('Thursday')}</Text>
                        {orderItemsThursday.map((orderItem) => (
                            <OrderItem key={createOrderItemKey(orderItem)} orderItem={orderItem} />
                        ))}
                    </div>
                </>
            )}
            {!!orderItemsFriday?.length && (
                <>
                    <div className={classes.container}>
                        <Text className={classes.day}>{translate('Friday')}</Text>
                        {orderItemsFriday.map((orderItem) => (
                            <OrderItem key={createOrderItemKey(orderItem)} orderItem={orderItem} />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
        paddingBottom: 8,
    },
    day: {
        color: theme.palette.primary.main,
    },
}));

type Props = {
    orderItems: Array<OrderItemVm>;
    currencyFormat: CurrencyFormatVm;
    timeZone: TimeZone;
};
