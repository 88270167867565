/**
 * @prettier
 */
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { MinusIcon } from 'src/icons/MinusIcon';
import { PlusIcon } from 'src/icons/PlusIcon';
import { TrashcanIcon } from 'src/icons/TrashcanIcon';
import { actions } from 'src/reducers';
import { createUserRemovedItemFromCartLogEvent } from 'src/services/logEvent/createUserRemovedItemFromCartLogEvent';
import { useAddItem } from 'src/services/order/useAddItem';
import { useRemoveItem } from 'src/services/order/useRemoveItem';
import type { CartItemVm } from 'src/types/CartItemVm';
import { checkCategoryLimit } from 'src/utils/cart/checkCategoryLimit';
import { checkMenuLimit } from 'src/utils/cart/checkMenuLimit';
import { checkProductLimit } from 'src/utils/cart/checkProductLimit';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CartQuantitySpinner({ cartItem, quantity }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const { addItem } = useAddItem();
    const { removeItem } = useRemoveItem();

    const isCheckoutDialogOpen = useSelector((state) => state.app.checkoutDialog.open);
    const limitProductsInOrder = useSelector((state) => state.app.restaurant?.limitProductsInOrder);
    const cartItems = useSelector((state) => state.app.cartItems);
    const orderType = useSelector((state) => state.app.orderType);
    const isCartLimited = useSelector((state) => state.app.isCartLimited);
    const categories = useSelector((state) => state.app.restaurantMenu?.menus[0]?.categories);
    const orderItemsMaximumQuantityMenu = useSelector((state) => state.app.restaurantMenu?.menus[0]?.orderItemsMaximumQuantity);

    const openWarningAlert = useAction(actions.openWarningAlert);
    const setIsCartLimited = useAction(actions.setIsCartLimited);
    const isDeliveryLimited = limitProductsInOrder && isDeliveryOrder(orderType);

    const handleCartLimitExceeded = () => {
        openWarningAlert({
            title: translate('Maximum of delivery reached'),
            message: translate(
                'To guarantee the quality of your shipment and that the product arrives without problem, we limit the maximum that the delivery person can carry. If you want you can continue creating another order when finished',
            ),
        });
        return;
    };

    const handleAddCartItem = () => {
        if (isDeliveryLimited) {
            if (isCartLimited) {
                handleCartLimitExceeded();
                return;
            } else {
                const isCartItemLimitExceeded = cartItem.orderItemsMaximumQuantity ? checkProductLimit(1, cartItems, cartItem) : false;
                const isCategoryLimitExceeded = cartItem.orderItemsMaximumQuantity ? checkCategoryLimit(1, cartItems, cartItem, categories) : false;
                const isMenuLimitExceeded = orderItemsMaximumQuantityMenu ? checkMenuLimit(1, cartItems, orderItemsMaximumQuantityMenu) : false;
                if (isCartItemLimitExceeded || isCategoryLimitExceeded || isMenuLimitExceeded) {
                    handleCartLimitExceeded();
                    setIsCartLimited(true);
                    return;
                }
            }
        }
        addItem({ ...cartItem, quantity: 1 });
    };

    const handleRemoveCartItem = () => {
        if (isCartLimited) {
            setIsCartLimited(false);
        }
        removeItem({ ...cartItem, quantity: 1 });
        createUserRemovedItemFromCartLogEvent({
            cartItem,
            quantity,
        });
    };

    const handleRemoveItem = () => {
        removeItem({ ...cartItem, quantity: 1 });
        createUserRemovedItemFromCartLogEvent({
            cartItem,
            quantity,
        });
    };

    return (
        <div className={classes.container}>
            {quantity === 1 && (
                <Button text classes={{ button: classes.trashcan }} onClick={handleRemoveItem}>
                    <TrashcanIcon color={!smallScreen || isCheckoutDialogOpen ? theme.palette.primary.main : 'white'} />
                </Button>
            )}
            {quantity !== 1 && (
                <Button
                    classes={{ button: classes.button }}
                    onClick={() => {
                        handleRemoveCartItem();
                    }}
                >
                    <MinusIcon width={12} height={12} color={smallScreen ? theme.palette.primary.main : 'white'} />
                </Button>
            )}
            <div className={classes.quantity} style={{ color: !smallScreen || isCheckoutDialogOpen ? theme.palette.primary.main : 'white' }}>
                {quantity}
            </div>
            <Button
                classes={{ button: classes.button }}
                onClick={() => {
                    handleAddCartItem();
                }}
            >
                <PlusIcon color={smallScreen ? theme.palette.primary.main : 'white'} />
            </Button>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        color: 'white',
    },
    button: {
        display: 'grid',
        placeItems: 'center',
        width: 30,
        height: 30,
        borderRadius: 4,
        minHeight: 30,
        backgroundColor: 'white',
        [theme.breakpoints.up('sm')]: {
            backgroundColor: theme.palette.primary.main,
        },
    },
    quantity: {
        [theme.breakpoints.up('sm')]: {
            color: theme.palette.primary.main,
        },
    },
    trashcan: {
        display: 'grid',
        placeItems: 'center',
        width: 30,
        height: 30,
        borderRadius: 4,
        minHeight: 30,
        '&:focus': {
            outline: 'none',
        },
    },
}));

type Props = {
    quantity: number;
    cartItem: CartItemVm;
};
