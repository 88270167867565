/**
 * @prettier
 */
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { BottomDialogCenteredButton } from 'src/components/BottomDialogCenteredButton';
import { BottomDialogCheckButton, runAfterCheckAnimation } from 'src/components/BottomDialogCheckButton';
import { Dialog } from 'src/components/Dialog';
import { PickupTimeTypes, type PickupTimeType } from 'src/constants/PickupTimeType';
import type { TimeZone } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { AsapOrderIcon } from 'src/icons/AsapOrderIcon';
import { PlannedOrderIcon } from 'src/icons/PlannedOrderIcon';
import { actions } from 'src/reducers';
import type { PickupTimesVm } from 'src/types/PickupTimesVm';
import { isToday } from 'src/utils/date/isToday';
import { isTomorrow } from 'src/utils/date/isTomorrow';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { upperCaseFirstLetterInString } from 'src/utils/transform/upperCaseFirstLetterInString';

export function SelectPickupTimeDialog(): React.ReactElement | null {
    const classes = useStyles();

    const restaurant = useSelector((state) => state.app.restaurant);
    const plannedOrdersEnabled = useSelector((state) => state.app.restaurant?.plannedOrdersEnabled);
    const open = useSelector((state) => state.app.selectPickupTimeDialog.open);
    const pickupTimeType = useSelector((state) => state.app.pickupTimeType);
    const pickupTime = useSelector((state) => state.app.pickupTime);
    const timeZone = useSelector((state) => state.app.restaurant?.timeZone);
    const preparationTime = useSelector((state) => state.app.restaurant?.preparationTime);
    const drivingDuration = useSelector((state) => state.app.deliveryEstimate?.drivingDuration);
    const pickupTimes = useSelector((state) => state.app.pickupTimes);
    const orderType = useSelector((state) => state.app.orderType);
    const driverArrivesAtStoreTime = useSelector((state) => state.app.driverArrivesAtStoreTime);
    const externalDeliveryEstimatedTime = useSelector((state) => state.app.restaurant?.externalDeliveryEstimatedTime);
    const hideDeliveryEstimatedTimeEnabled = useSelector((state) => state.app.restaurant?.hideDeliveryEstimatedTimeEnabled);

    const closeSelectPickupTimeDialog = useAction(actions.closeSelectPickupTimeDialog);
    const selectPickupTime = useAction(actions.selectPickupTime);

    const [selected, setSelected] = useState<PickupTimeType>();
    const [days, setDays] = useState<Array<string>>([]);
    const [times, setTimes] = useState<Array<string>>([]);
    const [dayIndex, setDayIndex] = useState(0);
    const [timeIndex, setTimeIndex] = useState(0);
    const [forceShowTimePickers, setForceShowTimePickers] = useState(false); // Used to avoid two animations when pickers are visible and selecting ASAP

    // set initial state when opening the dialog
    useEffect(() => {
        if (!open) return;

        setSelected(pickupTimeType);
        if (pickupTimeType === PickupTimeTypes.ASAP) {
            setDays(pickupTimes.planned.days);
            setTimes(pickupTimes.planned.times[0]);
            setDayIndex(0);
            setTimeIndex(0);
            setForceShowTimePickers(false);
        } else if (pickupTimeType === PickupTimeTypes.PLANNED) {
            const day = indexOfDay(pickupTimes, pickupTime);
            const time = indexOfTime(pickupTimes, pickupTime);
            setDayIndex(day);
            setTimeIndex(time);
            setDays(pickupTimes.planned.days);
            setTimes(pickupTimes.planned.times[day]);
            setForceShowTimePickers(false);
        }
    }, [open]);

    // refresh pickupTimes and change selected if it no longer exists
    useEffect(() => {
        if (!open) return;

        if (selected === PickupTimeTypes.ASAP) {
            if (!pickupTimes.asap) {
                // selected was ASAP time no longer exits, change to first PLANNED
                setSelected(PickupTimeTypes.PLANNED);
                setDays(pickupTimes.planned.days);
                setTimes(pickupTimes.planned.times[0]);
                setDayIndex(0);
                setTimeIndex(0);
                return;
            }
        } else if (selected === PickupTimeTypes.PLANNED) {
            const newDayIndex = pickupTimes.planned.days.findIndex((current) => current === days[dayIndex]);
            const newTimeIndex = pickupTimes.planned.times[newDayIndex]?.findIndex((current: string) => current === times[timeIndex]);
            if (newDayIndex === -1 || newTimeIndex === -1) {
                // selected PLANNED time no longer exits, change to ASAP and reset planned
                setSelected(PickupTimeTypes.ASAP);
                setDays(pickupTimes.planned.days);
                setTimes(pickupTimes.planned.times[0]);
                setDayIndex(0);
                setTimeIndex(0);
                return;
            }
            setDays(pickupTimes.planned.days);
            setTimes(pickupTimes.planned.times[newDayIndex]);
            setDayIndex(newDayIndex);
            setTimeIndex(newTimeIndex);
        }
    }, [pickupTimes]);

    const translateAsapPickupTime = () => {
        if (isDeliveryOrder(orderType)) {
            if (hideDeliveryEstimatedTimeEnabled) return translate('Order now');

            if (externalDeliveryEstimatedTime) {
                return translate('Estimated delivery @minutes min', { minutes: externalDeliveryEstimatedTime });
            }
            return translate('Estimated delivery @minutes min', { minutes: translate(`DriverArrivesAtStoreTimes.${driverArrivesAtStoreTime ?? 'DEFAULT'}`) });
        }
        return translate('Ready in @minutes min').replace('@minutes', `${preparationTime}-${preparationTime + 5}`);
    };

    if (!restaurant || !pickupTimes) {
        return null;
    }

    return (
        <Dialog open={open} onClose={closeSelectPickupTimeDialog} title={translate('When do you want your order?')}>
            <BottomDialogCheckButton
                checked={selected === PickupTimeTypes.ASAP}
                Icon={AsapOrderIcon}
                text={translateAsapPickupTime()}
                subtext={pickupTimes.asap ? undefined : translate('Not available for the moment')}
                onClickRow={() => {
                    setSelected(PickupTimeTypes.ASAP);
                    selectPickupTime({
                        pickupTimeType: PickupTimeTypes.ASAP,
                        pickupTime: asapToDateString(pickupTimes.asap as any, timeZone),
                    });
                    if (selected === PickupTimeTypes.PLANNED) {
                        setForceShowTimePickers(true); // Keep showing pickers while animating check to avoid two animations
                    }
                    runAfterCheckAnimation(closeSelectPickupTimeDialog);
                }}
                disabled={!pickupTimes.asap}
            />
            <BottomDialogCheckButton
                checked={selected === PickupTimeTypes.PLANNED}
                Icon={PlannedOrderIcon}
                text={translate('Select time for pre-ordering')}
                subtext={!pickupTimes.planned.days.length ? translate('Not available for the moment') : undefined}
                onClickRow={() => {
                    if (selected === PickupTimeTypes.PLANNED) {
                        selectPickupTime({
                            pickupTimeType: PickupTimeTypes.PLANNED,
                            pickupTime: plannedToDateString(dayIndex, timeIndex, days, times, timeZone),
                        });
                        runAfterCheckAnimation(closeSelectPickupTimeDialog);
                        return;
                    }
                    setSelected(PickupTimeTypes.PLANNED);
                    // animateEaseInEaseOut(); // Animates the pickers
                }}
                disabled={!pickupTimes.planned.days.length || !plannedOrdersEnabled}
            />
            {(selected === PickupTimeTypes.PLANNED || forceShowTimePickers) && (
                <>
                    <div className={classes.wheelPickersContainer}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <FormControl variant='outlined' className={classes.wheelPickerStyles} fullWidth>
                                    <InputLabel id='day-label'>{translate('Day')}</InputLabel>
                                    <Select
                                        labelId='day-label'
                                        id='day'
                                        value={days[dayIndex]}
                                        onChange={(event: React.ChangeEvent<any>) => {
                                            const newDayIndex = days.indexOf(event.target.value);
                                            const newTimes = pickupTimes.planned.times[newDayIndex];
                                            const newTimeIndex = newTimes.findIndex((time) => time === times[timeIndex]) ?? times[0]; // set new time to same if exists in new day or reset to first
                                            setTimes(newTimes);
                                            setDayIndex(newDayIndex);
                                            setTimeIndex(newTimeIndex);
                                        }}
                                        label={translate('Day')}
                                    >
                                        {days.map((day) => (
                                            <MenuItem key={day} value={day}>
                                                {translateDay(day, timeZone)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl variant='outlined' className={classes.wheelPickerStyles} fullWidth>
                                    <InputLabel id='time-label'>{translate('Time')}</InputLabel>
                                    <Select
                                        labelId='time-label'
                                        id='time'
                                        value={times[timeIndex]}
                                        onChange={(event: React.ChangeEvent<any>) => {
                                            const newTimeIndex = times.indexOf(event.target.value);
                                            setTimeIndex(newTimeIndex);
                                        }}
                                        label={translate('Time')}
                                    >
                                        {times.map((time) => (
                                            <MenuItem key={`${days[dayIndex]}-${time}`} value={time}>
                                                {time}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                    <BottomDialogCenteredButton
                        // Icon={CheckIcon}
                        text={translate('Select')}
                        onClick={() => {
                            selectPickupTime({
                                pickupTimeType: PickupTimeTypes.PLANNED,
                                pickupTime: plannedToDateString(dayIndex, timeIndex, days, times, timeZone),
                            });
                            runAfterCheckAnimation(closeSelectPickupTimeDialog);
                        }}
                    />
                </>
            )}
        </Dialog>
    );
}

function translateDay(day: string, timeZone: TimeZone): string {
    if (isToday(moment.tz(day, timeZone), timeZone)) {
        return translate('Today');
    } else if (isTomorrow(moment.tz(day, timeZone), timeZone)) {
        return translate('Tomorrow');
    } else {
        return upperCaseFirstLetterInString(moment.tz(day, timeZone).format('dddd'));
    }
}

function indexOfDay(pickupTimes: PickupTimesVm, pickupTime: string) {
    return pickupTimes.planned.days.indexOf(pickupTime?.substring(0, 10));
}

function indexOfTime(pickupTimes: PickupTimesVm, pickupTime: string) {
    const day = indexOfDay(pickupTimes, pickupTime);
    return pickupTimes.planned.times[day].indexOf(pickupTime?.substring(11, 16));
}

function asapToDateString(
    asap: {
        day: string;
        time: string;
    },
    timeZone: TimeZone,
): string | undefined {
    return moment.tz(`${asap.day}T${asap.time}`, timeZone).format();
}

function plannedToDateString(dayIndex: number, timeIndex: number, days: Array<string>, times: Array<string>, timeZone: TimeZone) {
    if (!days[dayIndex] || !times[timeIndex]) {
        return;
    }
    return moment.tz(`${days[dayIndex]}T${times[timeIndex]}`, timeZone).format();
}

const useStyles = makeStyles((theme) => ({
    wheelPickersContainer: {
        padding: 16,
    },
    wheelPickerStyles: {},
}));
