/**
 * @prettier
 */
import { getInLocalStorage } from 'src/localStorage/utils/getInLocalStorage';

export const KEY_NAME = '_restaurantColors';

export function getRestaurantColorsInLocalStorage(): [string, string] | undefined {
    return getInLocalStorage(KEY_NAME);
}
