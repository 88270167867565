/**
 * @prettier
 */
import type { RestaurantVm } from 'src/api/pidedirecto/getAppContextApi';
import type { OrderVm } from 'src/api/pidedirecto/types/OrderVm';
import { pidedirectoApiMethodPublic } from 'src/api/pidedirectoApiMethodPublic';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverArrivesAtStoreTime } from 'src/constants/DriverArrivesAtStoreTime';
import type { OrderId } from 'src/types/Id';

export async function findOrderApi(request: FindOrderApiRequest): ApiSauceResponse<FindOrderApiResponse | undefined> {
    return pidedirectoApiMethodPublic('order/findOrderApi', request);
}

export type FindOrderApiRequest = {
    orderId: OrderId;
};

type FindOrderApiResponse = {
    order: OrderVm;
    restaurant: RestaurantVm;
    driverArrivesAtStoreTime?: DriverArrivesAtStoreTime;
    trackInAppBannerImageUrl: string;
    trackInAppBannerColor: string;
    trackInAppBannerBrandName: string;
};
