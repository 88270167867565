/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';

export function negateNumberString(value?: string | null): string | null | undefined | undefined {
    if (!value) {
        return value;
    }
    return BigNumber(value).negated().toString();
}
