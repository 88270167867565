/**
 * @prettier
 */

export const Countries = Object.freeze({
    MX: 'MX',
    SE: 'SE',
    US: 'US',
    UY: 'UY',
    AR: 'AR',
    BR: 'BR',
    PE: 'PE',
    CL: 'CL',
    PY: 'PY',
    CO: 'CO',
});

export type Country = (typeof Countries)[keyof typeof Countries];
