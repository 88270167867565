/**
 * @prettier
 */
import { pidedirectoApiMethodPublic } from 'src/api/pidedirectoApiMethodPublic';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { EmailAddress, OrderId } from 'src/types/Id';

export async function createUruguayanOrderInvoiceApi(request: CreateUruguayanOrderInvoiceApiRequest): ApiSauceResponse<void> {
    return pidedirectoApiMethodPublic('invoice/createUruguayanOrderInvoiceApi', request);
}

export type CreateUruguayanOrderInvoiceApiRequest = {
    orderId: OrderId;
    email: EmailAddress;
};
