/**
 * @prettier
 */
import Amplify from 'aws-amplify';
import { envAWS_APPSYNC_AUTHENTICATION_TYPE } from 'src/env/envAWS_APPSYNC_AUTHENTICATION_TYPE';
import { envAWS_APPSYNC_GRAPHQL_ENDPOINT } from 'src/env/envAWS_APPSYNC_GRAPHQL_ENDPOINT';
import { envAWS_APPSYNC_REGION } from 'src/env/envAWS_APPSYNC_REGION';
import { envAWS_AUTH_IDENTITY_POOL_ID } from 'src/env/envAWS_AUTH_IDENTITY_POOL_ID';
import { envAWS_AUTH_REGION } from 'src/env/envAWS_AUTH_REGION';
import { envAWS_AUTH_USER_POOL_ID } from 'src/env/envAWS_AUTH_USER_POOL_ID';
import { envAWS_AUTH_USER_POOL_WEB_CLIENT_ID } from 'src/env/envAWS_AUTH_USER_POOL_WEB_CLIENT_ID';

export function configureAws() {
    Amplify.configure({
        Auth: {
            identityPoolId: envAWS_AUTH_IDENTITY_POOL_ID(),
            region: envAWS_AUTH_REGION(),
            userPoolId: envAWS_AUTH_USER_POOL_ID(),
            userPoolWebClientId: envAWS_AUTH_USER_POOL_WEB_CLIENT_ID(),
            mandatorySignIn: false,
            authenticationFlowType: 'CUSTOM_AUTH',
        },
        aws_appsync_graphqlEndpoint: envAWS_APPSYNC_GRAPHQL_ENDPOINT(),
        aws_appsync_region: envAWS_APPSYNC_REGION(),
        aws_appsync_authenticationType: envAWS_APPSYNC_AUTHENTICATION_TYPE(),
        Analytics: { disabled: true },
    } as AwsConfig);
}

type AwsConfig = {
    Auth: {
        identityPoolId: string;
        region: string;
        userPoolId: string;
        userPoolWebClientId: string;
        mandatorySignIn: false;
        authenticationFlowType: 'CUSTOM_AUTH';
    };
    aws_appsync_graphqlEndpoint: string;
    aws_appsync_region: string;
    aws_appsync_authenticationType: string;
    Analytics: {
        disabled: true;
    };
};
