/**
 * @prettier
 */
import { isArray } from 'src/utils/array/isArray';

export function removeDuplicates<T>(value?: Array<T>): Array<T> | undefined {
    if (!isArray(value)) {
        return value;
    }
    // @ts-ignore
    return [...new Set(value)];
}
