/**
 * @prettier
 */
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import type { RestaurantVm } from 'src/api/pidedirecto/getAppContextApi';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { RestaurantClosedDialog } from 'src/components/RestaurantClosedDialog';
import { TopBarDropdown } from 'src/components/TopBarDropdown';
import { translate } from 'src/i18n/translate';
import { CalendarIcon } from 'src/icons/CalendarIcon';
import { ChevronDownIcon } from 'src/icons/ChevronDownIcon';
import { DeleteIcon } from 'src/icons/DeleteIcon';
import { SearchIcon } from 'src/icons/SearchIcon';
import { actions } from 'src/reducers';
import { isWithinHours } from 'src/utils/hours/isWithinHours';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function TopBar({ restaurant, hideTopBarIcons }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [searchBarOpen, setSearchBarOpen] = useState(false);
    const [query, setQuery] = useState('');

    const openSelectRestaurantDialog = useAction(actions.openSelectRestaurantDialog);
    const openRestaurantOpeningHoursDialog = useAction(actions.openRestaurantOpeningHoursDialog);
    const searchMenuItems = useAction(actions.searchMenuItems);
    const clearSearchMenuItems = useAction(actions.clearSearchMenuItems);
    const openRestaurantClosedDialog = useAction(actions.openRestaurantClosedDialog);
    const plannedOrdersEnabled = useSelector((state) => state.app.restaurant?.plannedOrdersEnabled);
    const temporarilyOffline = restaurant.temporarilyOffline;
    const temporarilyWithoutDelivery = useSelector((state) => state.app.restaurant?.temporarilyWithoutDelivery);
    const closed = !isWithinHours(restaurant.hours, restaurant.timeZone);

    const specialCharactersRegExp = /[^a-zA-Z0-9\s]+/;

    const toggleSearchBar = () => setSearchBarOpen((current) => !current);

    const searchProduct = (value: any) => setQuery(value);

    useEffect(() => {
        if (!query) return;
        if (specialCharactersRegExp.test(query)) return;
        if (query) searchMenuItems(query);
    }, [query, searchMenuItems]);

    useEffect(() => {
        if ((closed && !plannedOrdersEnabled) || temporarilyOffline || temporarilyWithoutDelivery) {
            openRestaurantClosedDialog();
        }
    }, [closed, temporarilyOffline]);

    return (
        <div
            className={classNames(classes.container, searchBarOpen ? classes.containerWithSearchBar : '')}
            style={{
                justifyContent: restaurant.logoImageUrl || smallScreen ? 'space-between' : 'flex-end',
            }}
        >
            {!hideTopBarIcons && (
                <div
                    style={{ display: searchBarOpen ? 'none' : '' }}
                    className={classes.restaurantSelectorMobile}
                    onClick={() => {
                        openSelectRestaurantDialog();
                    }}
                >
                    <div className={classes.restaurantName}>{restaurant.name}</div>
                    <ChevronDownIcon color={'white'} />
                </div>
            )}
            {restaurant.logoImageUrl && <img className={classes.logo} src={restaurant.logoImageUrl} alt='logo' />}
            <div className={classes.iconsContainer} style={{ width: searchBarOpen ? '100%' : '' }}>
                {!hideTopBarIcons && (
                    <>
                        {!searchBarOpen && (
                            <Button onClick={toggleSearchBar} classes={{ button: classes.searchButton }}>
                                <SearchIcon color={'#333333'} />
                            </Button>
                        )}
                        <div className={classNames({ [classes.searchBarOpened]: searchBarOpen }, { [classes.searchBarClosed]: !searchBarOpen })}>
                            <Input classes={{ inputContainer: classes.input }} name='query' onChange={searchProduct} placeholder={translate('Search...')} leftIcon={<SearchIcon color={'#333333'} />} />
                            {!query && (
                                <div
                                    className={classes.deleteIcon}
                                    onClick={() => {
                                        clearSearchMenuItems();
                                        setSearchBarOpen(false);
                                    }}
                                >
                                    <DeleteIcon color={'#97979c'} />
                                </div>
                            )}
                        </div>
                        <div className={classNames(classes.iconContainer, classes.calendarIcon)} onClick={openRestaurantOpeningHoursDialog}>
                            <CalendarIcon color={'#333333'} />
                        </div>

                        <div className={classNames(classes.iconContainer, classes.calendarIcon)}>
                            <TopBarDropdown />
                        </div>
                    </>
                )}
                {!hideTopBarIcons && (
                    <div
                        className={classes.restaurantSelector}
                        onClick={() => {
                            openSelectRestaurantDialog();
                        }}
                    >
                        <div className={classes.restaurantName}>{restaurant.name}</div>
                        <ChevronDownIcon color={'#333333'} />
                    </div>
                )}
            </div>
            <RestaurantClosedDialog />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        top: 0,
        height: 120,
        width: '100%',
        background: 'transparent',
        display: 'flex',
        alignItems: 'center',
        padding: '0 24px',
        transition: '500ms',
        zIndex: 97,
        [theme.breakpoints.up('md')]: {
            padding: '0 90px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0 180px',
        },
    },
    restaurantSelectorMobile: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    restaurantSelector: {
        display: 'flex',
        alignItems: 'center',
        background: 'white',
        padding: '12px 18px',
        borderRadius: 8,
        marginLeft: 12,
        cursor: 'pointer',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    restaurantName: {
        color: 'white',
        marginRight: 8,
        fontSize: 14,
        [theme.breakpoints.up('sm')]: {
            color: '#333333',
            maxWidth: 100,
        },
    },
    logo: {
        width: 80,
        height: 80,
        borderRadius: '50%',
        background: 'white',
        objectFit: 'cover',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    iconsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: 12,
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 42,
        height: 42,
        padding: 8,
        borderRadius: '50%',
        background: 'white',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        cursor: 'pointer',
    },
    searchBarOpened: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 42,
        width: '80%',
        padding: 8,
        background: 'white',
        borderRadius: 12,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        animation: '$open .4s ease',
    },
    searchBarClosed: {
        display: 'none',
        width: '0',
    },
    '@keyframes open': {
        '0%': {
            width: '20%',
        },
        '100%': {
            width: '80%',
        },
    },
    searchIcon: {
        transition: '400ms',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    calendarIcon: {
        marginRight: 0,
    },
    cartIcon: {
        cursor: 'pointer',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    deleteIcon: {
        marginRight: 8,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    itemsQuantityContainer: {
        display: 'grid',
        placeItems: 'center',
        position: 'absolute',
        top: 5,
        right: 7,
        aspectRatio: 1,
        borderRadius: '50%',
        zIndex: 2,
    },
    itemsQuantity: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        backgroundColor: '#333333',
        borderRadius: '50%',
        fontSize: 11,
        width: 15,
        height: 15,
    },
    input: {
        marginLeft: 8,
        width: '100%',
        border: 'none',
    },
    searchButton: {
        backgroundColor: 'white',
        borderRadius: '50%',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    containerWithSearchBar: {},
}));

type Props = {
    restaurant: RestaurantVm;
    hideTopBarIcons?: boolean;
};
