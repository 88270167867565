/**
 * @prettier
 */
import * as React from 'react';
import { CheckoutElement } from 'src/components/checkout/CheckoutElement';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function SelectAddressButton(): React.ReactElement {
    const address = useSelector((state) => state.app.address);

    const openSelectAddressDialog = useAction(actions.openSelectAddressDialog);

    return (
        <CheckoutElement
            title={translate('Delivery address')}
            content={address ? address.street : translate('No delivery address')}
            action={openSelectAddressDialog}
            actionTitle={address ? translate('Change') : translate('Add')}
        />
    );
}
