/**
 * @prettier
 */
import Chip from '@material-ui/core/Chip';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useEffect, useRef } from 'react';
import * as React from 'react';
import type { ModifierGroupVm } from 'src/api/pidedirecto/getAppContextApi';
import { translate } from 'src/i18n/translate';
import { AppTheme } from 'src/styles/AppTheme';

export function ModifierGroupHeader({ modifierGroup, missingSectionName }: Props): React.ReactElement | null {
    const classes = useStyles();
    const container = useRef<HTMLDivElement>(null);

    const isMissing = missingSectionName === modifierGroup.name;

    useEffect(() => {
        if (missingSectionName === modifierGroup.name) {
            container.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [modifierGroup, missingSectionName]);

    const renderBadges = () => {
        if (!modifierGroup.requiredMin && !modifierGroup.requiredMax && !modifierGroup.freeModifiersQuantity) {
            return null;
        }

        const required = modifierGroup.requiredMin === modifierGroup.requiredMax && !!modifierGroup.requiredMax;
        const showMinBadge = !required && !!modifierGroup.requiredMin && modifierGroup.requiredMin >= 1;
        const showMaxBadge = !required && !!modifierGroup.requiredMax && modifierGroup.requiredMax >= 1;
        const freeModifiersQuantity = !!modifierGroup.freeModifiersQuantity;

        return (
            <>
                {required && (
                    <Chip
                        className={isMissing ? classes.missingTitle : ''}
                        size='small'
                        label={modifierGroup.requiredMin === 1 ? translate('REQUIRED') : `${String(modifierGroup.requiredMin)} ${translate('REQUIRED')}`}
                    />
                )}
                {showMinBadge && <Chip className={isMissing ? classes.missingTitle : ''} size='small' label={`${translate('MIN')} ${String(modifierGroup.requiredMin)}`} />}
                {showMaxBadge && <Chip className={isMissing ? classes.missingTitle : ''} size='small' label={`${translate('MAX')} ${String(modifierGroup.requiredMax)}`} />}

                {freeModifiersQuantity && (
                    <Tooltip title={translate('If it exceeds the free quantity, the higher value items will be added')}>
                        <Chip className={isMissing ? classes.missingTitle : ''} size='small' label={`${translate('Free quantity')} ${String(modifierGroup.freeModifiersQuantity)}`} />
                    </Tooltip>
                )}
            </>
        );
    };

    return (
        <Grid container direction='row' justify='flex-start' alignItems='center' spacing={1} style={{ marginTop: 16 }} ref={container}>
            <Grid item>
                <FormLabel component='legend'>{modifierGroup.name}</FormLabel>
            </Grid>
            <Grid item>{renderBadges()}</Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 5,
        paddingBottom: 5,
        backgroundColor: '#222',
    },
    headerText: {
        alignSelf: 'center',
        fontSize: 16,
        color: 'white',
        fontWeight: 'bold',
    },
    missingTitle: {
        animation: '$vibration 700ms ease-out, $color 700ms ease-out',
    },
    '@keyframes vibration': {
        '0%': {
            transform: 'translateX(0px)',
        },
        '25%': {
            transform: 'translateX(15px)',
        },
        '50%': {
            transform: 'translateX(0px)',
        },
        '75%': {
            transform: 'translateX(15px)',
        },
        '100%': {
            transform: 'translateX(0px)',
        },
    },
    '@keyframes color': {
        '0%': {
            backgroundColor: AppTheme.text.colorFailure,
        },
        '75%': {
            backgroundColor: AppTheme.text.colorFailure,
        },
    },
}));

type Props = {
    modifierGroup: ModifierGroupVm;
    missingSectionName: string;
};
