/**
 * @prettier
 */
import type { RestaurantVm } from 'src/api/pidedirecto/getAppContextApi';
import { translate } from 'src/i18n/translate';
import { transformHoursToWeekOpeningHours } from 'src/utils/openingHours/transformHoursToWeekOpeningHours';
import { isBlankString } from 'src/utils/string/isBlankString';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export function getRestaurantOpeningHoursPerDay(restaurant: RestaurantVm): Array<{
    day: string;
    openingHours: string;
}> {
    if (isBlankString(restaurant.hours)) return days.map((day) => ({ day: translate(day), openingHours: '00:00-24:00' }));

    const openingHours = transformHoursToWeekOpeningHours(restaurant.hours);

    const openingHoursPerDay = openingHours.map((day, index) => {
        const dayIsOpen = day.openingHours.length > 0;
        return {
            day: translate(days[index]),
            openingHours: dayIsOpen ? day.openingHours.map((openingHours) => `${openingHours.openingTime}-${openingHours.closingTime}`).join(', ') : translate('Closed'),
        };
    });

    return openingHoursPerDay;
}
