/**
 * @prettier
 */
import { isArray } from 'src/utils/array/isArray';

export function emptyArrayToUndefined<T>(value?: Array<T> | null): Array<T> | undefined {
    if (isArray(value) && (value as any).length === 0) {
        return;
    }
    return value as any;
}
