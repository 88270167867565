/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Text } from 'src/components/Text';
import { AppTheme } from 'src/styles/AppTheme';
import { classNames } from 'src/utils/react/classNames';

export function SummaryRow({ text, value, className }: Props): React.ReactElement | null {
    const classes = useStyles();

    if (!text && !value) {
        return null;
    }

    return (
        <div className={classes.container}>
            <Text className={classNames(classes.text, className)}>{text}</Text>
            <Text className={classNames(classes.value, className)}>{value}</Text>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    text: {
        fontWeight: 400,
        fontSize: 14,
        color: '#4F586E',
        fontFamily: AppTheme.typography.regular,
    },
    value: {
        fontWeight: 600,
        fontSize: 14,
        color: '#4F586E',
        fontFamily: AppTheme.typography.regular,
    },
}));

type Props = {
    text: string;
    value?: string;
    className?: any;
};
