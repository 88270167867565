/**
 * @prettier
 */
import * as React from 'react';

export function ChevronLeftIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M6.00016 10.6666L1.3335 5.99998L6.00016 1.33331' stroke='#4F586E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
}

type Props = {
    color: string;
};
