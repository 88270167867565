/**
 * @prettier
 */
import type { LanguageTag } from 'src/types/Id';

let language = (
    (navigator as any).userLanguage ||
    (navigator.languages && navigator.languages.length && navigator.languages[0]) ||
    navigator.language ||
    (navigator as any).browserLanguage ||
    (navigator as any).systemLanguage ||
    'en'
).substring(0, 2);

export function setLanguage(value: any) {
    language = value;
}

export function getLanguage(): LanguageTag {
    return language;
}
