/**
 * @prettier
 */
import type { PromoCodeVm } from 'src/api/pidedirecto/types/PromoCodeVm';

export function isPromoCodeApplicableForRequireAtLeastOneCard({ promoCode, numberOfCards }: Props): boolean {
    if (!promoCode?.requireAtLeastOneCard) {
        return true;
    }
    return numberOfCards >= 1;
}

type Props = {
    promoCode?: PromoCodeVm;
    numberOfCards: number;
};
