/**
 * @prettier
 */
import * as React from 'react';

export function DeleteIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M1 13L13 1M1 1L13 13' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
}
type Props = {
    color: string;
};
