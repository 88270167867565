/**
 * @prettier
 */
import moment from 'moment-timezone';
import type { RestaurantVm } from 'src/api/pidedirecto/getAppContextApi';
import { PickupTimeType, PickupTimeTypes } from 'src/constants/PickupTimeType';
import type { TimeZone } from 'src/constants/TimeZone';
import type { PickupTimesVm } from 'src/types/PickupTimesVm';

export function getDefaultPickupTimeAndType(
    restaurant: RestaurantVm,
    pickupTimes?: PickupTimesVm,
    rememberedPickupTimeType?: PickupTimeType,
    rememberedPickupTime?: string,
): {
    pickupTimeType?: PickupTimeType;
    pickupTime?: string;
} {
    const { timeZone } = restaurant;

    if (!pickupTimes) {
        return {
            pickupTimeType: undefined,
            pickupTime: undefined,
        };
    }

    if (rememberedPickupTimeType === PickupTimeTypes.ASAP && pickupTimes.asap) {
        return {
            pickupTimeType: PickupTimeTypes.ASAP,
            pickupTime: toDateString(pickupTimes.asap.day, pickupTimes.asap.time, timeZone),
        };
    }
    if (rememberedPickupTimeType === PickupTimeTypes.PLANNED) {
        const _rememberedPickupTime = moment.tz(rememberedPickupTime, timeZone);
        const rememberedDay = _rememberedPickupTime.format('YYYY-MM-DD');
        const dayIndex = pickupTimes.planned.days.findIndex((day) => day === rememberedDay);
        if (dayIndex !== -1) {
            const rememberedTime = _rememberedPickupTime.format('HH:mm');
            const timeIndex = pickupTimes.planned.times[dayIndex].findIndex((time) => time === rememberedTime);
            if (timeIndex !== -1) {
                return {
                    pickupTimeType: PickupTimeTypes.PLANNED,
                    pickupTime: toDateString(pickupTimes.planned.days[dayIndex], pickupTimes.planned.times[dayIndex][timeIndex], timeZone),
                };
            }
        }
    }

    if (pickupTimes.asap) {
        return {
            pickupTimeType: PickupTimeTypes.ASAP,
            pickupTime: toDateString(pickupTimes.asap.day, pickupTimes.asap.time, timeZone),
        };
    }
    if (pickupTimes.planned.days[0] && pickupTimes.planned.times[0][0]) {
        return {
            pickupTimeType: PickupTimeTypes.PLANNED,
            pickupTime: toDateString(pickupTimes.planned.days[0], pickupTimes.planned.times[0][0], timeZone),
        };
    }

    return {
        pickupTimeType: undefined,
        pickupTime: undefined,
    };
}

function toDateString(day: string, time: string, timeZone: TimeZone): string {
    return moment.tz(`${day}T${time}`, timeZone).format();
}
