/**
 * @prettier
 */
export const CardBrands = Object.freeze({
    VISA: 'VISA',
    VISA_DEBIT: 'VISA_DEBIT',
    OCA: 'OCA',
    MASTERCARD: 'MASTERCARD',
    AMERICAN_EXPRESS: 'AMERICAN_EXPRESS',
    CARNET: 'CARNET',
    EDENRED: 'EDENRED',
    SODEXO: 'SODEXO',
    PRIVADA: 'PRIVADA',
    UNKNOWN: 'UNKNOWN',
});

export type CardBrand = (typeof CardBrands)[keyof typeof CardBrands] | string;
