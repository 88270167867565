/**
 * @prettier
 */
import { envENVIRONMENT } from 'src/env/envENVIRONMENT';
import { requireStringNotBlank } from 'src/utils/require/requireStringNotBlank';

export function envAWS_APPSYNC_AUTHENTICATION_TYPE(): string {
    try {
        return requireStringNotBlank(process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE);
    } catch (e: any) {
        throw Error(`REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE is not configured correctly in .env.${envENVIRONMENT().toLowerCase()}`);
    }
}
