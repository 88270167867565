/**
 * @prettier
 */
import ButtonBase from '@material-ui/core/ButtonBase';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { type ModifierGroupVm as OrderModifierGroupVm } from 'src/api/pidedirecto/types/OrderVm';
import { SubModifier } from 'src/components/addMenuItemDialog/SubModifier';
import { SubModifierGroupHeader } from 'src/components/addMenuItemDialog/SubModifierGroupHeader';
import { BottomDialog } from 'src/components/BottomDialog';
import { BottomDialogHeader } from 'src/components/BottomDialogHeader';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { AppTheme } from 'src/styles/AppTheme';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { sum } from 'src/utils/reduce/sum';

export function AddMenuItemSubModifierGroupDialog({ selectedModifierGroups, onChangeSelectedModifierGroups }: Props): React.ReactElement | null {
    const classes = useStyles();

    const [missingSectionName, setMissingSectionName] = useState('');

    const open = useSelector((state) => state.app.addMenuItemSubModifierGroupDialog.open);
    const modifierGroup = useSelector((state) => state.app.addMenuItemSubModifierGroupDialog.modifierGroup);
    const modifier = useSelector((state) => state.app.addMenuItemSubModifierGroupDialog.modifier);

    const closeAddMenuItemSubModifierGroupDialog = useAction(actions.closeAddMenuItemSubModifierGroupDialog);

    useEffect(() => {
        if (missingSectionName) {
            setTimeout(() => setMissingSectionName(''), 700);
        }
    }, [missingSectionName]);

    if (!modifier) {
        return null;
    }

    const disabled =
        modifier.subModifierGroups?.some((subModifierGroup) => {
            if (subModifierGroup.requiredMin === undefined && subModifierGroup.requiredMax === undefined) {
                return false;
            }
            const selectedSubModifiers =
                selectedModifierGroups
                    .find((selectedModifierGroup) => selectedModifierGroup.name === modifierGroup.name)
                    ?.modifiers?.find((selectedModifier: any) => selectedModifier.name === modifier.name)
                    ?.subModifierGroups?.find((selectedSubModifierGroup: any) => selectedSubModifierGroup.name === subModifierGroup.name)?.subModifiers ?? [];

            const selectedSubModifiersQuantity =
                selectedSubModifiers
                    .map((selectedSubModifier) => selectedSubModifier.quantity)
                    .reduce(sum, BigNumber(0))
                    .toNumber() ?? 0;

            if (subModifierGroup.requiredMin !== undefined && selectedSubModifiersQuantity < subModifierGroup.requiredMin) {
                return true;
            }
            if (subModifierGroup.requiredMax !== undefined && selectedSubModifiersQuantity > subModifierGroup.requiredMax) {
                return true;
            }
            return false;
        }) ?? false;

    const handleClickOk = () => {
        if (disabled) {
            showMissingSection();
            return;
        }
        closeAddMenuItemSubModifierGroupDialog();
    };

    const showMissingSection = () => {
        for (const subModifierGroup of modifier?.subModifierGroups ?? []) {
            const selectedSubModifiers =
                selectedModifierGroups
                    .find((selectedModifierGroup) => selectedModifierGroup.name === modifierGroup.name)
                    ?.modifiers?.find((selectedModifier: any) => selectedModifier.name === modifier.name)
                    ?.subModifierGroups?.find((selectedSubModifierGroup: any) => selectedSubModifierGroup.name === subModifierGroup.name)?.subModifiers ?? [];
            const selectedSubModifiersQuantity =
                selectedSubModifiers
                    .map((selectedSubModifier) => selectedSubModifier.quantity)
                    .reduce(sum, BigNumber(0))
                    .toNumber() ?? 0;

            if (subModifierGroup.requiredMin !== undefined && selectedSubModifiersQuantity < subModifierGroup.requiredMin) {
                setMissingSectionName(subModifierGroup.name);
                break;
            }
            if (subModifierGroup.requiredMax !== undefined && selectedSubModifiersQuantity > subModifierGroup.requiredMax) {
                setMissingSectionName(subModifierGroup.name);
                break;
            }
        }
    };

    return (
        <BottomDialog open={open} onClickBackground={closeAddMenuItemSubModifierGroupDialog} onClickHardwareBack={closeAddMenuItemSubModifierGroupDialog}>
            <BottomDialogHeader header={translate('Sub modifier groups')} onClose={closeAddMenuItemSubModifierGroupDialog} />
            <DialogContent style={{ paddingBottom: 0 }}>
                {modifier.subModifierGroups?.map((subModifierGroup) => (
                    <React.Fragment key={subModifierGroup.name}>
                        <SubModifierGroupHeader subModifierGroup={subModifierGroup} missingSectionName={missingSectionName} />
                        <FormControl required error={false} component='fieldset' style={{ width: '100%', paddingTop: 8 }}>
                            <FormGroup>
                                {subModifierGroup.subModifiers.map((subModifier) => (
                                    <SubModifier
                                        key={subModifier.name}
                                        modifierGroup={modifierGroup}
                                        modifier={modifier}
                                        subModifierGroup={subModifierGroup}
                                        subModifier={subModifier}
                                        selectedModifierGroups={selectedModifierGroups}
                                        onMissingSectionName={() => setMissingSectionName(subModifierGroup.name)}
                                        onChangeSelectedModifierGroups={onChangeSelectedModifierGroups}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </React.Fragment>
                ))}
            </DialogContent>
            <div className={classes.bottomContainer}>
                <ButtonBase className={classNames(classes.button)} onClick={handleClickOk}>
                    {`${translate('Ok')}`}
                </ButtonBase>
            </div>
        </BottomDialog>
    );
}

const useStyles = makeStyles((theme) => ({
    image: {
        width: 'calc(100% + 48px)',
        height: '30vh',
        objectFit: 'cover',
        marginLeft: '-24px',
    },
    bottomContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        marginBottom: 10,
        padding: '8px 24px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    spinnerContainer: {
        width: '40%',
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: 20,
        padding: '10px 20px',
        fontSize: 16,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        height: 40,
        minWidth: '55%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: 10,
            borderRadius: 25,
        },
    },
    buttonDisabled: {
        backgroundColor: `${AppTheme.text.colorDisabled} !important`,
        cursor: 'pointer',
    },
}));

type Props = {
    selectedModifierGroups: Array<OrderModifierGroupVm>;
    onChangeSelectedModifierGroups: (modifierGroups: Array<OrderModifierGroupVm>) => any;
};
