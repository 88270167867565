/**
 * @prettier
 */
import { Auth } from 'aws-amplify';
import { pidedirectoApi } from 'src/api/pidedirectoApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { dateReviver } from 'src/api/utils/dateReviver';

export async function pidedirectoApiMethod(url: string, request: any): ApiSauceResponse<any> {
    const cognitoUserSession = await Auth.currentSession();
    return pidedirectoApi.post(url, request ?? {}, {
        headers: {
            'Content-Type': 'application/vnd.api.autoparsedates+json',
            accept: 'application/vnd.api.autoparsedates+json',
            authorization: cognitoUserSession.getIdToken().getJwtToken(),
        },
        transformResponse: [(data: any) => (data ? JSON.parse(data, dateReviver) : null)],
    });
}
