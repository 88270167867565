/**
 * @prettier
 */
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useContext } from 'react';
import * as React from 'react';
import { PageContext } from 'src/components/Page';
import { createUserClickedButtonLogEvent } from 'src/services/logEvent/createUserClickedButtonLogEvent';
import { AppTheme } from 'src/styles/AppTheme';
import { classNames } from 'src/utils/react/classNames';
import { convertReactNodeToString } from 'src/utils/react/convertReactNodeToString';

/**
 * @callback onClick
 */

/**
 *
 * @param {React.Node} children - content inside the button
 * @param {boolean} [disabled] - button disabled control
 * @param {boolean} [loading] - loading indicator
 * @param {boolean} [outlined] - renders button outlined version
 * @param {boolean} [text] - renders button text version
 * @param {string} [form] - form id to link the button
 * @param {string} [color] - color to be used when coloring the outlined button
 * @param {*} [buttonRef] - button element react ref
 * @param {string} [type] - html button type [docs]{@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/button}
 * @param {string} [id] - html button id
 * @param {Object} [classes] - classes object to override the default styles
 * @param {string} classes.button - class to override button style
 * @param {onClick} [onClick] - function to be called when the button is clicked
 * @returns {React.Node}
 */
export function Button({ disabled, loading, type, id, outlined, color, text, form, icon, buttonRef, classes: classesProp, children, onClick }: ButtonProps): React.ReactElement {
    const classes = useStyles({ color });

    const pageContext = useContext(PageContext);

    const getButtonClasses = () => {
        let buttonClasses = classes.button;

        if (outlined) buttonClasses = classNames(buttonClasses, classes.outlinedButton);
        if (icon) buttonClasses = classNames(buttonClasses, classes.iconButton);
        if (text) buttonClasses = classNames(buttonClasses, classes.textButton);

        if (disabled || loading) buttonClasses = classNames(buttonClasses, getDisabledClass());

        return buttonClasses;
    };

    const getDisabledClass = () => {
        if (outlined) return classes.outlinedDisabled;

        return classes.disabled;
    };

    const handleClick = (e: any) => {
        const buttonName = convertReactNodeToString(children);
        createUserClickedButtonLogEvent({ pageContext, buttonName });
        pageContext.clearStackTrace();
        onClick?.(e);
    };

    return (
        <button ref={buttonRef} onClick={handleClick} id={id} type={type} className={classNames(getButtonClasses(), classesProp?.button)} disabled={disabled || loading} form={form}>
            {loading && <CircularProgress size={40} style={{ position: 'absolute' }} />}
            {children}
        </button>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: AppTheme.typography.regular,
        fontSize: 16,
        padding: '8px 12px',
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        cursor: 'pointer',
        border: 0,
        minHeight: 42,
        transition: 'background-color 0.2s ease',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: `${theme.palette.primary.main}aa`,
        },
    },
    outlinedButton: {
        border: (props: any) => `1px solid ${props.color || theme.palette.primary.main}`,
        color: (props: any) => props.color || theme.palette.primary.main,
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: (props: any) => `${props.color || theme.palette.primary.main}11`,
        },
    },
    iconButton: {
        backgroundColor: 'transparent',
        width: 48,
        height: 48,
        borderRadius: 48,
        color: '#393B41',
        '&:hover': {
            backgroundColor: '#00000011',
        },
    },
    disabled: {
        backgroundColor: '#F1F1F1',
        color: '#8C9196',
        cursor: 'default',
        '&:hover': {
            backgroundColor: '#F1F1F1',
        },
    },
    outlinedDisabled: {
        backgroundColor: '#F1F1F1',
        color: '#8C9196',
        border: '1px solid #D9D9D9',
        cursor: 'default',
        '&:hover': {
            backgroundColor: '#F1F1F1',
            border: '1px solid #D9D9D9',
        },
    },
    textButton: {
        border: '0px',
        backgroundColor: 'transparent',
        color: '#324054',
        padding: '6px 8px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        '&:focus': {
            outline: '1px solid #1A73F9',
        },
    },
}));

export type ButtonProps = {
    disabled?: boolean;
    loading?: boolean;
    type?: 'submit' | 'reset' | 'button';
    id?: string;
    outlined?: boolean;
    text?: boolean;
    form?: string;
    color?: string;
    icon?: boolean;
    buttonRef?: any;
    classes?: {
        button: string;
    };
    children: React.ReactNode;
    onClick?: any;
};
