/**
 * @prettier
 */

export function setInLocalStorage<T>(key: string, value: T | undefined): void {
    if (value === undefined) {
        window.localStorage.removeItem(key);
        return;
    }
    window.localStorage.setItem(key, JSON.stringify(value));
}
