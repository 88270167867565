/**
 * @prettier
 */
export const PickupTimeTypes = Object.freeze({
    ASAP: 'ASAP',
    PLANNED: 'PLANNED',
    PLANNED_MENU_ITEMS: 'PLANNED_MENU_ITEMS',
});

export type PickupTimeType = (typeof PickupTimeTypes)[keyof typeof PickupTimeTypes];
