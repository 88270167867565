/**
 * @prettier
 */
import { OrderType, OrderTypes } from 'src/constants/OrderType';
import { translate } from 'src/i18n/translate';

export function translateOrderType(orderType: OrderType): string {
    switch (orderType) {
        case OrderTypes.TABLE_ORDER: {
            return translate('Eat Here');
        }
        case OrderTypes.SELF_SERVICE_ORDER: {
            return translate('Eat Here');
        }
        case OrderTypes.TAKE_AWAY_ORDER: {
            return translate('Take Away');
        }
        case OrderTypes.DELIVERY_ORDER: {
            return translate('Delivery');
        }
        default: {
            return '';
        }
    }
}
