/**
 * @prettier
 */
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { ChangePasswordDialog } from 'src/components/dialog/customer/ChangePasswordDialog';
import { AwsFacade, IsSignedInResults } from 'src/facade/aws/AwsFacade';
import { translate } from 'src/i18n/translate';
import { InfoIcon } from 'src/icons/InfoIcon';
import { actions } from 'src/reducers';
import { isArgentina } from 'src/utils/country/isArgentina';
import { isUruguay } from 'src/utils/country/isUruguay';
import { useAction } from 'src/utils/react/useAction';
import { useHasClickedOutside } from 'src/utils/react/useHasClickedOutside';
import { useSelector } from 'src/utils/react/useSelector';

export function TopBarDropdown(): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const dropdownRef = useRef(null);

    useHasClickedOutside({
        element: dropdownRef.current,
        onClick: (hasClickedOutside) => {
            if (hasClickedOutside) setDropdownOpen(false);
        },
    });

    const [dropDownOpened, setDropdownOpen] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [changePasswordDialogState, setChangePasswordDialogState] = useState({ open: false });

    const country = useSelector((state) => state.app.restaurant?.country);
    const hasPassword = useSelector((state) => state.app.hasPassword);

    const signOut = useAction(actions.signOut);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        checkSignIn();
    }, [dropDownOpened, signOut]);

    const openChangePasswordDialog = () => setChangePasswordDialogState({ open: true });

    const closeChangePasswordDialog = () => setChangePasswordDialogState({ open: false });

    const toggleDropdownOpen = () => {
        setDropdownOpen((current) => !current);
    };

    const openTermsAndConditions = () => {
        if (isArgentina(country) || isUruguay(country)) {
            window.open('/terms-and-conditions-uy', '_blank', 'noreferrer');
            return;
        }
        window.open('/terms-and-conditions', '_blank', 'noreferrer');
    };

    const openPrivacyPolicy = () => {
        window.open('/privacy', '_blank', 'noreferrer');
    };

    const checkSignIn = async () => {
        const result = await AwsFacade.isSignedIn(true);
        if (result === IsSignedInResults.SIGNED_IN) {
            setIsSignedIn(true);
            return;
        }
        if (result === IsSignedInResults.NETWORK_ERROR) {
            setIsSignedIn(false);
            return;
        }
    };

    return (
        <div className={classes.infoContainer} onClick={toggleDropdownOpen} ref={dropdownRef}>
            <ChangePasswordDialog open={changePasswordDialogState.open} onClose={closeChangePasswordDialog} />
            <div>
                <InfoIcon color={'#333333'} />
            </div>
            {dropDownOpened && (
                <div className={isSmallScreen ? classes.linkSelectCardLeft : classes.linkSelectCardRight}>
                    <div className={classes.linkListItem} onClick={openTermsAndConditions}>
                        {translate('Terms and Conditions')}
                    </div>
                    <div className={classes.linkListItem} onClick={openPrivacyPolicy}>
                        {translate('Privacy Policy')}
                    </div>
                    {isSignedIn && (
                        <div className={classes.linkListItem} onClick={signOut}>
                            {translate('Sign out')}
                        </div>
                    )}
                    {isSignedIn && !hasPassword && (
                        <div className={classes.linkListItem} onClick={openChangePasswordDialog}>
                            {translate('Create password')}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    infoContainer: {
        position: 'relative',
        top: 4,
        left: 2,
    },
    linkSelectCardLeft: {
        position: 'absolute',
        right: 0,
        top: '120%',
        backgroundColor: 'white',
        paddingTop: 12,
        paddingBottom: 12,
        borderRadius: 12,
        width: 170,
    },
    linkSelectCardRight: {
        position: 'absolute',

        top: '120%',
        backgroundColor: 'white',
        paddingTop: 12,
        paddingBottom: 12,
        borderRadius: 12,
        width: 170,
    },
    linkListItem: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        padding: '6px 12px',
        color: 'black',
        whiteSpace: 'nowrap',
        '&:hover': {
            backgroundColor: `#e7e7e7aa`,
        },
    },
}));
