/**
 * @prettier
 */
import { createStore } from '@pidedirecto/ui/hooks';
import { PromoCodeVm } from 'src/api/pidedirecto/types/PromoCodeVm';

export const [usePromoCodeStore, usePromoCodeActions] = createStore<State, Actions>({
    initialState: {
        promoCodes: [],
    },
    actions: {
        setPromoCodes: (state, promoCodes) => {
            state.promoCodes = promoCodes;
        },
    },
});

export type State = {
    promoCodes: Array<PromoCodeVm>;
};

type Actions = {
    setPromoCodes: (promoCodes: Array<PromoCodeVm>) => void;
};
