/**
 * @prettier
 */
import type { GeoJsonPointVm } from 'src/api/pidedirecto/types/GeoJsonPointVm';

export function toLatLng(point: undefined): { lng: number; lat: number } | undefined;
export function toLatLng(point: GeoJsonPointVm): { lng: number; lat: number };
export function toLatLng(point: GeoJsonPointVm | undefined): { lng: number; lat: number } | undefined;

export function toLatLng(point: GeoJsonPointVm | undefined): { lng: number; lat: number } | undefined {
    if (!point) {
        return;
    }
    return {
        lng: point.coordinates[0],
        lat: point.coordinates[1],
    };
}
