/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { PidedirectoBigIcon } from 'src/icons/PidedirectoBigIcon';

export function Privacy(): React.ReactElement {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <header>
                <a href='https://pidedirecto.mx/' target='_blank'>
                    <div className={classes.image}>
                        <PidedirectoBigIcon />
                    </div>
                </a>
            </header>
            <h1 className={classes.title}>{translate('Notice of Privacy (Privacy Policy) - Users')}</h1>
            <p className={classes.text}>
                De conformidad con lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y diversa normativa vigente y relacionada (la
                “Legislación de Datos Personales”), se emite el presente Aviso de Privacidad.
            </p>

            <h2 className={classes.subtitle}>1. Identidad y domicilio de los Responsables.</h2>
            <p className={classes.text}>
                Swedish Technologies, S.A.P.I. de C.V. (en lo sucesivo “TDF”) obtienen, usan y almacenan (en lo sucesivo el “Tratamiento”) los datos personales de usted como titular de las mismos
                (“Usted”, el “Titular” o “Usuario”), mismos que cuentan con domicilio para efectos del presente Aviso de Privacidad en Rio mississippi 116, 66220, San Pedro Garza García, Nuevo León,
                México.”
            </p>

            <h2 className={classes.subtitle}>2. Tratamiento de los Datos Personales.</h2>
            <p className={classes.text}>
                TR será responsable de los datos personales que recabe a través de la aplicación denominada “Pidedirecto”, su página de internet https://www.Pidedirecto.mx/ (el “Sitio Pidedirecto”) y
                sus redes sociales y blogs (las “Redes Sociales Pidedirecto”), dentro del App Pidedirecto (ambas el “App TDF” y conjuntamente con el App Pidedirecto (las “Apps”), sus páginas de
                internet que incluyendo www.Pidedirecto.mx (el “Sitio TDF” y conjuntamente con el Sitio Pidedirecto, los “Sitios”) y sus redes sociales y blogs (las “Redes Sociales TDF” y
                conjuntamente con las Redes Sociales Pidedirecto (las “Redes Sociales”). Cada uno de los Responsables también podrá recabar sus datos personales a través de cualquier otro contacto con
                Usted, de terceros y de otras fuentes permitidas por ley, tales como terceros, autoridades y sociedades de información crediticia y cada uno será responsable del Tratamiento de los
                mismos.
            </p>
            <p className={classes.text}>
                En caso de que haya proporcionado datos de contacto de terceros como referencias, autorizados, cotitulares, cónyuges, beneficiarios, coacreditados, referencias, garantes, obligados
                solidarios, avales, fiadores, tutores, proveedores, beneficiarios y demás figuras legales relacionadas con productos y/o servicios de los Responsables, Usted garantiza que cuenta con
                su autorización para proporcionar dichos datos, incluyendo en su caso los datos personales aplicables, y es responsable de comunicar a dichas personas sobre los términos y razones por
                las cuales los contactaremos y, en su caso, el Tratamiento de sus datos personales conforme al presente Aviso de Privacidad, así como los medios para conocer el contenido íntegro del
                mismo. Adicionalmente, mediante su consentimiento al presente Aviso de Privacidad, usted ratifica su autorización para que los Responsables para que contacte a dichas personas.
            </p>

            <h2 className={classes.subtitle}>3. Datos personales Tratados por los Responsables.</h2>
            <p className={classes.text}>Para las finalidades indicadas en el presente Aviso de Privacidad, trataremos las siguientes categorías de datos personales y datos personales específicos:</p>
            <p className={classes.text}>
                Dato Personal o Categoría, Datos de identificación y contacto., Datos financieros y patrimoniales, Identificadores en línea, Intereses del Usuario, Datos laborales, Datos migratorios,
                Interacciones con perfiles y comportamientos en las Redes Sociales, los Sitios y las Apps, Datos de localización, Llamadas y conversaciones en nuestros centros de atención (call
                centers o chat en línea) o mediante correo electrónico, Datos referentes a personas sancionadas o consideradas como incumplidoras por organismos gubernamentales nacionales y
                extranjeros.
            </p>
            <p className={classes.text}>Para las finalidades indicadas en el presente Aviso de Privacidad, los Responsables tratan las siguientes categorías de datos personales sensibles:</p>
            <p className={classes.text}>Datos Personales Sensibles, Datos de filiación política (Persona Expuesta Políticamente), Datos Biométricos</p>

            <h2 className={classes.subtitle}>4. Finalidades del Tratamiento.</h2>
            <p className={classes.text}>Trataremos los datos personales para las siguientes finalidades primarias y necesarias:</p>
            <ol>
                <li className={classes.text}>
                    Mantener, desarrollar, administrar, controlar y cumplir con la relación contractual derivada de la solicitud y/o contratación de nuestros productos y/o servicios, incluyendo
                    cualquier operación o servicio financiero.
                </li>
                <li className={classes.text}>
                    Exhibir y/o comercializar productos y/o servicios personalizados en nuestros Sitios y Apps conforme a los intereses y necesidades particulares del Usuario.
                </li>
                <li className={classes.text}>Crear, ordenar, catalogar, clasificar, dividir, separar y almacenar datos y expedientes de los Usuarios.</li>
                <li className={classes.text}>
                    Creación y administración de la cuenta de los Usuarios en nuestros Sitios y Apps y verificar, validar y confirmar la identidad de los Usuarios y la información proporcionada por
                    los mismos, internamente o a través de cualquier autoridad o tercero.
                </li>
                <li className={classes.text}>
                    Proporcionar al Usuario información, comunicaciones y notificaciones sobre nuestros Sitios y Apps, nuestros productos y/o servicios y los productos y servicios de terceros
                    exhibidos en nuestros Sitios y Apps y los términos y condiciones aplicables a los mismos.
                </li>
                <li className={classes.text}>Administrar y procesar pagos, operaciones y transacciones de los Usuarios que utilicen nuestros Sitios y Apps.</li>
                <li className={classes.text}>
                    Administrar, desarrollar, dar mantenimiento, personalizar y mejorar nuestros Sitios y Apps, nuestros productos y/o servicios y sistemas operativos y realizar las actividades
                    necesarias para que éstos funcionen de manera óptima y correcta, así como prevenir o detectar fraudes o abusos.
                </li>
                <li className={classes.text}>Realizar estrategias de mercado y elaboración de segmentación de mercados.</li>
                <li className={classes.text}>Realizar encuestas de satisfacción de nuestros productos y/o servicios.</li>
                <li className={classes.text}>El ofrecimiento o reconocimiento de ofertas, promociones y beneficios de programas de lealtad y beneficios de los Responsables y/o terceros.</li>
                <li className={classes.text}>
                    Proporcionarle asesoría, aclaraciones, atención de quejas, servicio al cliente y servicio postventa, así como monitorear la calidad de los mismos mediante la grabación de llamadas
                    y almacenamiento de las conversaciones correspondiente.
                </li>
                <li className={classes.text}>
                    Tener acceso a centrales de riesgo o burós de créditos para conocer su capacidad y comportamiento de pago, así como los estados financieros de los Usuarios.
                </li>
                <li className={classes.text}>
                    Cumplir con cualesquiera disposiciones y obligaciones legales, nacionales o internacionales, aplicables los Responsables, sus subsidiarias y/o afiliadas, así como para gestionar
                    cualquier proceso judicial o tramitar y responder cualesquier requerimientos o solicitudes de las autoridades.
                </li>
                <li className={classes.text}>
                    Realizar procesos relacionados con restructuras corporativas de los Responsables, tales como la fusión, consolidación, venta, liquidación o transferencia de activos.
                </li>
                <li className={classes.text}>Realizar actividades de cobranza.</li>
                <li className={classes.text}>Actividades complementarias necesarias para la realización de los fines anteriores.</li>
                <li className={classes.text}>
                    En el caso de la Aplicación “Soy Pidedirecto”, para que sus usuarios puedan aceptar y tramitar las órdenes más cercanas a su ubicación, la Aplicación “Soy Pidedirecto” recogerá
                    datos de su localización, aún cuando la Aplicación se encuentre cerrada o no esté en uso.
                </li>
                <li className={classes.text}>
                    El app ‘Soy Pidedirecto’ recoge y almacena el número telefónico del usuario el cual es almacenado en nuestra base de datos usando métodos de encriptación para garantizar la
                    protección de la información personal del usuario. La data recolectada se utiliza para identificar posibles comportamientos atípicos dentro de la aplicación y no es compartida ni
                    transferida a terceros.
                </li>
            </ol>
            <p className={classes.text}>Trataremos los datos personales para las siguientes finalidades secundarias o accesorias:</p>
            <ol>
                <li className={classes.text}>
                    Mercadotecnia, publicidad, promoción, prospección comercial, procesos de referencias, la elaboración, valoración y análisis de perfiles de los Usuarios para el desarrollo y
                    ofrecimiento de nuevos productos y servicios, realización de encuestas ajenas a nuestros productos o servicios, fines analíticos y estadísticos, todos ellos relacionados con los
                    productos y servicios de los Responsables, sus afiliadas, subsidiarias, aliados y otros terceros; y
                </li>
                <li className={classes.text}>
                    Para contactarlo sobre o enviarle información de novedades, noticias, ofertas de los Responsables o terceros, boletines, foros de educación, publicidad o marketing, ventas a
                    distancia, haciendo uso de medios tales como, pero sin limitar, correo electrónico, notificaciones PUSH, mensajes de texto (SMS), mensajería instantánea, llamadas y ventanas de
                    diálogo (pop-ups).
                </li>
            </ol>
            <p className={classes.text}>
                En caso de que no desee que sus datos personales sean tratados para estas finalidades secundarias o accesorias, Usted puede (i) enviar desde este momento un correo electrónico a
                soporte@Pidedirecto.com, siguiendo el procedimiento a que se refiere el numeral 7 del presente Aviso de Privacidad, o (ii) en el caso del párrafo b. antes mencionado, a través de
                nuestras Apps.
            </p>

            <h2 className={classes.subtitle}>5. Opciones y medios que le ofrecemos para limitar el uso o divulgación de sus datos personales.</h2>
            <p className={classes.text}>
                En todos aquellos casos legalmente procedentes, Usted podrá limitar el uso o divulgación de sus datos personales, presentando una solicitud conforme al procedimiento establecido en el
                numeral 7 del presente Aviso de Privacidad.
            </p>

            <h2 className={classes.subtitle}>6. Transferencia de datos personales.</h2>
            <p className={classes.text}>
                Sus datos personales podrán ser transferidos dentro del territorio nacional o hacia el extranjero (en cumplimiento con la regulación aplicable), conforme a lo siguiente:
            </p>
            <ol>
                <li className={classes.text}>
                    A autoridades, como puede ser de forma enunciativa más no limitativa el Instituto Nacional Electoral (“INE”), organismos o entidades gubernamentales, en cumplimiento a o en
                    relación con las obligaciones contempladas en la legislación aplicable a uno de los Responsables, sus subsidiarias y/o afiliadas, así como en cumplimiento de requerimientos
                    efectuados a las mismas.
                </li>
                <li className={classes.text}>
                    A autoridades, como puede ser de forma enunciativa más no limitativa el INE, organismos o entidades gubernamentales, cuando la transferencia sea requerida oficialmente y/o
                    necesaria para el reconocimiento, ejercicio o defensa de un derecho de uno de los Responsables, sus subsidiarias y/o afiliadas en un proceso judicial.
                </li>
                <li className={classes.text}>
                    A compañías controladoras y/o afiliadas y/o subsidiarias y/o empresas relacionadas o pertenecientes al mismo grupo empresarial de los Responsables, que operen bajo los mismos
                    procesos y políticas internas de los Responsables, con finalidades de mercadotecnia, publicidad, promoción, prospección comercial, de procesos de referencias, de elaboración,
                    valoración y análisis de perfiles de los Usuarios, de resguardo centralizado de la información, de fines analíticos y estadísticos y de registro histórico de usuarios y de la
                    realización de encuestas referentes a los Responsables, sus subsidiarias y/o afiliadas.
                </li>
                <li className={classes.text}>
                    A terceros adquirientes, derivado de una reestructura corporativa de uno de los Responsables, incluyendo, la fusión, consolidación, venta, liquidación o transferencia de activos,
                    cuando la transferencia sea necesaria para el mantenimiento o cumplimiento de una relación entre dicho Responsable y el Titular.
                </li>
                <li className={classes.text}>
                    A terceros que exhiben y comercializan productos y/o servicios o aceptan realizar la gestión de un encargo a través de los Sitios y de las Apps, así como a prestadores de servicios
                    y aliados comerciales incluyendo instituciones financieras, cuando la transferencia sea necesaria para el mantenimiento o cumplimiento de una relación entre uno de los Responsables
                    y el Titular o para administrar, desarrollar, dar mantenimiento, personalizar y mejorar nuestros Sitios y Apps, nuestros productos y/o servicios y sistemas operativos y realizar
                    las actividades necesarias para que éstos funcionen de manera óptima y correcta, así como prevenir o detectar fraudes o abusos y/o el cumplimiento de cualquiera de las finalidades
                    descritas en el apartado 4 del presente Aviso de Privacidad incluyendo.
                </li>
                <li className={classes.text}>
                    A terceros y/o prestadores de servicios para la atención del propio Titular respecto de los servicios contratados y las actividades relacionadas con el seguimiento y cumplimiento
                    de las obligaciones derivadas de los servicios contratados.
                </li>
                <li className={classes.text}>
                    A terceros que exhiben y comercializan servicios y/o productos o aceptan realizar la gestión de un encargo a través de los Sitios y de las Apps a través de los Sitios y de las
                    Apps, con la finalidad de dar seguimiento a o realizar actividades relacionadas con cualquier adquisición y entrega de productos y/o servicios a través de los Sitios y de las Apps,
                    así como dar cumplimiento a las obligaciones derivadas de la relación jurídica entre el Titular y dichos terceros.
                </li>
                <li className={classes.text}>Con base en los demás supuestos establecidos en la Legislación de Datos Personales, que no requieren de su consentimiento.</li>
            </ol>
            <p className={classes.text}>
                Con excepción de aquellas mencionadas en el inciso (g) del presente numeral, las transferencias antes mencionadas no requieren de su consentimiento conforme a la Legislación de Datos
                Personales. Si no manifiesta su negativa para dicha transferencia, lo cual puede realizar desde este momento enviando un correo electrónico al correo electrónico
                soporte@Pidedirecto.com, entenderemos que nos ha otorgado su consentimiento.
            </p>
            <p className={classes.text}>
                Adicionalmente, en caso que así lo autorice el Usuario mediante los Sitios, las Apps o cualquier otro medio aplicable, los Responsables podrán transferir sus datos personales a
                terceros que exhiben y comercializan productos y/o servicios a través de los Sitios y de las Apps, aliados comerciales y otros aliados estratégicos de los Responsables, para fines
                mercadológicos, de publicidad o de prospección comercial.
            </p>
            <p className={classes.text}>
                En cualquier caso de los señalados anteriormente, comunicaremos a los terceros receptores el presente Aviso de Privacidad, con el fin de asegurar que su información se utilice en los
                términos del mismo.
            </p>

            <h2 className={classes.subtitle}>7. Medios para el ejercicio sus derechos.</h2>
            <p className={classes.text}>
                En todos aquellos casos legalmente procedentes, Usted podrá en todo momento y a través del procedimiento establecido en el presente numeral, ejercer ante cada uno de los Responsables
                los siguientes derechos referentes a sus datos personales: (i) derechos de Acceso, Rectificación, Cancelación y Oposición (“Derechos ARCO”); (ii) revocar el consentimiento otorgado a
                cada uno de los Responsables para el tratamiento de sus datos personales; (iii) limitar el uso o divulgación de sus datos personales; y (iv) manifestar su negativa para el tratamiento
                de sus datos personales con respecto a las finalidades secundarias y accesorias antes mencionadas. Para el ejercicio de dichos derechos referentes a sus datos personales deberá
                presentar la solicitud correspondiente, mediante el debido llenado del formulario electrónico que ponemos a su disposición y debe de descargar{' '}
                <a className={classes.link} href='#' target='_blank'>
                    aquí
                </a>
                ,
            </p>
            <p className={classes.text}>
                enviándolo a la dirección de correo electrónico soporte@Pidedirecto.com en formato electrónico y con firma autógrafa al calce del mismo, estableciendo como asunto “Ejercicio de
                derechos sobre datos personales” y acompañando lo siguiente:
            </p>
            <ul>
                <li className={classes.text}>
                    copia de los documentos que acrediten su identidad (identificación oficial vigente con fotografía) o, en su caso, la representación legal, conforme a los términos establecidos en
                    la Legislación de Datos Personales; y
                </li>
                <li className={classes.text}>cualquier otro elemento o documento que facilite la localización de sus datos personales.</li>
            </ul>
            <p className={classes.text}>
                Le daremos trámite a las solicitudes de los Titulares para el ejercicio de sus derechos referentes a datos personales en un plazo no mayor a 20 (veinte) días hábiles contados a partir
                de su recepción y realizar requerimiento o subsanar conforme a los tiempos de la Legislación de Datos Personales; los Responsables podrán ampliar este plazo hasta por 20 (veinte) días
                hábiles más, cuando el caso lo amerite, previa notificación. En caso de que su solicitud resulte procedente, la misma se hará efectiva dentro de los 15 (quince) días hábiles siguientes
                de nuestra respuesta.
            </p>
            <p className={classes.text}>
                Usted podrá obtener la información o datos personales solicitados a través de documentos electrónicos en formatos convencionales o a través de cualquier otro medio legítimo que
                garantice y acredite el ejercicio efectivo del derecho solicitado.
            </p>
            <p className={classes.text}>
                Según se establece anteriormente, los Departamentos de Datos Personales de cada uno de los Responsables están a cargo de tramitar cualesquier solicitudes de los titulares para el
                ejercicio de sus derechos referentes a datos personales y puede ponerse en contacto con los mismos a través del correo electrónico soporte@Pidedirecto.com.
            </p>
            <p className={classes.text}>
                Los Responsables desean mantener actualizados sus datos personales en todo momento. Por lo anterior, solicitamos su cooperación para comunicarnos oportunamente cualquier cambio que
                debamos tener en cuenta, para incorporarlo a nuestras bases de datos. Usted garantiza y responde, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de sus datos
                personales o los de cualquier tercero bajo su custodia o representación legal, en el entendido de que cada uno los Responsables cumplirá con lo establecido en la legislación aplicable
                en relación con los datos de dichos terceros recibidos por uno de los Responsables.
            </p>

            <h2 className={classes.subtitle}>8. Medios que permiten recabar datos personales de manera automática.</h2>
            <p className={classes.text}>
                Le informamos que los Sitios, las Apps y las Redes Sociales hacen uso de cookies y otras tecnologías, a través de las cuales es posible monitorear automáticamente su comportamiento,
                brindarle nuestros productos y/o servicios y otorgarle una experiencia optima y personalizada durante el uso de los mismos, así como ofrecerle nuevos productos y servicios basados en
                sus preferencias. Los datos personales que se recaban a través de estas tecnologías son: dirección IP, sitios web y secciones de los mismos que usted visita desde los Sitios, antes de
                los Sitios o en páginas relacionadas con los Sitios y las Apps, características de navegador, características de dispositivos, sistema operativo, preferencias de idiomas, URL a las que
                se hace referencia, información sobre conductas y acciones realizadas en los Sitios y las Apps, información sobre conductas y acciones realizadas mientras nuestra App está abierta,
                fechas y horas de las visitas a los Sitios o acceso las Apps, secciones o contenido consultadas en los mismos y datos de ubicación y localización del Usuario mientras nuestras Apps
                estén abierta.
            </p>
            <p className={classes.text}>
                Estas tecnologías pueden deshabilitarse accediendo a la(s) opción(es) de Privacidad y/o Seguridad ubicada(s) en la(s) sección(es) de Opciones, Herramientas, Preferencias de Internet o
                funciones similares del navegador de Internet que esté utilizando o a través del ícono de configuración, herramientas o similares del dispositivo en el cual esté instalada las Apps;
                sin embargo, al desactivarlas debe tomar en cuenta que dicha acción podría provocar que no sea capaz de obtener el funcionamiento total que los Sitios y las Apps pudiera ofrecerle. Si
                usted desea obtener información adicional acerca de las cookies mencionadas en esta sección, puede visitar www.allaboutcookies.org/es.
            </p>

            <h2 className={classes.subtitle}>9. Cambios al Aviso de Privacidad.</h2>
            <p className={classes.text}>
                El presente Aviso de Privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales, de nuestras propias necesidades por los productos o
                servicios que ofrecemos, de nuestras prácticas, programas o políticas de privacidad, de cambios en nuestro modelo de negocio o por otras causas.
            </p>
            <p className={classes.text}>
                Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente Aviso de Privacidad, a través de los Sitios y de las Apps, en la sección correspondiente de
                Aviso de Privacidad.
            </p>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 40,
    },
    title: {
        textAlign: 'center',
        color: theme.palette.primary.main,
        fontSize: 25,
    },
    text: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
    },
    subtitle: {
        fontSize: 18,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 'bold',
    },
    link: {
        color: 'black',
        fontWeight: 'bold',
        textDecoration: 'none',
    },
    image: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
}));
