/**
 * @prettier
 */
import * as React from 'react';

export function ComingSoonIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='41' height='40' viewBox='0 0 41 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M30.5556 4.37558C24.8264 6.66802 18.7106 7.84002 12.5398 7.82802H10.9743C8.22477
             7.82405 5.57418 8.85385 3.5485 10.7131C1.52281 12.5723 0.270073 15.1251 0.0388416
              17.8649C-0.19239 20.6047 0.614787 23.3314 2.30021 25.5038C3.98564 27.6762 6.42614 
              29.1357 9.13748 29.5926C9.82843 32.4204 10.7917 35.1747 12.0138 37.8167C12.9823
               39.9124 15.508 40.572 17.4033 39.4782L18.7747 38.6871C20.6115 37.6268 21.2064 
               35.3641 20.3861 33.5356C19.893 32.4402 19.4555 31.3207 19.0753 30.1812C23.0788 
               30.7198 26.9236 31.7467 30.5556 33.1995C32.1075 28.5529 32.8963 23.6855 32.8913 
               18.7865C32.8913 13.7498 32.071 8.903 30.5556 4.37558ZM33.434 3.11066C35.1532 8.158
               36.0277 13.4544 36.0223 18.7865C36.0277 24.1186 35.1532 29.415 33.434 34.4624C33.3192 
               34.7984 33.2023 35.1345 33.0792 35.4685C32.9363 35.8585 32.9543 36.2892 33.1291 36.666C33.3039
                37.0428 33.6211 37.3347 34.0112 37.4775C34.4012 37.6203 34.8319 37.6024 35.2087 37.4276C35.5855
                 37.2528 35.8774 36.9355 36.0202 36.5455C37.6028 32.2245 38.5962 27.7101 38.9738 23.124C40.0951
                  21.9586 40.7207 20.4037 40.7188 18.7865C40.7188 17.1041 40.0529 15.572 38.9738 14.449C38.5938
                   9.86325 37.6005 5.34915 36.0202 1.0275C35.9495 0.83439 35.8414 0.657098 35.7022 0.50575C35.5629 0.354401
                    35.3953 0.23196 35.2087 0.145416C34.8319 -0.0293667 34.4012 -0.0473186 34.0112 0.0955088C33.6211
                     0.238336 33.3039 0.530243 33.1291 0.907014C32.9543 1.28379 32.9363 1.71456 33.0792 2.10456C33.2023
                      2.43854 33.3213 2.77251 33.434 3.11066Z'
                fill={color}
                stroke={color}
            />
        </svg>
    );
}

type Props = {
    color: string;
};
