/**
 * @prettier
 */
import type { PageContextType } from 'src/components/Page';
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';

export function createUserClickedButtonLogEvent({ pageContext, buttonName }: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_CLICKED_BUTTON,
        message: `button ${buttonName} clicked`,
        details: `${pageContext.stackTrace}/${normalizeUiStackTrace(`button_${buttonName}`)}`,
        data: {
            buttonName,
            buttonId: normalizeUiStackTrace(`button_${buttonName}`),
            pathId: ` ${pageContext.stackTrace}/${normalizeUiStackTrace(`button_${buttonName}`)}`,
            stackTrace: pageContext.stackTrace,
        },
    });
}

type Params = {
    buttonName: string;
    pageContext: PageContextType;
};
