/**
 * @prettier
 */
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function CancelButton({ tooltip, onClick }: Props): React.ReactElement {
    return (
        <>
            <Tooltip title={tooltip || translate('Cancel')}>
                <IconButton onClick={onClick}>
                    <ClearIcon />
                </IconButton>
            </Tooltip>
        </>
    );
}

type Props = {
    tooltip?: string;
    onClick?: any;
};
