/**
 * @prettier
 */
import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { removeAddressApi } from 'src/api/pidedirecto/address/removeAddressApi';
import { BottomDialog } from 'src/components/BottomDialog';
import { BottomDialogHeader } from 'src/components/BottomDialogHeader';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RemoveAddressDialog(): React.ReactElement {
    const classes = useStyles();

    const customerId = useSelector((state) => state.app.customerId);
    const open = useSelector((state) => state.app.removeAddressDialog.open);
    const addressId = useSelector((state) => state.app.removeAddressDialog.addressId);

    const closeRemoveAddressDialog = useAction(actions.closeRemoveAddressDialog);
    const removeAddress = useAction(actions.removeAddress);

    const removeSelectedAddress = async () => {
        const request = {
            customerId: customerId,
            addressId: addressId,
        } as const;
        const response = await removeAddressApi(request);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        removeAddress(request);
        closeRemoveAddressDialog();
    };

    const handleCloseDialog = () => {
        closeRemoveAddressDialog();
    };

    return (
        <BottomDialog open={open} onClickBackground={handleCloseDialog} onClickHardwareBack={handleCloseDialog}>
            <BottomDialogHeader header={translate('Are you sure to continue?')} />

            <div className={classes.footerContainer}>
                <ButtonBase onClick={handleCloseDialog}>
                    <div className={classes.buttonCancelContainer}>
                        <Text className={classes.buttonCancelTextCloseDialog}>{translate('Cancel')}</Text>
                    </div>
                </ButtonBase>
                <ButtonBase onClick={removeSelectedAddress}>
                    <div className={classes.buttonConfirmContainer}>
                        <Text className={classes.buttonConfirmTextDialog}>{translate('Confirm')}</Text>
                    </div>
                </ButtonBase>
            </div>
        </BottomDialog>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    buttonConfirmContainer: {
        marginRight: '10px',
        borderRadius: '15px',
        backgroundColor: theme.palette.primary.main,
    },
    buttonConfirmTextDialog: {
        padding: '15px',
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold',
    },
    buttonCancelTextCloseDialog: {
        padding: '30px',
        textAlign: 'center',
    },
    buttonTextContainer: {
        padding: '30px',
        textAlign: 'center',
    },
    footerContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    buttonCancelContainer: {},
}));
