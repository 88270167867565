/**
 * @prettier
 */
import type { MenuItemVm } from 'src/api/pidedirecto/getAppContextApi';
import type { OrderItemVm } from 'src/api/pidedirecto/types/OrderVm';
import type { CartItemVm } from 'src/types/CartItemVm';

export function isItemSoldByWeight(item?: OrderItemVm | CartItemVm | MenuItemVm): boolean {
    return !!item?.isSoldByWeight || !!item?.salesUnit;
}
