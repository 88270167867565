/**
 * @prettier
 */
import * as React from 'react';
import type { OrderItemVm } from 'src/api/pidedirecto/types/OrderVm';
import { OrderItem } from 'src/components/OrderItem';
import type { CurrencyFormatVm } from 'src/types/CurrencyFormatVm';
import { createOrderItemKey } from 'src/utils/order/createOrderItemKey';

export function OrderItemsPickup({ orderItems, currencyFormat }: Props): React.ReactElement {
    return (
        <div style={{ margin: '10px 0' }}>
            {orderItems.map((orderItem) => (
                <OrderItem key={createOrderItemKey(orderItem)} orderItem={orderItem} />
            ))}
        </div>
    );
}

type Props = {
    orderItems: Array<OrderItemVm>;
    currencyFormat: CurrencyFormatVm;
};
