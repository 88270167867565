/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@pidedirecto/ui';
import { OutlinedDoorbellIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GoogleMapsTrackDriverPosition } from 'src/components/googlemaps/GoogleMapsTrackDriverPosition';
import { KeepShoppingButton } from 'src/components/KeepShoppingButton';
import { OrderCancelButton } from 'src/components/OrderCancelButton';
import { OrderDetails } from 'src/components/OrderDetails';
import { OrderStepper } from 'src/components/OrderStepper';
import { TrackInAppLink } from 'src/components/TrackInAppLink';
import { translate } from 'src/i18n/translate';
import { DeliveryIcon } from 'src/icons/DeliveryIcon';
import { FoodIcon } from 'src/icons/FoodIcon';
import { PickFoodIcon } from 'src/icons/PickFoodIcon';
import { AppTheme } from 'src/styles/AppTheme';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isNewOrder } from 'src/utils/order/isNewOrder';
import { isRoomServiceOrder } from 'src/utils/order/isRoomServiceOrder';
import { isSelfServiceOrder } from 'src/utils/order/isSelfServiceOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function Order(): React.ReactElement {
    const classes = useStyles();
    const history = useHistory();
    const { urlPathname } = useParams<{
        urlPathname?: string;
        orderId?: string;
    }>();

    const orderStatus = useSelector((state) => state.app.orderStatus);
    const firstName = useSelector((state) => state.app.firstName);
    const orderType = useSelector((state) => state.app.orderType);
    const showMap = useSelector((state) => state.app.showMap);
    const shortSurveyId = useSelector((state) => state.app.order?.shortSurveyId);

    const goToSurvey = () => {
        history.push(`/${urlPathname}/survey/${shortSurveyId}`);
    };

    return (
        <div className={classes.container}>
            <div className={classes.infoContainer}>
                <h1 className={classes.title}>{translate('@firstName, thanks for your order', { firstName })}</h1>
                <TrackInAppLink />
                <div className={classes.introOrder}>
                    <h2 className={classes.subTitle}>{translate('Your order')}</h2>
                    {isDeliveryOrder(orderType) && (
                        <div className={classes.orderTypeInfo}>
                            <DeliveryIcon color={'#C1C3C8'} />
                            <p>{translate(`OrderTypes.${orderType}`)}</p>
                        </div>
                    )}
                    {isTakeAwayOrder(orderType) && (
                        <div className={classes.orderTypeInfo}>
                            <PickFoodIcon color={'#C1C3C8'} />
                            <p>{translate(`OrderTypes.${orderType}`)}</p>
                        </div>
                    )}
                    {isSelfServiceOrder(orderType) && (
                        <div className={classes.orderTypeInfo}>
                            <FoodIcon color={'#C1C3C8'} />
                            <p>{translate(`OrderTypes.${orderType}`)}</p>
                        </div>
                    )}
                    {isRoomServiceOrder(orderType) && (
                        <div className={classes.orderTypeInfo}>
                            <OutlinedDoorbellIcon />
                            <p>{translate(`OrderTypes.${orderType}`)}</p>
                        </div>
                    )}
                </div>
                <OrderStepper />
                {isCompletedOrder(orderStatus) && !!shortSurveyId && (
                    <Button variant={'outline'} onClick={goToSurvey} classes={{ button: classes.surveyButton }}>
                        {translate('Rate your experience')}
                    </Button>
                )}
                <OrderDetails />
                <div className={classes.buttonsContainer}>
                    {isNewOrder(orderStatus) && <OrderCancelButton />}
                    {isCompletedOrder(orderStatus) && <KeepShoppingButton />}
                </div>
            </div>
            {showMap && (
                <div className={classes.mapRestaurantInfoContainer}>
                    <GoogleMapsTrackDriverPosition />
                </div>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            gap: 42,
            flexDirection: 'row',
        },
    },
    title: {
        fontFamily: AppTheme.typography.bold,
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
        },
    },
    subTitle: {
        fontFamily: AppTheme.typography.semiBold,
        margin: 0,
    },
    infoContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: 42,
        },
    },
    mapRestaurantInfoContainer: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            marginTop: 50,
        },
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    introOrder: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '18px 0',
        marginLeft: '10px',
    },
    orderTypeInfo: {
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        color: '#4F586E',
        fontSize: 15,
        fontFamily: AppTheme.typography.medium,
        height: 'fit-content',
        '& p': {
            margin: 0,
        },
    },
    buttonsContainer: {
        width: '100%',
        display: 'flex',
        height: '100%',
        maxHeight: '60px',
        textAlign: 'center',
        justifyContent: 'center',
        gap: 24,
        marginBottom: 12,
        marginTop: 12,
    },
    surveyButton: {
        left: 8,
        marginBottom: '12px',
        width: '95%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
            marginBottom: '8px',
        },
    },
}));
