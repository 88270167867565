/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Form, FormPasswordField } from '@pidedirecto/ui/form';
import { useForm } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { useState } from 'react';
import { changePasswordApi } from 'src/api/pidedirecto/customer/changePasswordApi';
import { Dialog } from 'src/components/Dialog';
import { PasswordValidatorIndicator } from 'src/components/input/password/PasswordValidatorIndicator';
import { translate } from 'src/i18n/translate';
import { validatePassword } from 'src/services/customer/validatePassword';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { encryptDataInTransit } from 'src/utils/crypto/encryptDataInTransit';
import { useSelector } from 'src/utils/react/useSelector';

export function ChangePasswordDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const { setError, watch } = form;

    const [loading, setLoading] = useState(false);

    const customerId = useSelector((state) => state.app.customerId);

    const password = watch('password');

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const changePassword = async (form: any) => {
        if (form.password !== form.confirmPassword) {
            setError('confirmPassword', { message: translate('Password does not match') });
            return;
        }
        setLoading(true);
        const response = await changePasswordApi({
            customerId,
            encryptedPassword: encryptDataInTransit(form.password),
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onClose();
        onSuccess?.();
    };

    const isPasswordValidated = () => Object.values(validatePassword(password)).every(Boolean);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            loading={loading}
            title={translate('Set your new password')}
            actionButtonFormId='form'
            actionText={translate('Create password')}
            actionButtonDisabled={!isPasswordValidated()}
        >
            <Form form={form} onSubmit={changePassword} classes={{ form: classes.form }} id='form'>
                <div>
                    <FormPasswordField name='password' label={translate('Password')} required={{ value: true, message: translate('This field is required') }} />
                    <PasswordValidatorIndicator password={password ?? ''} />
                </div>
                <FormPasswordField name='confirmPassword' label={translate('Confirm password')} required={{ value: true, message: translate('This field is required') }} />
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
}));

type Props = {
    open: boolean;
    onClose: Function;
    onSuccess?: Function;
};
