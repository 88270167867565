/**
 * @prettier
 */
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export function formatAsInternationalPhoneNumber<T extends string | null | undefined>(value: T): T {
    if (value === undefined || value === null) {
        return value;
    }
    const parsedPhoneNumber = parsePhoneNumberFromString(value);
    if (!parsedPhoneNumber) {
        return value.trim().replace(/\s+/g, ' ') as any;
    }

    return parsedPhoneNumber.format('INTERNATIONAL') as T;
}
