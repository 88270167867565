/**
 * @prettier
 */
import { Grid, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { findOrderForInvoiceApi } from 'src/api/pidedirecto/invoice/findOrderForInvoiceApi';
import { OrderVm } from 'src/api/pidedirecto/types/OrderVm';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { Pages } from 'src/constants/Page';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { InvoiceInfoForm } from 'src/scenes/invoicePage/InvoiceInfoForm';
import { AppTheme } from 'src/styles/AppTheme';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { transformImageUrlRestaurant } from 'src/utils/image/transformImageUrlRestaurant';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function InvoicePage(): React.ReactElement {
    const classes = useStyles();
    const { shortOrderId } = useParams<{
        shortOrderId?: string;
    }>();

    const [shortOrderIdValue, setShortOrderIdValue] = useState(shortOrderId);
    const [order, setOrder] = useState<OrderVm>();
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurant?.restaurantId);
    const restaurant = useSelector((state) => state.app.restaurant);

    const setPage = useAction(actions.setPage);

    useEffect(() => {
        setPage(Pages.INVOICE_PAGE);
    }, []);

    const handleShortOrderId = (value: string) => {
        setShortOrderIdValue(value);
    };

    const handleSubmit = async () => {
        if (!shortOrderIdValue) return;

        setLoading(true);
        const response = await findOrderForInvoiceApi({
            restaurantId,
            shortOrderId: shortOrderIdValue.toUpperCase(),
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        if (!response.data) {
            return alert(translate('Was not possible to find the order'));
        }
        if (response.data.invoiced) {
            return alert(translate('This order has already been invoiced'));
        }
        setOrder(response.data);
    };

    return (
        <div className={`${classes.container} ${order ? '' : classes.center}`}>
            <Grid container direction='column' justify='center' alignItems='center' lg={order ? 5 : 3} md={order ? 7 : 4} sm={order ? 10 : 7} className={classes.background}>
                {!order && (
                    <Grid container direction='column' spacing={4} justify='center' alignItems='center'>
                        <Grid item>
                            <img className={classes.image} src={transformImageUrlRestaurant(restaurant?.imageUrl)} draggable='false' alt='imageUrl' />
                        </Grid>
                        <Grid item>
                            <h1 className={classes.title}>{translate('Generate your invoice automatically')}</h1>
                        </Grid>

                        <Grid item container direction='column' justify='center' alignItems='center' spacing={2}>
                            <Grid item className={classes.subContainer}>
                                <Input
                                    name={'orderId'}
                                    defaultValue={shortOrderId}
                                    value={shortOrderIdValue}
                                    onChange={handleShortOrderId}
                                    label={translate('Order Id')}
                                    placeholder={translate('Example: @data', { data: '4961D4' })}
                                />
                            </Grid>
                            <Grid item className={classes.subContainer}>
                                <Button disabled={loading} classes={{ button: classes.button }} onClick={handleSubmit}>
                                    {translate('Search')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid container direction='column' justify='center' alignItems='center'>
                    <InvoiceInfoForm order={order} />
                    <div className={classes.footer}>
                        {translate('Electronic billing powered by')}
                        <a className={classes.footerLinkText} href={'https://www.ambit.la/'}>
                            Ambit.la
                        </a>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    center: {
        height: '100vh',
    },
    subContainer: {
        width: '80%',
        maxWidth: '328px',
        textAlign: 'center',
    },
    button: {
        width: '100%',
    },
    title: {
        fontFamily: AppTheme.typography.semiBold,
        fontSize: 18,
        margin: '0 40px',
        textAlign: 'center',
    },
    footer: {
        fontFamily: AppTheme.typography.regular,
        color: '#616B79',
        fontSize: 10,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 24,
        paddingBottom: 24,
        gap: 4,
    },
    image: {
        width: '100%',
        maxWidth: 250,
        paddingTop: 40,
    },
    background: {
        backgroundColor: '#FFF',
        borderRadius: 15,
    },
    footerLinkText: {
        fontFamily: AppTheme.typography.regular,
    },
}));
