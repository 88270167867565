/**
 * @prettier
 */
import { useEffect, useState } from 'react';

export function useIsVisible(
    ref: {
        current: HTMLDivElement | null;
    },
    container?: HTMLDivElement,
): boolean {
    const [isVisible, setIsVisible] = useState(false);

    const checkIfVisible = () => {
        if (!ref?.current) return;

        const rect = ref.current.getBoundingClientRect();
        const viewHeight = container?.clientHeight ?? 0;
        const isVisible = rect.top < viewHeight * 0.5 && rect.top > 0;
        setIsVisible(isVisible);
    };

    useEffect(() => {
        checkIfVisible();
        container?.addEventListener('scroll', checkIfVisible);
        return () => {
            container?.removeEventListener('scroll', checkIfVisible);
        };
    }, [ref, container]);

    return isVisible;
}
