/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { useLoadScript } from '@react-google-maps/api';
import * as React from 'react';
import { GoogleMapsCheckoutEmptyState } from 'src/components/emptyStates/GoogleMapsCheckoutEmptyState';
import { googleMapsConfig } from 'src/config/googleMapsConfig';
import { toLatLng } from 'src/utils/googlemaps/toLatLng';
import { useCurrentLocation } from 'src/utils/react/useCurrentLocation';
import { useSelector } from 'src/utils/react/useSelector';

// Google Map Static API https://developers.google.com/maps/documentation/maps-static/start?hl=es-419
export function GoogleMapsCheckoutDialog(): React.ReactElement {
    const { isLoaded, loadError } = useLoadScript(googleMapsConfig);
    const { location } = useCurrentLocation();
    const classes = useStyles();

    const restaurantLocation = useSelector((state) => state.app.restaurant?.location);
    const addressLocation = useSelector((state) => state.app.address?.location);

    const customerLocation = addressLocation || location;

    const formatUrlMap = () => {
        const url = `https://maps.googleapis.com/maps/api/staticmap`;
        const customerMapMarkersUrl = `?markers=${getCustomerMapMarkers()}`;
        const restaurantMapMarkersUrl = `&markers=${getRestaurantMapMarkers()}`;
        return url + customerMapMarkersUrl + restaurantMapMarkersUrl + `&size=700x400&key=${googleMapsConfig.googleMapsApiKey}`;
    };

    const getCustomerMapMarkers = () => {
        return `size:mid|icon:${CUSTOMER_PIN_ICON}|` + getCustomerLocation();
    };

    const getCustomerLocation = () => {
        const customerLocationCoordinates = toLatLng(customerLocation);
        if (!customerLocationCoordinates) return '';
        return `${customerLocationCoordinates.lat},${customerLocationCoordinates.lng}`;
    };

    const getRestaurantMapMarkers = () => {
        return `size:mid|icon:${RESTAURANT_PIN_ICON}|` + getRestaurantLocation();
    };

    const getRestaurantLocation = () => {
        const restaurantLocationCoordinates = toLatLng(restaurantLocation);
        if (!restaurantLocationCoordinates) return '';
        return `${restaurantLocationCoordinates.lat},${restaurantLocationCoordinates.lng}`;
    };

    if (!isLoaded || loadError) return <GoogleMapsCheckoutEmptyState customerLocation={customerLocation} />;

    return (
        <div className={classes.container}>
            <img className={classes.image} src={formatUrlMap()} />
        </div>
    );
}

const CUSTOMER_PIN_ICON = 'https://images.letseat.mx/b98c0288d1afc02a450f6ddc7c660209.png';
const RESTAURANT_PIN_ICON = 'https://images.letseat.mx/877ea66ba44cc1f788ecb95ec98e11ea.png';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '40vh',
        top: 0,
        [theme.breakpoints.up('sm')]: {
            height: 250,
        },
        [theme.breakpoints.down('xs')]: {
            position: 'fixed',
        },
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
}));
