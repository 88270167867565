/**
 * @prettier
 */
import type { TimeZoneName } from 'src/types/Id';

export const TimeZones = Object.freeze({
    AMERICA_MONTERREY: 'America/Monterrey' as TimeZoneName,
    EUROPE_STOCKHOLM: 'Europe/Stockholm' as TimeZoneName,
    ETC_UTC: 'Etc/UTC' as TimeZoneName,
});

export type TimeZone = (typeof TimeZones)[keyof typeof TimeZones];
