/**
 * @prettier
 */
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';

export function createUserAddedValidPromoCodeLogEvent(params: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_ADDED_VALID_PROMO_CODE,
        message: `Promo code: ${params.promoCode}`,
        data: params,
    });
}

type Params = {
    promoCode: string;
};
