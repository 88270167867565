/**
 * @prettier
 */
import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomerId, EmailAddress } from 'src/types/Id';

export async function setEmailApi(request: SetEmailApiRequest): ApiSauceResponse<void> {
    return pidedirectoApiMethod('customer/setEmailApi', request);
}

export type SetEmailApiRequest = {
    customerId: CustomerId;
    email: EmailAddress;
    urlSubdomain?: string;
};
