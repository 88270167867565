/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';

export function roundDigits(number: string | number | BigNumber | void, digits?: number): string {
    if (!number) return '0';
    return BigNumber(number)
        .decimalPlaces(digits || 2, BigNumber.ROUND_DOWN)
        .toString();
}
