/**
 * @prettier
 */
import type { OrderVm } from 'src/api/pidedirecto/types/OrderVm';
import { pidedirectoApiMethod } from 'src/api/pidedirectoApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentId } from 'src/types/Id';

export async function markPaymentAsPaidAndVerifiedWithStripe3dsVerificationApi(request: MarkOrderAsPayedAndVerifiedWithStripe3dsVerificationApiRequest): ApiSauceResponse<OrderVm> {
    return pidedirectoApiMethod('order/markPaymentAsPaidAndVerifiedWithStripe3dsVerificationApi', request);
}

export type MarkOrderAsPayedAndVerifiedWithStripe3dsVerificationApiRequest = {
    paymentId: PaymentId;
};
