/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import type { MenuCategoryVm, MenuItemVm } from 'src/api/pidedirecto/getAppContextApi';
import type { CartItemVm } from 'src/types/CartItemVm';

export function checkCategoryLimit(quantity: number, cartItems: Array<CartItemVm>, menuItem: MenuItemVm | CartItemVm, categories: Array<MenuCategoryVm>): boolean {
    const category = categories.find((category) => category.menuCategoryId === menuItem?.menuCategoryId);

    if (!category) return false;
    const cartItemsFromCategory = cartItems.filter((item) => item.menuCategoryId === category.menuCategoryId);
    if (!cartItemsFromCategory) {
        if (!category.orderItemsMaximumQuantity) return false;
        if (BigNumber(quantity).isGreaterThan(parseInt(category.orderItemsMaximumQuantity))) {
            return true;
        }
    }
    const cartItemsQuantity = cartItemsFromCategory.reduce((total, cartItem) => total + cartItem.quantity, 0);

    if (!category.orderItemsMaximumQuantity) return false;
    const isBiggerThanLimitCategory = BigNumber(quantity + cartItemsQuantity).isGreaterThan(parseInt(category.orderItemsMaximumQuantity));

    return isBiggerThanLimitCategory;
}
