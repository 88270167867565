/**
 * @prettier
 */
const rfcRegExp = /^[A-Za-z0-9<>Ññ&]{12,13}$/;
export function isRfc(value?: string | null): boolean {
    if (!value) {
        return false;
    }
    return rfcRegExp.test(value);
}
