/**
 * @prettier
 */
import { GiftEmitters } from 'src/constants/GiftEmitter';
import type { GiftVm } from 'src/types/GiftVm';

export function giftsByPaymentOrder(prev: GiftVm, next: GiftVm): number {
    if (prev.giftEmitter === GiftEmitters.COMPANY && next.giftEmitter !== GiftEmitters.COMPANY) {
        return -1;
    }
    if (next.giftEmitter === GiftEmitters.COMPANY && prev.giftEmitter !== GiftEmitters.COMPANY) {
        return 1;
    }
    if (prev.validTo === next.validTo) {
        return 0;
    }
    if (!next.validTo) {
        return -1;
    }
    if (!prev.validTo) {
        return 1;
    }
    return prev.validTo < next.validTo ? -1 : 1;
}
