/**
 * @prettier
 */
export const Pages = Object.freeze({
    ORDER_PAGE: 'ORDER_PAGE',
    INVOICE_PAGE: 'INVOICE_PAGE',
    SURVEY_PAGE: 'SURVEY_PAGE',
    PAYMENT_LINK_PAGE: 'PAYMENT_LINK_PAGE',
    MENU_PAGE: 'MENU_PAGE',
    RESTAURANT_CHAIN_PAGE: 'RESTAURANT_CHAIN_PAGE',
});

export type Page = (typeof Pages)[keyof typeof Pages];
