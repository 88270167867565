/**
 * @prettier
 */
import { isString } from 'src/utils/string/isString';

export function upperCaseFirst<T extends string | null | undefined>(value: T): T {
    if (value === undefined || value === null) {
        return value;
    }
    if (!isString(value)) {
        return value;
    }
    return (value.charAt(0).toUpperCase() + value.slice(1)) as any;
}
