/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment/moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { createRedeemablePaymentApi } from 'src/api/pidedirecto/payment/createRedeemablePaymentApi';
import { Dialog } from 'src/components/Dialog';
import { LoadingContentProgress } from 'src/components/LoadingContentProgress';
import { DiscountType } from 'src/constants/DiscountType';
import { MenuItemType } from 'src/constants/MenuItemType';
import { OrderTypes } from 'src/constants/OrderType';
import { PromoType } from 'src/constants/PromoType';
import { RedeemablePaymentTypes } from 'src/constants/RedeemablePaymentType';
import { UnitOfMeasurement } from 'src/constants/UnitOfMeasurement';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import type { PaymentId } from 'src/types/Id';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { toMinutes } from 'src/utils/duration/toMinutes';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { WebSocketEvents } from 'src/utils/webSocketEvent/WebSocketEvents';

export function PlexoPaymentTransferDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const plexoLoads = useRef(1);
    const plexoFormRef = useRef<HTMLIFrameElement | null>(null);

    const [loading, setLoading] = useState(true);
    const [plexoPaymentTransferUrl, setPlexoPaymentTransferUrl] = useState('');
    const [currentPaymentId, setCurrentPaymentId] = useState<PaymentId | undefined>(undefined);

    const customerId = useSelector((state) => state.app.customerId);
    const orderType = useSelector((state) => state.app.orderType);
    const address = useSelector((state) => state.app.address);
    const restaurant = useSelector((state) => state.app.restaurant);
    const deliveryEstimate = useSelector((state) => state.app.deliveryEstimate);
    const cartItems = useSelector((state) => state.app.cartItems);
    const pickupTimeType = useSelector((state) => state.app.pickupTimeType);
    const pickupTime = useSelector((state) => state.app.pickupTime);
    const payment = useSelector((state) => state.app.payment);
    const promoCode = useSelector((state) => state.app.promoCode);
    const mandatoryCashAmount = useSelector((state) => state.app.mandatoryCashAmount);
    const useLetsEatCredits = useSelector((state) => state.app.useLetsEatCredits);

    const handleDeliveryEstimate = isDeliveryOrder(orderType) && address?.location;

    useEffect(() => {
        const plexoTransferPaymentWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.REDEEMABLE_PAYMENT_PAID, handlePlexoTransferPayment);
        return () => {
            plexoTransferPaymentWebSocketEvent.remove();
        };
    }, [onSuccess, currentPaymentId]);

    useEffect(() => {
        if (!open) plexoLoads.current = 1;
        return () => {
            plexoLoads.current = 1;
        };
    }, [open]);

    useEffect(() => {
        if (open) {
            loadPlexoPaymentTransferUrl();
        } else {
            setPlexoPaymentTransferUrl('');
            setCurrentPaymentId(undefined);
        }
        return () => {
            setPlexoPaymentTransferUrl('');
            setCurrentPaymentId(undefined);
        };
    }, [open]);

    const handlePlexoTransferPayment = (event: WebSocketEvent<{ paymentId: PaymentId }>) => {
        console.log('event = ', event);
        console.log('currentPaymentId = ', currentPaymentId);
        if (event.data?.paymentId !== currentPaymentId) return;
        onSuccess?.({ paymentId: event.data?.paymentId });
    };

    const loadPlexoPaymentTransferUrl = async () => {
        setLoading(true);

        const response = await createRedeemablePaymentApi({
            ...buildRequest(),
            redeemablePaymentType: RedeemablePaymentTypes.PLEXO_TRANSFER,
        });

        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setCurrentPaymentId(response.data.paymentId);
        setPlexoPaymentTransferUrl(response.data.plexoTransferPaymentUrl ?? '');
    };

    const buildRequest = () => {
        return {
            restaurantId: restaurant.restaurantId,
            customerId,
            deliveryEstimateId: handleDeliveryEstimate ? deliveryEstimate.deliveryEstimateId : undefined,
            orderType,
            mandatoryCashAmount,
            pickupTimeType: [OrderTypes.SELF_SERVICE_ORDER, OrderTypes.TAKE_AWAY_ORDER, OrderTypes.PICKUP_STATION_ORDER, OrderTypes.DELIVERY_ORDER].includes(orderType) ? pickupTimeType : undefined,
            pickupTime: getPickupTime(),
            orderItems: cartItems.map((cartItem) => {
                return {
                    menuItemId: cartItem.menuItemId,
                    menuItemType: cartItem.menuItemType as MenuItemType,
                    name: cartItem.name,
                    unitPrice: cartItem.unitPrice,
                    promoUnitPrice: cartItem.promoUnitPrice,
                    promoText: cartItem.promoText,
                    imageUrl: cartItem.imageUrl,
                    modifierGroups: cartItem.modifierGroups,
                    isSoldByWeight: cartItem.isSoldByWeight,
                    salesUnit: cartItem.salesUnit as UnitOfMeasurement,
                    note: cartItem.note,
                    quantity: cartItem.quantity,
                    pickupTime: cartItem.pickupTime,
                };
            }),
            promoCode: getPromoCode(),
            useLetsEatCredits: useLetsEatCredits,
        };
    };

    const getPromoCode = () => {
        if (!payment.promoCodeDiscount && !payment.promoCodeCredits) return;
        return {
            promoCodeId: promoCode.promoCodeId,
            code: promoCode.code,
            promoType: promoCode.promoType as PromoType,
            discount: promoCode.discount,
            discountType: promoCode.discountType as DiscountType,
            endsAt: promoCode.endsAt,
        };
    };

    const getPickupTime = () => {
        const isRightOrderType = [OrderTypes.SELF_SERVICE_ORDER, OrderTypes.TAKE_AWAY_ORDER, OrderTypes.PICKUP_STATION_ORDER, OrderTypes.DELIVERY_ORDER, OrderTypes.ROOM_SERVICE_ORDER].includes(
            orderType,
        );
        if (!isRightOrderType) return;

        if (isDeliveryOrder(orderType) || !address?.location) {
            return moment(pickupTime) // pickupTime here is actually delivery time for delivery orders
                .subtract(toMinutes(deliveryEstimate?.drivingDuration), 'minutes')
                .toDate();
        }

        return moment(pickupTime).toDate();
    };

    const handleLoadPlexoForm = () => {
        if (!hasPlexoFormFinished()) return;
        setLoading(true);
    };

    const hasPlexoFormFinished = () => {
        try {
            const plexoFormUrl = plexoFormRef.current?.contentWindow?.location.href ?? '';
            return plexoFormUrl.includes(window.location.hostname);
        } catch (e) {
            return false;
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <div className={classes.container}>
                {loading && <LoadingContentProgress loading={loading} />}
                {!loading && <iframe src={plexoPaymentTransferUrl} className={classes.plexoForm} onLoad={handleLoadPlexoForm} ref={plexoFormRef} />}
            </div>
        </Dialog>
    );
}

const PlexoFormLoads = 8;

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '70vh',
        '& svg': {
            color: `${theme.palette.primary.main} !important`,
        },
    },
    plexoForm: {
        width: '100%',
        height: '100%',
        border: 0,
        minHeight: '70vh',
    },
}));

type Props = {
    open: boolean;
    onClose: Function;
    onSuccess: Function;
};
