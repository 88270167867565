/**
 * @prettier
 */
export function duplicates<T>(prev: Array<T>, next?: T): Array<T> {
    if (!next) {
        return prev;
    }
    if (prev.includes(next)) {
        return prev;
    }
    return [...prev, next]; // NOTE: prev.concat(next) does not work well on older devices (e.g. iPhone 5 iOS 10.3.1)
}
