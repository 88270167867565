/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Form } from 'src/components/form/Form';
import { FormPrefixPhoneNumberField } from 'src/components/form/FormPrefixPhoneNumberField';
import { Text } from 'src/components/Text';
import { AuthenticationTypes } from 'src/constants/AuthenticationType';
import { AwsFacade } from 'src/facade/aws/AwsFacade';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { AppTheme } from 'src/styles/AppTheme';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function SignUpDialog({ onSuccess }: Props): React.ReactElement {
    const classes = useStyles();

    const form = useForm();

    const [error, setError] = useState<{ errorType: string; message: string }>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const open = useSelector((state) => state.app.signUpDialog.open);
    const restaurantId = useSelector((state) => state.app.restaurant?.restaurantId);

    const closeSignUpDialog = useAction(actions.closeSignUpDialog);
    const openSignInDialog = useAction(actions.openSignInDialog);
    const openEnterVerificationCodeDialog = useAction(actions.openEnterVerificationCodeDialog);

    const formMobileNumber = useWatch({ name: 'mobileNumber', control: form.control });

    useEffect(() => {
        if (open) setError(undefined);
    }, [open]);

    const handleSignInWithCode = async (form: { mobileNumber: string }) => {
        setIsSubmitting(true);
        const response = await AwsFacade.requestSignUpSignIn({ username: form.mobileNumber, authenticationType: AuthenticationTypes.SMS, restaurantId });
        setIsSubmitting(false);
        if (response.error) {
            setError(response.error);
            return;
        }
        closeSignUpDialog();
        openEnterVerificationCodeDialog({
            mobileNumber: form.mobileNumber,
            authenticationType: AuthenticationTypes.SMS,
            isSignUp: true,
            onSuccess: onSuccess,
        });
    };

    const handleSignIn = () => {
        closeSignUpDialog();
        openSignInDialog();
    };

    return (
        <Dialog classes={{ dialog: classes.dialog, title: classes.title }} open={open} loading={isSubmitting} title={translate('Create an account')} onClose={closeSignUpDialog}>
            <Form form={form} onSubmit={handleSignInWithCode} id={'form'}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Text style={{ textAlign: 'center' }}>{translate('Enter your phone number to begin registration and continue at your order')}</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <FormPrefixPhoneNumberField
                            label={translate('Phone Number')}
                            name={'mobileNumber'}
                            disabled={isSubmitting}
                            helperText={translate('Make sure to select the flag of your country')}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {!!error && <Text>{translate(error.message)}</Text>}
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button type={'submit'} classes={{ button: classes.button }} disabled={isSubmitting || !formMobileNumber}>
                        {translate('Send SMS')}
                    </Button>
                    <div className={classes.text}>
                        {translate('Do you already have an account?')}
                        <Button onClick={handleSignIn} variant={'text'} classes={{ button: classes.signInButton }}>
                            {translate('Sign in')}
                        </Button>
                    </div>
                </DialogActions>
            </Form>
        </Dialog>
    );
}
const useStyles = makeStyles((theme) => ({
    dialog: {
        borderRadius: 8,
        borderBottomRightRadius: 18,
        borderBottomLeftRadius: 18,
        maxWidth: '90%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 420,
        },
        overflow: 'hidden',
    },
    text: {
        width: '100%',
        fontFamily: AppTheme.typography.regular,
        color: theme.palette.text.primary,
        textAlign: 'center',
    },
    signInButton: {
        textDecoration: 'underline',
        display: 'inline',
        width: 'fit-content !important',
        marginLeft: 5,
        '&:disabled': {
            backgroundColor: 'transparent',
        },
    },
    button: {
        flexGrow: 1,
    },
    title: {
        color: theme.palette.text.primary,
    },
}));

type Props = {
    onSuccess?: Function;
};
