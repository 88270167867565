/**
 * @prettier
 */

export const WebSocketEventTypes = Object.freeze({
    HEARTBEAT: 'HEARTBEAT',
    SUBSCRIBE_TO_RESTAURANT_EVENTS: 'SUBSCRIBE_TO_RESTAURANT_EVENTS',
    REDEEMABLE_PAYMENT_PAID: 'REDEEMABLE_PAYMENT_PAID',
    PLEXO_CARD_ADDED: 'PLEXO_CARD_ADDED',
});

export type WebSocketEventType = (typeof WebSocketEventTypes)[keyof typeof WebSocketEventTypes];
