/**
 * @prettier
 */
export function datesAscending(a?: string | Date, b?: string | Date): -1 | 0 | 1 {
    if ((a === undefined || a === null) && (b === undefined || b === null)) {
        return 0;
    }
    if (a === undefined || a === null) {
        return 1;
    }
    if (b === undefined || b === null) {
        return -1;
    }

    if (a < b) {
        return -1;
    } else if (b < a) {
        return 1;
    } else {
        return 0;
    }
}
