/**
 * @prettier
 */
export const GiftEmitters = Object.freeze({
    PROMO_CODE: 'PROMO_CODE',
    COMPANY: 'COMPANY',
    LETS_EAT: 'LETS_EAT',
});

export type GiftEmitter = (typeof GiftEmitters)[keyof typeof GiftEmitters];
