/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

export function Footer({ children }: Props): React.ReactElement {
    const classes = useStyles();
    return <footer className={classes.footerContainer}>{children}</footer>;
}

const useStyles = makeStyles((theme) => ({
    footerContainer: {
        display: 'flex',
        fontFamily: theme.typography.regular,
        fontSize: 12,
        color: '#4F586E',
        justifyContent: 'center',
        alignItems: 'center',
        borderTop: '1px solid #E6E8EC',
        padding: '16px 0',
        gap: 4,
        marginTop: 12,
    },
}));

type Props = {
    children: React.ReactNode;
};
