/**
 * @prettier
 */
import { actions } from 'src/reducers';
import type { CartItemVm } from 'src/types/CartItemVm';
import { createCartItemKey } from 'src/utils/cart/createCartItemKey';
import { deepClone } from 'src/utils/object/deepClone';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function useAddItem(): { addItem: AddItemFunction } {
    const cartItems = useSelector((state) => state.app.cartItems);
    const cartItemsByMenuItem = useSelector((state) => state.app.cartItemsByMenuItem);

    const setCartItems = useAction(actions.setCartItems);

    const addItem = (item: CartItemVm): void => {
        const cartItem = { ...item, key: createCartItemKey(item) } as const;
        const currentItems = deepClone(cartItems);
        const currentCartItemsByMenuItem = deepClone(cartItemsByMenuItem);

        const existingCartItem = currentItems.find((current) => current.key === cartItem.key);
        if (existingCartItem) {
            existingCartItem.quantity = existingCartItem.quantity + cartItem.quantity;
        }

        if (!existingCartItem) {
            currentItems.push(cartItem);
        }

        if (!currentCartItemsByMenuItem[cartItem.menuItemId]) {
            currentCartItemsByMenuItem[cartItem.menuItemId] = [];
        }

        currentCartItemsByMenuItem[cartItem.menuItemId]?.push(cartItem);

        setCartItems({ cartItems: currentItems, cartItemsByMenuItem: currentCartItemsByMenuItem });
    };

    return { addItem };
}

type AddItemFunction = (item: CartItemVm) => void;
