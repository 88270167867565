/**
 * @prettier
 */
export const PositiveSurveyAnswers = Object.freeze({
    PUNCTUALITY: 'PUNCTUALITY',
    APPLICATION: 'APPLICATION',
    FOOD: 'FOOD',
    PRESENTATION: 'PRESENTATION',
    SERVICE: 'SERVICE',
});

export type PositiveSurveyAnswer = (typeof PositiveSurveyAnswers)[keyof typeof PositiveSurveyAnswers];
