/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { useStripe } from '@stripe/react-stripe-js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { isKnownStripeError } from 'src/components/stripe/isKnownStripeError';
import { stripePaymentSucceeded } from 'src/components/stripe/stripePaymentSucceeded';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function CheckoutFormStripe(): React.ReactElement {
    const classes = useStyles();
    const stripe = useStripe();

    const [message, setMessage] = useState<string>();

    const stripe3dsClientSecret = useSelector((state) => state.app.stripe3dsClientSecret);
    const paymentId = useSelector((state) => state.app.paymentId);
    const onAuthorized = useSelector((state) => state.app.stripe3dSecureDialog.onAuthorized);
    const onUnauthorized = useSelector((state) => state.app.stripe3dSecureDialog.onUnauthorized);

    useEffect(() => {
        if (!stripe) return;
        if (!stripe3dsClientSecret) return;
        handleSubmit();
    }, [stripe]);

    const handleSubmit = async () => {
        if (!stripe) return;

        setMessage(translate('Processing payment'));
        const { paymentIntent, error } = await stripe.confirmCardPayment(stripe3dsClientSecret);
        if (paymentIntent?.status === 'succeeded') await stripePaymentSucceeded({ setMessage, paymentId, onAuthorized, onUnauthorized });

        if (isKnownStripeError(error?.type)) {
            onUnauthorized?.();
            return setMessage(error?.message);
        }
        return onUnauthorized?.();
    };

    return (
        <div id='payment-message'>
            <p className={classes.statusText}>{message}</p>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    statusText: {
        fontWeight: 400,
        fontSize: 13,
        lineHeight: '19px',
        textAlign: 'center',
        margin: 2,
    },
}));
