/**
 * @prettier
 */
import moment from 'moment-timezone';
import type { TimeZone } from 'src/constants/TimeZone';

export function localDateToMoment(date: Date, timeZone: TimeZone): moment.Moment {
    return moment.tz(
        `${date.getFullYear()}-${padTwoZeros(date.getMonth() + 1)}-${padTwoZeros(date.getDate())}T${padTwoZeros(date.getHours())}:${padTwoZeros(date.getMinutes())}:${padTwoZeros(
            date.getSeconds(),
        )}.${padThreeZeros(date.getMilliseconds())}`,
        timeZone,
    );
}

function padTwoZeros(value: number): string {
    return value.toString().padStart(2, '0');
}

function padThreeZeros(value: number): string {
    return value.toString().padStart(3, '0');
}
