/**
 * @prettier
 */
import { Languages } from 'src/constants/Language';
import { en } from 'src/i18n/en';
import { es } from 'src/i18n/es';
import { getLanguage } from 'src/i18n/language';

export function translate<T extends string | null | undefined>(text: T, data?: { [key: string]: string | number | undefined }): string {
    if (!text) {
        return '';
    }
    const language = getLanguage();
    if (language === Languages.ES) {
        return replaceData(es[text] ?? text, data);
    }
    return replaceData(en[text] ?? text, data);
}

function replaceData(
    translation: string,
    data?: {
        [key: string]: string | number | undefined;
    },
): string {
    if (!data) {
        return translation;
    }
    return Object.entries(data).reduce((result, entry) => {
        const [key, value]: [string, string] = entry as any;
        return result.replace(new RegExp(`@${key}`, 'g'), value ?? '');
    }, translation);
}
