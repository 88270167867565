/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import type { OrderItemVm } from 'src/api/pidedirecto/types/OrderVm';
import type { CartItemVm } from 'src/types/CartItemVm';
import { calculateOrderItemProductDiscountUnitPrice } from 'src/utils/order/calculateOrderItemProductDiscountUnitPrice';

export function calculateOrderItemProductDiscountPrice(orderItem: OrderItemVm | CartItemVm): BigNumber {
    return calculateOrderItemProductDiscountUnitPrice(orderItem).multipliedBy(orderItem.quantity);
}
