/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { runAfterCheckAnimation } from 'src/components/BottomDialogCheckButton';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { Text } from 'src/components/Text';
import { AuthenticationType, AuthenticationTypes } from 'src/constants/AuthenticationType';
import { translate } from 'src/i18n/translate';
import { LetterIcon } from 'src/icons/LetterIcon';
import { WhatsAppIcon } from 'src/icons/WhatsAppIcon';
import { useSelector } from 'src/utils/react/useSelector';

export function SelectAuthenticationTypeDialog({ open, onClose, onSelectAuthenticationType }: Props): React.ReactElement {
    const classes = useStyles();

    const whatsAppAuthenticationEnabled = useSelector((state) => state.app.settings?.whatsAppAuthenticationEnabled);

    const handleSelectAuthentication = (authenticationType: AuthenticationType) => {
        runAfterCheckAnimation(() => {
            onClose();
            onSelectAuthenticationType(authenticationType);
        });
    };

    return (
        <Dialog open={open} onClose={onClose} title={translate('How do you prefer to confirm your user?')}>
            <Button onClick={() => handleSelectAuthentication(AuthenticationTypes.SMS)} text>
                <LetterIcon />
                <p>{translate('SMS')}</p>
            </Button>
            {whatsAppAuthenticationEnabled && (
                <Button onClick={() => handleSelectAuthentication(AuthenticationTypes.WHATSAPP)} text>
                    <WhatsAppIcon />
                    <p>{translate('WhatsApp')}</p>
                </Button>
            )}
            <Button onClick={() => handleSelectAuthentication(AuthenticationTypes.EMAIL)} text>
                <div>
                    <div className={classes.textIcon}>
                        <LetterIcon />
                        <p className={classes.text}>{translate('Email')}</p>
                    </div>
                    <Text size={'small'}>{translate('Email will be sent to the address you registered with the provided phone number')}</Text>
                </div>
            </Button>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    text: {
        margin: 0,
    },
    textIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

type Props = {
    open: boolean;
    onClose: (arg1?: any) => any;
    onSelectAuthenticationType: (authenticationType: AuthenticationType) => any;
};
