/**
 * @prettier
 */
import type { RestaurantVm } from 'src/api/pidedirecto/getAppContextApi';
import type { GeoJsonPointVm } from 'src/api/pidedirecto/types/GeoJsonPointVm';
import { distanceInKm } from 'src/utils/geojson/distanceInKm';

export function isRestaurantWithinDeliveryDistance(restaurant?: RestaurantVm, location?: GeoJsonPointVm): boolean {
    if (!restaurant) return false;
    const distance = distanceInKm(location, restaurant.location);
    if (distance === undefined) return false;
    return distance <= restaurant.deliveryRadiusInKm;
}
