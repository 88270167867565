/**
 * @prettier
 */
export const Apps = Object.freeze({
    LETSEATAPP: 'LETSEATAPP',
    PIDEDIRECTO: 'PIDEDIRECTO',
    LETSEATMANAGER: 'LETSEATMANAGER',
    LETSEATDRIVERAPP: 'LETSEATDRIVERAPP',
});

export type App = (typeof Apps)[keyof typeof Apps];
