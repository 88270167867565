/**
 * @prettier
 */
import { getInLocalStorage } from 'src/localStorage/utils/getInLocalStorage';
import type { DeviceId } from 'src/types/Id';

export const KEY_NAME = 'deviceId';

export function getDeviceIdInLocalStorage(): DeviceId | undefined {
    return getInLocalStorage(KEY_NAME);
}
